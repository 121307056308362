import Parse from 'parse/dist/parse.min.js';
import { handleParseError } from '../../../errorhandling';
import router from '../../../router/router';

let clientFileSubscription = undefined;
let clientRecordCategorySubscription = undefined;
let documentCreatorSubscription = undefined;
let commentSubscription = undefined;

let creatorIds = undefined;
let categoryIds = undefined;
let recordIds = undefined;

function createCategorySubscription(store, query) {
	query.subscribe().then((subscription) => {
		
		clientRecordCategorySubscription = subscription;

		subscription.on('create', object => {
			
			store.dispatch('clientRecordsCategories/insert', object)
		});
		subscription.on('update', object => {
		
			store.dispatch('clientRecordsCategories/replace', object)
		});
		subscription.on('enter', object => {
			
			store.dispatch('clientRecordsCategories/insert', object)
		});
		subscription.on('leave', object => {
			
			store.dispatch('clientRecordsCategories/delete', object)
		});
		subscription.on('delete', object => {
			
			store.dispatch('clientRecordsCategories/delete', object)
		});
		subscription.on('close', () => {
			
		});
		subscription.on('error', error => {
			
		})
	})
}

function createRecordCommentSubscription(store, commentQuery) {
	commentQuery.subscribe().then((subscription) => {
	
		commentSubscription = subscription;

		subscription.on('create', object => {
			
			store.dispatch('recordcomments/insert', object)
		});
		subscription.on('update', object => {
			
			store.dispatch('recordcomments/replace', object)
		});
		subscription.on('enter', object => {
			
			store.dispatch('recordcomments/insert', object)
		});
		subscription.on('leave', object => {
			
			store.dispatch('recordcomments/delete', object)
		});
		subscription.on('delete', object => {
			
			store.dispatch('recordcomments/delete', object)
		});
		subscription.on('close', () => {
			
		});
		subscription.on('error', error => {
			
		})
	})
}

function createCreatorSubscription(store, query) {
	query.subscribe().then((subscription) => {
		
		documentCreatorSubscription = subscription;

		subscription.on('create', object => {
			
			store.dispatch('documentCreators/insert', object)
		});
		subscription.on('update', object => {
			
			store.dispatch('documentCreators/replace', object)
		});
		subscription.on('enter', object => {
		
			store.dispatch('documentCreators/insert', object)
		});
		subscription.on('leave', object => {
			
			store.dispatch('documentCreators/delete', object)
		});
		subscription.on('delete', object => {
			
			store.dispatch('documentCreators/delete', object)
		});
		subscription.on('close', () => {
			
		});
		subscription.on('error', error => {
			
		})
	})
}

function getDocuments(store, recordIds, categoryIds, creatorIds) {
	let categoryQuery = new Parse.Query('ClientRecordCategory');
	categoryQuery.limit(1000000)
	categoryQuery.notEqualTo("deleted", true)
	categoryQuery.containedIn("objectId", categoryIds)
	categoryQuery.descending("createdAt")
		.find()
		.then((results) => {
			store.dispatch('clientRecordsCategories/set', results)
		})
		.catch((error) => {
			handleParseError(error);
			console.error(error);
		});
	createCategorySubscription(store, categoryQuery);

	let creatorQuery = new Parse.Query('DocumentCreator');
	creatorQuery.limit(1000000)
	creatorQuery.notEqualTo("deleted", true)
	creatorQuery.containedIn("objectId", creatorIds)
	creatorQuery.descending("createdAt")
		.find()
		.then((results) => {
			store.dispatch('documentCreators/set', results)
		})
		.catch((error) => {
			handleParseError(error);
			console.error(error);
		});
	createCreatorSubscription(store, creatorQuery);

	let commentQuery = new Parse.Query('RecordComment');
	commentQuery.limit(1000000)
	commentQuery.notEqualTo("deleted", true)
	commentQuery.ascending("createdAt")

	let entryPointers = recordIds.map(id => {
		return {
			__type: 'Pointer',
			className: 'ClientFile',
			objectId: id
		}
	})


	commentQuery.containedIn("parent", entryPointers)
		.find()
		.then((results) => {
			store.dispatch('recordcomments/set', results)
		})
		.catch((error) => {
			handleParseError(error);
		
		});

	createRecordCommentSubscription(store, commentQuery);
}

async function createSubscription(store, clientId) {

	let client_uuid = "";

	let clientQuery = new Parse.Query('Client');
	clientQuery.equalTo("objectId", clientId);
	clientQuery.notEqualTo("deleted", true);
	await clientQuery.find().then(response => client_uuid = response[0].attributes.uuid);

	let all_clients = "";

	let clientQuery2 = new Parse.Query('Client');
	clientQuery2.equalTo("uuid", client_uuid);
	clientQuery2.notEqualTo("deleted", true);
	await clientQuery2.find().then(response => all_clients = response);

	let clientFileQuery = new Parse.Query('ClientFile');
	clientFileQuery.limit(10000000000)
	clientFileQuery.include("category")
	clientFileQuery.include("creator")
	clientFileQuery.notEqualTo("deleted", true)
	clientFileQuery.descending("createdAt")
	clientFileQuery.containedIn("client", all_clients)

	store.dispatch("clientrecords/load", false)
	// var clientPointer = {
	// 	__type: 'Pointer',
	// 	className: 'Client',
	// 	objectId: clientId
	// }

	clientFileQuery
		.find()
		.then((results) => {
			store.dispatch("clientrecords/load", true)
			store.dispatch('clientrecords/set', results)
			creatorIds = results.map((res) => {
				return res.attributes.creator.id
			})
			categoryIds = results.filter(c => c.attributes.category).map((res) => {
				return res.attributes.category.id
			})
			recordIds = results.map((res) => {
				return res.id;
			});

			getDocuments(store, recordIds, categoryIds, creatorIds)

		})
		.catch((error) => {
			handleParseError(error);
		
		});

	clientFileQuery.subscribe().then((subscription) => {
		
		clientFileSubscription = subscription

		subscription.on('create', object => {
			
			store.dispatch('clientrecords/insert', object)

			categoryIds = [...categoryIds, object.attributes.category.id]
			creatorIds = [...creatorIds, object.attributes.creator.id]
			recordIds = [...recordIds, object.id]
			clientFileSubscription.unsubscribe();
			getDocuments(store, recordIds, categoryIds, creatorIds)
		});
		subscription.on('update', object => {
			
			store.dispatch('clientrecords/replace', object)
		});
		subscription.on('enter', object => {
			
			store.dispatch('clientrecords/insert', object)

			categoryIds = [...categoryIds, object.attributes.category.id]
			creatorIds = [...creatorIds, object.attributes.creator.id]
			recordIds = [...recordIds, object.id]
			clientFileSubscription.unsubscribe();
			getDocuments(store, recordIds, categoryIds, creatorIds)
		});
		subscription.on('leave', object => {
			
			store.dispatch('clientrecords/delete', object)
		});
		subscription.on('delete', object => {
			
			store.dispatch('clientrecords/delete', object)
		});
		subscription.on('close', () => {
		
		});
		subscription.on('error', error => {
			
		})
	})
}

// ClientFile subscription
export default function createClientRecordsSubscription() {
	return store => {
		store.subscribe(mutation => {
			if (mutation.type === 'clients/SET_FOCUSED_CLIENT_ID' && (router.currentRoute.name == "ClientRecord" || router.currentRoute.name == "ClientRecordDetail" || router.currentRoute.name == "Notifications")) {
				if (clientFileSubscription) {
					clientFileSubscription.unsubscribe();
					clientRecordCategorySubscription.unsubscribe();
					documentCreatorSubscription.unsubscribe();
				}
				if(mutation.payload)
				{
					createSubscription(store, mutation.payload)
				}
			}
		}),
		store.subscribe(mutation => {
			if (mutation.type === 'ADD_LAST_ROUTE' && (router.currentRoute.name == "ClientRecord" || router.currentRoute.name == "ClientRecordDetail" || router.currentRoute.name == "Notifications")) {
				if(store.state.clients.focusedClientId){
					if (clientFileSubscription) {
						clientFileSubscription.unsubscribe();
						clientRecordCategorySubscription.unsubscribe();
						documentCreatorSubscription.unsubscribe();
					}
					if(store.state.clients.focusedClientId)
					{
						createSubscription(store, store.state.clients.focusedClientId)
					}
				}	
			}
		})
	}
}