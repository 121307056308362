var SERVER_URL = process.env.VUE_APP_SERVER_URL;
var APP_ID = process.env.VUE_APP_APP_ID;
var APP_KEY = process.env.VUE_APP_APP_KEY;
var APP_LOGO = process.env.VUE_APP_LOGO;
var APP_LOGO_COPYRIGHT = process.env.VUE_APP_LOGO_COPYRIGHT;
var APP_IMPRINT = process.env.VUE_APP_IMPRINT;
var APP_DATA_PROTECTION = process.env.VUE_APP_DATA_PROTECTION;

var APP_LINK_BIRTHDAY = process.env.VUE_APP_LINK_BIRTHDAY;
var APP_LINK_REPORT = process.env.VUE_APP_LINK_REPORT;
var APP_LINK_BALANCE = process.env.VUE_APP_LINK_BALANCE;
var APP_LINK_SCHEDULE = process.env.VUE_APP_LINK_SCHEDULE;
var APP_LINK_HANDBOOK = process.env.VUE_APP_LINK_HANDBOOK;

var APP_USE_PROFILE = process.env.VUE_APP_USE_PROFILE;
var APP_USE_DIARY = process.env.VUE_APP_USE_DIARY;
var APP_USE_MEDICATION = process.env.VUE_APP_USE_MEDICATION
var APP_USE_NOTIFICATION = process.env.VUE_APP_USE_NOTIFICATION;
var APP_USE_RECORD = process.env.VUE_APP_USE_RECORD;
var APP_USE_CHART = process.env.VUE_APP_USE_CHART;
var APP_USE_GOAL = process.env.VUE_APP_USE_GOAL;

var APP_PRIVATE_DIARY_ENTRIES = process.env.VUE_APP_PRIVATE_DIARY_ENTRIES;

export { SERVER_URL, APP_ID, APP_KEY, APP_LOGO, APP_LOGO_COPYRIGHT, APP_IMPRINT, APP_DATA_PROTECTION, 
	APP_LINK_BIRTHDAY, APP_LINK_REPORT, APP_LINK_BALANCE, APP_LINK_SCHEDULE, APP_LINK_HANDBOOK, APP_PRIVATE_DIARY_ENTRIES,
	APP_USE_PROFILE, APP_USE_DIARY, APP_USE_MEDICATION, APP_USE_NOTIFICATION, APP_USE_RECORD, APP_USE_CHART, APP_USE_GOAL}