<template>
	<div class="scrollable is-flex is-flex-direction-column m-3">
		<h3 class="is-subtitle" v-if="!isMobile">Ziel anlegen</h3>
		<b-input maxlength="80" :placeholder=dataObject.attributes.title id="entryTitle" v-model="goalTitle"
			v-on:input="$v.goalTitle.$touch" autocomplete="off" type="search" :size="isMobile ? 'is-small' : ''"></b-input>
		<div class="tag is-danger is-light" v-if="!$v.goalTitle.required">
			Titel benötigt!
		</div>
		<h3 class="is-subtitle" v-if="!isMobile">Zielbild</h3>
		<div class="
									my-2
									is-flex is-flex-direction-column is-justify-content-center
							">
			<b-upload v-model="goalPicture" accept="image/*" drag-drop expanded>
				<div class="has-text-centered">
					<template v-if="goalPictureModified">
						<img class="image" :src="goalPictureModified" alt="Keine Vorschau verfügbar" />
					</template>
					<template v-else>
						<p>
							<b-icon icon="upload" size="is-medium"></b-icon>
						</p>
						Bild für das Ziel einfügen!
					</template>
				</div>
			</b-upload>
			<button v-if="goalPictureModified" class="delete is-medium mt-2" type="button"
				@click="removePicture()"></button>
		</div>
		<div class="tag is-danger is-light" v-if="!$v.goalPictureModified.required">
			Bild benötigt!
		</div>
		<h3 class="is-subtitle mb-3 mt-3" v-if="!isMobile && entryAttributes && entryAttributes.length > 0">Attribute
		</h3>
		<div class="is-flex is-flex-direction-row is-flex-wrap-wrap">
			<b-checkbox v-model="attributes" v-for="attribute in undeletedAttributes" :key="attribute.id"
				:native-value="attribute.id" class="m-2">
				{{ attribute.attributes.name }}
			</b-checkbox>
		</div>
		<div class="is-flex is-justify-content-center">
			<b-button class="mt-3 button" type="is-primary" @click="saveGoal" v-bind:loading="isLoading"
				v-bind:disabled="$v.$invalid" :size="isMobile ? 'is-small' : ''">Speichern
			</b-button>
		</div>
	</div>
</template>

<script>
/**
 * Module allowing the edit of a existing goal.
 * @displayName GoalEdit
 */

import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import Parse from "parse/dist/parse.min.js";
import { getModifiedImage } from "@/lib/imageFunctions.js";
import { v4 as uuid } from "uuid";

export default {
	name: "GoalEdit",
	props: {
        id: {
            type: String,
            default: "",
        },
    },
	validations: {
		goalTitle: { required },
		goalPictureModified: { required },
	},
	data() {
		return {
			goalTitle: "",
			isLoading: false,
			goalPicture: undefined,
			goalPictureModified: undefined,
			attributes: [],
		};
	},
	mounted() {
		this.dataObject.attributes.attributes.forEach(element => {
			this.attributes.push(element.id)
		});
		this.goalTitle = this.dataObject.attributes.title
		this.goalPictureModified = this.dataObject.attributes.picture._url

	},
	computed: {
		...mapGetters({
            goals: "goals/getGoals",
            goalsLoaded: "goals/areGoalsLoaded",
			focusedClient: "clients/getFocusedClient",
			entryAttributes: "attributes/getAttributes",
        }),
        dataObject() {
            if (this.goalsLoaded) {
                return this.goals.filter((goal) => {
                    return goal.id === this.id;
                })[0];
            }

            return undefined;
        },
		getSize() {
			return this.$store.getters.isMobile ? "is-small" : "";
		},
		isMobile() {
			return this.$store.getters.isMobile;
		},
		getGoalPictureURL() {
			if (this.goalPictureModified) {
				return URL.createObjectURL(this.goalPictureModified);
			}
			return undefined;
		},
		undeletedAttributes() {
			return this.entryAttributes.filter(e => !e.attributes.deleted)
		}
	},
	methods: {
		async saveGoal() {
			this.isLoading = true;
			let imageFile;
			/*
				Wenn bereits ein Bild aus der Datenbank vorhanden ist, liegt dieses bereits als Parse-File vor. 
			*/
			if (this.goalPictureModified.includes("https")) {
				imageFile = this.dataObject.attributes.picture
			} else {
				imageFile = new Parse.File(
					uuid(),
					{ base64: this.goalPictureModified },
					"image/jpeg"
				);
				await imageFile.save();
			}

			const goalParams = {
				goalId: this.dataObject.id,
				title: this.goalTitle,
				picture: imageFile,
				attributeIds: this.attributes,
			};

			await Parse.Cloud.run("editGoal", goalParams);
			this.isLoading = false;
			this.$router.push({ name: "GoalDetail" , params: { id: this.dataObject.id } });
		},
		setModifiedDropFiles(value) {
			this.dropFilesModified = value;
		},
		removePicture() {
			this.goalPicture = undefined;
			this.goalPictureModified = undefined;
		},
	},
	watch: {
		goalPicture: {
			handler: function (fileArray) {
				if (fileArray) {
					this.goalPictureModified = undefined;
					if (fileArray.type.match(/image.*/)) {
						getModifiedImage(fileArray, 150)
							.then((response) => {
								this.goalPictureModified = response;
							})
							.catch(function (error) {
								console.error(error);
							});
					}
				}
			},
			deep: true,
		},
	},
};
</script>

<style lang="scss" scoped>
.scrollable {
	overflow: auto;
	height: 100%;
	width: 100%;
	padding-bottom: 1.5rem;
}

.image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
</style>
