import Parse from 'parse/dist/parse.min.js';
import { handleParseError } from '../../../errorhandling';
import router from '../../../router/router';

let localSubscription = undefined;

function createSubscription(store, clientId) {
	//TODO: Das kann man sicher noch schöner machen, so dass hier vllt. sogar nur eine Number zurückkommt. Bzw Client - Number?
	let dataQuery = new Parse.Query('MedicationLog');
	dataQuery.include("client", "instantMedication", "instantMedication.medication", "instantMedication.medication.medicationUnit", "medication", "medication.contactUser", "medication.contactUser.dependency", "medication.medication", "medication.medication.medicationUnit", "givingUser");
	dataQuery.descending("date");
	dataQuery.equalTo("state", 0);

	dataQuery.find()
		.then((results) => {
			store.dispatch('medicationLogNotifications/set', results)
		})
		.catch((error) => {
			handleParseError(error);
		
		});
	
	dataQuery.subscribe().then((subscription) => {
		
		localSubscription = subscription;

		subscription.on('create', object => {
			store.dispatch('medicationLogNotifications/insert', object)
		});
		subscription.on('update', object => {
			store.dispatch('medicationLogNotifications/replace', object)
		});
		subscription.on('enter', object => {
			store.dispatch('medicationLogNotifications/insert', object)
		});
		subscription.on('leave', object => {
			store.dispatch('medicationLogNotifications/delete', object)
		});
		subscription.on('delete', object => {
			store.dispatch('medicationLogNotifications/delete', object)
		});
		subscription.on('close', () => {
			
		});
		subscription.on('error', error => {
			
		})
	})
}

export default function createMedicationLogNotificationSubscription() {
	return store => {
		//Erzeuge einmalig eine neue Subscription für den Datensatz. 
		//Fall "Bereits in der Anwendung"
		if(store.state.currentUser && !localSubscription) {
			createSubscription(store)
		}
		//Erzeuge einmalig eine neue Subscription für den Datensatz. 
		//Fall "Normaler Login"
		store.subscribe(mutation => {
			if (mutation.type === 'SET_CURRENT_USER') {
				if (!localSubscription) {
					createSubscription(store)
				}
			}
		})
	}
}
