<template>
	<div>
		<template v-if="focusedEntry.attributes.attachment">
			<hr class="mt-2 mb-3" />
			<h2 class="">Bestehende Anhänge</h2>
			<div v-if="entryRequest.pictures" class="columns imagesContainer" style="flex-wrap: wrap">
				<div v-for="(image, i) in entryRequest.pictures.map(
					(pic) => pic._url
				)" class="column is-one-third imageContainer" :key="i">
					<img class="image" :src="image" />
					<b-icon class="imageDelete" @click.native="removePicture(i)" icon="close" />
				</div>
			</div>
			<div v-if="entryRequest.otherFiles">
				<hr style="height: 1px; background-color: black" />
				<div v-for="(file, i) in entryRequest.otherFiles" class="column is-one-third" :key="i">
					<div class="fileItem">
						<b-icon icon="file-pdf-box" size="is-medium" />
						<a class="text-base" tag="a" :href="file.attributes.file._url" target="_blank" style="padding-top: 4px; margin-left: 6px">
							{{ file.attributes.fileTitle }}
						</a>
						<b-icon class="fileDelete" @click.native="removeFile(i)" icon="close" />
					</div>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
/**
 * Component for the editting of attachements on an entry.
 * @displayName EditAttachements
 */

export default {
	name: "EditAttachements",
	props: ["focusedEntry", "entryRequest", "removePicture", "removeFile"],
};
</script>

<style scoped>
.imageContainer {
	position: relative !important;
	margin-top: 0.25rem !important;
}

.columns {
	display: flex;
}

.fileItem {
	flex-wrap: wrap !important;
	display: flex !important;
	align-items: flex-start !important;
	flex-direction: row !important;
	align-content: flex-start !important;
	justify-content: flex-start !important;
}

.fileItem span {
	width: auto !important;
}

.imageDelete {
	background-color: rgb(255 255 255) !important;
	border-radius: 0.375rem !important;
	position: absolute !important;
	top: 0.5rem !important;
	right: 0.5rem !important;
	cursor: pointer !important;
	width: auto !important;
}

.fileDelete {
	border-radius: 0.375rem !important;
	cursor: pointer !important;
	width: auto !important;
	padding-top: 10px !important;
	margin-left: 10px !important;
}

@media (max-width: 769px) {
	.columns:not(:last-child) {
		margin-bottom: 0;
	}

	hr {
		margin: 0.5rem;
	}

	.columns {
		display: flex;
		align-items: center;
		flex-direction: row;
		padding: 0px;
		margin: 0px;
	}

	.column {
		padding: 0;
	}
}

@media (max-width: 600px) {
	.column {
		padding: 0;
		margin: 0;
	}
}

@media (max-width: 450px) {
	.button.is-small:not(.is-rounded) {
		margin: 0;
	}
}
</style>
