<template>
	<b-field expanded :message="enableValidation ? (($v.selectedValue.$invalid) ? validationMessage : '') : ''"
		:type="enableValidation ? (($v.selectedValue.$invalid) ? 'is-danger' : 'is-success') : ''">
		<template #label>
			<div class="align-content-center" style="display: inline-flex">
				<p> {{ labelText }}</p>
			</div>
		</template>
		<b-autocomplete class="autocomplete_break" open-on-focus clearable v-model="inputString" :data="orderedFilteredDataArray" icon="magnify"
			:custom-formatter="option => (option?.attributes.number + ': ' + option?.attributes.name + ', ' + option?.attributes.date.toLocaleDateString('de-DE', {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
	}) + ', ' + getDoctorName(option?.attributes?.doctor))" @select="option => set(option)">

			<template slot-scope="props">
				<div class="media">
					<div class="media-content">
						{{ props?.option?.attributes.number + ': ' + props?.option?.attributes.name + ', ' +
		props?.option?.attributes.date.toLocaleDateString('de-DE', {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
		}) + ', ' + getDoctorName(props?.option?.attributes?.doctor) }}
					</div>
				</div>
			</template>

			<template #empty>Keine Ergebnisse gefunden.</template>
		</b-autocomplete>
	</b-field>
</template>

<script>
import { mapGetters } from "vuex";
import { required, requiredIf } from "vuelidate/lib/validators";

export default {
	name: "MedicationPrescriptionSearchableSelect",
	props: [
		"editObject",
		"select",
		"dataOptions",
		"enableValidation",
		"validationMessage",
		"labelText",
		"enableOrder"
	],
	data() {
		return {
			inputString: '',
			selectedValue: undefined
		}
	},
	validations: {
		selectedValue: {
			required: requiredIf(function (enableValidation) {
				return enableValidation
			}),
		},
	},
	mounted() { },
	computed: {
		...mapGetters({
			doctorArray: "doctors/getData",
		}),
		orderedFilteredDataArray() {
			if (this.enableOrder) {
				let sortedArray = this.filteredDataArray
				return sortedArray.sort((a, b) =>
					(a.attributes.number > b.attributes.number) ? 1
						: ((b.attributes.number > a.attributes.number) ? -1 : 0));
			}
			else {
				return this.filteredDataArray
			}
		},
		filteredDataArray() {
			let returnVal = this.dataOptions.filter((element) => {
				let searchString = element.attributes.number + " " + element.attributes.name.toLowerCase() + " " + element.attributes.date.toLocaleDateString('de-DE', {
					year: 'numeric',
					month: '2-digit',
					day: '2-digit',
				}) + (this.getDoctorName(element.attributes.doctor)).toLowerCase()
				return searchString.indexOf((this.inputString.toLowerCase())) >= 0
			})
			return returnVal
		}
	},
	methods: {
		getDoctorName(doctorObject) {
			let doctorValue = doctorObject;
			if (doctorValue && doctorValue.__type == 'Pointer') {
				doctorValue = this.doctorArray.find((element) => this.doctorValue.objectId == element.id)
			}
			return doctorObject.attributes.name
		},
		set(option) {
			if (option) {
				this.select(option.toPointer())
			}
			if (option == null) {
				this.selectedValue = undefined
			}
		}
	},
	watch: {
		editObject(newValue) {
			if (newValue) {
				this.selectedValue = this.orderedFilteredDataArray.find((element) => this.editObject.objectId == element.id)
				this.inputString = this.selectedValue.attributes.number + ": " + this.selectedValue.attributes.name + ', ' +
					this.selectedValue.attributes.date.toLocaleDateString('de-DE', {
						year: 'numeric',
						month: '2-digit',
						day: '2-digit',
					}) + ', ' + this.getDoctorName(this.selectedValue.attributes.doctor)
			}
		}
	}
}
</script>

<style lang="scss">

.autocomplete_break.autocomplete .dropdown-item, 
.autocomplete_break.autocomplete .dropdown .dropdown-menu .has-link a
{
	white-space: wrap;
}

.autocomplete_break.autocomplete .dropdown-item .media-content span
{
	display:inline;
}
</style>