<template>
    <div v-if="readers.length > 0" style="user-select: none">
        <h2 class="is-font-bold is-font-large">Leser</h2>
        <div v-for="(combined, index) in readersWithTimestamp" :key="index">
            <div
                class="
                    is-flex
                    is-flex-direction-row
                    is-align-items-center
                    is-justify-content-space-between
                    m-1
                "
            >
                <div
                    class="is-flex is-flex-direction-row is-align-items-center"
                >
                    <figure class="user_image image is-32x32 mr-3">
                        <img
                            class="is-rounded"
                            :src="getPicture(combined.reader)"
                            alt="Profilbild"
                        />
                    </figure>
                    {{
                        combined.reader.attributes.dependency.attributes
                            .first_name +
                        " " +
                        combined.reader.attributes.dependency.attributes
                            .last_name
                    }}
                </div>
                <div class="is-flex is-align-items-center commentTime">
                    {{
                        combined.timestamp.toLocaleDateString("de-DE", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                        }) +
                        " - " +
                        combined.timestamp.toLocaleTimeString("de-DE", {
                            hour: "2-digit",
                            minute: "2-digit",
                        })
                    }}
                </div>
            </div>
            <hr class="mb-1 mt-1" v-if="index < readers.length - 1" />
        </div>

        <!--/b-collapse-->
    </div>
</template>

<script>
/**
 * Component for the display of readers of an entry.
 * @displayName EntryReader
 */

import { getUserPicture } from "@/lib/imageFunctions";

export default {
    name: "EntryReader",
    props: {
        readers: {
            type: Array,
        },
        timestamps: {
            type: Array,
        },
    },
    created() {},
    updated() {},
    computed: {
        readersWithTimestamp() {
            let combinedList = [];
            for (var j = 0; j < this.readers.length; j++) {
                combinedList.push({
                    reader: this.readers[j],
                    timestamp: this.timestamps[j],
                });
            }
            combinedList.sort(function (a, b) {
                return a.timestamp > b.timestamp
                    ? -1
                    : a.timestamp == b.timestamp
                    ? 0
                    : 1;
            });
            return combinedList;
        },
    },
    methods: {
        getPicture(reader) {
            const pictureObject = {
                newProfilePicture: undefined,
                userPicture: reader.attributes.dependency.attributes
                    .profile_picture
                    ? reader.attributes.dependency.attributes.profile_picture
                          ._url
                    : undefined,
                userGender: reader.attributes.dependency.attributes.gender,
            };
            return getUserPicture(pictureObject);
        },
    },
};
</script>

<style lang="scss" scoped>
.user_image img {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.commentTime {
    font-feature-settings: "tnum";
}
</style>
