import Vue from 'vue';
import Vuex from "vuex";
import Parse from 'parse/dist/parse.min.js';

//Common Data
import clientSubscription from './plugins/common/clients';
import userSubscription from './plugins/common/users';
import userMetaSubscription from './plugins/common/usermetas';
import groupSubscription from './plugins/common/groups';
import notificationSubscription from "./plugins/common/notifications";

//Diary Data
import attributesSubscription from './plugins/diary/attributes';
import entryTypesSubscription from './plugins/diary/entrytypes';
import attributeConfigsSubscription from './plugins/diary/attributeconfigs';
import createEntrySubscription from './plugins/diary/entries';
import createEntryCommentSubscription from './plugins/diary/comments';
import createOptAttributeConfigSubscription from "./plugins/diary/optionalattributeconfigs";

//Client record Data
import createClientRecordsSubscription from './plugins/clientRecords/clientrecords';

//Goal Data
import createGoalSubscription from './plugins/goal/goals';
import createRatingSubscription from './plugins/goal/ratings';
import createRatingSummarySubscription from './plugins/goal/ratingsummary';

//Medication Data
import createMedicationEntriesSubscription from './plugins/medication/medicationEntries';
import createMedicationLogEntriesSubscription from './plugins/medication/medicationLogEntries';
import createMedicationSubscription from './plugins/medication/medicationMedication';
import createMedicationUnitSubscription from './plugins/medication/medicationUnit';
import createMedicationClassSubscription from './plugins/medication/medicationClass';
import createMedicationFormSubscription from './plugins/medication/medicationForm';
import createDoctorSubscription from './plugins/medication/doctors';
import createDoctorSpecializationSubscription from './plugins/medication/doctorSpecialization';
import createPrescriptionSubscription from './plugins/medication/prescriptions';
import createDiagnoseSubscription from './plugins/medication/diagnoses';
import createICD10CodeSubscription from './plugins/medication/icd_10_codes';
import createReportSubscription from './plugins/medication/medicationReport';
import createMedicationLogNotificationSubscription from './plugins/medication/medicationLogNotifications';

//Common Store
import weekDays from './modules/weekDays';
import versions from './modules/versions';
import pagination from './modules/pagination';

//Common Data Store
import clients from './modules/common/clients';
import users from './modules/common/users';
import usermetas from './modules/common/usermetas';
import notifications from "./modules/common/notifications";
import groups from './modules/common/groups'

//Diary Data Store
import attributes from './modules/diary/attributes';
import comments from './modules/diary/comments';
import entrylinks from './modules/diary/entrylinks';
import entrytypes from './modules/diary/entrytypes';
import attributeconfigs from './modules/diary/attributeconfigs';
import optionalattributeconfigs from "./modules/diary/optionalattributeconfigs"

//Client records Data Store
import recordcomments from './modules/clientRecords/recordcomments'
import clientrecords from "./modules/clientRecords/clientrecords"
import documentCreators from "./modules/clientRecords/documentcreator"
import clientRecordsCategories from "./modules/clientRecords/clientrecordcategory"

//Goal Data Store
import goals from "./modules/goal/goals"
import ratings from "./modules/goal/ratings"
import ratingsummary from './modules/goal/ratingsummary';

//Medication Data Store
import docfilter from './modules/medication/docfilter';
import medicationMedication from './modules/medication/medicationMedication';
import medicationClass from './modules/medication/medicationClass';
import medicationForm from './modules/medication/medicationForm';
import medicationUnit from './modules/medication/medicationUnit';
import medicationEntries from './modules/medication/medicationEntries';
import medicationLogEntries from './modules/medication/medicationLogEntries';
import doctors from './modules/medication/doctors';
import doctorSpecialization from './modules/medication/doctorSpecialization';
import prescriptions from './modules/medication/prescriptions';
import icd_10_codes from './modules/medication/icd_10_codes';
import diagnoses from './modules/medication/diagnoses'
import medicationReport from './modules/medication/medicationReport';
import medicationLogNotifications from './modules/medication/medicationLogNotifications';

import { MOBILE, MOBILE_SMALL } from "@/lib/constants";
import { initParse } from "@/lib/store/InitParse";

initParse()
// Vuex include
Vue.use(Vuex);

let store = new Vuex.Store({
	state: {
		focusedEntryId: undefined,
		focusedRecordId: undefined,
		currentUser: Parse.User.current(),
		searchQuery: undefined,
		entrySearchQuery: "",
		selectedClients: [],
		sidePanelOpen: true,
		hasNewNotifications: true,
		openedKeyBoard: false,
		deleteUserPicture: false,
		diary: true,
		notificationContactChildren: false,
		lastRoutes: [],
		windowWidth: window.innerWidth,
		chartData: undefined,
		ratingDay: 0,
		isLoading: false,
		chartMonths: 3,
		focusedEntry: undefined,
	},
	getters: {
		getChartMonths(state) {
			return state.chartMonths
		},
		getChartData(state) {
			return state.chartData
		},
		getEntrySearchQuery(state) {
			return state.entrySearchQuery;
		},
		isMobile(state) {
			return state.windowWidth < MOBILE
		},
		isMobileSmall(state) {
			return state.windowWidth < MOBILE_SMALL
		},
		ratingDay(state) {
			return state.ratingDay;
		},
		isLoading(state) {
			return state.isLoading;
		}
	},
	mutations: {
		SET_CHART_MONTHS(state, data) {
			state.chartMonths = data
		},
		SET_QUERY(state, data) {
			state.searchQuery = data;
		},
		SET_ENTRY_QUERY(state, data) {
			state.entrySearchQuery = data;
		},
		SET_QUERY_INPUT(state, data) {
			state.searchQueryInput = data;
		},
		SET_SELECTED_CLIENTS(state, data) {
			state.selectedClients = data;
		},
		SET_SIDE_PANEL_OPEN(state, data) {
			state.sidePanelOpen = data
		},
		SET_HAS_NOTIFICATIONS(state, data) {
			state.hasNewNotifications = data
		},
		SET_FOCUSED_ENTRY_ID(state, data) {
			state.focusedEntryId = data
		},
		SET_FOCUSED_RECORD_ID(state, data) {
			state.focusedRecordId = data
		},
		SET_CURRENT_USER(state, data) {
			state.currentUser = data
		},
		TOGGLE_OPENED_KEY_BOARD(state) {
			state.openedKeyBoard = !state.openedKeyBoard
		},
		CLOSE_OPENED_KEYBOARD(state) {
			state.openedKeyBoard = !state.openedKeyBoard
		},
		SET_DELETE_USER_PICTURE(state, data) {
			state.deleteUserPicture = data
		},
		SET_CONTACT_CHILDREN_NOTIFICATIONS(state, data) {
			state.notificationContactChildren = data
		},
		ADD_LAST_ROUTE(state, data) {
			state.lastRoutes.push(data)
		},
		SET_WINDOW_WIDTH(state, data) {
			state.windowWidth = data
		},
		SET_CHART_DETAILS(state, data) {
			state.chartData = data
		},
		SET_RATING_DAY(state, data) {
			state.ratingDay = data;
		},
		SET_IS_LOADING(state, data) {
			state.isLoading = data;
		},
		SET_FOCUSED_ENTRY(state, data) {
			state.focusedEntry = data;
		}
	},
	actions: {
		logoutUser({ commit, state }) {
			return new Promise((resolve, reject) => {
				Parse.User.logOut()
					.then((succ) => {
						commit('SET_CURRENT_USER', undefined)
						
						resolve(succ)
						location.reload();
					})
					.catch((err) => {
						reject(err)
					});
			})
		},
		loginUser({ commit, state }, { email, password }) {
			return new Promise((resolve, reject) => {

				Parse.User.logIn(email, password)
					.then((user) => {
						commit('SET_CURRENT_USER', Parse.User.current())
						resolve(user)
					})
					.catch((err) => {
						reject(err)
					});
			})
		},
		setSearchQuery({ commit }, query) {
			commit('SET_QUERY', query)
		},
		setEntrySearchQuery({ commit }, query) {
			commit('SET_ENTRY_QUERY', query)
		},
		setSelectedClients({ commit }, query) {
			commit('SET_SELECTED_CLIENTS', query)
		},
		setSidePanelOpen({ commit }, query) {
			commit("SET_SIDE_PANEL_OPEN", query)
		},
		setHasNotifications({ commit }, query) {
			commit("SET_HAS_NOTIFICATIONS", query)
		},
		setFocusedEntryId({ commit }, query) {
			commit("SET_FOCUSED_ENTRY_ID", query)
		},
		setFocusedEntry({ commit }, query) {
			commit("SET_FOCUSED_ENTRY", query)
		},
		setFocusedRecordId({ commit }, query) {
			commit("SET_FOCUSED_RECORD_ID", query)
		},
		toggleOpenedKeyboard({ commit }) {
			commit("TOGGLE_OPENED_KEY_BOARD")
		},
		closeOpenedKeyboard({ commit }) {
			commit("CLOSE_OPENED_KEYBOARD")
		},
		setDeleteUserPicture({ commit }, query) {
			commit("SET_DELETE_USER_PICTURE", query)
		},
		setContactChildrenNotification({ commit }, query) {
			commit("SET_CONTACT_CHILDREN_NOTIFICATIONS", query)
		},
		setWindowWidth({ commit }, query) {
			commit("SET_WINDOW_WIDTH", query)
		},
		setChartDetails({ commit }, query) {
			commit("SET_CHART_DETAILS", query)
		},
		setChartMonths({ commit }, query) {
			commit("SET_CHART_MONTHS", query)
		}
	},
	modules: {
		//common modules
		versions,
		weekDays,
		pagination,
		//common data modules
		clients,
		users,
		usermetas,
		notifications,
		groups,
		//diary data modules
		attributes,
		comments,
		entrytypes,
		attributeconfigs,
		entrylinks,
		optionalattributeconfigs,
		//client record data modules
		clientrecords,
		documentCreators,
		clientRecordsCategories,
		recordcomments,
		//goal data modules
		goals,
		ratings,
		ratingsummary,
		//medication data modules
		medicationMedication,
		medicationClass,
		medicationForm,
		medicationUnit,
		doctors,
		doctorSpecialization,
		prescriptions,
		icd_10_codes,
		diagnoses,
		medicationReport,
		medicationEntries,
		medicationLogEntries,
		docfilter,
		medicationLogNotifications,
	},
	plugins: [
		//common plugins
		notificationSubscription(),
		clientSubscription(),
		userSubscription(),
		userMetaSubscription(),
		groupSubscription(),
		//diary plugins
		createEntryCommentSubscription(),
		entryTypesSubscription(),
		attributesSubscription(),
		attributeConfigsSubscription(),
		createEntrySubscription(),
		createOptAttributeConfigSubscription(),
		//client record plugins
		createClientRecordsSubscription(),
		//goal plugins
		createGoalSubscription(),
		createRatingSubscription(),
		createRatingSummarySubscription(),
		//medication plugins
		createMedicationSubscription(),
		createMedicationUnitSubscription(),
		createMedicationClassSubscription(),
		createMedicationFormSubscription(),
		createDoctorSubscription(),
		createDoctorSpecializationSubscription(),
		createMedicationEntriesSubscription(),
		createMedicationLogEntriesSubscription(),
		createPrescriptionSubscription(),
		createDiagnoseSubscription(),
		createICD10CodeSubscription(),
		createReportSubscription(),
		createMedicationLogNotificationSubscription(),
	]
});

const unsubscribe = store.subscribe((mutation, state) => {
	if (mutation.type == "clients/DELETE") {
		if (mutation.payload.id == state.focusedClientId) {
			state.focusedClientId = undefined;
		}
	}
})

export default store;
