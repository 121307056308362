<template>
    <div
        class="scrollable p-3"
        v-if="clientRecords && getFocusedClientFile"
        ref="commentparentComponent"
    >
        <h1 class="is-title mb-0">
            {{ getFocusedClientFile.attributes.title }}
        </h1>
        <div>
            <div class="is-font-medium">
                {{ getFocusedClientFile.attributes.creator }}
                <time>
                    {{
                        getFocusedClientFile.createdAt.toLocaleDateString(
                            "de-DE",
                            {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                            }
                        ) +
                        " - " +
                        getFocusedClientFile.createdAt.toLocaleTimeString(
                            "de-DE"
                        )
                    }}
                </time>
            </div>
            <ColoredTag
				v-if="getFocusedClientFile.attributes.category"
                :tag-name="
                    getFocusedClientFile.attributes.category.attributes.name
                "
                :tag-color="
                    getFocusedClientFile.attributes.category.attributes.color
                "
            />
        </div>
        <b-button
            icon-left="arrow-top-right-thick"
            class="my-3"
            v-on:click="openDocument()"
            tag="a"
            :href="getFocusedClientFile.attributes.pdf._url"
            target="_blank"
            >Dokument öffnen</b-button
        >
        <record-comment
            ref="commentComponent"
            :parent="getFocusedClientFile"
        ></record-comment>
        <entry-reader
            :readers="
                getFocusedClientFile.attributes.readers
                    ? getFocusedClientFile.attributes.readers
                    : []
            "
            :timestamps="
                getFocusedClientFile.attributes.reader_timestamps
                    ? getFocusedClientFile.attributes.reader_timestamps
                    : []
            "
        ></entry-reader>
    </div>
</template>

<script>
/**
 * Module displaying the records associated with the focusedClient.
 * Records are represented with a h-ref to the pdf document.
 * @displayName ClientRecordDetail
 */

import { mapGetters } from "vuex";
import EntryReader from "@/components/helper/EntryReader.vue";
import RecordComment from "@/components/helper/RecordComment.vue";
import ColoredTag from "@/components/tags/ColoredTag";
import Parse from "parse/dist/parse.min"

export default {
    name: "ClientRecordDetail",
    props: {
        id: {
            type: String,
            default: "empty-id",
        },
    },
    data() {
        return {
            isLoading: false,
        };
    },
    mounted() {
		const filePointer = Parse.Object.extend("ClientFile").createWithoutData(this.$route.params.id)
		const query = new Parse.Query("Notification")
		query.equalTo("clientFile",filePointer)
		query.first().then(res => res && res.destroy())
	},
    updated() {},
    computed: {
        ...mapGetters({
            clientRecords: "clientrecords/getFocusedClientRecords",
        }),
        getFocusedClientFile() {
            return this.clientRecords.filter((rec) => rec.id === this.id)[0];
        },
    },
    methods: {
        openDocument() {
            if (
                this.getFocusedClientFile.attributes.readers &&
                this.getFocusedClientFile.attributes.readers.length > 0
            ) {
                let readerIds =
                    this.getFocusedClientFile.attributes.readers.map(
                        (element) => element.id
                    );
               
                if (readerIds.indexOf(this.$store.state.currentUser.id) <= -1) {
                    this.getFocusedClientFile.set("readers", [
                        ...this.getFocusedClientFile.attributes.readers,
                        this.$store.state.currentUser,
                    ]);
                    this.getFocusedClientFile.set("reader_timestamps", [
                        ...this.getFocusedClientFile.attributes
                            .reader_timestamps,
                        new Date(),
                    ]);
                    this.getFocusedClientFile
                        .save()
                        .then((result) => {})
                        .catch((err) => {
                            this.$buefy.toast.open({
                                message: err.toString(),
                                type: "is-danger",
                                position: "is-bottom",
                            });
                            this.isLoading = false;
                        });
                }
            } else if (
                (this.getFocusedClientFile.attributes.readers &&
                    this.getFocusedClientFile.attributes.readers.length <= 0) ||
                !this.getFocusedClientFile.attributes.readers
            ) {
                this.getFocusedClientFile.set("readers", [
                    this.$store.state.currentUser,
                ]);
                this.getFocusedClientFile.set("reader_timestamps", [
                    new Date(),
                ]);
                this.getFocusedClientFile
                    .save()
                    .then((result) => {})
                    .catch((err) => {
                        this.$buefy.toast.open({
                            message: err.toString(),
                            type: "is-danger",
                            position: "is-bottom",
                        });
                        this.isLoading = false;
                    });
            }
        },
    },
    components: {
        EntryReader,
        RecordComment,
        ColoredTag,
    },
};
</script>

<style scoped>
.scrollable {
    overflow: auto;
    height: 100%;
    width: 100%;
    padding-bottom: 1.5rem;
}

.entry-box {
    margin-right: 0.5em;
    margin-left: 0.5em;
    cursor: pointer;
    padding-bottom: 15px;
}

.entry-box-entry {
    margin-right: 0.5em;
    margin-left: 0.5em;
    padding-bottom: 15px;
    text-align: center;
}

.white-text {
    color: white;
}

@media (max-width: 1026px) {
    .scrollable {
        overflow: auto;
        height: 100%;
        width: 100%;
    }
}

@media (max-width: 769px) {
    .tabs:not(:last-child),
    .pagination:not(:last-child),
    .message:not(:last-child),
    .level:not(:last-child),
    .breadcrumb:not(:last-child),
    .block:not(:last-child),
    .title:not(:last-child),
    .subtitle:not(:last-child),
    .table-container:not(:last-child),
    .table:not(:last-child),
    .progress:not(:last-child),
    .progress-wrapper.is-not-native:not(:last-child),
    .notification:not(:last-child),
    .content:not(:last-child),
    .box:not(:last-child) {
        margin-bottom: 0;
    }
    .scrollable {
        height: 100%;
        width: 95vw;
    }
}
</style>
