const state = {
	weekDays : [],
	currentDay: -1
};

const getters = {
	getWeekDays(state){
		return state.weekDays
	},
	getCurrentDay(state){
		return state.currentDay
	}
};

const mutations = {
	SETWEEKDAYS(state, objects) {
		state.weekDays = objects
	},
	SETCURRDAY(state, objects) {
		state.currentDay = objects
	}
};

const actions = {
	setWeekDays({ commit }, objects) {
		commit('SETWEEKDAYS', objects)
	}, 
	setCurrDay({ commit }, objects) {
		commit('SETCURRDAY', objects)
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
