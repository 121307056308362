import { v4 as uuid } from "uuid";
import Parse from "parse/dist/parse.min.js";
import { negativeFeedBack, positiveFeedBack } from "@/lib/Toast";
import store from "./store/store";
import { savePdfFiles } from "./saveFiles";

const saveData = async (entry, dropFilesModified = [], pictures = [], existingData = []) => {
	let pictureArray = []
	let dataArray = []

	dropFilesModified.forEach(element => {
		if (element.type == "application/pdf") {
			dataArray.push(element)
		} else {
			pictureArray.push(element)
		}
	});
	let savedPdfs = await savePdfFiles("EntryFile", dataArray)
	entry.set("otherFiles", savedPdfs.concat(existingData))
	savedPdfs.concat(existingData).length > 0 || (entry.attributes.pictures).length > 0 ? entry.set("attachment", true) : entry.set("attachment", false);
	await entry.save()
	await savePictures(entry, pictureArray, pictures)
}

const savePictures = async (entry, dropFilesModified = [], pictures = []) => {
	const parseFiles = [];
	for (const file of dropFilesModified) {
		const parseFile = new Parse.File(uuid(), { base64: file }, "image/jpeg")
		parseFiles.push(parseFile);
	}
	entry.set("pictures", parseFiles.concat(pictures))
	for (const file of parseFiles) {
		await file.save()
	}
	parseFiles.concat(pictures).length > 0 || (entry.attributes.otherFiles).length > 0? entry.set("attachment", true) : entry.set("attachment", false);
	await entry.save()
}

export const saveNewEntry = async (setIsLoading, { entryRequest, dropFilesModified, selectedClients, selectedRating }, routeBack) => {

	setIsLoading(true)
	const currentUser = Parse.User.current();
	const attributeIds = [];
	entryRequest.attributes.forEach((arr) => {
		if (Array.isArray(arr)) {
			arr.forEach((att) => {
				attributeIds.push(att.id);
			});
		} else {
			attributeIds.push(arr.id);
		}
	});
	entryRequest.optAttributes.forEach((arr) => {
		if (Array.isArray(arr) && arr.length > 0) {
			arr.forEach((att) => {
				attributeIds.push(att.id);
			});
		} else if (!Array.isArray(arr)) {
			if (arr) attributeIds.push(arr.id);
		}
	});
	const entryParams = {
		authorId: currentUser.id,
		text: entryRequest.entryInput,
		title: entryRequest.entryTitle,
		typeId: entryRequest.entry_type.id,
		diaryAttributeIds: attributeIds,
		clientIds: selectedClients.map(c => c.id),
		ratings: selectedRating,
		visibility: entryRequest.visibility,
		isPrivate: entryRequest.entry_private
	}

	const { entry } = await Parse.Cloud.run("saveEntry", entryParams)
	await saveData(entry, dropFilesModified)
	await positiveFeedBack("Eintrag angelegt!")
	setIsLoading(false)
	await routeBack()
}

export const saveEditedEntry = async (setIsLoading, { entryRequest, dropFilesModified, selectedClients, selectedRating, focusedEntry, isEntryEdit }, routeBack) => {
	setIsLoading(true)
	const currentUser = Parse.User.current();
	const entryParams = {
		entryId: focusedEntry.id,
		authorId: currentUser.id,
		text: entryRequest.entryInput,
		title: entryRequest.entryTitle,
		selectedClientIds: selectedClients.map(c => c.id),
		ratings: selectedRating,
		isEntryEdit: isEntryEdit,
		visibility: entryRequest.visibility,
		isPrivate: entryRequest.isPrivate
	}
	const entry = await Parse.Cloud.run("editEntry", entryParams)
	await saveData(entry, dropFilesModified, entryRequest.pictures, entryRequest.otherFiles)
	positiveFeedBack("Eintrag bearbeitet!")
	setIsLoading(false)
	let id = store.getters["clients/getFocusedClientId"]
	await store.dispatch("clients/setFocusedClientId", undefined);
	await store.dispatch("clients/setFocusedClientId", id);
	await routeBack() 
}
