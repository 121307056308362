<template>
    <div class="ml-2" v-if="numberOfComments > 0">
        <b-tooltip label="Kommentare" position="is-bottom">
            <div
                style="
                    display: grid;
                    grid-template-columns: repeat(2, 0.1fr);
                    grid-template-areas: 'icon num';
                "
            >
                <div style="grid-area: icon">
                    <b-icon icon="comment"></b-icon>
                </div>
                <div style="grid-area: num">
                    {{ numberOfComments }}
                </div>
            </div>
        </b-tooltip>
    </div>
</template>

<script>
/**
 * Icon displaying the comment count of an entry.
 * @displayName CommentIcon
 */


export default {
    name: "CommentIcon",
    props: ["numberOfComments"],
};
</script>

<style scoped>
@media (max-width: 450px) {
    .ml-2 {
        margin-left: 0 !important;
        margin-right: 0.5rem !important;
    }
}
</style>
