<template>
	<div>
		<MedicationPrescriptionSearchableSelect :data-options="filteredPrescriptionArray"
			:editObject="existingPrescription" :enable-validation="false"
			validationMessage="" label-text="Bestehendes Rezept wählen" :enable-order="true"
			:select="(object) => (existingPrescription = object)" />
		<b-field class="mt-5" expanded>
			<template #label>
				<div class="align-content-center" style="display: inline-flex">
					<p>Neues Rezept anlegen</p>
				</div>
				<hr class="m-0 mt-1 mb-1" style="border-bottom:1px solid gray;">
			</template>
		</b-field>
	</div>
</template>

<script>

import { mapGetters } from "vuex";
import { minLength, required, minValue } from "vuelidate/lib/validators";
import MedicationPrescriptionSearchableSelect from "@/components/helper/SearchableSelects/MedicationPrescriptionSearchableSelect.vue";
import Parse from "parse/dist/parse.min.js";

export default {
	name: "MedicationPrescriptionInputSelect",
	components: {
		MedicationPrescriptionSearchableSelect,
	},
	props: [],
	data() {
		return {
			existingPrescription: undefined
		};
	},
	mounted() {
	},
	computed: {
		...mapGetters({
			filteredPrescriptionArray: "prescriptions/getData",
		}),
	},
	methods: {},
	watch: {
		existingPrescription(newVal) {
			if (newVal) {
				for (var i = 0; i < this.filteredPrescriptionArray.length; i++) {
					if (newVal.id == this.filteredPrescriptionArray[i].objectId) {
						this.$emit("emit-existing-prescription-object-selected", this.filteredPrescriptionArray[i])
						break;
					}

				}

			}

		},
	}
}

</script>

<style scoped></style>
