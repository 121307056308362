<template>
	<div class="
					is-flex
					is-flex-direction-row
					is-flex-direction-row
					is-flex-align-items-center
				" style="width: 100%"
				:class="!hasNoConnection? 'has-darker-grey-color' : 'is-not-online'">
		<template v-if="isInModule">
			<template v-if="!isMobileViewport">
				<user-header style="width: 25%" />
				<client-header style="width: 75%" />
			</template>
			<template v-else>
				<template v-if="isSidePanelOpen">
					<user-header style="width: 100%" />
				</template>
				<template v-else>
					<client-header style="width: 100%" />
				</template>
			</template>
		</template>
		<template v-else>
			<root-user-header style="width: 100%" />
		</template>
		<version-modal v-model="isVersionModalActive"></version-modal>
	</div>
</template>

<script>
/**
 * TopBar component of the applciation displaying the user and the focused client.
 * @displayName TopBar
 */

import UserHeader from "./UserHeader";
import RootUserHeader from "./RootUserHeader";
import ClientHeader from "./ClientHeader";
import { mapGetters } from "vuex";
import Parse from "parse/dist/parse.min.js";
import { version } from "../../../../package.json"
import VersionModal from "../../modals/VersionModal.vue";

export default {
	name: "TopBar",
	data() {
		return {
			applicationVersion: version,
			isVersionModalActive: false,
			hasNoConnection: false
		};
	},
	async mounted() {
		this.checkServerStatus();
		document.addEventListener('visibilitychange', this.handleVisibilityChange);
		setInterval(function () {
			this.checkServerStatus();
		}.bind(this), 10000); 
	},
	beforeDestroy() {
		document.removeEventListener('visibilitychange', this.handleVisibilityChange);
	},
	computed: {
		...mapGetters({
			isMobileViewport: "isMobile",
			dismissUpdate: "versions/getdismissUpdate",
			apiVersion: "versions/getApiVersion"
		}),
		isInModule() {
			return (
				this.$route.name !== "Root" &&
				this.$route.name !== "Login" &&
				this.$route.name !== "Profile" &&
				this.$route.name !== "NewWidget"
			);
		},
		isSidePanelOpen() {
			return this.$store.state.sidePanelOpen;
		},
	},
	watch: {
		focusedClient(){
			this.checkServerStatus()
		},
	},
	methods: {
		async checkServerStatus() {
			let parseServerStatus = Parse.getServerHealth()
			let timeoutID = setTimeout(function () {this.hasNoConnection = true }.bind(this), 3000)
			parseServerStatus.then(value => {
				this.checkVersion()
				this.hasNoConnection = false
				clearTimeout(timeoutID); 
			})
			parseServerStatus.catch(error => {
				this.hasNoConnection = true
				clearTimeout(timeoutID); 
			})
		},
		async checkVersion() {
			var apiVersion = await Parse.Cloud.run("getDiaryVersion")
			this.$store.dispatch("versions/setApiVersion", apiVersion);
			if (!this.dismissUpdate) {
				if (apiVersion != this.applicationVersion) {
					this.isVersionModalActive = true
				} else {
					this.isVersionModalActive = false
				}
			}
		},
		handleVisibilityChange() {
			if (!document.hidden) {
				let parseServerStatus = Parse.getServerHealth()
				let timeoutID = setTimeout(function () {this.hasNoConnection = true }.bind(this), 3000)
				parseServerStatus.then(value => {
					this.hasNoConnection = false
					clearTimeout(timeoutID); 
					if (!this.dismissUpdate) {
						if (this.apiVersion != this.applicationVersion) {
							this.isVersionModalActive = true
						} else {
							this.isVersionModalActive = false
						}
					}
				})
				parseServerStatus.catch(error => {
					this.hasNoConnection = true
					clearTimeout(timeoutID); 
				})
			}
		}
	},
	components: { ClientHeader, UserHeader, RootUserHeader, VersionModal },
};
</script>

<style scoped></style>