import Parse from "parse/dist/parse.min.js";

export const queryByArray = async (pointerIds, parseClassName) => {
	if (pointerIds.length >= 1) {
		const query = new Parse.Query(parseClassName);
		query.limit(1000000);
		query.notEqualTo("deleted", true);
		query.containedIn("objectId", pointerIds);
		return await query.find();
	} else {
		return [];
	}
};
