<template>
	<b-modal v-model="isActive" has-modal-card @close="closeModal()" :can-cancel="false">
		<div class="modal-card modalCardContainer"
			style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%);">
			<header class="modal-card-head" style="background-color: rgba(40, 40, 40, 1); color: white">
				ICD-Code wählen
			</header>
			<section class="modal-card-body" style="padding: 0.5em; color: black">

				<b-field expanded>
					<template #label>
						<div class="align-content-center" style="display: inline-flex">
							<p>ICD-10-Codes</p>
						</div>
					</template>
					<b-input placeholder="Geben Sie bitte mind. 2 Zeichen zum Suchen ein." class="m-0" expanded type="text" v-model="searchICDCode" />
				</b-field>

				<b-field expanded>

					<b-menu>
						<b-menu-item expanded :key="option.id" v-for="option in filteredICDCodes"
							:label="option.attributes.icd_10_code + ' ' + option.attributes.name" @click="selectICDCode(option)"></b-menu-item>
					</b-menu>


				</b-field>

			</section>
			<footer class="modal-card-foot footerContainer" style="color: white">
				<b-button @click="closeModal()" type="is-primary">
					Abbrechen
				</b-button>
			</footer>
		</div>
	</b-modal>
</template>

<script>

import { mapGetters } from "vuex";
import { minLength, required } from "vuelidate/lib/validators";
import Parse from "parse/dist/parse.min.js";

export default {
	name: "NewICD10CodeModal",
	props: ["value"],
	data() {
		return {
			isLoading: false,
			searchICDCode: ""
		};
	},
	validations: {
		icd10code: {
			required,
		},
	},
	mounted() { },
	computed: {
		...mapGetters({
			icd10CodeArray: "icd_10_codes/getData",
		}),
		isActive: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit("input", val);
			},
		},
		filteredICDCodes() {
			return this.icd10CodeArray.filter(option => {
				
				if (this.searchICDCode.toLowerCase() != "" && this.searchICDCode.toLowerCase().length > 1) {
					return (
						(option.attributes.icd_10_code + "" + option.attributes.name)
							.toString()
							.toLowerCase()
							.indexOf(this.searchICDCode.toLowerCase()) >= 0
					)
				}else
				{
					return false;
				}

			})
		}
	},
	methods: {
		closeModal() {
			this.isActive = false;
			this.searchICDCode = "";
		},
		async selectICDCode(code) {
			this.isLoading = true;
			this.$emit("emit-new-icd-10-code", code)
			this.isLoading = false;
			this.closeModal()
		},
	}
}

</script>

<style scoped></style>
 