<template>
    <div style="width: 100%" v-if="attributeConfigs">
        <div
            v-for="(v, index) in $v.entryRequestAttributes.$each.$iter"
            :key="index"
        >
            <template v-if="attributeConfigs[index].attributes.entryAttributes">
                <h3 class="attribute_titel mb-3 mt-3">
                    {{ attributeConfigs[index].attributes.name }}
                </h3>
                <div
                    class="
                        columns
                        is-flex is-flex-wrap-wrap is-justify-content-flex-start
                    "
                    style="width: 100%"
                >
                    <div
                        v-if="
                            attributeConfigs[index].attributes.isSingleSelection
                        "
                    >
                        <b-radio
                            v-model="entryRequestAttributes[index]"
                            v-on:input="$v.entryRequestAttributes.$touch"
                            v-for="entryAttribute in attributeConfigs[
                                index
                            ].attributes.entryAttributes.filter(
                                (att) => att.attributes.deleted !== true
                            )"
                            :key="entryAttribute.id"
                            :native-value="entryAttribute"
                            class="column is-flex-grow-0"
                            style="flex-basis: initial"
                        >
                            {{ entryAttribute.attributes.name }}
                        </b-radio>
                    </div>
                    <div v-else>
                        <b-checkbox
                            v-model="entryRequestAttributes[index]"
                            v-on:input="$v.entryRequestAttributes.$touch"
                            v-for="entryAttribute in attributeConfigs[
                                index
                            ].attributes.entryAttributes.filter(
                                (att) => att.attributes.deleted !== true
                            )"
                            :key="entryAttribute.id"
                            :native-value="entryAttribute"
                            class="column is-flex-grow-0"
                            style="flex-basis: initial"
                        >
                            {{ entryAttribute.attributes.name }}
                        </b-checkbox>
                    </div>
                </div>
                <div
                    class="tag is-danger is-light padded dangerTag"
                    v-if="!v.required"
                >
                    {{ attributeConfigs[index].attributes.name }}
                    benötigt!
                </div>
            </template>
        </div>
    </div>
</template>

<script>
/**
 * Component for the creation of attributes on an entry.
 * @displayName EntryAttributes
 */

import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";

export default {
    name: "EntryAttributes",
    props: ["entryAttributes", "setEntryAttributes", "addEntryAttributes"],
    validations: {
        entryRequestAttributes: {
			required,
			$each: {
				required,
			},
		}
    },
    computed: {
        ...mapGetters({
            unfilteredAttributeConfigs: "attributeconfigs/getAttributeConfig",
        }),
        attributeConfigs() {

            return this.unfilteredAttributeConfigs.filter(
                (config) => config.attributes.deleted !== true
            );
        },
        entryRequestAttributes: {
            set(value) {
                this.setEntryAttributes(value);
            },
            get() {
                return this.entryAttributes;
            },
        },
    },
    mounted() {
        this.setEntryAttributes([]);
        this.attributeConfigs.forEach((config) => {
            if (config.attributes.isSingleSelection) {
                this.addEntryAttributes(undefined);
            } else {
                this.addEntryAttributes([]);
            }
        });
    },
};
</script>

<style lang="scss" scoped>
.padded {
    margin-top: 12px;
    margin-bottom: 12px;
}

.attribute_titel {
    margin-top: 12px;
    font-weight: bold;
}

@media (max-width: 769px) {
    .columns:not(:last-child) {
        margin-bottom: 0;
    }
    .field {
        padding: 0;
        margin-bottom: 0;
    }
    .padded {
        padding: 0.25rem;
        margin: 0;
    }
    .attribute_titel {
        margin: 0.5rem 0 !important;
    }
    .columns {
        display: flex;
        align-items: center;
        flex-direction: row;
        padding: 0px;
        margin: 0px;
    }

    .column {
        padding: 0;
        margin: 0;
    }
}

@media (max-width: 450px) {
    .b-radio.radio + .radio {
        margin-right: 3px;
        margin-left: 2px;
    }
}
</style>
