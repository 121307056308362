<template>
    <div class="hero">
        <div
            class="
                hero-head
                is-flex is-justify-content-center is-align-content-center
            "
        >
            <b-button
                @click="routeBack()"
                class="m-1"
                icon-left="chevron-left"
            >
                Zurück
            </b-button>
        </div>
        <div class="hero-body container scrollable" v-html="this.imprint[this.app_imprint]" />
    </div>
</template>

<script>

import { APP_IMPRINT } from "@/configuration/AppConfigurations";
import DATA_IMPRINT from '../../../assets/texts/imprint.json'

export default {
    name: "Imprint",
    data() {
        return {
			imprint : DATA_IMPRINT,
			app_imprint : APP_IMPRINT
		};
    },
    computed: {},
	methods: {
		async routeBack() {
			return await this.$router.back();
		}
	}
};
</script>

<style scoped>
.scrollable {
    overflow: auto;
    height: 93%;
}
</style>