import Parse from 'parse/dist/parse.min.js';
import { handleParseError } from '@/lib/errorhandling';
import { queryByArray } from '@/lib/Notifications.js'

let localSubscription = undefined;

async function queryDependencies(store) {
	let entryIds = store.state.notifications.data.map(not => { if (not.attributes.dependencyEntryLink) { return not.attributes.dependencyEntry.id } })
	let entryLinkIds = store.state.notifications.data.map(not => {
		if (not.attributes.dependencyEntryLink) { return not.attributes.dependencyEntryLink.id }
	});
	let commentIds = store.state.notifications.data.map(not => {
		if (not.attributes.dependencyComment) {
			return not.attributes.dependencyComment.id
		}
	});
	entryIds = entryIds.filter(item => item)
	entryLinkIds = entryLinkIds.filter(item => item)
	commentIds = commentIds.filter(item => item)
	await store.dispatch('notifications/setEntries', await queryByArray(entryIds, "Entry"))
	await store.dispatch('notifications/setEntryLinks', await queryByArray(entryLinkIds, "EntryLink"))
	await store.dispatch('notifications/setComments', await queryByArray(commentIds, "Comment"))
}
// Entry subscription
async function createSubscription(store) {
	const notificationQuery = new Parse.Query('Notification');
	notificationQuery.limit(1000000);
	notificationQuery.notEqualTo("deleted", true);
	notificationQuery.equalTo("user", store.state.currentUser);
	notificationQuery.include("client", "dependencyEntryLink", "dependencyEntryLink.client", "dependencyEntry");
	notificationQuery.descending("createdAt");
	try {
		// const results = await notificationQuery.find()
		const notifications = await notificationQuery.find()


		// const filteredResults = results.filter(notification => notification.attributes.users.some(user => user.id === Parse.User.current().id))
		await store.dispatch('notifications/set', notifications)
		await queryDependencies(store);

	} catch (error) {
		handleParseError(error);
		
	}
	notificationQuery.subscribe().then((subscription) => {
		
		localSubscription = subscription;

		subscription.on("open", async (object) => {

		})

		subscription.on('create', async object => {
			await store.dispatch('notifications/insert', object)

			// if(Parse.User.current().id === object.attributes.user.id){
			// 	await positiveFeedBack(object.attributes.message)
			// }

			queryDependencies(store);
		});
		subscription.on('update', async object => {
			await store.dispatch('notifications/replace', object)
			queryDependencies(store);
		});
		subscription.on('enter', async object => {
			await store.dispatch('notifications/insert', object)
			queryDependencies(store);
		});
		subscription.on('leave', async object => {
			await store.dispatch('notifications/delete', object)
			queryDependencies(store);
		});
		subscription.on('delete', async object => {
			await store.dispatch('notifications/delete', object)
			queryDependencies(store);
		});
		subscription.on('close', () => {
		});
		subscription.on('error', error => {
		})
	})
}

export default function createNotificationSubscription() {
	return store => {
		//Erzeuge einmalig eine neue Subscription für den Datensatz. 
		//Fall "Bereits in der Anwendung"
		if(store.state.currentUser && !localSubscription) {

			if (localSubscription) {
				localSubscription.unsubscribe();
			}

			createSubscription(store)
		}
		//Erzeuge einmalig eine neue Subscription für den Datensatz. 
		//Fall "Normaler Login"
		store.subscribe(mutation => {
			
			if (mutation.type === 'SET_CURRENT_USER' && store.state.currentUser) {
				
				if (localSubscription) {
					localSubscription.unsubscribe();
				}
				createSubscription(store)
			}
		})
	}
}
