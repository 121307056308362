<template>
    <div class="ml-2" v-if="entryLink.attributes.entry.attributes.attachment">
        <b-tooltip label="Anhang" position="is-bottom">
            <b-icon icon="attachment"> </b-icon>
        </b-tooltip>
    </div>
</template>

<script>
/**
 * Icon if attechements are associated with an entry.
 * @displayName AttachementIcon
 */

export default {
    name: "AttachementIcon",
    props: ["entryLink"],
};
</script>

<style scoped>
@media (max-width: 450px) {
    .ml-2 {
        margin-left: 0 !important;
        margin-right: 0.5rem !important;
    }
}
</style>
