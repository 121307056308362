import { render, staticRenderFns } from "./EditInstantMedicationModal.vue?vue&type=template&id=525b3010&scoped=true&"
import script from "./EditInstantMedicationModal.vue?vue&type=script&lang=js&"
export * from "./EditInstantMedicationModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "525b3010",
  null
  
)

export default component.exports