<template>
    <router-link :to="{ name: `Notifications` }" tag="div">
        <span
            v-if="numberOfNotifications > 0"
            style="display: flex; justify-content: space-around"
            ><b class="notifNumber">{{ numberOfNotifications }}</b></span
        >
    </router-link>
</template>

<script>
/**
 * Tag displaying the number of notifications for the signed-in user.
 * @displayName NotificationTag
 */

import { mapGetters } from "vuex";

export default {
    name: "NotificationTag",
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            numberOfNotifications: "getNumberOfNotifications",
        }),
    },
};
</script>

<style scoped>
span {
    color: white;
    background-color: red;
    border-radius: 50%;
    padding: 3px;
    -webkit-box-shadow: 0px 0px 5px 0px #000000;
    box-shadow: 0px 0px 5px 0px #000000;
	cursor: pointer
}
</style>