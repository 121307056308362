<template>
	<b-modal v-model="isActive" has-modal-card @close="closeModal()" :can-cancel="false">
		<div class="modal-card modalCardContainer" style="
							position: absolute;
							left: 50%;
							top: 50%;
							transform: translate(-50%, -50%);
					">
			<header class="modal-card-head" style="background-color: rgba(40, 40, 40, 1); color: white;">
				Sind Sie sich sicher, dass Sie das Medikationslog erstellen wollen?
			</header>
			<section class="modal-card-body" style="padding: 0.5em; color: black">
				<p>
					Log-relevante Informationen (Medikation, Medikationsplan) können anschließend nicht mehr bearbeitet
					werden. Noch nicht gespeicherte Informationen gehen verloren.
				</p>
				<p class="mt-3">
					<b>Variante: </b>
					<span v-if="proppedMedicationLogArt == 1">
						Standard-Plan
					</span>
					<span v-if="proppedMedicationLogArt == 2">
						Zu besonderen Zeiten
					</span>
					<span v-if="proppedMedicationLogArt == 3">
						Selbstmedikation
					</span>
				</p>
				<p>
					<b>Medikament: </b>{{ medicationDisplayName }}
				</p>
				<table v-if="proppedMedicationLogArt == 1 || proppedMedicationLogArt == 2" expanded
					class="medicationPlanTable mt-3">
					<tr>
						<th><small>Tag</small></th>
						<th><small>Zeit</small></th>
						<th><small>Menge</small></th>
					</tr>
					<tr v-for="option in proppedMedicationLog" :key="option.index">
						<td>
							<small>
								{{ option['date'].toLocaleDateString("de-DE",
									{
										year: "numeric",
										month: "2-digit",
										day: "2-digit",
									}) }}
								({{ option['name'] }})
							</small>
						</td>
						<td>
							<small>
								{{ option['time'] }}
							</small>
						</td>
						<td>
							<small>
								{{ option['menge'] }}
								{{ medicationSymbol }}
							</small>
						</td>
					</tr>
				</table>
				<p v-if="proppedMedicationLogArt == 3">
					<b>Gültigkeitszeitraum: </b>
					<span>
						{{ proppedMedicationEntry.attributes.medication.attributes.startDate.toLocaleDateString("de-DE",
							{
								year: "numeric",
								month: "2-digit",
								day: "2-digit",
							}) }} - {{
		proppedMedicationEntry.attributes.medication.attributes.endDate.toLocaleDateString("de-DE",
			{
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
			}) }}
					</span>
				</p>
				<p v-if="proppedMedicationLogArt == 3">
					<b>Einzeldosis: </b>
					<span>
						{{ String(proppedMedicationEntry.attributes.medicationPlan.attributes.einzeldosis).replace(".", ",")
						}} {{ medicationSymbol }}
					</span>
				</p>

			</section>
			<footer class="modal-card-foot footerContainer" style="background-color: rgba(40, 40, 40, 1); color: white">
				<b-button @click="closeModal()" type="is-primary">
					Abbrechen
				</b-button>
				<b-button @click="createLog();" type="is-primary">
					Log erstellen
				</b-button>
			</footer>
		</div>

	</b-modal>
</template>

<script>

import { mapGetters } from "vuex";

export default {
	name: "LogConfirmationModal",
	props: ["value", "proppedMedicationLog", "proppedMedicationLogArt", "proppedMedicationEntry"],
	data() {
		return {};
	},
	mounted() {
	},
	computed: {
		isActive: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit("input", val);
			},
		},
		medicationDisplayName: function () {
			if (this.proppedMedicationLog && this.proppedMedicationLog.length != 0) {
				return this.proppedMedicationLog[0].medication.attributes.medication.attributes.medication.attributes.displayName;
			} else if (this.proppedMedicationEntry) {
				return this.proppedMedicationEntry.attributes.medication.attributes.medication.attributes.displayName;
			} else {
				return "";
			}
		},
		medicationSymbol: function () {
			if (this.proppedMedicationLog && this.proppedMedicationLog.length != 0) {
				return this.proppedMedicationLog[0].medication.attributes.medication.attributes.medication.attributes.medicationUnit.attributes.symbol;
			} else if (this.proppedMedicationEntry) {
				return this.proppedMedicationEntry.attributes.medication.attributes.medication.attributes.medicationUnit.attributes.symbol;
			} else {
				return "";
			}
		}
	},
	methods: {
		closeModal() {
			this.isActive = false;
		},
		createLog() {
			this.isActive = false;
			this.$emit("emit-create-medication-log", true);
		}
	}
}

</script>

<style scoped>
.medicationPlanTable {
	width: 100%;
}

.medicationPlanTable td,
.medicationPlanTable th {
	border: 1px solid #313131;
	padding: 2px;
	vertical-align: middle;
}
</style>
