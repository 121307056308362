<template>
	<div ref="editor_body">
		<h2 class="is-subtitle">Kommentare</h2>
		<div class="editor_body">
			<div @click="() => (this.referencedComment = undefined)" v-if="referencedComment" class="mr-2"
				style="display: flex; justify-content: end; cursor: pointer">
				<b-icon icon="close"></b-icon>
			</div>
			<CommentTreePrevious :comment="referencedComment" />
			<editor ref="editor" v-model="commentInput" v-on:input="$v.commentInput.$touch" />
			<b-button class="mt-3 commitComment" type="is-primary" v-on:click="saveComment" v-bind:loading="isLoading"
				v-bind:disabled="$v.commentInput.$invalid">Kommentar absenden</b-button>
		</div>
		<div class="mt-4">
			<div v-for="comment in getSortedComment(comments)" :key="comment.id"
				style="position: relative; height: 100%">
				<CommentTree class="pt-2" :comment="comment" :reply="reply" :padded="false" style="height: 100%" />
				<hr class="m-1 mb-2" style="background-color: rgba(0, 0, 0, 0.3)" />

			</div>
		</div>
	</div>
</template>

<script>
/**
 * Component for the display of comments of an entry and the comment creation.
 * @displayName EntryComment
 */

import Parse from "parse/dist/parse.min.js";
import Editor from "@/components/helper/Editor";
import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import CommentTree from "@/components/helper/CommentTree";
import CommentTreePrevious from "@/components/helper/CommentTreePrevious";

export default {
	name: "EntryComment",
	data() {
		return {
			commentInput: "",
			isLoading: false,
			htmlRegex: /<\/?[^>]+(>|$)/g,
			referencedComment: undefined,
		};
	},
	computed: {
		...mapGetters({
			comments: "comments/getFilteredComments",
		}),
		commentKeys() {
			if (!this.comments) return []
			return Object.keys(this.comments)
		},
	},
	validations: {
		commentInput: { required },
	},
	methods: {
		getSortedComment(commentArray) { //sorting for the first layer of the graph (esp. unconnected nodes)
			return commentArray.sort((c1, c2) => {
				if (c2.createdAt && c1.createdAt) {
					return c2.createdAt.getTime() - c1.createdAt.getTime()
				}
				if (c1.createdAt && c2[0].createdAt) { //first or second item is array caused by data structure needed from vue js
					return c2[0].createdAt.getTime() - c1.createdAt.getTime()
				}
				if (c2.createdAt && c1[0].createdAt) {
					return c2.createdAt.getTime() - c1[0].createdAt.getTime()
				}
				if (c2[0].createdAt && c1[0].createdAt) {
					return c2[0].createdAt.getTime() - c1[0].createdAt.getTime()
				}
				return 0
			})
		},
		saveComment() {
			this.isLoading = true;

			const currentUser = Parse.User.current();

			const Entry = Parse.Object.extend("Entry");
			var query = new Parse.Query(Entry);
			query.get(this.$store.state.focusedEntryId).then((entry) => {
				const Comment = Parse.Object.extend("Comment");
				const comment = new Comment();
				comment.set("author", currentUser);
				comment.set("parent", entry);
				comment.set("text", this.commentInput);
				comment.set("reference", this.referencedComment);
				comment
					.save()
					.then((result) => {
						this.referencedComment = undefined;
						this.$buefy.toast.open({
							message: "Kommentar angelegt!",
							type: "is-success",
							position: "is-bottom",
						});
						this.isLoading = false;
						this.commentInput = "";
					})
					.catch((err) => {
						this.$buefy.toast.open({
							message: err.toString(),
							type: "is-danger",
							position: "is-bottom",
						});
						this.isLoading = false;
					});

				// entry.set("readers", [])
				// entry.set("reader_timestamps", [])
				entry
					.save()
					.catch((e) =>
						console.error(
							"coudldnt deleted readers from entry " + e
						)
					);
			});
		},
		reply(comment) {
			this.referencedComment = comment;
			
			// this.$refs.editor.editor.commands.setContent(
			//     "<blockquote>" +
			//         comment.attributes.text +
			//         "<hr>" +
			//         "<small>" +
			//         comment.attributes.author.attributes.dependency.attributes
			//             .first_name +
			//         " " +
			//         comment.attributes.author.attributes.dependency.attributes
			//             .last_name +
			//         " vom " +
			//         comment.createdAt.toLocaleDateString("de-DE", {
			//             year: "numeric",
			//             month: "2-digit",
			//             day: "2-digit",
			//         }) +
			//         " - " +
			//         comment.createdAt.toLocaleTimeString("de-DE") +
			//         "</small>" +
			//         "</blockquote><p></p>"
			// );
			let count =
				this.$parent.$refs.commentPanel.$el.offsetTop -
				this.$parent.$refs.entryDetailPanel.scrollTop; // - xx // xx = any extra distance from top ex. 60
			this.$parent.$refs.entryDetailPanel.scrollBy({
				top: count,
				left: 0,
				behavior: "smooth",
			});
			this.$refs.editor.editor.commands.focus("end");
		},
		isReferenced(comment) {
			const commentReferences = this.comments.filter(c => c.attributes.reference)
			const commentReferenceIds = commentReferences.map(ref => ref.id)
			return !commentReferenceIds.includes(comment.id)
		}
	},
	components: { CommentTreePrevious, CommentTree, Editor },
};
</script>

<style lang="scss" scoped>
/* Basic editor styles */
::v-deep .ProseMirror {
	//background-color: #f5f5f5;
	padding: 10px;

	>*+* {
		margin-top: 0.75em;
	}

	code {
		background-color: rgba(#616161, 0.1);
		color: #616161;
	}

	blockquote {
		padding-left: 1rem;
		border-left: 2px solid rgba(#0d0d0d, 0.1);
	}
}

::v-deep .content {
	padding: 1rem 0 0;

	h3 {
		margin: 1rem 0 0.5rem;
	}

	pre {
		border-radius: 5px;
		color: #333;
	}

	code {
		display: block;
		white-space: pre-wrap;
		padding: 0.75rem 1rem;
		background-color: #e9ecef;
		color: #495057;
	}
}

::v-deep .editor_body {
	border: 1px solid lightgrey;
	padding: 10px;
}

::v-deep .editor_body .buttons {
	margin-bottom: 0px;
}

::v-deep .editor_body .buttons button {
	padding: 0px 10px;
	height: auto;
}

::v-deep blockquote {
	padding-left: 1rem;
	border-left: 2px solid rgba(#0d0d0d, 0.1);
	background-color: rgba(#616161, 0.1);
	padding-bottom: 5px;
	padding-top: 5px;
}

::v-deep blockquote hr {
	margin-top: 3px;
	margin-bottom: 3px;
}

.box {
	margin: 0.5em;
}

.scrollable {
	overflow: auto;
	height: 600px;
}

.scrollable::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollable {
	-ms-overflow-style: none;
	/* IE and Edge */
	scrollbar-width: none;
	/* Firefox */
}

.articleContainer {
	display: grid;
	grid-template-columns: 1fr 9fr;
}

.authorPicture {
	height: 100%;
	width: 100%;
	object-fit: cover;
}

@media (max-width: 400px) {
	.authorPicture {
		height: 80%;
		width: 80%;
		object-fit: cover;
	}
}
</style>
