<template>
	<b-modal v-model="isActive" has-modal-card v-if="client" @close="closeModal()" :can-cancel="false">
		<div class="modal-card modalCardContainer" style="
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            ">
			<header class="modal-card-head" style="background-color: rgba(40, 40, 40, 1); color: white">
				<div class="column is-flex" style="align-items: center">
					<AuthorImage :client="client" />
					<ClientGroupAndName :client="client" />
				</div>
			</header>
			<section class="modal-card-body" style="padding: 0.5em">
				<div class="panel m-5" style="background-color: white">
					<p class="panel-heading" style="
                            background-color: rgba(0, 0, 0, 0.8);
                            color: white;
                        ">
						Profilbild
					</p>
					<div class="columns is-centered">
						<div class="column image_column">
							<div style="text-align: center">
								<div style="
                                        display: flex;
                                        justify-content: center;
                                    ">
									<b-upload v-model="newProfilePicture" style="cursor: pointer">
										<b-image class="
                                                imageContainer
                                                mt-5
                                                is-128x128
                                            " style="
                                                display: flex;
                                                justify-content: center;
                                            " :src="getPicture" alt="Profilbild" rounded></b-image>
									</b-upload>
								</div>
								<div style="
                                        display: flex;
                                        justify-content: center;
                                    ">
									<button v-if="this.clientPicture ||
										newProfilePicture
										" class="delete is-small mt-2" type="button" style="
                                            display: flex;
                                            justify-content: center;
                                        " @click="removePicture()"></button>
								</div>
							</div>
							<section class="buttons mt-2" style="display: flex; justify-content: center">
								<b-button type="is-primary" @click="saveNewClientPicture()">Speichern
								</b-button>
							</section>
						</div>
					</div>
				</div>
				<client-table :client="client" />
				<div class="
                        is-flex
                        flex-direction-row
                        is-justify-content-center is-align-items-center
                        box
                        p-3
                        mb-1
                        mx-1
                    "
                >
                    <b-field label="Bewertungsstichtag">
                        <b-select v-model="goalDay" class="my-1">
                            <option :value="1">Montag</option>
                            <option :value="2">Dienstag</option>
                            <option :value="3">Mittwoch</option>
                            <option :value="4">Donnerstag</option>
                            <option :value="5">Freitag</option>
                            <option :value="6">Samstag</option>
                            <option :value="7">Sonntag</option>
                        </b-select>
                    </b-field>
                    <div style="display: flex; justify-content: center">
                        <b-button
                            class="mt-3 button"
                            type="is-primary"
                            @click="saveGoalDay"
                            v-bind:loading="isLoading"
                            >Speichern
                        </b-button>
                    </div>
                </div>
            </section>
            <footer
                class="modal-card-foot footerContainer"
                style="background-color: rgba(40, 40, 40, 1); color: white"
            >
                <b-button @click="closeModal" type="is-danger">
                    Schließen
                </b-button>
            </footer>
        </div>
    </b-modal>
</template>

<script>
/**
 * Modal for the management of a client.
 * @displayName ClientModal
 */

import { mapGetters } from "vuex";
import Parse from "parse/dist/parse.min.js";
import { v4 as uuid } from "uuid";
import { getClientPicture, getModifiedImage } from "@/lib/imageFunctions.js";
import ClientGroupAndName from "@/components/bars/sideBar/ClientGroupAndName";
import AuthorImage from "@/components/helper/AuthorImage";
import ClientTable from "@/components/helper/ClientTable";
import router from "@/lib/router/router";

export default {
	name: "ClientModal",
	props: ["value"],
	data() {
		return {
			isLoading: false,
			newProfilePicture: undefined,
			newProfilePictureModified: undefined,
			goalDay: 0,
			goalDayN: 0
		};
	},
	mounted() {
		this.$store.dispatch("clients/setDeleteClientPicture", false);
		if (this.client) this.goalDay = this.client.attributes.goal_day
	},
	computed: {
		...mapGetters({
			clientId: "clients/getFocusedClientId",
			clientPicture: "clients/getFocusedClientPicture",
			clientName: "clients/getFocusedClientName",
			client: "clients/getFocusedClient",
		}),
		isActive: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit("input", val);
			},
		},
		getPicture() {
			let profilePictureUrl = undefined;
			if (this.$store.state.clients.data.length === 0) return undefined;
			const associatedClients = this.$store.state.clients.data.filter(
				(c) => this.client.attributes.uuid === c.attributes.uuid
			);
			associatedClients.forEach((c) => {
				const picture = c.attributes.profile_picture;
				if (picture) profilePictureUrl = picture._url;
			});
			const pictureObject = {
				newProfilePicture: this.newProfilePicture,
				clientPicture: profilePictureUrl,
				clientGender: this.client.attributes.gender,
			};
			return getClientPicture(pictureObject);
		},
	},
	methods: {
		removePicture() {
			this.newProfilePicture = undefined;
			this.$store.dispatch("clients/setDeleteClientPicture", true);
		},
		async saveNewClientPicture() {
			const Client = Parse.Object.extend("Client");
			const query = new Parse.Query(Client);
			let client = undefined;
			try {
				client = await query.get(this.clientId);
			} catch (e) {
				console.error("couldnt find the client" + e);
			}
			if (client) {
				if (
					this.$store.state.deleteClientPicture &&
					!this.newProfilePicture
				) {
					client.unset("profile_picture");
				}
				if (this.newProfilePicture !== undefined) {
					const imageFile = new Parse.File(
						uuid(),
						{ base64: this.newProfilePictureModified },
						"image/jpeg"
					);
					client.set("profile_picture", imageFile);
					await imageFile.save();
				}
				try {
					await client.save();
				} catch (e) {
					console.error("couldnt save the client " + e);
				}
			}
			await this.$store.dispatch("clients/setDeleteClientPicture", false);
			this.isActive = false;
			this.newProfilePicture = undefined;
			this.newProfilePictureModified = undefined;

			this.$buefy.toast.open({
				message: "Neues Klientenbild gespeichert!",
				type: "is-primary",
				position: "is-bottom",
			});
		},
		async saveGoalDay() {
			this.isLoading = true;

			this.$buefy.dialog.confirm({
				cancelText: "Abbrechen",
				confirmText: "Bestätigen",
				message:
					"Stichtag für Bewertungen abändern? Alle Bewertungen werden unwiderbringlich <b>gelöscht</b>.",
				onConfirm: async () => {
					const goalDayParams = {
						clientId: this.client.id,
						goalDay: this.goalDay,
					};

					await Parse.Cloud.run("saveGoalDay", goalDayParams);
					if (
						this.$route.name === "GoalDetail"
					){
						router.push({ name: 'Goal' })
					}
				},
				onCancel: () => {
					this.goalDay = this.client.attributes.goal_day;
				},
			});
			this.isLoading = false;
		},
		closeModal() {
			this.$store.dispatch("clients/setDeleteClientPicture", false);
			this.newProfilePicture = undefined;
			this.isActive = false;
		},
	},
	components: { AuthorImage, ClientGroupAndName, ClientTable },
	watch: {
		newProfilePicture: {
			handler: function (fileArray) {
				if (fileArray) {
					this.newProfilePictureModified = undefined;
					if (fileArray.type.match(/image.*/)) {
						getModifiedImage(fileArray, 150)
							.then((response) => {
								this.newProfilePictureModified = response;
							})
							.catch(function (error) {
								console.error(error);
							});
					}
				}
			},
			deep: true,
		},
		client(newVal) {
			if (!newVal) return
			this.goalDay = newVal.attributes.goal_day;
		},
	},
};
</script>

<style scoped>
::v-deep .selectedtext {
	color: white;
	padding-left: 0.75rem;
}

.user_image img {
	object-fit: cover;
	height: 100%;
	width: 100%;
}

::v-deep .imageContainer img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

section {
	padding: 0.5rem;
}

.modalCardContainer {
	max-width: 80%;
	max-height: 80%;
}

@media (max-width: 1026px) {
	#clientHeader {
		display: grid;
		grid-template-columns: 10% auto 10% 10%;
		/*grid-auto-columns: auto ;*/
	}
}

@media (max-width: 769px) {
	.modal-card-head {
		padding: 10px;
	}

	.columns {
		display: flex;
		flex-direction: row;
	}

	section {
		padding: 2px;
	}
}

@media (max-width: 650px) {
	.section {
		padding: 0;
	}

	.panel.m-5 {
		margin: 0.25rem !important;
	}

	.modal-card-head {
		padding: 0px;
	}

	.modal-card-foot {
		padding: 0.5rem;
	}

	.modalCardContainer {
		width: 90%;
		max-width: 100%;
	}

	section {
		padding: 2px;
	}

	.columns {
		display: flex;
		flex-direction: column;
	}

	.twoColumnContainer {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}

	.threeColumnContainer {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		margin-right: 1.5em;
	}
}

@media (max-width: 430px) {
	.twoColumnContainer {
		display: grid;
		grid-template-columns: 1fr;
	}

	.threeColumnContainer {
		display: grid;
		grid-template-columns: 1fr;
		margin-right: 1.5em;
	}

	.user_image img {
		object-fit: cover;
		height: 100%;
		width: 100%;
	}
}

p {
	color: black;
}
</style>
