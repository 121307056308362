<template>
	<div class="scrollable p-3" v-if="focusedEntry && focusedEntryLink && this.$store.state.entrylinks.loaded
		" ref="entryDetailPanel">
		<div class="detailContainer">
			<div class="mb-0 headContainer">
				<div class="entryHead">
					<h1 class="is-title mb-0">
						{{ focusedEntry.attributes.title }}
					</h1>
					<div class="is-font-medium">
						{{
							focusedEntry.attributes.author.attributes.dependency
								.attributes.first_name +
							" " +
							focusedEntry.attributes.author.attributes.dependency
								.attributes.last_name
						}},
						<time>
							{{
								focusedEntry.createdAt.toLocaleDateString(
									"de-DE",
									{
										year: "numeric",
										month: "2-digit",
										day: "2-digit",
									}
								) +
								" - " +
								focusedEntry.createdAt.toLocaleTimeString(
									"de-DE"
								)
							}}
						</time>
						<span style="float:right;" class="mr-1" v-if="focusedEntry.attributes.entryCounter">
							Eintrag: {{ focusedEntry.attributes.entryCounter }} </span>
					</div>
				</div>
				<div class="entryTypeTag">
					<small style="float: right; text-align: right">
						<div style="float: right" v-if="focusedEntry.attributes.type">
							<span class="tag m-1 is-dark is-rounded entryTag is-font-small" v-bind:style="{
								'background-color': focusedEntry.attributes.type.attributes.color,
								color: getColor(focusedEntry.attributes.type.attributes.color),
							}">
								{{ focusedEntry.attributes.type.attributes.name }}</span>

							<span v-if="focusedEntry.attributes.isPrivate" class="tag m-1 is-dark is-rounded entryTag is-font-small" v-bind:style="{
								'background-color': '#7b2ec8',
								color: '#ffffff',
							}">
								Privat</span>

						</div>
						<div style="float: right; margin-top: 6px" class="mr-2" v-if="focusedEntryLink.attributes.entry.attributes.numberOfAssociatedClients &&
							focusedEntryLink.attributes.entry.attributes.numberOfAssociatedClients > 1">
							<b-tooltip label="Klienten" position="is-bottom">
								<div style="
                                        display: grid;
                                        grid-template-columns: repeat(2, 0.1fr);
                                        grid-template-areas: 'icon num';
                                        cursor: pointer;
                                    " @click="openClientEntryLinkModal()">
									<div style="grid-area: icon">
										<b-icon icon="account-multiple">
										</b-icon>
									</div>
									<div style="grid-area: num">
										{{ focusedEntryLink.attributes.entry.attributes.numberOfAssociatedClients }}
									</div>
								</div>
							</b-tooltip>
							<client-entry-links-modal v-model="isClientEntryLinkModalOpen" />
							<!--b-icon icon="account-multiple" size="is-small">
							</b-icon>
							<span>Gruppeneintrag</span-->
						</div>
					</small>
				</div>
			</div>

			<span v-for="entry_attribute in focusedEntry.attributes.attributes" :key="entry_attribute.id"
				class="tag is-dark is-rounded entryTag mr-1 mb-2 is-font-small" v-bind:style="{
					'background-color':
						entry_attribute.attributes.attribute_config.attributes
							.color,
					color: getColor(
						entry_attribute.attributes.attribute_config.attributes
							.color
					),
				}">{{ entry_attribute.attributes.name }}</span>


			<div class="content mb-2 ml-1" v-if="focusedEntry">
				<div v-html="focusedEntry.attributes.text" class="mb-2"></div>
				<div v-if="focusedEntry.attributes.attachment" class="columns imagesContainer mb-1" style="flex-wrap: wrap">
					<div v-for="(image, i) in images" class="column is-one-third imageContainer" :key="i">
						<img class="image" :src="image" @click="index = i" />
					</div>
					<vue-gallery-slideshow :images="images" :index="index" @close="index = null"></vue-gallery-slideshow>
				</div>
				<div v-if="otherFiles">
					<hr style="height: 1px; background-color: black" />
					<div v-for="(file, i) in otherFiles" :key="i">
						<div class="fileItem">
							<b-icon icon="file-pdf-box" size="is-medium" />
							<a tag="a" :href="file.attributes.file._url" target="_blank"
								style="padding-top: 4px; margin-left: 6px">
								{{ file.attributes.fileTitle }}
							</a>
						</div>
					</div>
				</div>
			</div>
			<entry-rating :ratings="focusedRatings"></entry-rating>
			<hr class="mb-2 mt-2" />
			<entry-comment v-if="!focusedEntry.attributes.isPrivate" ref="commentPanel"></entry-comment>
			<entry-reader :readers="focusedEntryLink.attributes.readers
				? focusedEntryLink.attributes.readers
				: []
				" :timestamps="focusedEntryLink.attributes.reader_timestamps
		? focusedEntryLink.attributes.reader_timestamps
		: []
		"></entry-reader>
		</div>
	</div>
</template>

<script>
/**
 * Module displaying the content of an entry.
 * @displayName EntryDetail
 */

import Parse from "parse/dist/parse.min.js";
import VueGallerySlideshow from "vue-gallery-slideshow";
import EntryReader from "@/components/helper/EntryReader.vue";
import EntryComment from "@/components/helper/EntryComment.vue";
import EntryRating from "@/components/helper/EntryRating.vue";
import { mapGetters } from "vuex";
import { HEX2RGB } from "@/lib/Color";
import ClientEntryLinksModal from "@/components/modals/ClientEntryLinksModal";
import { printDiaryPdf } from "../../../lib/pdfPrinter/DiaryPrinter";

export default {
	name: "EntryDetail",
	props: ["focusedEntry", "focusedEntryLink"],
	data() {
		return {
			entry: undefined,
			index: null,
			commentInput: "",
			isLoading: false,
			clientsOnEntry: 1,
			deletedNotifications: false,
			isClientEntryLinkModalOpen: false,
		};
	},
	async mounted() {
		this.createReaders();
		this.deleteNotifications();
		//await this.countClientsOnEntry();
		this.$root.$on('printDiaryEntry', () => {
			this.printData()
		})
	},
	beforeDestroy() {
		this.$root.$off('printDiaryEntry')
	},
	created() {
		this.deletedNotifications = false;
		this.createReaders();
	},
	updated() {
		//this.countClientsOnEntry();
		this.createReaders();
		this.deleteNotifications();
	},
	computed: {
		...mapGetters({
			focusedClientId: "clients/getFocusedClientId",
			ratings: "entrylinks/getRatings",
			focusedClient: "clients/getFocusedClient",
			comments: "comments/getFilteredComments",
		}),
		images() {
			if (this.focusedEntry.attributes.pictures) {
				return this.focusedEntry.attributes.pictures.map(
					(pic) => pic._url
				);
			}
			return undefined;
		},
		otherFiles() {
			if (this.focusedEntry.attributes.otherFiles) {
				return this.focusedEntry.attributes.otherFiles
			}
			return undefined;
		},
		focusedRatings() {
			if (this.ratings) {
				return this.ratings.filter((link) => {
					return (
						link.attributes.entry.id == this.focusedEntry.id &&
						link.attributes.client.id == this.focusedClientId
					);
				});
			}
			return undefined;
		},
	},
	methods: {
		async printData() {
			printDiaryPdf(this.focusedEntry, this.focusedClient, this.comments, this.focusedRatings)
		},
		/*
		async countClientsOnEntry() {
			if (this.$store.state.entrylinks.loaded) {
				const EntryLink = Parse.Object.extend("EntryLink");
				const query = new Parse.Query(EntryLink);
				var entryPointer = {
					__type: "Pointer",
					className: "Entry",
					objectId: this.focusedEntry.id,
				};
				query.equalTo("entry", entryPointer);
				query.limit(1000000);
				const count = await query.count();
				this.clientsOnEntry = count;
			}
		}, */
		getColor(hex) {
			let number = 0;
			HEX2RGB(hex).forEach((num) => (number += num));
			if (number > 393) return "black";
			return "white";
		},
		deleteNotifications() {
			if (
				this.$store.state.entrylinks.loaded &&
				!this.deletedNotifications
			) {
				var clientPointer = {
					__type: 'Pointer',
					className: 'Client',
					objectId: this.focusedEntryLink.attributes.client.id
				}
				//delete notifications:
				const queryNotifications = new Parse.Query("Notification");
				queryNotifications.limit(1000000);
				const notificationsArray = [
					"newEntry",
					"newComment",
				];
				queryNotifications.containedIn("type", notificationsArray);
				queryNotifications.equalTo("user", Parse.User.current());
				queryNotifications.equalTo("client", clientPointer);
				queryNotifications.equalTo(
					"dependencyEntryLink",
					this.focusedEntryLink
				);

				queryNotifications
					.find()
					.then((notifications) => {
						for (const notification of notifications) {
							notification.destroy().catch((e) => { });
						}
					})
					.catch((e) => { });
				this.deletedNotifications = true;
			}
		},
		createReaders() {
			if (this.$store.state.entrylinks.loaded) {
				if (
					this.focusedEntry.attributes.author.id !==
					this.$store.state.currentUser.id
				) {
					if (
						this.focusedEntryLink.attributes.readers &&
						this.focusedEntryLink.attributes.readers.length > 0
					) {
						let readerIds =
							this.focusedEntryLink.attributes.readers.map(
								(element) => element.id
							);
						if (
							readerIds.indexOf(
								this.$store.state.currentUser.id
							) <= -1
						) {
							this.focusedEntryLink.set("readers", [
								...this.focusedEntryLink.attributes.readers,
								this.$store.state.currentUser,
							]);
							this.focusedEntryLink.set("reader_timestamps", [
								...this.focusedEntryLink.attributes
									.reader_timestamps,
								new Date(),
							]);
							this.focusedEntryLink
								.save()
								.then((result) => { })
								.catch((err) => {
									this.$buefy.toast.open({
										message: err.toString(),
										type: "is-danger",
										position: "is-bottom",
									});
									this.isLoading = false;
								});
						}
					} else if (
						(this.focusedEntryLink.attributes.readers &&
							this.focusedEntryLink.attributes.readers.length <=
							0) ||
						!this.focusedEntryLink.attributes.readers
					) {
						this.focusedEntryLink.set("readers", [
							this.$store.state.currentUser,
						]);
						this.focusedEntryLink.set("reader_timestamps", [
							new Date(),
						]);
						this.focusedEntryLink
							.save()
							.then((result) => { })
							.catch((err) => {
								this.$buefy.toast.open({
									message: err.toString(),
									type: "is-danger",
									position: "is-bottom",
								});
								this.isLoading = false;
							});
					}
				}
			}
		},
		openClientEntryLinkModal() {
			this.isClientEntryLinkModalOpen = true;
		},
		closeClientEntryLinkModal() {
			this.isClientEntryLinkModalOpen = false;
		},
		check_is_private(entryLink) {
			return entryLink.attributes.isPrivate;
		}
	},
	components: {
		ClientEntryLinksModal,
		VueGallerySlideshow,
		EntryReader,
		EntryComment,
		EntryRating,
	},
};
</script>

<style lang="scss" scoped>
.fileItem {
	flex-wrap: wrap !important;
	display: flex !important;
	align-items: flex-start !important;
	flex-direction: row !important;
	align-content: flex-start !important;
	justify-content: flex-start !important;
}

.image {
	width: 100%;
	height: 200px;
	background-size: cover;
	cursor: pointer;
	border-radius: 3px;
	border: 1px solid lightgray;
	object-fit: cover;
}

.scrollable {
	overflow: auto;
	height: 93%;
}

.scrollable::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollable {
	-ms-overflow-style: none;
	/* IE and Edge */
	scrollbar-width: none;
	/* Firefox */
}

.vgs {
	background-color: rgba(0, 0, 0, 1);
}

@media (min-width: 769px) {
	::v-deep div.vgs__container {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
}

@media (max-width: 769px) {
	.detailContainer {
		width: 100%;
	}

	.scrollable {
		height: 93%;
	}

	.entryHead {
		grid-area: head;
	}

	.entryTypeTag {
		grid-area: tag;
	}

	.imageContainer {
		// display: grid;
		// grid-template-columns: repeat(2, 1fr);
		width: 33%;
		display: inline-block;
	}

	.image {
		height: 100px;
	}
}
</style>
