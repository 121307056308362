<template>
    <div
        class="panel m-5"
        style="background-color: white; color: black"
        id="dataToPrint"
    >
        <div
            class="
                panel-heading
                is-flex
                is-flex-direction-row
                is-align-items-center
                is-justify-content-space-between
            "
            style="background-color: rgba(0, 0, 0, 0.8); color: white"
        >
            <p>Stammdaten</p>
            <b-button
                icon-left="download"
                @click="printData"
                type="is-primary"
                id="printButton"
            >
                Herunterladen
            </b-button>
        </div>
        <div class="py-2 is-flex is-flex-direction-column">
            <row
                title="Name, Vorname"
                :value="
                    user.attributes.last_name +
                    ', ' +
                    user.attributes.first_name
                "
            />
            <row title="Straße" :value="user.attributes.street" />
            <row
                title="PLZ, Ort"
                :value="getUserPlzLocation(user.attributes.plz, user.attributes.location)"
            />
            <row
                title="Mail"
                :value="user.attributes.email"
                :href="'mailto:' + user.attributes.email"
            />
            <row
                title="Telefon 1"
                :href="'tel:' + user.attributes.phone1"
                :value="user.attributes.phone1"
            />
            <row title="Info 1" :value="user.attributes.info1" />
            <row
                title="Telefon 2"
                :href="'tel:' + user.attributes.phone2"
                :value="user.attributes.phone2"
            />
            <row title="Info 2" :value="user.attributes.info2" />
        </div>
    </div>
</template>

<script>
import Row from "@/components/helper/Row";
import {getPlzLocation, printUserPdf} from "@/lib/pdfPrinter/UserPdf";

export default {
    name: "UserTable",
    components: { Row },
    props: ["user"],
    computed: {},
    methods: {
        async printData() {
            printUserPdf(this.user)
        },
		getUserPlzLocation(plz, location){
			return getPlzLocation(plz,location)
		}
    },
};
</script>

<style scoped>
hr {
    margin: 0.25rem !important;
}
</style>
