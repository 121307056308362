/**
 * specific function for live triggers to prevent duplicates in notifications
 */
export const setArray = (array) =>{
    const ids = []
    const newArray = []
    for(const item of array){
        if(!ids.includes(item.id)){
            newArray.push(item)
        }
        ids.push(item.id)
    }
    return newArray
}
