import Parse from 'parse/dist/parse.min.js';
import { handleParseError } from '../../../errorhandling';
import router from '../../../router/router';

let localSubscription = undefined;

async function createSubscription(store, clientId) {

	let client_uuid = "";

	let clientQuery = new Parse.Query('Client');
	clientQuery.equalTo("objectId", clientId);
	clientQuery.notEqualTo("deleted", true);
	await clientQuery.find().then(response => client_uuid = response[0].attributes.uuid);

	let all_clients = "";

	let clientQuery2 = new Parse.Query('Client');
	clientQuery2.equalTo("uuid", client_uuid);
	clientQuery2.notEqualTo("deleted", true);
	await clientQuery2.find().then(response => all_clients = response);

	let goalQuery = new Parse.Query('Goal');
	goalQuery.limit(1000000)

	goalQuery.notEqualTo("deleted", true)
	goalQuery.descending("createdAt")
	goalQuery.containedIn("client", all_clients)

	// var clientPointer = {
	// 	__type: 'Pointer',
	// 	className: 'Client',
	// 	objectId: clientId
	// }

	goalQuery.find()
		.then((results) => {
			store.dispatch('goals/set', results)
		})
		.catch((error) => {
			handleParseError(error);

		});

	goalQuery.subscribe().then((subscription) => {

		localSubscription = subscription

		subscription.on('create', object => {

			store.dispatch('goals/insert', object)
		});
		subscription.on('update', object => {

			store.dispatch('goals/replace', object)
		});
		subscription.on('enter', object => {

			store.dispatch('goals/insert', object)
			localSubscription.unsubscribe();
		});
		subscription.on('leave', object => {

			store.dispatch('goals/delete', object)
		});
		subscription.on('delete', object => {

			store.dispatch('goals/delete', object)
		});
		subscription.on('close', () => {

		});
		subscription.on('error', error => {

		})
	})
}


export default function createGoalSubscription() {
	return store => {
		store.subscribe(mutation => {
			if (mutation.type === 'clients/SET_FOCUSED_CLIENT_ID' && (router.currentRoute.name == "Goal" || router.currentRoute.name == "GoalDetail" ||
				router.currentRoute.name == "GoalEdit" || router.currentRoute.name == "GoalInput" || router.currentRoute.name == "GoalRating")) {
				if (localSubscription) {
					localSubscription.unsubscribe();
				}
				if(mutation.payload)
				{
					createSubscription(store, mutation.payload)
				}
				
			}
		}),
		store.subscribe(mutation => {
			if (mutation.type === 'ADD_LAST_ROUTE' && (router.currentRoute.name == "Goal" || router.currentRoute.name == "GoalDetail" ||
			router.currentRoute.name == "GoalEdit" || router.currentRoute.name == "GoalInput" || router.currentRoute.name == "GoalRating")) {
				if (localSubscription) {
					localSubscription.unsubscribe();
				}
				if (store.state.clients.focusedClientId) {
					
					createSubscription(store, store.state.clients.focusedClientId)
				}
			}
		})
	}
}
