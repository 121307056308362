<template>
    <div style="width: 100%; position: relative" class="parentTitle">
        <h3 class="is-subtitle" v-if="!isMobile">Titel</h3>
        <b-input
            maxlength="80"
            placeholder="Titel"
            id="entryTitle"
            v-model="entryTitle"
            v-on:input="$v.entryTitle.$touch"
            autocomplete="off"
            type="search"
            :size="isMobile ? 'is-small' : ''"
            @input="edit()"
        ></b-input>
        <div
            style="position: absolute; bottom: 0"
            class="tag is-danger is-light padded dangerTag"
            v-if="!$v.entryTitle.required"
        >
            Titel benötigt!
        </div>
    </div>
</template>

<script>
/**
 * Component for creation of a title on an entry.
 * @displayName DiaryTitle
 */

import { required } from "vuelidate/lib/validators";

export default {
    name: "DiaryTitle",
    props: ["diaryTitle", "setTitle", "editEntry"],
    data() {
        return {};
    },
    validations: {
        entryTitle: { required },
    },
    computed: {
        isMobile() {
            return this.$store.getters.isMobile;
        },
        entryTitle: {
            set(value) {
                this.setTitle(value);
            },
            get() {
                return this.diaryTitle;
            },
        },
    },
    methods: {
        edit() {
            if (this.editEntry) this.editEntry();
        },
    },
};
</script>


<style lang="scss" scoped>
.attribute_titel {
    font-weight: bold;
    margin: 1rem 0rem 0.25rem 0rem;
    //color: #5b7a99;
}

.parentTitle {
    min-height: 110px;
}

::v-deep .input {
    border-radius: 6px;
}

div.vgs__container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.padded {
    margin-top: 12px;
    margin-bottom: 12px;
}
.field:not(:last-child) {
    margin-bottom: 0;
}

@media (max-width: 769px) {
    .parentTitle {
        min-height: 60px;
        margin-bottom: 0.5rem;
    }
    .padded {
        padding: 0.25rem;
        margin: 0;
    }
}
</style>
