<template>
	<b-modal v-model="isActive" has-modal-card @close="closeModal()" :can-cancel="false">
		<div class="modal-card modalCardContainer" style="
							position: absolute;
							left: 50%;
							top: 50%;
							transform: translate(-50%, -50%);
					">
			<header class="modal-card-head" style="background-color: rgba(40, 40, 40, 1); color: white">
				Wichtiger Hinweis
			</header>
			<section class="modal-card-body" style="padding: 0.5em; color: black">
				<P>
					Sie verwenden eine veraltete Version. Ein Update wird dringend empfohlen.
					Mit "Jetzt updaten" werden Sie abgemeldet und wechseln unmittelbar auf die neue Version. Nicht
					gespeicherte Inhalte gehen dabei verloren.
				</P>
			</section>
			<footer class="modal-card-foot footerContainer" style="background-color: rgba(40, 40, 40, 1); color: white">
				<b-button @click="updateNow" type="is-primary">
					Jetzt updaten
				</b-button>
			</footer>
		</div>

	</b-modal>
</template>

<script>

import { mapGetters } from "vuex";

export default {
	name: "VersionModal",
	props: ["value"],
	data() {
		return {};
	},
	mounted() { },
	computed: {
		...mapGetters({
			dismissUpdate: "versions/getdismissUpdate",
		}),
		isActive: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit("input", val);
			},
		},
	},
	methods: {
		closeModal() {
            this.isActive = false;
        },
		updateLater() {
			//this.$store.dispatch("versions/setDismissUpdate", true);
			//this.isActive = false;
		},
		updateNow() {
			this.$store.state.focusedClientId = undefined;
			this.$store
				.dispatch("logoutUser")
				.then((succ) => {
					
					this.$buefy.toast.open({
						message: "Nutzer abgemeldet!",
						type: "is-success",
						position: "is-bottom",
					});
					this.isActive = false;
					let data = true
					//this.$router.push({ name: "Login", params: { data } });
				})
				.catch((err) => {
					
					this.$buefy.toast.open({
						message: "Fehler " + err,
						type: "is-error",
						position: "is-bottom",
					});
				});
		}
	}
}

</script>

<style scoped></style>
