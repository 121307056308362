<template>
	<div style="width: 100%">
		<h3 class="attribute_titel" v-if="!isMobile">Text</h3>
		<div class="editor_body">
			<editor id="entryBody" v-model="entryInput" v-on:input="$v.entryInput.$touch" @input="edit()" />
		</div>
		<div class="tag is-danger is-light padded dangerTag" v-if="!$v.entryInput.required">
			Eingabe benötigt!
		</div>
	</div>
</template>

<script>
/**
 * Component for the entry text editor.
 * @displayName EntryText
 */

import Editor from "@/components/helper/Editor";
import { required } from "vuelidate/lib/validators";
export default {
	name: "EntryText",
	components: { Editor },
	props: ["text", "setText", "editEntry"],
	validations: {
		entryInput: { required },
	},
	computed: {
		isMobile() {
			return this.$store.getters.isMobile;
		},
		entryInput: {
			set(value) {
				this.setText(value);
			},
			get() {
				return this.text;
			},
		},
	},
	methods: {
		edit() {
			if (this.editEntry) this.editEntry();
		},
	},
};
</script>

<style lang="scss" scoped>
/* Basic editor styles */
::v-deep .ProseMirror {
	padding: 10px;

	>*+* {
		margin-top: 0.75em;
	}

	code {
		background-color: rgba(#616161, 0.1);
		color: #616161;
	}
}

::v-deep .content {
	padding: 1rem 0 0;

	h3 {
		margin: 1rem 0 0.5rem;
	}

	pre {
		border-radius: 5px;
		color: #333;
	}

	code {
		display: block;
		white-space: pre-wrap;
		padding: 0.75rem 1rem;
		background-color: #e9ecef;
		color: #495057;
	}
}

::v-deep .editor_body .buttons {
	margin-bottom: 0px;
}

::v-deep .editor_body .buttons button {
	padding: 0px;
	padding-left: 10px;
	padding-right: 10px;
	height: auto;
}

.attribute_titel {
	font-weight: bold;
	margin: 1rem 0rem 0.25rem 0rem;
	//color: #5b7a99;
}

div.vgs__container {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.padded {
	margin-top: 12px;
	margin-bottom: 12px;
}

@media (max-width: 769px) {
	.field {
		padding: 0;
		margin-bottom: 0;
	}

	.padded {
		padding: 0.25rem;
		margin: 0;
	}
}
</style>
