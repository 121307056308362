<template>
    <div v-if="notifications && isLoaded">
        <div
            style="height: auto"
            v-for="notification of notifications"
            :key="notification.id + notification.attributes.client.id"
            class="
                notification-box
                p-0
                mb-2
                card-content
                collapseContentContainer
                box
            "
        >
            <article
                class="media p-3 article"
                @click="triggerNotificationTask(notification)"
                v-if="isAuthorLoaded &&
                    isAuthorMetaClassLoaded &&
                    areEntryLinksLoaded &&
                    areEntryTypesLoaded &&
                    areClientsLoaded">
                <div class="media-left">
                    <AuthorImage :client="notification.attributes.client" />
                </div>
                <div class="media-content" style="overflow: hidden">
                    <div class="mb-0">
                        <div class="columns mt-0 mb-0">
                            <div class="column pt-0 clientTagContainer is-flex is-justify-content-space-between">
                                <AuthorNameAndCreationTime
                                    :author-meta-class="
                                        notification.attributes.client
                                    "
                                    :group="
                                        notification.attributes.client
                                            .attributes.group
                                    "
                                />
								<div class="is-flex is-justify-content-space-between gap-2">
									<ColoredTag
										:tag-name="notification.attributes.message"
										tag-color="#000000"
									/>
									<ColoredTag
										v-if="type === 'diary'"
										:tag-name="
                                        notification.attributes.dependencyEntry
                                            .attributes.type.attributes.name
                                    "
										:tag-color="
                                        notification.attributes.dependencyEntry
                                            .attributes.type.attributes.color
                                    "
									/>
								</div>

                            </div>
                        </div>
                        <template
                            v-if="type === 'diary'"
                        >
                            <EntryTitle
                                :entry="notification.attributes.dependencyEntry"
                            />
                        </template>
						<template
							v-if="type === 'document' && areDocumentsLoaded"
						>
							<EntryTitle
								:entry="notification.attributes.clientFile"
							/>
						</template>
                    </div>
                    <template v-if="type === 'diary' || type === 'document'">
                        <AuthorNameAndCreationTime
                            :object="notification"
                            :author-meta-class="
                                notification.attributes.author
                                    .attributes.dependency"
                        />
                    </template>
                </div>
            </article>
        </div>
    </div>
</template>

<script>
/**
 * Component for the display of notificatons.
 * @displayName NotificationItems
 */

import ColoredTag from "@/components/tags/ColoredTag";
import EntryTitle from "@/components/helper/EntryTitle";
import AuthorNameAndCreationTime from "@/components/helper/AuthorNameAndCreationTime";
import AuthorImage from "@/components/helper/AuthorImage";
import { mapGetters } from "vuex";
import { HEX2RGB } from "@/lib/Color";
import Parse from "parse/dist/parse.min";

export default {
    name: "NotificationItems",
    props: ["notifications", "type"],
    components: {
        ColoredTag,
        EntryTitle,
        AuthorNameAndCreationTime,
        AuthorImage,
    },
    computed: {
        ...mapGetters({
            areEntryLinksLoaded: "entrylinks/areEntryLinksLoaded",
            isAuthorLoaded: "users/isAuthorLoaded",
            isAuthorMetaClassLoaded: "usermetas/isAuthorMetaClassLoaded",
            areEntryTypesLoaded: "entrytypes/areEntryTypesLoaded",
			areDocumentsLoaded: "clientrecords/areDocumentsLoaded",
            entryNotifs: "notifications/getFilteredEntryNotifications",
            entryEditNotifs: "notifications/getFilteredEntryEditNotifications",
			commentNotifs: "notifications/getFilteredCommentNotifications",
			areClientsLoaded: "clients/areClientsLoaded",
			focusedClient: "clients/getFocusedClient",
        }),
    },
    data() {
        return {
            isLoaded: false,
        };
    },
    async mounted() {
        const query = new Parse.Query("EntryTypes");
        await query.find();
        this.isLoaded = true;
    },
    methods: {
        async triggerNotificationTask(notification) {
            await this.$store.dispatch("entrylinks/load", false);
            await this.$store.dispatch("setSidePanelOpen", false);
            await this.$store.dispatch(
                "clients/setFocusedClientId",
                //notification.attributes.client.id
				this.focusedClient.id
            );

            if (this.type === "diary") {
                await this.$store.dispatch(
                    "setFocusedEntryId",
                    notification.attributes.dependencyEntryLink.attributes.entry
                        .id
                );
				await this.$store.dispatch("setFocusedEntry", notification.attributes.dependencyEntryLink.attributes.entry);
                await this.$store.dispatch(
                    "entrylinks/setFocusedEntryLinkId",
                    notification.attributes.dependencyEntryLink.id
                );
            }

            if (this.type === "diary") {
                // await this.$router.push(`/module/diary`);
                await this.$router.push({
					name: 'EntryDetail',
					params: {
						focusedEntry: notification.attributes.dependencyEntryLink.attributes.entry,
						focusedEntryLink: notification.attributes.dependencyEntryLink
					}
				});
            }
			if (this.type === "document") {
				await this.$router.push({
					name: "ClientRecordDetail",
					params: {
						id:  notification.attributes.clientFile.id,
					},
				});
			}




        },
        getColor(hex) {
            let number = 0;
            HEX2RGB(hex).forEach((num) => (number += num));
            if (number > 393) return "black";
            return "white";
        },
    },
};
</script>

<style scoped>
.margin-desktop-left-1 {
    margin-left: 0.25rem !important;
}
.notification-box {
    margin-right: 0.5em;
    margin-left: 0.5em;
    cursor: pointer;
    padding-bottom: 15px;
}

.box {
    margin-right: 5px;
    margin-left: 5px;
    margin-bottom: 0.5em;
    padding: 12px;
    border-radius: 15px;
}

.box:hover {
    background-color: whitesmoke;
    cursor: pointer;
}

.gap-2{
	gap: .25rem;
}

@media (max-width: 769px) {
    .tabs:not(:last-child),
    .pagination:not(:last-child),
    .message:not(:last-child),
    .level:not(:last-child),
    .breadcrumb:not(:last-child),
    .block:not(:last-child),
    .title:not(:last-child),
    .subtitle:not(:last-child),
    .table-container:not(:last-child),
    .table:not(:last-child),
    .progress:not(:last-child),
    .progress-wrapper.is-not-native:not(:last-child),
    .notification:not(:last-child),
    .content:not(:last-child),
    .box:not(:last-child) {
        margin-bottom: 0;
    }

    .media-left {
        margin-right: 0.25em;
    }

    .column {
        padding-bottom: 0;
    }
}

@media (max-width: 450px) {
    .margin-desktop-left-1 {
        margin-left: 0 !important;
    }
    .clientTagContainer {
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 0.2rem;
        margin-bottom: 0.2rem;
    }
}
</style>
