<template>
    <div style="height: 100%; overflow: auto">
        <!--        <div class="panel m-5" style="background-color: white">-->
        <!--            <p class="panel-heading">Profilverwaltung</p>-->
        <!--        </div>-->
        <div style="background-color: white; border-radius: 5px; padding: 5px" v-if="currentUserMeta">
            <div class="panel m-5" style="background-color: white">
                <p
                    class="panel-heading"
                    style="background-color: rgba(0, 0, 0, 0.8); color: white"
                >
                    Profilbild
                </p>
                <div class="columns is-centered">
                    <div
                        class="
                            column
                            image_column
                            profilePictureChangeContainer
                        "
                    >
                        <div class="profilePictureContainer mt-2">
                            <div style="display: flex; justify-content: center">
                                <b-upload
                                    v-model="newProfilePicture"
                                    accept="image/*"
                                    style="cursor: pointer"
                                >
                                    <b-image
                                        class="profileImage is-128x128"
                                        :src="getPicture"
                                        alt="Profilbild"
                                        rounded
                                    ></b-image>
                                </b-upload>
                            </div>
                            <div style="display: flex; justify-content: center">
                                <button
                                    v-if="
                                        newProfilePicture ||
                                        this.userProfilePicture
                                    "
                                    class="delete is-small mt-2"
                                    type="button"
                                    @click="removePicture()"
                                ></button>
                            </div>
                        </div>
                        <section
                            class="buttons mt-2"
                            style="display: flex; justify-content: center"
                        >
                            <b-button
                                type="is-primary"
                                @click="saveNewUserPicture()"
                                >Speichern</b-button
                            >
                        </section>
                    </div>
                </div>
            </div>
            <user-table :user="currentUserMeta" />
            <div class="panel m-5" style="background-color: white">
                <p
                    class="panel-heading"
                    style="background-color: rgba(0, 0, 0, 0.8); color: white"
                >
                    Gruppen
                </p>
                <div
                    class="pb-3 pt-3"
                    style="display: flex; justify-content: center"
                >
                    <ul>
                        <li
                            class="content column pt-0 pb-0 mb-0"
                            style="width: 100%; list-style-type: none"
                            v-for="group in currentUserMeta.attributes.groups"
                            :key="group.id"
                        >
                            <p style="font-weight: 500">
                                {{ group.attributes.group_name }}
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="panel m-5 pb-2" style="background-color: white">
                <p
                    class="panel-heading"
                    style="background-color: rgba(0, 0, 0, 0.8); color: white"
                >
                    Passwort
                </p>
                <b-field
                    label="Neues Passwort"
                    class="pl-2 pr-2 pt-2"
                    :message="
                        !$v.newPassword.$invalid
                            ? ''
                            : 'Passwort ist nicht lang genug!'
                    "
                    :type="
                        !$v.newPassword.$invalid ? 'is-success' : 'is-danger'
                    "
                >
                    <b-input
                        v-model="newPassword"
                        v-on:input="$v.newPassword.$touch"
                        password-reveal
                        type="password"
                        autocomplete="off"
                    ></b-input>
                </b-field>
                <b-field
                    label="Neues Passwort wiederholen"
                    class="pl-2 pr-2"
                    :message="
                        !$v.repeatNewPassword.$invalid
                            ? ''
                            : 'Passwort stimmt nicht überein!'
                    "
                    :type="
                        !$v.repeatNewPassword.$invalid &&
                        !$v.newPassword.$invalid
                            ? 'is-success'
                            : 'is-danger'
                    "
                >
                    <b-input
                        v-model="repeatNewPassword"
                        v-on:input="$v.repeatNewPassword.$touch"
                        password-reveal
                        type="password"
                        autocomplete="off"
                    ></b-input>
                </b-field>
                <div style="display: flex; justify-content: center">
                    <b-button
                        type="is-primary"
                        @click="saveNewPassword()"
                        v-bind:disabled="$v.$invalid"
                        >Passwort speichern</b-button
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/**
 * Module for the management of the current user.
 * @displayName Profile
 */

import { mapGetters } from "vuex";
import Parse from "parse/dist/parse.min.js";
import { v4 as uuid } from "uuid";
import { required, sameAs, minLength } from "vuelidate/lib/validators";
import { getModifiedImage, getUserPicture } from "../../../lib/imageFunctions";
import UserTable from "../../helper/UserTable.vue";
export default {
    components: { UserTable },
    name: "ModuleSelection",
    data() {
        return {
            newPassword: "",
            repeatNewPassword: "",
            showSettings: false,
            newProfilePicture: undefined,
            newProfilePictureModified: undefined,
        };
    },
    mounted() {
        this.$store.dispatch("setDeleteUserPicture", false);
    },
    updated() {},
    computed: {
        ...mapGetters({
            userProfilePicture: "usermetas/getUserProfilePicture",
            userFullName: "usermetas/getUserFullName",
            currentUserMeta: "usermetas/getCurrentUserMeta",
        }),
        newProfilePictureUrl() {
            if (this.newProfilePicture) {
                return URL.createObjectURL(this.newProfilePicture);
            }
            return undefined;
        },
        isActive: {
            get() {
                return this.value;
            },
            set(val) {
                if (val == false) {
                    this.newPassword = "";
                    this.repeatNewPassword = "";
                    this.newProfilePicture = undefined;
                    this.newProfilePictureModified = undefined;
                    this.showSettings = false;
                }
                this.$emit("input", val);
            },
        },
        getPicture() {
			if(!this.currentUserMeta) return undefined
            const pictureObject = {
                newProfilePicture: this.newProfilePicture,
                userPicture: this.userProfilePicture,
                userGender: this.currentUserMeta.attributes.gender,
            };
            return getUserPicture(pictureObject);
        },
    },
    validations: {
        newPassword: { required, minLength: minLength(6) },
        repeatNewPassword: {
            sameAsPassword: sameAs("newPassword"),
        },
    },
    methods: {
        closeModal() {
            this.$store.dispatch("setDeleteUserPicture", false);
            this.isActive = false;
            this.showSettings = false;
        },
        removePicture() {
            this.newProfilePicture = undefined;
            this.$store.dispatch("setDeleteUserPicture", true);
        },
        logout() {
            this.$store.state.clients.focusedClientId = undefined;
            this.$store
                .dispatch("logoutUser")
                .then((succ) => {
                 
                    this.$buefy.toast.open({
                        message: "Nutzer abgemeldet!",
                        type: "is-success",
                        position: "is-bottom",
                    });
                    this.isActive = false;
                    //this.$router.push({ name: "Login" });
                })
                .catch((err) => {
                    
                    this.$buefy.toast.open({
                        message: "Fehler " + err,
                        type: "is-error",
                        position: "is-bottom",
                    });
                });
        },
        async saveNewUserPicture() {
            const UserMetaClass = Parse.Object.extend("UserMetaClass");
            const query = new Parse.Query(UserMetaClass);
            let usermeta = undefined;
            try {
                usermeta = await query.get(
                    Parse.User.current().attributes.dependency.id
                );
            } catch (e) {
                console.error("couldnt find the usermeta" + e);
            }
            if (usermeta) {
                if (
                    this.$store.state.deleteUserPicture &&
                    !this.newProfilePicture
                ) {
                    usermeta.unset("profile_picture");
                }
                if (this.newProfilePicture !== undefined) {
                    const imageFile = new Parse.File(
                        uuid(),
                        { base64: this.newProfilePictureModified },
                        "image/jpeg"
                    );
                    usermeta.set("profile_picture", imageFile);
                    await imageFile.save();
                }
                try {
                    await usermeta.save();
                } catch (e) {
                    console.error("couldnt save the usermeta " + e);
                }
            }
            await this.$store.dispatch("setDeleteUserPicture", false);
            this.newProfilePicture = undefined;
            this.newProfilePictureModified = undefined;
            this.$buefy.toast.open({
                message: "Neues Profilbild gespeichert!",
                type: "is-primary",
                position: "is-bottom",
            });
        },
        async saveNewPassword() {
            let currentUser = Parse.User.current();
            await currentUser.setPassword(this.newPassword);
            await currentUser.save();
            this.$buefy.toast.open({
                message: "Neues Passwort gespeichert!",
                type: "is-primary",
                position: "is-bottom",
            });
        },
        setIsActive(value) {
            this.isActive = value;
        },
    },
    watch: {
        newProfilePicture: {
            handler: function (fileArray) {
                if (fileArray) {
                    this.newProfilePictureModified = undefined;
                    if (fileArray.type.match(/image.*/)) {
                        getModifiedImage(fileArray, 150)
                            .then((response) => {
                                this.newProfilePictureModified = response;
                            })
                            .catch(function (error) {
                                console.error(error);
                            });
                    }
                }
            },
            deep: true,
        },
    },
};
</script>

<style>
.profileImage.image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
</style>
<style scoped>
.profilePictureInit {
    min-width: 50px;
}

.user_image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.profileImage.image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.module-tile {
    flex: 1;
    flex-basis: 25%;
    min-width: 0;
    max-width: 25%;
    overflow: hidden;
}

@media (max-width: 769px) {
    .module-tile {
        flex-basis: 50%;
        max-width: 50%;
    }

    ::v-deep div > label {
        margin-top: 0.25rem;
    }

    ::v-deep button.is-primary {
        padding: 0.25rem 0.5rem !important;
    }

    .columns {
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 7fr;
    }

    .box {
        margin-right: 0.5px;
        margin-left: 0.5px;
        margin-bottom: 0.5em;
        background-color: rgb(185, 205, 216);
        cursor: pointer;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .column {
        padding: 0;
    }

    .button {
        padding: 0.1em;
    }

    div.column.is-one-quarter {
        justify-self: center;
    }

    .iconContainer {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0.3em;
    }

    .footerContainer {
        display: grid;
        padding: 0.5em;
        grid-template-columns: 1fr 1fr;
        gap: 0.3em;
    }

    ::v-deep.closeButton > span {
        width: 100% !important;
        padding: 0;
        margin: 0;
        justify-self: center;
    }

    .columns {
        padding: 0;
        display: flex;
    }

    .profilePictureChangeContainer {
        display: grid;
        margin: 0.5em 0;
    }

    .section {
        padding: 0.5em;
    }

    .profilePictureInit {
        height: 100% !important;
    }
    ::v-deep button.button.is-primary span {
        width: 100%;
        margin-top: 0.8em;
        height: 100%;
    }

    .container {
        padding: 1em;
    }

    ::v-deep .backButton > span {
        width: 100% !important;
        height: auto !important;
        margin-left: 0;
    }
}

@media (max-width: 650px) {
    .modal-card-foot {
        padding: 0.5rem;
    }
    .panel.m-5 {
        margin: 0.75rem 0.5rem 1rem 0.5rem !important;
    }
    .modalCardContainer {
        width: 90% !important;
        overflow: auto;
    }
}

@media (max-width: 550px) {
    .iconContainer {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    ::v-deep button.is-primary {
        padding: 0.2rem 0.3rem !important;
    }
}
</style>
