<template>
	<div>
		<template v-if="focusedClient">
			<ChildStonks/>
		</template>
		<template v-else>
			<div
				ref="entryPanel"
				class="scrollable is-flex is-flex-direction-column"
			>
				<article class="p-3 article" style="text-align: center">
					Kein Klient ausgewählt!
				</article>
			</div>
		</template>
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import ChildStonks from "@/components/charts/ChildStonks";

export default {
	name: "Charts",
	components: {ChildStonks},
	computed: {
		...mapGetters({
			focusedClient: "clients/getFocusedClient"
		})
	}

}
</script>

<style scoped>

</style>
