<template>
    <router-link
        tag="div"
        :to="{
            path: `/`,
        }"
        v-if="currentUserId && currentUserMeta"
        class="is-flex is-align-items-center"
    >
        <figure
            class="user_image image is-48x48 is-flex is-align-items-center"
            id="userImage"
            style="cursor: pointer"
        >
            <img
                id="userImageOpenModal"
                class="is-rounded"
                :src="getPicture"
                alt="Profilbild"
            />
        </figure>
    </router-link>
</template>

<script>
/**
 * TopBar profile picture of the signed-in user.
 * @displayName UserImage
 */

import { mapGetters } from "vuex";
import { getUserPicture } from "@/lib/imageFunctions";

export default {
    name: "UserImage",
    data() {
        return {};
    },
    mounted() {},
    updated() {},
    computed: {
        ...mapGetters({
            userProfilePicture: "usermetas/getUserProfilePicture",
            currentUserId: "users/getUserId",
            numberOfNotifications: "getNumberOfNotifications",
            currentUserMeta: "usermetas/getCurrentUserMeta",
        }),
        searchQuery: {
            set(searchQuery) {
                this.$store.dispatch("setSearchQuery", searchQuery);
            },
            get() {
                // Or remove mapState and use this.$store.state.values.example
                return this.$store.state.searchQuery;
            },
        },
        getPicture() {
            const pictureObject = {
                newProfilePicture: undefined,
                userPicture: this.userProfilePicture,
                userGender: this.currentUserMeta.attributes.gender,
            };
            return getUserPicture(pictureObject);
        },
    },
    methods: {
        logout() {
            this.$store
                .dispatch("logoutUser")
                .then((succ) => {
                    this.$buefy.toast.open({
                        message: "Nutzer abgemeldet!",
                        type: "is-success",
                        position: "is-bottom",
                    });
                   // this.$router.push({ name: "Login" });
                })
                .catch((err) => {
                    console.error(err);
                    this.$buefy.toast.open({
                        message: "Fehler " + err,
                        type: "is-error",
                        position: "is-bottom",
                    });
                });
        },
    },
    components: { },
};
</script>

<style scoped>
.side-bar {
    background-color: rgb(185, 205, 216);
}

.top-bar {
    opacity: 0.5;
}

.padded {
    padding: 0.25em;
}

.columns.is-gapless:not(:last-child) {
    margin-bottom: 0em;
}

.user_image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

#userImageOpenModal {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
@media (max-width: 769px) {
    .user_image span {
        bottom: -2px;
    }

    #userImageOpenModal {
        width: 80%;
        height: 80%;
        object-fit: cover;
    }

    .figureContainer {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    .userTopBar {
        display: grid;
        grid-template-columns: 1fr 7fr;
    }
}
</style>
