<template>
	<div class="is-flex is-flex-direction-column" style="width:100%;">
		<template>
			<section v-if="filteredMedicationLog.length > 0" class="modal-card-body" style="padding: 0.5em; color: #313131">
				<p>
					<b v-if="medicationDisplayName">Medikament: </b>{{
						medicationDisplayName
					}}
				</p>
				<table expanded class="medicationPlanTable mt-3">
					<tr>
						<th><small>Tag</small></th>
						<th><small>Zeit</small></th>
						<th><small>Menge</small></th>
						<th><small>Status</small></th>
						<th><small>Begründung für Verweigerung/Sonstige Anmerkungen</small></th>
					</tr>
					<tr v-for="option in filteredMedicationLog" :key="option.index">
						<td>
							<small>
								{{ option.attributes.date.toLocaleDateString("de-DE",
									{
										year: "numeric",
										month: "2-digit",
										day: "2-digit",
									}) }}
								({{ weekArray[option.attributes.date.getDay()] }})
							</small>
						</td>
						<td>
							<small>
								{{ option.attributes.time }}
							</small>
						</td>
						<td>
							<small
								v-if="filteredMedicationLog[0].attributes.medication.attributes.medication.attributes.medication.attributes">
								{{ option.attributes.amount }}
								{{
									filteredMedicationLog[0].attributes.medication.attributes.medication.attributes.medication.attributes.medicationUnit.attributes.symbol
								}}
							</small>
						</td>
						<td
							v-if="proppedMedicationObject.attributes.dropDate ? proppedMedicationObject.attributes.dropDate.setHours(0, 0, 0, 0) <= option.attributes.date.setHours(0, 0, 0, 0) : false">
							<small>
								Abgesetzt
							</small>
						</td>
						<td v-else>
							<small>
								{{ option.attributes.state ? createStateString(option) : "" }}
							</small>
						</td>
						<td>
							<small>
								{{ option.attributes.hint ? option.attributes.hint : "" }}
							</small>
						</td>
					</tr>
				</table>
			</section>
			<section v-else>
				Keine Log-Einträge vorhanden.
			</section>
		</template>
	</div>
</template>

<script>
//TODO Die Platzhalter füllen. Evtl den Part mit den Inputs nicht nur durch 2 Schleifen (Tage und Zeiten) ersetzen, sondern in eine seperate Komponente auslagern.
/**
 * Sub-Module allowing the creation of a new medicationplan.
 * @displayName MedicationPlanInput
 *  
 */

import { mapGetters } from "vuex";

export default {
	name: "MedicationLog",
	props: ["proppedMedicationObject"],
	components: {
	},
	emits: [],

	data() {
		return {
			weekArray: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
		};
	},
	mounted() { },
	updated() { },
	computed: {
		...mapGetters({
			medicationLog: "medicationLogEntries/getData",
			focusedEntry: "medicationEntries/getFocusedMedicationEntry",
		}),
		filteredMedicationLog: function () {
			return (this.medicationLog.filter((element) => element.attributes.medication && element.attributes.medication.id === this.focusedEntry.id)).sort(this.compareByOrderDate)
		},
		medicationDisplayName: function () {
			if (this.filteredMedicationLog && this.filteredMedicationLog.length != 0) {
				return this.filteredMedicationLog[0].attributes.medication.attributes.medication.attributes.medication.attributes.displayName;
			} else {
				return "";
			}

		}
	},
	methods: {
		compareByOrderDate(a, b) {
			return b.attributes.date - a.attributes.date;
		},
		createStateString(logObject) {
			let state = logObject.attributes.state
			let externalMedication = logObject.attributes.externalMedication
			let returnString = ""
			if (state === 0) {
				returnString = "Keine Angabe"
			} else if (state === 1) {
				returnString = "Genommen"
			} else if (state === -1) {
				returnString = "Verweigert"
			}
			if(externalMedication){
				returnString = returnString + " (Fremdvergabe)"
			}
			return returnString
		}
	},
	watch: {
		proppedMedicationObject: {
			handler: function (newMedicationObject) {
			},
			deep: true,
		},
	}
};
</script>

<style scoped>
.medicationPlanTable {
	width: 100%;
}

.medicationPlanTable td,
.medicationPlanTable th {
	border: 1px solid #313131;
	padding: 2px;
	vertical-align: middle;
}
</style>
