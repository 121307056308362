<template>
	<div style="height: 100%; overflow: auto">
		<div class="card ml-2 p-2 mr-2 is-flex is-flex-wrap-wrap">
			<b-button class="m-1" :class="getButtonClass(undefined)" @click="choseAll()">Alle</b-button>
			<b-button class="m-1" :class="getButtonClass(12)" @click="choseTwelve()">12 Monate</b-button>
			<b-button class="m-1" :class="getButtonClass(6)" @click="choseSix()">6 Monate</b-button>
			<b-button class="m-1" :class="getButtonClass(3)" @click="choseThree()" id="defaultMonthButton">3
				Monate</b-button>
		</div>
		<DiarySkeleton v-if="isLoading" :isCharts="true" />
		<div v-if="!isLoading && charts.length !== 0 && entryLinks" :key="focusedClient.id">

			<div v-for="chart in charts" :key="chart.id">
				<div v-if="chart.showChart" class="box entry-box p-1 my-1 is-flex is-flex-direction-column">
					<article class="media" @click="route(chart)">
						<div class="media-left">
							<b-icon icon="chart-box" class="pt-2 pl-2" :size="isMobile ? 'is-medium' : 'is-large'"></b-icon>
						</div>
						<div class="media-content p-3">
							<ColoredTag :tag-name="avgLabel(chart.avg.all)" tag-color="#000000CC" :is-right="true" />
							<ColoredTag class="mr-1" :tag-name="slopeLabel(chart)" :tag-color="slopeColor(chart)"
								:is-right="true" />
							<small class="is-font-small">
								{{ chart.attrConfig }}
								<time>
									{{
										chart.start.toLocaleDateString(
											"de-DE",
											{
												year: "numeric",
												month: "2-digit",
												day: "2-digit",
											}
										) +
										" - " +
										chart.end.toLocaleDateString(
											"de-DE",
											{
												year: "numeric",
												month: "2-digit",
												day: "2-digit",
											}
										)
									}}
								</time>
							</small>
							<div class="is-title">
								{{ chart.attrName }}
							</div>
							<div class="text-box" :key="focusedClient.id">
								<AttributeChart :id="chart.id" :data="chart.data.all.dataset" :clientId="focusedClient.id"
									:numberStats="chart.data.all.numberStats" :maxNumberStats="maxNumberStats" />
							</div>
						</div>
					</article>
				</div>
			</div>
		</div>
		<div v-if="charts.length === 0 && !isLoading" class="p-2 is-flex is-justify-content-center">
			Keine Einträge
		</div>
		<div class="article has-darker-grey-color white-text m-2 p-3" style="text-align: center" v-if="focusedClient">
			<template v-if="focusedClient.attributes.entry">
				Zugang am
				{{
					focusedClient.attributes.entry.toLocaleDateString("de-DE", {
						year: "numeric",
						month: "2-digit",
						day: "2-digit",
					})
				}}
			</template>
			<template v-else>
				kein Zugangsdatum definiert
			</template>
			<!--			<b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>-->
		</div>
	</div>
</template>

<script>

import DiarySkeleton from "@/components/modules/diary/DiarySkeleton";
const createDates = (minDate) => {
	const today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1)
	const dates = []
	const minDateCopy = new Date(minDate.getTime())
	for (let newDateTime = minDateCopy.getTime(); newDateTime <= today.getTime(); newDateTime += 1000 * 60 * 60 * 24) {
		dates.push(new Date(newDateTime).toLocaleDateString("de-DE", {
			year: "numeric",
			month: "2-digit",
			day: "2-digit",
		}))
	}
	return dates
}

import { mapGetters } from "vuex";
import ColoredTag from "@/components/tags/ColoredTag";
import Parse from "parse/dist/parse.min"
import AttributeChart from "@/components/charts/AttributeChart";

export default {
	name: "ChildStonks",
	components: {
		DiarySkeleton,
		ColoredTag,
		AttributeChart
	},
	data() {
		return {
			chart: undefined,
			chartOptions: {
				responsive: true
			},
			charts: [],
			chartsDiagrams: [],
			clientId: undefined,
			isLoading: false,
			maxNumberStats: 1,
		}
	},
	computed: {
		...mapGetters({
			focusedClient: "clients/getFocusedClient",
			entryLinks: "entrylinks/getFilteredEntryLinks",
			areEntryLinksLoaded: "entrylinks/areEntryLinksLoaded",
			areAuthorMetaClassesLoaded: "usermetas/isAuthorMetaClassLoaded",
			areAuthorsLoaded: "users/isAuthorLoaded",
			isMobile: "isMobile",
			months: "getChartMonths"
		}),
	},
	async mounted() {
		if (this.areEntryLinksLoaded && this.charts.length === 0) {
			this.isLoading = true
			await this.updateData()
		}
		await this.$store.dispatch("setChartMonths", 3)
	},
	updated() {
		this.clientId = this.focusedClient.id
	},
	watch: {
		focusedClient() {
			// this.isLoading = true
			this.charts = []
		},
		entryLinks() {
			if (this.entryLinks === undefined || this.entryLinks.length === 0) {
				this.isLoading = false
				return this.charts = []
			} else {
				this.isLoading = true;
			}
			this.updateData()
		},
		months() {
			if (this.entryLinks === undefined || this.entryLinks.length === 0) {
				this.isLoading = false
				return this.charts = []
			} else {
				this.isLoading = true;
			}
			this.updateData()
		}
	},
	methods: {
		getButtonClass(month) {
			let className = ""
			if (month === this.months && this.isMobile) return "monthSelectionFocus is-small"
			if (month === this.months) return "monthSelectionFocus"
			if (month !== this.months && this.isMobile) return "monthSelection is-small"
			if (month !== this.months) return "monthSelection"
			return ""
		},
		async updateData() {
			if (this.entryLinks) {
				this.isLoading = true

				//this.message = "Daten laden!"
				this.charts = []
				const params = { clientId: this.focusedClient.id, months: this.months }
				const clientMonthsData = await Parse.Cloud.run("newChartAll", params)

				const entryLinks = this.entryLinks;
				let lastDate = new Date();
				if (entryLinks) {
					lastDate = entryLinks.length > 0 ? entryLinks[entryLinks.length - 1].attributes.createdAt : new Date();
				}


				let minDate = undefined
				if (this.months) {
					const today = new Date()
					minDate = new Date(today.getFullYear(), today.getMonth() - this.months, today.getDate())
					if (lastDate > minDate) minDate = lastDate
				} else {
					minDate = new Date(lastDate.getFullYear(), lastDate.getMonth(), lastDate.getDate())
				}
				const dates = createDates(minDate)
				clientMonthsData.forEach(chart => {

					for (const authorId in chart.data) {
						const newRatings = new Array(dates.length).fill(0)
						const newNumbers = new Array(dates.length).fill(0)
						const newDates = dates
						let i = 0
						let startDate = new Date(minDate.getTime())
						let endDate = new Date()
						for (const date of chart.data[authorId]["months"]) {
							if (startDate < new Date(+date)) {
								startDate = new Date(+date)
							}
							if (endDate > new Date(+date)) {
								endDate = new Date(+date)
							}
							const dateString = new Date(+date).toLocaleDateString("de-DE", {
								year: "numeric",
								month: "2-digit",
								day: "2-digit",
							})
							const index = dates.indexOf(dateString)
							newRatings[index] = chart.data[authorId]["ratings"][i]
							newNumbers[index] = chart.data[authorId]["numbers"][i]
							i++
						}
						const numberOfRatedDay = (startDate.getTime() - endDate.getTime()) / (1000 * 60 * 60 * 24)
						const newSlope = chart.data[authorId]["slope"] * numberOfRatedDay
						chart.data[authorId]["newDates"] = newDates
						chart.data[authorId]["newNumbers"] = newNumbers
						chart.data[authorId]["newRatings"] = newRatings
						chart.data[authorId]["newSlope"] = Math.round(newSlope * 10) / 10
					}

				})

				if (!clientMonthsData) {
					//this.message = "Keine Daten gefunden!"
					this.isLoading = false
					return this.charts = []

				}

				clientMonthsData.forEach(attrData => {
					const labels = attrData.data["all"].newDates

					attrData.showChart = false
					const data = attrData.data["all"].newRatings.map(d => {
						if (d === 0) return null
						attrData.showChart = true
						return d
					})

					const colorsData = data.map(d => {
						if (attrData.data["all"]["slope"] === 0) return "#000000"
						if (attrData.data["all"]["slope"] > 0) return "#178a1f"
						if (attrData.data["all"]["slope"] < 0) return "#a11014"
					})

					attrData.data["all"].dataset = {
						labels: labels,
						datasets: [{
							label: "Durchschnitt",
							data: data,
							backgroundColor: colorsData,
							borderColor: colorsData,
							borderWidth: 2,
							pointBackgroundColor: colorsData,
							pointBorderColor: colorsData,
							pointRadius: 0.1,
						},
						{
							label: "Bewertungsgrenze",
							data: data.map(d => -2.5),
							borderColor: "rgba(0,0,0,.8)",
							borderWidth: .5,
							pointRadius: 0,
							borderDash: [10, 5]
						}]
					}

					const maxEntryLinks = Math.max(...attrData.data["all"].newNumbers)
					if (maxEntryLinks > this.maxNumberStats) this.maxNumberStats = maxEntryLinks
					attrData.data["all"].numberStats = {
						labels: labels,
						datasets: [
							{
								label: "Anzahl Einträge",
								data: attrData.data["all"].newNumbers,
								backgroundColor: colorsData,
								borderColor: colorsData,
								color: colorsData,
								borderWidth: 2,
							}
						]
					}
				})
				this.charts = clientMonthsData.sort((chart1, chart2) => chart1.avg["all"] - chart2.avg["all"])

			}

			this.isLoading = false

		},
		slopeLabel(chart) {
			return `Trend: ${chart.data["all"].newSlope}`
		},
		slopeColor(chart) {
			if (chart.data["all"].newSlope > 0) return "#178a1f"
			if (chart.data["all"].newSlope === 0) return "#000000CC"
			return "#a11014"
		},
		avgLabel(avg) {
			return `Durchschnitt: ${-Math.round(avg * 10) / 10}`
		},
		async route(chart) {
			await this.$store.dispatch("setChartDetails", chart)
			await this.$router.push({ name: "ChartDetails" })
		},
		async choseAll() {
			await this.$store.dispatch("setChartMonths", undefined)
			this.isLoading = true;
			await this.updateData()
		},
		async choseTwelve() {
			await this.$store.dispatch("setChartMonths", 12)
			this.isLoading = true;
			await this.updateData()
		},
		async choseSix() {
			await this.$store.dispatch("setChartMonths", 6)
			this.isLoading = true;
			await this.updateData()
		},
		async choseThree() {
			await this.$store.dispatch("setChartMonths", 3)
			this.isLoading = true;
			await this.updateData()
		},
	}
}
</script>

<style scoped>
.scrollable {
	overflow: auto;
	height: 100%;
	width: 100%;
	padding-bottom: 1.5rem;
}

.entry-box {
	margin-right: 0.5em;
	margin-left: 0.5em;
	cursor: pointer;
	padding-bottom: 15px;
}

.entry-box:hover {
	background-color: whitesmoke;
}

.article {
	border-radius: 6px;
}

.white-text {
	color: white;
}

.text-box {
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
	overflow: hidden;
}

@media (max-width: 1026px) {
	.scrollable {
		overflow: auto;
		height: 100%;
		width: 100%;
	}
}

@media (max-width: 769px) {

	.tabs:not(:last-child),
	.pagination:not(:last-child),
	.message:not(:last-child),
	.level:not(:last-child),
	.breadcrumb:not(:last-child),
	.block:not(:last-child),
	.title:not(:last-child),
	.subtitle:not(:last-child),
	.table-container:not(:last-child),
	.table:not(:last-child),
	.progress:not(:last-child),
	.progress-wrapper.is-not-native:not(:last-child),
	.notification:not(:last-child),
	.content:not(:last-child),
	.box:not(:last-child) {
		margin-bottom: 0;
	}

	.scrollable {
		height: 100%;
		width: 95vw;
	}

}

.monthSelection {
	background-color: rgba(0, 0, 0, .8);
	color: white;
	margin-left: .1rem;
	border: 0px;
}

.monthSelection:hover {
	background-color: rgba(0, 0, 0, .6);
	color: white;
	margin: .15rem 0 0 0.1rem;
}

.monthSelectionFocus:focus {
	color: white;
	box-shadow: none !important;
}

.monthSelectionFocus {
	background-color: rgba(0, 0, 0, .4);
	margin-left: .1rem;
	color: white;
	border: 0px;
	box-shadow: none !important;
}
</style>
