import Parse from 'parse/dist/parse.min.js';

function compareByOrderDate(a, b) {
	return b.attributes.date - a.attributes.date;
}

const state = {
	data: [],
	filteredData: [],
	focusedMedicationLogEntryId: undefined,
	focusedMedicationLogEntry: undefined,
};

const getters = {
	getFilteredMedicationLogEntries(state) {
		if (state.filteredData) {
			for (var i = 0; i < state.filteredData.length; i++) {
				let aktiv = 1;
				let dropped = 0;
				let date = state.filteredData[i].attributes.date;
				let akt_date = new Date();

				let medicationDropDate = undefined;

				if(state.filteredData[i].attributes.medication)
				{
					if (state.filteredData[i].attributes.medication.attributes.medication.attributes.dropDate) {
						medicationDropDate = state.filteredData[i].attributes.medication.attributes.medication.attributes.dropDate;

						if (date.setHours(0, 0, 0, 0) >= medicationDropDate.setHours(0, 0, 0, 0)) {

							dropped = 1;
						}
					}
				}

				if (akt_date.setHours(0, 0, 0, 0) > date.setHours(0, 0, 0, 0)) {
					aktiv = 0;
				}

				state.filteredData[i].active = aktiv;
				state.filteredData[i].dropped = dropped;
			}
			return state.filteredData;
		}
		return undefined
	},
	getData(state){
		if(state.data){
			return state.data
		} else {
			return undefined
		}
	},
	getFocusedMedicationLogEntry(state, getters, rootState, rootGetters) {
		return state.focusedMedicationLogEntry
	}, 
	getInstantMedicationLogs(state, getters, rootState, rootGetters){
		let returnArray = []

		state.data.forEach(element => {
			if(element.attributes.instantMedication){
				returnArray.push(element)
			}
		});

		return returnArray
	}
};


const mutations = {
	SET(state, objects) {
		state.data = objects
		let filteredArray = []
		let akt_date = new Date((new Date()).setHours(23,59,59,59));
		state.data.forEach(element => {
			if(element.attributes.date <= akt_date){
				filteredArray.push(element)
			}
		});
		state.filteredData = filteredArray
	},
	INSERT(state, object) {
		object.set("date", new Date(object.attributes.date))
		state.data.push(object)
		let filteredArray = []
		let akt_date = new Date((new Date()).setHours(23,59,59,59));
		state.data.forEach(element => {
			if(element.attributes.date <= akt_date){
				filteredArray.push(element)
			}
		});
		state.filteredData = filteredArray
		state.filteredData.sort(compareByOrderDate)
		state.data.sort(compareByOrderDate)
		
	},
	DELETE(state, object) {
		state.data = state.data.filter((elem) => {
			return elem.id != object.id
		})
		state.filteredData = state.filteredData.filter((elem) => {
			return elem.id != object.id
		})
	},
	REPLACE(state, object) {
		object.set("date", new Date(object.attributes.date))
		const oldObject = state.data.filter(item => item.id == object.id)
		const index = state.data.indexOf(oldObject[0]);
		if (index > -1) {
			state.data.splice(index, 1, object);
		}
		const oldObjectFiltered = state.filteredData.filter(item => item.id == object.id)
		const indexFiltered = state.filteredData.indexOf(oldObjectFiltered[0]);
		if (index > -1) {
			state.filteredData.splice(indexFiltered, 1, object);
		}
	},
	LOAD(state, load) {
		state.loaded = load
	},
	SET_FOCUSED_MEDICATION_LOG_ENTRY_ID(state, value) {
		state.focusedMedicationLogEntryId = value
	},
	SET_FOCUSED_MEDICATION_LOG_ENTRY(state, value) {
		state.focusedMedicationLogEntry = value;
	}
};

const actions = {
	set({ commit }, objects) {
		commit('SET', objects)
	},
	insert({ commit }, object) {
		commit('INSERT', object)
	},
	delete({ commit }, object) {
		commit('DELETE', object)
	},
	replace({ commit }, object) {
		commit('REPLACE', object)
	},
	load({ commit }, object) {
		commit('LOAD', object)
	},
	setFocusedMedicationEntryId({ commit }, query) {
		commit("SET_FOCUSED_MEDICATION_LOG_ENTRY_ID", query)
	},
	setFocusedMedicationEntry({ commit }, query) {
		commit("SET_FOCUSED_MEDICATION_LOG_ENTRY", query)
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};