import Parse from 'parse/dist/parse.min.js';
import { handleParseError } from '../../../errorhandling';

let localSubscription = undefined;

// Client subscription
function createSubscription(store) {
	let groupQuery = new Parse.Query('Group');
	groupQuery.limit(10000)
	groupQuery.notEqualTo("deleted", true)
		.find()
		.then((results) => {
			store.dispatch('groups/set', results)
		})
		.catch((error) => {
			handleParseError(error);
		
		});
	groupQuery.subscribe().then((subscription) => {
		
		localSubscription = subscription;

		subscription.on('create', (object) => {
		
			store.dispatch('groups/insert', object)
		});
		subscription.on('update', (object) => {
			
			store.dispatch('groups/replace', object)
		});
		subscription.on('enter', (object) => {
			
			store.dispatch('groups/insert', object)
		});
		subscription.on('leave', (object) => {
			
			store.dispatch('groups/delete', object)
		});
		subscription.on('delete', (object) => {
			
			store.dispatch('groups/delete', object)
		});
		subscription.on('close', () => {
			
		});
	})

}

export default function createGroupSubscription() {
	return store => {
		//Erzeuge einmalig eine neue Subscription für den Datensatz. 
		//Fall "Bereits in der Anwendung"
		if(store.state.currentUser && !localSubscription) {
			createSubscription(store)
		}
		//Erzeuge einmalig eine neue Subscription für den Datensatz. 
		//Fall "Normaler Login"
		store.subscribe(mutation => {
			if (mutation.type === 'SET_CURRENT_USER') {
				if (!localSubscription) {
					createSubscription(store)
				}
			}
		})
	}
}
