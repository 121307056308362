import jsPDF from "jspdf";
var config = require('../../../client_config.json')
import { APP_ID } from "../../configuration/AppConfigurations";

export const printDiaryPdf = async (entry, client, comments, ratings) => {
	/*
		Default is "a4". [x/Breite: 595.28, y/Höhe: 841.89] Angaben sind in Points/pt
		Unterstriche haben immer die Höhe des elements + 3
	*/

	let docTitle = entry.attributes.title ? entry.attributes.title : ""
	let client_last_name = client.attributes.last_name ? client.attributes.last_name : ""
	let client_first_name =  client.attributes.first_name ? client.attributes.first_name : ""
	let client_name = client_last_name + ", " + client_first_name
	let group_name = client.attributes.group.attributes.group_name ? client.attributes.group.attributes.group_name : ""
	let docText = entry.attributes.text ? entry.attributes.text : ""
	let docAuthor = entry.attributes.author.attributes.dependency.attributes.first_name + " " + entry.attributes.author.attributes.dependency.attributes.last_name
	let docCreationTime = entry.attributes.createdAt ? entry.attributes.createdAt.toLocaleDateString("de-DE", {
		year: "numeric",
		month: "2-digit",
		day: "2-digit"
	}) : "" + " - " + entry.attributes.createdAt ? entry.attributes.createdAt.toLocaleTimeString("de-DE", {
		hour: "2-digit",
		minute: "2-digit"
	}
	) : ""
	let birthday = client.attributes.birth_date ? client.attributes.birth_date.toLocaleDateString("de-DE", {
		year: "numeric",
		month: "2-digit",
		day: "2-digit"
	}) : "-"
	let entrydate = client.attributes.entry ? client.attributes.entry.toLocaleDateString("de-DE", {
		year: "numeric",
		month: "2-digit",
		day: "2-digit"
	}) : "-"

	const doc = new jsPDF({
		unit: 'pt'
	})

	//Header
	let headerHeight = 70
	doc.setFontSize(10)
	doc.setFont("Helvetica", "Bold")
	// doc.text("Kinder- und Jugendhilfezentrum \"Am Wasserturm\"", 70, headerHeight)

	if(APP_ID == "KJD")
	{
		doc.text(config.kjd_adress_titel, 70, headerHeight)
	}else
	{
		doc.text(config.kjhz_adress_titel, 70, headerHeight)
	}
	headerHeight += 15
	// doc.text("OT Groß Börnecke", 70, headerHeight)
	if(APP_ID == "KJD")
	{
		doc.text(config.kjd_adress_titel_zusatz, 70, headerHeight)
	}else
	{
		doc.text(config.kjhz_adress_titel_zusatz, 70, headerHeight)
	}

	headerHeight += 15
	// doc.text("Holzweg 9", 70, headerHeight)
	if(APP_ID == "KJD")
	{
		doc.text(config.kjd_adress_strasse, 70, headerHeight)
	}else
	{
		doc.text(config.kjhz_adress_strasse, 70, headerHeight)
	}

	headerHeight += 15
	// doc.text("39444 Hecklingen", 70, headerHeight)
	if(APP_ID == "KJD")
	{
		doc.text(config.kjd_adress_stadt, 70, headerHeight)
	}else
	{
		doc.text(config.kjhz_adress_stadt, 70, headerHeight)
	}
	doc.setFont("Helvetica", "")
	headerHeight += 20
	// doc.text("Tel: 039267-6460-0", 70, headerHeight)
	if(APP_ID == "KJD")
	{
		doc.text(config.kjd_telefon, 70, headerHeight)
	}else
	{
		doc.text(config.kjhz_telefon, 70, headerHeight)
	}
	headerHeight += 15
	// doc.text("Fax: 039267-9140", 70, headerHeight)
	if(APP_ID == "KJD")
	{
		doc.text(config.kjd_fax, 70, headerHeight)
	}else
	{
		doc.text(config.kjhz_fax, 70, headerHeight)
	}
	headerHeight += 15
	// doc.text("E-Mail: verwaltung@kjhz-grossboernecke.de", 70, headerHeight)
	if(APP_ID == "KJD")
	{
		doc.text(config.kjd_mail, 70, headerHeight)
	}else
	{
		doc.text(config.kjhz_mail, 70, headerHeight)
	}
	headerHeight += 15
	// doc.text("Web: www.kjhz-grossboernecke.de", 70, headerHeight)
	if(APP_ID == "KJD")
	{
		doc.text(config.kjd_web, 70, headerHeight)
	}else
	{
		doc.text(config.kjhz_web, 70, headerHeight)
	}
	let image = config.kjhz_logo
	
	if(APP_ID == "KJD")
	{
		image = config.kjd_logo
	}
	
	let widhtmax = 525
	doc.addImage(image, "JPEG", 340, 50, 168, 156);
	doc.setFont("Helvetica", "Bold")
	headerHeight += 20
	doc.text("Tagebucheintrag", 70, headerHeight)
	doc.setFont("Helvetica", "")
	headerHeight += 3
	doc.line(70, headerHeight, 525, headerHeight)
	headerHeight += 15
	doc.text("Name, Vorname:", 70, headerHeight)
	doc.text(client_name, (widhtmax - doc.getTextWidth(client_name)), headerHeight)
	headerHeight += 15
	doc.text("Gruppe:", 70, headerHeight)
	doc.text(group_name, (widhtmax - doc.getTextWidth(group_name)), headerHeight)
	headerHeight += 15
	doc.text("Geboren am:", 70, headerHeight)
	doc.text(birthday, (widhtmax - doc.getTextWidth(birthday)), headerHeight)
	headerHeight += 15
	doc.text("Hilfebeginn:", 70, headerHeight)
	doc.text(entrydate, (widhtmax - doc.getTextWidth(entrydate)), headerHeight)
	headerHeight += 15
	doc.text("Autor:", 70, headerHeight)
	doc.text(docAuthor, (widhtmax - doc.getTextWidth(docAuthor)), headerHeight)
	headerHeight += 15
	doc.text("Erstelldatum:", 70, headerHeight)
	doc.text(docCreationTime, (widhtmax - doc.getTextWidth(docCreationTime)), headerHeight)
	headerHeight += 5
	doc.line(70, headerHeight, 525, headerHeight)
	headerHeight += 75
	doc.setFont("Helvetica", "Bold")
	doc.setFontSize(20)
	let splitted_Title = doc.splitTextToSize(docTitle, 315)
	let titleWidht = 0
	for (let i = 0; i < splitted_Title.length; i++) {
		titleWidht = ((315 - doc.getTextWidth(splitted_Title[i])) / 2) + 140
		doc.text(splitted_Title[i], titleWidht, headerHeight)
		headerHeight += 33.3333
	}

	doc.addPage()

	//Text
	doc.setFontSize(10)
	doc.setFont("Helvetica", "")
	let currentHeight = 70
	let docText_new = docText.replaceAll("<p>", "").replaceAll("</p>", " \n")
	let docText_new_splitted = doc.splitTextToSize(docText_new, 455)
	let maxSize = 0
	docText_new_splitted.forEach((element) => {
		if (element.length > maxSize) {
			maxSize = element.length
		}
	})
	let textHeight = 16.6667 //23.3333 alt
	for (let i = 0; i < docText_new_splitted.length; i++) {
		doc.text(docText_new_splitted[i], 70, currentHeight)
		if (docText_new_splitted[i].length < maxSize * 0.75) {
			currentHeight = currentHeight + textHeight
		}
		if (currentHeight + textHeight > 770) {
			doc.addPage()
			currentHeight = 70
		} else {
			currentHeight = currentHeight + textHeight
		}
	}

	
	currentHeight = currentHeight + 10
	doc.setFont("Helvetica", "Bold")
	doc.text("Tags:", 70, currentHeight)
	doc.line(70, currentHeight + 3, (70 + doc.getTextWidth("Tags:")), currentHeight + 3)
	currentHeight = currentHeight + textHeight
	doc.setFont("Helvetica", "")
	// doc.text(listOfTags, 70, currentHeight)
	//Tags
	let listOfTags = ""
	entry.attributes.attributes.forEach(function (element, idx, array) {
		// if (idx === array.length - 1) {
		// 	listOfTags += element.attributes.name
		// } else {
		// 	listOfTags += element.attributes.name + ",<br>"
		// }
		doc.text(element.attributes.name, 70, currentHeight)
		currentHeight = currentHeight + textHeight
	});

	currentHeight = currentHeight + (textHeight * 2)
	if (ratings.length > 0) {
		doc.setFont("Helvetica", "Bold")
		doc.text("Verhaltensbewertungen:", 70, currentHeight)
		doc.line(70, currentHeight + 3, (70 + doc.getTextWidth("Verhaltensbewertungen:")), currentHeight + 3)
		currentHeight = currentHeight + textHeight
		doc.setFont("Helvetica", "")
		ratings.forEach((element) => {
			let name = element.attributes.client.attributes.first_name + " " + element.attributes.client.attributes.last_name
			doc.text(name, 70, currentHeight)
			let rating = element.attributes.rating ? element.attributes.rating.toString() : "neutral"
			doc.text(rating, (70 + doc.getTextWidth(name) + 20), currentHeight)
			currentHeight = currentHeight + textHeight
		})
	}

	//Comments
	if (comments.length > 0) {
		doc.addPage()
		doc.setFont("Helvetica", "Bold")
		doc.text("Kommentare:", 70, 70)
		doc.line(70, 73, (70 + doc.getTextWidth("Kommentare:")), 73)
		doc.setFont("Helvetica", "")
		addCommentArray(90, comments, doc)
	}

	//Images
	if (entry.attributes.attachment) {
		doc.addPage()
		doc.setFont("Helvetica", "Bold")
		doc.text("Anhänge:", 70, 70)
		doc.line(70, 73, (70 + doc.getTextWidth("Anhänge:")), 73)
		doc.setFont("Helvetica", "")
		let docPictures = entry.attributes.pictures
		let startheight = 80
		let url_array = []
		for (const image of docPictures) {
			url_array.push(image._url)
		}
		await loadImages(url_array).then(images => {
			images.forEach((element, index) => {
				let currImgHeight = element.naturalHeight
				let currImgWidht = element.naturalWidth
				let ratio = currImgHeight / currImgWidht
				let height = 445 * ratio
				if (index != 0 && index % 2 == 0) {
					doc.addPage()
					startheight = 80
				}
				doc.addImage(element.currentSrc, 'JPEG', 70, startheight, 445, height, '', 'FAST')
				startheight = startheight + height + 20
			})
		})
	}
	doc.save("Tagebuch_" + `${docTitle}.pdf`)

	
}

async function loadImages(imageUrlArray) {
	const promiseArray = []; // create an array for promises
	const imageArray = []; // array for the images

	for (let imageUrl of imageUrlArray) {

		promiseArray.push(new Promise(resolve => {

			const img = new Image();
			// if you don't need to do anything when the image loads,
			// then you can just write img.onload = resolve;

			img.onload = function () {
				// do stuff with the image if necessary

				// resolve the promise, indicating that the image has been loaded
				resolve();
			};

			img.src = imageUrl;
			imageArray.push(img);
		}));
	}

	await Promise.all(promiseArray); // wait for all the images to be loaded
	return imageArray;
}

function addCommentArray(height, comments, doc) {
	comments.forEach((element) => {
		if (!element.length) {
			addComment(doc, element, height)
		} else {
			addCommentArray(height, element, doc)
		}
		height = height + 45
	})
}

function addComment(doc, comment, height) {
	let comTitle = comment.attributes.author.attributes.dependency.attributes.first_name + " " +
		comment.attributes.author.attributes.dependency.attributes.last_name + " " + comment.attributes.createdAt.toLocaleDateString("de-DE", {
			year: "numeric",
			month: "2-digit",
			day: "2-digit"
		}) + " - " + comment.attributes.createdAt.toLocaleTimeString(
			"de-DE"
		)
	doc.text(comTitle, 70, height)
	let commentText = comment.attributes.text.replaceAll("<p>", "").replaceAll("</p>", " \n")
	//doc.setFontSize(14) //Fontsize muss hier reduziert werden, damit die Höhenberechnung funktioniert
	let commentText_splitted = doc.splitTextToSize(commentText, 455)
	doc.text(commentText_splitted, 70, height + 15)
}