<template>
	<div ref="entryPanel" v-if="medicationEntries &&
		this.$store.state.usermetas.data.length > 0 &&
		this.$store.state.users.data.length > 0
		" style="user-select: none" class="scrollable is-flex is-flex-direction-column" id="entryContainer">
		<div class="pr-1" @click="navigateMedicationEntryDetail(entryLink)" v-for="(entryLink) in medicationEntries"
			:key="'active_' + entryLink.id">
			<MedicationEntriesData v-if="entryLink.active == 1" :entryLink="entryLink" />
		</div>
		<div style="background-color:transparent;" class="entry-box-entry p-0 box mb-1 mt-1">
			<div class="has-text-centered article has-darker-grey-color white-text p-3">
				Historie
			</div>
		</div>
		
		<div class="pr-1" @click="navigateMedicationEntryDetail(entryLink)" v-for="(entryLink) in medicationEntries"
			:key="'inactive_' + entryLink.id">
			<MedicationEntriesData v-if="entryLink.active == 0" :entryLink="entryLink" />
		</div>

	</div>
	<div v-else>
		<EntryInfo />
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import MedicationEntriesData from "@/components/modules/medication/MedicationEntriesData";

export default {
	name: "DiaryEntries",
	components: {
		MedicationEntriesData
	},
	data() {
		return {
		};
	},
	beforeCreate() { },
	mounted() {
	},
	updated() {

	},
	watch: {
		focusedClient() {
			this.maxViewPortIndex = 10
			this.scrollPosition = 0
			this.viewPortIncreaseIteration = 1
		},
	},
	computed: {
		...mapGetters({
			medicationEntries: "medicationEntries/getFilteredMedicationEntries",
			focusedClient: "clients/getFocusedClient",
		}),
	},
	methods: {
		navigateMedicationEntryDetail(entry) {
			this.$store.dispatch(
				"medicationEntries/setFocusedMedicationEntry",
				entry
			);
			this.$store.dispatch(
				"medicationEntries/setFocusedMedicationEntryMask",
				'view'
			);
			this.$store.dispatch(
				"medicationEntries/setFocusedMedicationEntryId",
				entry.id
			);
			this.$router.push({
				name: 'MedicationEntry',
			})
		}
	},
}
</script>

<style scoped>
.medicationEntryActive0 {
	background-color: #f5f5f5;
}

.hasNotMedicationLog {
	background-color: #feecef;
}


::v-deep .skeleton-title {
	width: 30%;
	height: 1.15rem;
	margin-bottom: .7rem;
	border-radius: .125rem;
}

::v-deep .skeleton-author {
	width: 20%;
	height: .6rem;
	margin-bottom: .5rem;
	margin-top: .25rem;
	border-radius: .25rem;
}

.white-text {
	color: white;
}

.entry-box {
	margin-right: 0.5em;
	margin-left: 0.5em;
	cursor: pointer;
	padding-bottom: 15px;
}

.entry-box:hover {
	background-color: whitesmoke;
}

.scrollable {
	overflow: auto;
	height: 100%;
	width: 100%;
	padding-bottom: 1.5rem;
}

.article {
	width: 100%;
	border-radius: 0.5rem;
}

.entry-box-entry {
	margin-right: 0.5em;
	margin-left: 0.5em;
	padding-bottom: 15px;
	text-align: center;
}

@media (max-width: 1026px) {
	.scrollable {
		overflow: auto;
		height: 100%;
		width: 100%;
	}
}

@media (max-width: 769px) {

	.tabs:not(:last-child),
	.pagination:not(:last-child),
	.message:not(:last-child),
	.level:not(:last-child),
	.breadcrumb:not(:last-child),
	.block:not(:last-child),
	.title:not(:last-child),
	.subtitle:not(:last-child),
	.table-container:not(:last-child),
	.table:not(:last-child),
	.progress:not(:last-child),
	.progress-wrapper.is-not-native:not(:last-child),
	.notification:not(:last-child),
	.content:not(:last-child),
	.box:not(:last-child) {
		margin-bottom: 0;
	}

	.scrollable {
		height: 100%;
		width: 95vw;
	}

	.entry-box {
		display: flex;
		width: 96%;
	}
}

@media (max-width: 400px) {
	div.media-left {
		margin: 0.1em 0 0 0;
	}
}

@media (max-width: 450px) {
	.authorTagContainer {
		display: flex;
		flex-direction: row;
		align-items: start;
	}

	.iconContainer {
		justify-content: start !important;
		display: flex !important;
		flex-direction: row !important;
	}
}
</style>
