import Parse from 'parse/dist/parse.min.js';
import { handleParseError } from '../../../errorhandling';
import router from '../../../router/router';

let localSubscription = undefined;

function createSubscription(store) {
	let dataQuery = new Parse.Query('MedicationMedication');
	dataQuery.notEqualTo("deleted", true)
	dataQuery.find()
		.then((results) => {
			store.dispatch('medicationMedication/set', results)
		})
		.catch((error) => {
			handleParseError(error);
			
		});
	dataQuery.subscribe().then((subscription) => {
	
		localSubscription = subscription;

		subscription.on('create', object => {
			
			store.dispatch('medicationMedication/insert', object)
		});
		subscription.on('update', object => {
			
			store.dispatch('medicationMedication/replace', object)
		});
		subscription.on('enter', object => {
			
			store.dispatch('medicationMedication/insert', object)
		});
		subscription.on('leave', object => {
			
			store.dispatch('medicationMedication/delete', object)
		});
		subscription.on('delete', object => {
			
			store.dispatch('medicationMedication/delete', object)
		});
		subscription.on('close', () => {
			
		});
		subscription.on('error', error => {
			
		})
	})
}

export default function createMedicationSubscription() {
	return store => {
		store.subscribe(mutation => {
			//1: Check - Wird die richtige Mutation ausgelöst? Diese wird im Router bei jedem Routing-Vorgang ausgelöst.
			if (mutation.type === 'ADD_LAST_ROUTE') {
				//2: Check - Bin ich aktuell in der Medikation?
				if (router.currentRoute.name == "Medication" || router.currentRoute.name == "MedicationEntry") {
					//3: Check - Prüfe, ob bereits eine lokale Subscription vorliegt.
					if (!localSubscription) {
						createSubscription(store)
					}
				}
			}
		})
	}
}
