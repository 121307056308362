
<template>
	<div class="box entry-box p-0 mt-1 mb-1">
		<article class="media p-3 article">
			<div class="media-left">
				<div style="color:#201e1e" class="user_image image is-48x48 skeleton">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
						<path fill="currentcolor"
							d="M4.22,11.29L11.29,4.22C13.64,1.88 17.43,1.88 19.78,4.22C22.12,6.56 22.12,10.36 19.78,12.71L12.71,19.78C10.36,22.12 6.56,22.12 4.22,19.78C1.88,17.43 1.88,13.64 4.22,11.29M5.64,12.71C4.59,13.75 4.24,15.24 4.6,16.57L10.59,10.59L14.83,14.83L18.36,11.29C19.93,9.73 19.93,7.2 18.36,5.64C16.8,4.07 14.27,4.07 12.71,5.64L5.64,12.71Z" />
					</svg>
				</div>
			</div>
			<div class="media-content" style="overflow: hidden">
				<div class="mb-2">
					<div class="mt-0 mb-0">
						<div class="
								pt-0
								authorTagContainer
								is-font-medium
							">

							<small v-if="entryLink.attributes.medication">
								{{
									autor.attributes?.dependency.attributes.first_name
									+
									" " +
									autor.attributes?.dependency.attributes.last_name
								}},
								<time>
									Einnahme vom {{
										entryLink.attributes?.medication.attributes?.startDate.toLocaleDateString("de-DE",
											{
												year: "numeric",
												month: "2-digit",
												day: "2-digit",
											}) +
										" bis zum " +
										entryLink.attributes?.medication.attributes?.endDate.toLocaleDateString("de-DE",
											{
												year: "numeric",
												month: "2-digit",
												day: "2-digit",
											})
									}}</time>
								<time v-if="entryLink.attributes?.medication.attributes?.dropDate">,
									abgesetzt am
									{{
										entryLink.attributes?.medication.attributes?.dropDate.toLocaleDateString("de-DE",
											{
												year: "numeric",
												month: "2-digit",
												day: "2-digit",
											})
									}}</time>
								<time v-if="entryLink.attributes?.medication.attributes?.expirationDate">,
									haltbar
									bis
									{{
										entryLink.attributes?.medication.attributes?.expirationDate.toLocaleDateString("de-DE",
											{
												year: "numeric",
												month: "2-digit",
												day: "2-digit",
											})
									}}</time>

							</small>

							<ColoredTag class="mr-1" :tag-name="new Date().toLocaleDateString('de-DE', {
										year: 'numeric', month: '2-digit', day: '2-digit',
									})" :tag-color="'#313131'" :is-right="true" />
							<ColoredTag v-if="entryLink.attributes?.medicationPlan && entryLink.attributes?.medication"
								class="mr-1"
								:tag-name="getAmountString(entryLink.attributes?.medicationPlan.attributes.einzeldosis) + ' ' + entryLink.attributes?.medication.attributes?.medication.attributes?.medicationUnit.attributes.symbol"
								:tag-color="'#313131'" :is-right="true" />

							<!--ColoredTag v-if="entryLink.attributes?.medication" class="mr-1"
								:tag-name="entryLink.attributes?.medicationPlan.attributes?.art == 3 ? 'Selbstmedikation' : ''"
								:tag-color="'#313131'" :is-right="true" /-->


						</div>
					</div>
					<p v-if="entryLink.attributes.medication" class="is-font-extra-large is-font-bold mb-1">
						Selbstmedikation - Log-Eintrag erstellen
					</p>
					<p>
						<small>M{{ entryLink.attributes?.medication.attributes?.number }}: {{
							entryLink.attributes?.medication.attributes?.medication.attributes?.displayName
						}}
						</small>
					</p>
					<p>
						<small>Bitte wählen Sie den Vergabezeitpunkt aus, wenn Sie einen Log-Eintrag für die
							Selbstmedikation erstellen möchten.</small>
					</p>

				</div>


			</div>
		</article>
	</div>
</template>

<script>
import ColoredTag from "@/components/tags/ColoredTag";

export default {
	name: "MedicationSelfLogEntryData",
	props: ["entryLink"],
	components: {
		ColoredTag,
		// MedicationIcon
	},
	data() {
		return {

		};
	},
	beforeCreate() { },
	mounted() {
	},
	updated() {

	},
	watch: {
	},
	computed: {
		autor: function () {

			if (this.entryLink.attributes?.medication) {
				return this.entryLink.attributes?.medication.attributes?.contactUser;
			} else {
				return undefined;
			}
		}
	},
	methods: {
		getAmountString(value) {
			return String(value).replace(".", ",");
		}
	},
}
</script>
<style scoped></style>