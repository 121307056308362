export const MOBILE = 769

export const MOBILE_SMALL = 550


export const BEAUTIFUL_COLORS = ["rgb(79, 48, 17)", "rgb(0, 0, 100)", "#6D4C41", "rgb(181, 184, 0)",
	"rgb(191, 70, 0)", "rgb(0, 82, 105)", "rgb(23, 22, 23)", "rgb(68, 0, 69)",
"rgb(209, 98, 98)","rgb(209, 0, 185)", "rgb(0, 203, 230)", "rgb(100,100,100)"]; // add colors


export const COLORS = [
	"#90CAF9",
	"#80CBC4",
	"#9E9E9E",
	"#EF9A9A",
	"#F48FB1",
	"#B39DDB",
	"#9FA8DA",
	"#C5E1A5",
	"#E6EE9C",
	"#FFF59D",
	"#FFE082",
	"#FFCC80",
	"#FFAB91",
	"#BCAAA4",
	"#546E7A",
	"#A5D6A7",
	"#90CAF9"
]

export const WEEK_DAYS = {
	1: "Montag",
	2: "Dienstag",
	3: "Mittwoch",
	4: "Donnerstag",
	5: "Freitag",
	6: "Samstag",
	7: "Sonntag",
}