import Parse from 'parse/dist/parse.min.js';
import { handleParseError } from '../../../errorhandling';
import router from '../../../router/router';

let localSubscription = undefined;

async function createSubscription(store, clientId) {

	let client_uuid = "";

	let clientQuery = new Parse.Query('Client');
	clientQuery.equalTo("objectId", clientId);
	clientQuery.notEqualTo("deleted", true);
	await clientQuery.find().then(response => client_uuid = response[0].attributes.uuid);

	let all_clients = "";

	let clientQuery2 = new Parse.Query('Client');
	clientQuery2.equalTo("uuid", client_uuid);
	clientQuery2.notEqualTo("deleted", true);
	await clientQuery2.find().then(response => all_clients = response);

	let dataQuery = new Parse.Query('MedicationClientMedicationConnector');
	dataQuery.include("client", "medication", "medication.contactUser", "medication.contactUser.dependency", "medication.medication", "medication.medication.medicationUnit", "medicationPrescription", "medicationReport", "medicationPlan", "medicationReport.diagnose", "medicationReport.icd_10_code_array");
	// dataQuery.include(["client", "medication", "medication.contactUser","medication.contactUser.dependency", "medication.medication", "medicationPrescription"]);

	// var clientPointer = {
	// 	__type: 'Pointer',
	// 	className: 'Client',
	// 	objectId: clientId
	// }

	//dataQuery.equalTo("client", clientPointer)
	dataQuery.containedIn("client", all_clients)

	dataQuery.find()
		.then((results) => {
			store.dispatch('medicationEntries/set', results)
		})
		.catch((error) => {
			handleParseError(error);

		});
	store.dispatch("entrylinks/load", true)
	dataQuery.subscribe().then((subscription) => {

		localSubscription = subscription;

		subscription.on('create', object => {
			store.dispatch('medicationEntries/insert', object)
		});
		subscription.on('update', object => {
			store.dispatch('medicationEntries/replace', object)
		});
		subscription.on('enter', object => {
			store.dispatch('medicationEntries/insert', object)
		});
		subscription.on('leave', object => {

			store.dispatch('medicationEntries/delete', object)
		});
		subscription.on('delete', object => {

			store.dispatch('medicationEntries/delete', object)
		});
		subscription.on('close', () => {

		});
		subscription.on('error', error => {

		})
	})
}

export default function createMedicationEntriesSubscription() {
	return store => {
		store.subscribe(mutation => {
			if (mutation.type === 'clients/SET_FOCUSED_CLIENT_ID' && (router.currentRoute.name == "Medication" || router.currentRoute.name == "MedicationEntry")) {
				if (localSubscription) {
					localSubscription.unsubscribe();
				}

				if(mutation.payload)
				{
					createSubscription(store, mutation.payload)
				}
			}
		}),
		store.subscribe(mutation => {
			if (mutation.type === 'ADD_LAST_ROUTE' && (router.currentRoute.name == "Medication" || router.currentRoute.name == "MedicationEntry")) {
				if (localSubscription) {
					localSubscription.unsubscribe();
				}
				if (store.state.clients.focusedClientId) {
					createSubscription(store, store.state.clients.focusedClientId)
				}
			}
		})
	}
}
