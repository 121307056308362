const state = {
	data: [],
};

const getters = {
	isAuthorMetaClassLoaded(state) {
		return state.data && state.data.length > 0;
	},
	getCurrentUserMeta(state, getters, rootState) {
		if (rootState.currentUser) {
			return state.data.find((meta) => meta.id === rootState.currentUser.attributes.dependency.id)
		}
		return undefined
	}, getUserProfilePicture(state, getters, rootState) {
		if (rootState.deleteUserPicture) {
			return undefined
		}
		const currentUserMeta = getters.getCurrentUserMeta
		if (currentUserMeta && currentUserMeta.attributes.profile_picture) {
			return currentUserMeta.attributes.profile_picture._url;
		}
		return undefined
	}, getUserFullName(state, getters, rootState) {
		const currentUserMeta = getters.getCurrentUserMeta
		if (!currentUserMeta) return undefined
		return `${currentUserMeta.attributes.first_name} ${currentUserMeta.attributes.last_name}`
	},
	getUserMetas(state, getters, rootState) {
		if (state.data) {
			return state.data;
		}
		return undefined
	}
};

const mutations = {
	SET(state, objects) {
		state.data = objects
	},
	INSERT(state, object) {
		state.data.push(object)
	},
	DELETE(state, object) {
		state.data = state.data.filter((elem) => {
			return elem.id != object.id
		})
	},
	REPLACE(state, object) {
		const oldObject = state.data.filter(item => item.id == object.id)
		const index = state.data.indexOf(oldObject[0]);
		if (index > -1) {
			state.data.splice(index, 1, object);
		}
		
	},
};

const actions = {
	set({ commit }, objects) {
		commit('SET', objects)
	},
	insert({ commit }, object) {
		commit('INSERT', object)
	},
	delete({ commit }, object) {
		commit('DELETE', object)
	},
	replace({ commit }, object) {
		commit('REPLACE', object)
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
