<template>
    <span
        class="tag is-dark is-rounded is-font-small"
        style="width: fit-content"
        v-bind:class="{ isRight: isRight }"
        v-bind:style="{
            'background-color': tagColor,
            color: getColor(tagColor),
        }"
    >
        {{ tagName }}
    </span>
</template>

<script>
/**
 * Tag displaying the associated EntryType with a tagColor and tagColor.
 * @displayName EntryTypeTag
 */

import { HEX2RGB } from "@/lib/Color";

export default {
    name: "EntryTypeTag",
    props: ["tagName", "tagColor", "isRight"],
    methods: {
        getColor(hex) {
            let number = 0;
            HEX2RGB(hex).forEach((num) => (number += num));
            if (number > 393) return "black";
            return "white";
        },
    },
};
</script>

<style scoped>
.isRight {
    float: right;
}
</style>
