import { render, staticRenderFns } from "./CommentTree.vue?vue&type=template&id=485c36bc&scoped=true&style=height%3A%20100%25&"
import script from "./CommentTree.vue?vue&type=script&lang=js&"
export * from "./CommentTree.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "485c36bc",
  null
  
)

export default component.exports