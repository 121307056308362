<template>
	<div class="barContainer is-flex is-flex-direction-column">
		<div class="
					scrollDiv
					is-flex
					is-flex-direction-row
					is-justify-content-center
					is-align-content-center
					is-flex-wrap-wrap
					" :style="getScrollDivStyle()" style="height: 100%; overflow-y: hidden">
			<div v-for="(routeObject, index) in routeObjects" :key="index" class="routeContainer" :class="[
				{ disabledRoute: !routeObject.isActivated },
				{
					notificationRoute:
						routeObject.isNotification &&
						numberOfNotifications > 0,
				},
			]" @click="route(routeObject)">
				<div class="route m-1">
					<template v-if="routeObject.isActivated">
						<b-button @click="unselectClient" :icon-left="routeObject.icon" :size="isMobile() ? 'is-small' : ''"
							:class="[
								{
									selectedRoute: selectedRoute(
										routeObject.route
									),
								},
								{
									disabledRouteIcon: !routeObject.isActivated,
								},
							]">{{ routeObject.text }}</b-button>
					</template>
					<template v-else>
						<b-button :icon-left="routeObject.icon" :size="isMobile() ? 'is-small' : ''" :key="activeRoute"
							:class="[
								{
									selectedRoute: selectedRoute(
										routeObject.route
									),
								},
								{ disabledRouteIcon: !routeObject.isActivated },
							]">{{ routeObject.text }}</b-button>
					</template>
				</div>
			</div>
		</div>
		<div class="
					is-flex
					is-flex-direction-column
					is-justify-content-center
					is-align-content-center
					m-1
					text-center
					">

			<figure class="mb-5 is-align-self-center is-flex
					is-flex-direction-column
					is-justify-content-center" style="width: 150px;">
				<small class="is-align-self-center" style="font-size:10px; color:#313131; margin-bottom:-5px;"><b>{{ applicationVersion }}</b></small>
				<img :src="require('../../assets/logo/' + this.app_logo_copyright)" />
			</figure>
		</div>
	</div>
</template>

<script>
/**
 * BottomBar for fast and easy module navigation.
 * @displayName AboutBar
 */
import { mapGetters } from "vuex";
import { version } from "../../../package.json"
import { APP_LOGO_COPYRIGHT } from "@/configuration/AppConfigurations";

export default {
	name: "AboutBar",
	data() {
		const routeObjects = [
			{
				text: "Impressum",
				icon: "help-circle",
				route: "/imprint",
				isActivated: true,
			},
			{
				text: "Datenschutz",
				icon: "shield",
				route: "/dataprotection",
				isActivated: true,
			},
		];
		return {
			routeObjects: routeObjects,
			applicationVersion: version,
			app_logo_copyright: APP_LOGO_COPYRIGHT
		};
	},
	methods: {
		async route(object) {
			const { route, isActivated } = object;
			if (!this.$store.state.focusedClientId)
				await this.$store.dispatch("setSidePanelOpen", true);
			if (this.selectedRoute(route) || !isActivated) return;
			await this.$router.push({ path: route });
		},
		selectedRoute(route) {
			if (route === "/") return this.activeRoute === route;
			return this.activeRoute.includes(route);
		},
		isMobile() {
			return this.$store.getters.isMobile;
		},
		getScrollDivStyle() {
			let style = "";
			style += `min-width: ${(this.routeObjects.length + 2) * 40}px;`;
			return style;
		},
		unselectClient() {
			this.$store.dispatch("clients/setFocusedClientId", undefined);
		},
	},
	computed: {
		activeRoute() {
			return this.$route.path;
		},
		...mapGetters({
			numberOfNotifications: "notifications/getNumberOfNotifications",
		}),
	},
};
</script>

<style scoped>
.scrollDiv {
	height: 100%;
}

.routeText {
	text-align: center;
}

.barContainer {
	position: absolute;
	width: 100%;
	min-height: 30px;
	overflow: auto;
}

.routeContainer {
	cursor: pointer;
	border-radius: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
}

.route {
	min-height: 2.5rem;
	display: flex;
	align-items: center;
}

.selectedRoute {
	background-color: rgba(0, 0, 0, 0.8);
}

::v-deep .selectedRoute .mdi {
	color: whitesmoke;
}

::v-deep .notificationRoute .mdi {
	color: red;
}

.disabledRouteIcon {
	padding: 1rem;
	cursor: default;
	background-color: lightgrey;
}

.disabledRoute {
	cursor: default;
}

@media (max-width: 769px) {
	.bar {
		max-width: 95%;
	}
}
</style>
