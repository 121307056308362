export default class Edge{
	constructor(source, target) {
		this.source = source
		this.target = target
	}

	getTarget(){
		return this.target
	}

	getSource(){
		return this.source
	}
}

