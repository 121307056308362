var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"100%","overflow":"auto"}},[(_vm.currentUserMeta)?_c('div',{staticStyle:{"background-color":"white","border-radius":"5px","padding":"5px"}},[_c('div',{staticClass:"panel m-5",staticStyle:{"background-color":"white"}},[_c('p',{staticClass:"panel-heading",staticStyle:{"background-color":"rgba(0, 0, 0, 0.8)","color":"white"}},[_vm._v(" Profilbild ")]),_c('div',{staticClass:"columns is-centered"},[_c('div',{staticClass:"column image_column profilePictureChangeContainer"},[_c('div',{staticClass:"profilePictureContainer mt-2"},[_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('b-upload',{staticStyle:{"cursor":"pointer"},attrs:{"accept":"image/*"},model:{value:(_vm.newProfilePicture),callback:function ($$v) {_vm.newProfilePicture=$$v},expression:"newProfilePicture"}},[_c('b-image',{staticClass:"profileImage is-128x128",attrs:{"src":_vm.getPicture,"alt":"Profilbild","rounded":""}})],1)],1),_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[(
                                    _vm.newProfilePicture ||
                                    this.userProfilePicture
                                )?_c('button',{staticClass:"delete is-small mt-2",attrs:{"type":"button"},on:{"click":function($event){return _vm.removePicture()}}}):_vm._e()])]),_c('section',{staticClass:"buttons mt-2",staticStyle:{"display":"flex","justify-content":"center"}},[_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.saveNewUserPicture()}}},[_vm._v("Speichern")])],1)])])]),_c('user-table',{attrs:{"user":_vm.currentUserMeta}}),_c('div',{staticClass:"panel m-5",staticStyle:{"background-color":"white"}},[_c('p',{staticClass:"panel-heading",staticStyle:{"background-color":"rgba(0, 0, 0, 0.8)","color":"white"}},[_vm._v(" Gruppen ")]),_c('div',{staticClass:"pb-3 pt-3",staticStyle:{"display":"flex","justify-content":"center"}},[_c('ul',_vm._l((_vm.currentUserMeta.attributes.groups),function(group){return _c('li',{key:group.id,staticClass:"content column pt-0 pb-0 mb-0",staticStyle:{"width":"100%","list-style-type":"none"}},[_c('p',{staticStyle:{"font-weight":"500"}},[_vm._v(" "+_vm._s(group.attributes.group_name)+" ")])])}),0)])]),_c('div',{staticClass:"panel m-5 pb-2",staticStyle:{"background-color":"white"}},[_c('p',{staticClass:"panel-heading",staticStyle:{"background-color":"rgba(0, 0, 0, 0.8)","color":"white"}},[_vm._v(" Passwort ")]),_c('b-field',{staticClass:"pl-2 pr-2 pt-2",attrs:{"label":"Neues Passwort","message":!_vm.$v.newPassword.$invalid
                        ? ''
                        : 'Passwort ist nicht lang genug!',"type":!_vm.$v.newPassword.$invalid ? 'is-success' : 'is-danger'}},[_c('b-input',{attrs:{"password-reveal":"","type":"password","autocomplete":"off"},on:{"input":_vm.$v.newPassword.$touch},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}})],1),_c('b-field',{staticClass:"pl-2 pr-2",attrs:{"label":"Neues Passwort wiederholen","message":!_vm.$v.repeatNewPassword.$invalid
                        ? ''
                        : 'Passwort stimmt nicht überein!',"type":!_vm.$v.repeatNewPassword.$invalid &&
                    !_vm.$v.newPassword.$invalid
                        ? 'is-success'
                        : 'is-danger'}},[_c('b-input',{attrs:{"password-reveal":"","type":"password","autocomplete":"off"},on:{"input":_vm.$v.repeatNewPassword.$touch},model:{value:(_vm.repeatNewPassword),callback:function ($$v) {_vm.repeatNewPassword=$$v},expression:"repeatNewPassword"}})],1),_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('b-button',{attrs:{"type":"is-primary","disabled":_vm.$v.$invalid},on:{"click":function($event){return _vm.saveNewPassword()}}},[_vm._v("Passwort speichern")])],1)],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }