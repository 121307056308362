const state = {
	data: [],
	isLoaded: false
};

const getters = {
	getFocusedClientRecords(state) {
		if (state.data) {
			return state.data;
		}
		return undefined;
	},
	areDocumentsLoaded(){
		return state.data && state.data.length > 0;
	},
	getLoaded(){
		return state.isLoaded
	}
};

const mutations = {
	SET(state, objects) {
		state.data = objects
	},
	INSERT(state, object) {
	
		state.data.unshift(object)
		
	},
	DELETE(state, object) {
		state.data = state.data.filter((elem) => {
			return elem.id != object.id
		})
	},
	REPLACE(state, object) {
		const oldObject = state.data.filter(item => item.id == object.id)
		const index = state.data.indexOf(oldObject[0]);
		if (index > -1) {
			state.data.splice(index, 1, object);
		}
		
	},
	LOAD(state, load){
		state.isLoaded = load
	},
};

const actions = {
	set({ commit }, objects) {
		const clientRecords = objects;
		commit('SET', clientRecords)
	},
	insert({ commit }, object) {
		commit('INSERT', object)
	},
	delete({ commit }, object) {
		commit('DELETE', object)
	},
	replace({ commit }, object) {
		commit('REPLACE', object)
	},
	load({ commit }, object) {
		commit('LOAD', object)
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
  };
