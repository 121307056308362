<template>
	<div class="side-bar" style="height: 100%">
		<div class="scrollable" style="user-select: none">
			<ul>
				<template v-for="(client, index) in clients">
					<template v-if="client">
						<template v-if="hasSeparation(client, index)">
							<hr :key="client.id + 'hr'" style="
                                border: none;
                                height: 2px;
                                margin: 0 0 0 0;
                                background-color: #4a4a4a;
                            " class="m-2" />
						</template>
						<template>
							<li :key="index" class="m-2">
								<div class="
									is-flex is-justify-content-flex-start
									user-info-container
								">
									<div class="user-info is-gapless" expanded v-on:click="setFocusedClient(client)"
										:class="{ selected: clientId === client.id }">
										<ClientFigure :client="client" />
										<div :class="client.attributes.lastEntryDate ? 'nameAndDate' : 'name'"
											class="p-1">

											<div>
												<div v-if="medicationView || medicationEntry">
													<div v-if="createNumberOfUnseenMedicationLogs(client)"
														class="notificationBubble">
														{{ createNumberOfUnseenMedicationLogs(client) }}
													</div>
												</div>

												<div v-if="client.attributes.lastEntryDate" class="lastEntryDate pr-2">
													<time class="is-font-small">
														{{
					client.attributes.lastEntryDate.toLocaleDateString("de-DE",
						{
							year: "numeric",
							month: "2-digit",
							day: "2-digit",
						}
					)
				}}
													</time>
												</div>
											</div>
											<ClientGroupAndName class="groupAndName" :client="client" />
										</div>

										<div class="pl-1 pr-1" style="
											background-color: red;
											border-radius: 50%;
											position: absolute;
											color: white;
										" v-if="notificationPath && numberOfNotifications(client)">
											{{ numberOfNotifications(client) }}
										</div>
									</div>
								</div>
							</li>
						</template>
					</template>
				</template>
			</ul>
		</div>
	</div>
</template>

<script>
/**
 * SideBar displaying the clients in the application.
 * @displayName DiarySideBar
 */

import { mapGetters } from "vuex";
import ClientGroupAndName from "@/components/bars/sideBar/ClientGroupAndName";
import ClientFigure from "@/components/bars/sideBar/ClientFigure";
import { getNotificationNumberOfClient } from "@/lib/notificationFunctions";

export default {
	components: { ClientFigure, ClientGroupAndName },
	data() {
		return {
			searchQuery: null,
			isUserModalActive: false,
			isOpen: 0,
		};
	},
	computed: {
		...mapGetters({
			clientId: "clients/getFocusedClientId",
			userProfilePicture: "usermetas/getUserProfilePicture",
			userFullName: "usermetas/getUserFullName",
			userName: "users/getUserName",
			filteredClients: "clients/getFilteredClients",
			contactClients: "clients/getOnlyContactClients",
			entryNotifications: "notifications/getEntryNotifications",
			commentNotifications: "notifications/getCommentNotifications",
			documentNotifications: "notifications/getDocumentNotifications",
			currentUserMeta: "usermetas/getCurrentUserMeta",
			medicationLogEntries: "medicationLogNotifications/getFilteredMedicationLogEntries",
		}),
		newProfilePictureUrl() {
			if (this.newProfilePicture) {
				return URL.createObjectURL(this.newProfilePicture);
			}
			return undefined;
		},
		clients() {
			return this.filteredClients;
		},
		notificationPath() {
			return this.$route.path.includes("notification");
		},
		medicationView() {
			return this.$route.name === "Medication";
		},
		medicationEntry() {
			return this.$route.name === "MedicationEntry";
		},
	},
	methods: {
		createNumberOfUnseenMedicationLogs(client) {
			let filteredLogs = this.medicationLogEntries.filter((element) => element.attributes.client.id == client.id)
			return filteredLogs.length > 0 ? filteredLogs.length : ""
		},
		async setFocusedClient(client) {
			await this.$store.dispatch("entrylinks/load", false);
			await this.$store.dispatch("clients/setFocusedClientId", client.id);
			await this.$store.dispatch("setChartMonths", 3)

			if (
				this.$route.name === "EntryDetail" ||
				this.$route.name === "EntryInput" ||
				this.$route.name === "EntryEdit"
			) {
				await this.$router.push({ path: "/module/diary" });
			}
			else if (this.$route.name === "ClientRecordDetail") {
				await this.$router.push({ path: "/module/record" });
			}
			else if (this.$route.name === "ChartDetails") {
				await this.$router.push({ path: "/module/charts" });
			}
			else if (
				this.$route.name === "GoalDetail" ||
				this.$route.name === "GoalEdit" ||
				this.$route.name === "GoalRating" ||
				this.$route.name === "GoalInput") {
				await this.$router.push({ path: "/module/goal" });
			}
			else if (this.$route.name === "MedicationEntry") {
				await this.$router.push({ path: "/module/medication" });
			}

			await this.$store.dispatch("setSidePanelOpen", false);
			this.$store.dispatch("pagination/setPage", undefined);
			this.$store.dispatch("entrylinks/load", true)
		},
		isMobile() {
			return this.$store.getters.isMobile;
		},
		numberOfNotifications(client) {
			if (!this.entryNotifications) return undefined;
			if (!this.commentNotifications) return undefined;
			if (!this.documentNotifications) return undefined;
			const notifications = {
				entryNotifications: this.entryNotifications,
				commentNotifications: this.commentNotifications,
				documentNotifications: this.documentNotifications,
			};
			return getNotificationNumberOfClient(notifications, client);
		},
		hasSeparation(client, index) {
			if (index === 0) return false; //no separation before first client
			const contactClientIds =
				this.currentUserMeta.attributes.clients.map((c) => c.id);
			if (contactClientIds.includes(client.id)) return false; //no separation between contact clients
			if (contactClientIds.includes(this.clients[index - 1].id))
				return true; //line after contact clients
			return (
				this.clients[index - 1].attributes.group.id !==
				client.attributes.group.id
			); //line if group changed from previous client
		},
	},
};
</script>

<style scoped>
.notificationBubble {
	background-color: rgba(0, 0, 0, .8);
	color: rgb(255 255 255);
	border-radius: 9999px;
	width: 1.5rem;
	height: 1.5rem;
	display: flex;
	justify-content: center;
	place-items: center;
	font-size: 0.75rem;
	line-height: 1rem;
	margin-left: 0.25rem;
	margin-right: 8px;
	float: right;
}

.user-info-container {
	width: 100%;
}

.user-info {
	border: 0px solid black;
	border-radius: 0.5rem;
	display: flex;
	width: 100%;
	cursor: pointer;
	position: relative;
}

.nameAndDate {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.name {
	display: flex;
	justify-content: start;
	width: 100%;
}

.groupAndName {
	order: -1;
}

.user-info:hover {
	background-color: whitesmoke;
	/*rgb(220, 220, 220)*/
}

.selected {
	background-color: whitesmoke;
	/*rgb(220, 220, 220)*/
	color: black;
}

.scrollable {
	overflow: scroll;
	overflow-x: hidden;
	height: 100%;
}

/*.scrollable::-webkit-scrollbar {*/
/*    display: flex;*/
/*}*/

.side-bar {
	/*max-width: 270px;*/
	/*min-width: 200px;*/
	border-right: 2px solid rgb(240, 240, 240);
	background-color: white;
}

@media (max-width: 1377px) {
	.side-bar {
		max-width: 100%;
	}
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollable {
	/*-ms-overflow-style: none; !* IE and Edge *!*/
	/*scrollbar-width: none; !* Firefox *!*/
}

.user-info img {
	object-fit: cover;
	height: 100%;
	width: 100%;
}

@media (max-width: 1200px) {
	.nameAndDate {
		flex-direction: column;
		justify-content: start;
	}

	.groupAndName {
		order: 2;
	}
}

@media (max-width: 769px) {
	.nameAndDate {
		justify-content: space-between;
	}

	.groupAndName {
		order: 2;
	}

	.user-info {
		display: flex;
	}

	li>div {
		display: flex;
	}

	.clientObject>div {
		display: flex;
	}
}

@media (max-width: 900px) {
	.user-info-container {
		width: 100%;
	}

	.user-info {
		width: 100%;
	}
}
</style>
