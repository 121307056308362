<template>
	<div>
		<div class="container p-3">
			<div class="columns">
				<div class="column">
					<b-field label="Name">
						<b-input v-model="name"></b-input>
					</b-field>
					<div class="tag is-danger is-light" v-if="!$v.name.required">
						Name benötigt!
					</div>
				</div>
				<div class="column">
					<b-field label="Link">
						<b-input v-model="link"></b-input>
					</b-field>
					<div class="tag is-danger is-light" v-if="!$v.link.required">
						Link benötigt!
					</div>
					<div class="tag is-danger is-light" v-if="!$v.link.url">
						Korrekten Link einfügen!
					</div>
				</div>
				<div style="text-align:center;" class="column is-one-fifth ">
					<b-field label="Icon">
						<b-dropdown v-model="icon" aria-role="list" :scrollable="true">

							<template #trigger>
								<b-button type="is-primary" :icon-left="icon" icon-right="menu-down" />
							</template>


							<b-dropdown-item v-for="option in ICONS" :value="option" :key="option" aria-role="listitem">
								<div class="media">
									<b-icon class="media-left" :icon=option></b-icon>
								</div>
							</b-dropdown-item>
						</b-dropdown>
					</b-field>
					<div class="tag is-danger is-light" v-if="!$v.icon.required">
						Icon benötigt!
					</div>
				</div>
			</div>
		</div>
		<div class="buttons m-5" style="display: flex; justify-content: center">
			<b-button class="mt-3 button" type="is-primary" @click="saveWidget" v-bind:loading="isLoading"
				v-bind:disabled="$v.$invalid" :size="isMobile ? 'is-small' : ''">Speichern
			</b-button>
			<b-button class="mt-3 button" type="is-danger" tag="router-link" :to="'/'"
				:size="isMobile ? 'is-small' : ''">Abbrechen
			</b-button>
		</div>
	</div>
</template>

<script>
/**
 * Module allowing the creation of a new goal.
 * @displayName GoalInput
 */

import { required, url } from "vuelidate/lib/validators";
import Parse from "parse/dist/parse.min.js";
import { icon_list } from '../../../src/assets/icon_list.js'

export default {
	name: "EditWidget",
	validations: {
		name: {
			required
		},
		link: {
			required,
			url
		},
		icon: {
			required
		}
	},
	data() {
		return {
			name: "",
			link: "",
			icon: "",
			widgetID: "",
			isLoading: false,
			ICONS: icon_list
		};
	},
	async mounted() {
		const widget = this.$route.params.data
		if (widget) {
			this.name = widget.title
			this.link = widget.link
			this.icon = widget.icon
			this.widgetID = widget.widgetID
		} else {
			await this.$router.push({ name: "Root" });
		}
	},
	computed: {
		getSize() {
			return this.$store.getters.isMobile ? "is-small" : "";
		},
		isMobile() {
			return this.$store.getters.isMobile;
		}
	},
	methods: {
		async saveWidget() {
			this.isLoading = true;

			const widgetParams = {
				widgetID: this.widgetID,
				title: this.name,
				icon: this.icon,
				link: this.link,
			};
			await Parse.Cloud.run("editWidget", widgetParams);
			this.isLoading = false;
			await this.$router.push({ name: "Root" });
		}
	},
	watch: {},
};
</script>

<style lang="scss" scoped></style>
