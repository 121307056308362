var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"100%"}},_vm._l((_vm.entryRequestAttributes),function(v,index){return _c('div',{key:index},[(_vm.attributeConfigs[index] && _vm.attributeConfigs[index].attributes.entryAttributes)?[_c('h3',{staticClass:"attribute_titel mb-3 mt-3"},[_vm._v(" "+_vm._s(_vm.attributeConfigs[index].attributes.name)+" ")]),_c('div',{staticClass:"columns is-flex is-flex-wrap-wrap is-justify-content-flex-start",staticStyle:{"width":"100%"}},[(
                            _vm.attributeConfigs[index].attributes.isSingleSelection
                        )?_c('div',[_c('RadioCheckbox',{attrs:{"set-selected-option":(option) => _vm.selectEntryRequestOption(index, option),"options":_vm.attributeConfigs[
                                index
                            ].attributes.entryAttributes.filter(
                                (att) => att.attributes.deleted !== true
                            )}})],1):_c('div',_vm._l((_vm.attributeConfigs[
                                index
                            ].attributes.entryAttributes.filter(
                                (att) => att.attributes.deleted !== true
                            )),function(entryAttribute){return _c('b-checkbox',{key:entryAttribute.id,staticClass:"column is-flex-grow-0",staticStyle:{"flex-basis":"initial"},attrs:{"native-value":entryAttribute},model:{value:(_vm.entryRequestAttributes[index]),callback:function ($$v) {_vm.$set(_vm.entryRequestAttributes, index, $$v)},expression:"entryRequestAttributes[index]"}},[_vm._v(" "+_vm._s(entryAttribute.attributes.name)+" ")])}),1)])]:_vm._e()],2)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }