<template>
    <div class="is-1">
        <figure
            id="clientFigure"
            class="
                m-1
                image
                is-48x48 is-flex is-align-items-center is-justify-content-center
            "
        >
            <img
                id="clientProfilePicture"
                class="is-rounded"
                :src="getPicture(client)"
                alt="Klientenbild"
            />
        </figure>
    </div>
</template>

<script>
/**
 * Profile picture of a client.
 * @displayName ClientFigure
 */

import { getClientPicture } from "@/lib/imageFunctions";

export default {
    name: "ClientFigure",
    props: ["client"],
    methods: {
        getPicture(client) {
			let profilePictureUrl = undefined
			if(this.$store.state.clients.data.length === 0) return undefined
			const associatedClients = this.$store.state.clients.data.filter(c => client?.attributes.uuid === c?.attributes.uuid)
			associatedClients.forEach(c =>{
				const picture = c.attributes.profile_picture
				if(picture) profilePictureUrl = picture._url
			})
            const pictureObject = {
                newProfilePicture: undefined,
                clientPicture: profilePictureUrl,
                clientGender: client?.attributes.gender,
            };
            return getClientPicture(pictureObject);
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
    },
};
</script>

<style scoped>
.user-info img {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

#clientProfilePicture {
    height: 90%;
    width: 90%;
    object-fit: cover;
}

@media (max-width: 769px) {
    li > div {
        display: flex;
    }
    .clientObject > div {
        display: flex;
    }
}

@media (max-width: 400px) {
    #clientProfilePicture {
        height: 80%;
        width: 80%;
        object-fit: cover;
    }

    #clientFigure {
        margin: 0 !important;
    }
}
</style>
