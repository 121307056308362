<template>
    <div
        class=""
        id="mobileDiaryContainer"
        style="height: 100%; width: 100%"
        v-if="mobile()"
    >
        <b-collapse
            :open="this.$store.state.sidePanelOpen"
            id="mobileDiarySidePanel"
            @open="togglePanel()"
            v-if="user"
            :style="this.$store.state.sidePanelOpen ? 'height: 100%' : ''"
        >
            <div style="height: 100%; width: 100%">
                <DiarySidePanel
                    @click="togglePanel()"
                    v-if="user"
                    style="height: 100%; width: 100%"
                ></DiarySidePanel>
            </div>
        </b-collapse>

        <div
            id="mobileDiaryEntries"
            style="width: 100%"
            :class="this.$store.state.openedKeyBoard ? 'mt-5' : ''"
            :style="this.$store.state.openedKeyBoard ? 'height: 130%' : ''"
        >
            <transition name="show" mode="out-in">
                <router-view style="height: 100%; width: 100%" />
            </transition>
        </div>
    </div>
</template>

<script>
/**
 * Container containing the mobile router-view of the application.
 * @displayName MobileContainer
 */

import { mapGetters } from "vuex";
import DiarySidePanel from "@/components/bars/sideBar/DiarySideBar";

export default {
    name: "MobileContainer",
    data() {
        return {
            isPanelOpen: true,
            isUserModalActive: false,
            isOpen: 0,
            numbers: [2, 5],
            isClientEditModalActive: false,
            newProfilePicture: undefined,
        };
    },
    computed: {
        user() {
            return this.$store.state.currentUser;
        },
        backButtonVisible() {
            return !(this.$route.name === "Root");
        },
        ...mapGetters({
            clientId: "clients/getFocusedClientId",
            clientPicture: "clients/getFocusedClientPicture",
            clientName: "clients/getFocusedClientName",
            userProfilePicture: "usermetas/getUserProfilePicture",
            focusedEntry: "entrylinks/getFocusedEntry",
            currentUserId: "users/getUserId",
            notifications: "getEntryNotifications",
        }),
        searchQuery: {
            set(searchQuery) {
                this.$store.dispatch("setSearchQuery", searchQuery);
            },
            get() {
                // Or remove mapState and use this.$store.state.values.example
                return this.$store.state.searchQuery;
            },
        },
    },
    methods: {
        mobile() {
            return this.$store.getters.isMobile;
        },
        closePanel() {
            this.$store.state.sidePanelOpen = false;
            // this.isPanelOpen = false
        },
        togglePanel() {
            this.$store.state.sidePanelOpen = !this.$store.state.sidePanelOpen;
        },
        unsetFocusClient() {
            this.$store.state.focusedClientId = undefined;
        },
    },
    components: {
        DiarySidePanel,
    },
};
</script>

<style>
html {
    height: 100%;
    width: 100vw;
    overflow: hidden !important;
}

body {
    height: 100%;
    width: 100%;
}

.columns {
    height: 100%;
}

.show-enter-active,
.show-leave-enter {
    transform: translateX(0);
    transition: all 0.3s linear;
}

.show-enter,
.show-leave-to {
    transform: translateX(100%);
}

/*ul {*/
/*	height: 91vh;*/
/*}*/

.clientHeader {
    height: 64px;
    width: 100%;
}

.entryPanel {
    height: 100%;
    width: 100%;
}

@media (max-width: 769px) {
    .diaryContainer {
        display: none;
    }

    .topBar {
        display: none;
    }

    #mobileDiarySidePanel {
        grid-area: panel;
    }

    #mobileDiaryEntries {
        display: flex;
        grid-area: entries;
        overflow: auto;
        height: 100%;
    }

    div > span {
        display: block;
        /* height: 2px; */
    }
}

@media (max-width: 1030px) {
}

@media (min-width: 769px) {
    #mobileDiaryContainer {
        display: none;
    }

    .diaryContainer {
        display: flex;
    }

    #mobileDiaryEntries {
        display: none;
    }
}

@media (max-width: 400px) {
    .clientHeader {
        height: 52px;
        width: 100%;
    }
    .entryPanel {
        height: 100%;
        width: 100%;
    }
}
</style>

<style>
div.collapse-content {
    height: 100%;
}
</style>
