import Graph from "@/lib/store/commentGraph/Graph"
import Edge from "@/lib/store/commentGraph/Edge";
const state = {
	data: [],
};

const recurseReferences= (comment, graph) =>{
	if(!comment.attributes.reference) return
	if(!graph.isNodeInGraph(comment)) graph.addNode(comment)
	const edge = new Edge(comment.attributes.reference, comment)
	if(!graph.isEdgeInNode(edge)) graph.addEdge(edge)
	recurseReferences(comment.attributes.reference, graph)
}

const recurseTargetNodes = (targetNodes, storingList, graph) =>{
	if(targetNodes.length > 0){

		for(const targetNode of targetNodes){
			const newList = []
			newList.push(targetNode)
			const newTargetNodes = graph.getTargetNodes(targetNode)
			recurseTargetNodes(newTargetNodes, newList, graph)
			storingList.push(newList)
		}
	}

}

const getters = {
	getFilteredRecordComments(state, getters, rootState) {

		if (rootState.focusedRecordId) {
			const comments = state.data.filter((item) => item.attributes.parent.id === rootState.focusedRecordId).reverse()
			
			const graph = new Graph()
			for(const comment of comments){
				if(!graph.isNodeInGraph(comment)) graph.addNode(comment)
				recurseReferences(comment, graph)
			}
			const masterList = []
			for(const node of graph.getUnConnectedNodes()){
				masterList.push(node)
			}
			for(const node of graph.getRootNodes()){
				const storingList = [node]
				recurseTargetNodes(graph.getTargetNodes(node), storingList, graph)
				masterList.push(storingList)
			}
			
			return masterList
		}
		return undefined;
	},
	getNumberOfRecordComments(state, getters, rootState) {
		return parent => {
			var numberOfComments = 0
			state.data.forEach((item) => {
				if (item.attributes.parent.id === parent.id) {
					numberOfComments++;
				}
			})
			return numberOfComments;
		}
	}
};

const mutations = {
	SET(state, objects) {
		state.data = objects
	},
	INSERT(state, object) {
		
		state.data.push(object)
	
	},
	DELETE(state, object) {
		state.data = state.data.filter((elem) => {
			return elem.id != object.id
		})
	},
	REPLACE(state, object) {
		const oldObject = state.data.filter(item => item.id == object.id)
		const index = state.data.indexOf(oldObject[0]);
		if (index > -1) {
			state.data.splice(index, 1, object);
		}
		
	},
};

const actions = {
	set({ commit }, objects) {
		commit('SET', objects)
	},
	insert({ commit }, object) {
		commit('INSERT', object)
	},
	delete({ commit }, object) {
		commit('DELETE', object)
	},
	replace({ commit }, object) {
		commit('REPLACE', object)
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
  };
