<template>
	<div style="height: 100%">
		<div
			v-if="
                clientRecords &&
                clientRecords.length > 0
            "
			style="user-select: none"
			class="scrollable is-flex is-flex-direction-column"
		>
			<b-collapse
				class="m-2 card"
				animation="slide"
				aria-id="filter-collapse"
			>
				<template #trigger="props">
					<div
						class="card-header"
						role="button"
						aria-controls="filter-collapse"
						:aria-expanded="props.open"
					>
						<p class="card-header-title">
							<b-icon :icon="'filter-variant'"> </b-icon>Filter
						</p>
						<a class="card-header-icon">
							<b-icon
								:icon="props.open ? 'menu-down' : 'menu-up'"
							>
							</b-icon>
						</a>
					</div>
				</template>

				<div class="card-content">
					<b-field label="Kategorie">
						<b-autocomplete
							field="attributes.name"
							placeholder="Kategorie"
							v-model="categoryInput"
							:data="filteredDataArray"
							icon="magnify"
							@select="option => selectedCategory = option"
							clearable
							open-on-focus
						>
							<template #empty>Keine Treffer gefunden</template>
						</b-autocomplete>
					</b-field>
					<b-field label="Volltextsuche">
						<b-input
							placeholder="Volltextsuche"
							type="search"
							icon="magnify"
							v-model="searchTerm"
							autocomplete="off"
							expanded
							:size="isMobile() ? 'is-small' : ''"
						></b-input>
					</b-field>
					<b-field label="Datum">
						<div class="columns">
							<b-datepicker
								:first-day-of-week=1
								placeholder="von"
								icon="calendar-today"
								trap-focus
								class="mr-1 column"
								:date-formatter="dateFormatter"
								v-model="startDate"
								:icon-right="startDate ? 'close-circle' : ''"
								icon-right-clickable
								@icon-right-click="clearStartDate"
							>
							</b-datepicker>
							<b-datepicker
								:first-day-of-week=1
								class="column"
								:date-formatter="dateFormatter"
								placeholder="bis"
								icon="calendar-today"
								trap-focus
								v-model="endDate"
								:icon-right="endDate ? 'close-circle' : ''"
								icon-right-clickable
								@icon-right-click="clearEndDate"
							>
							</b-datepicker>
						</div>
					</b-field>
					<b-button type="is-primary" @click="clearAllFilter">Filter zurücksetzen</b-button>
				</div>
			</b-collapse>

			<router-link
				:to="{ path: `/module/record/${clientRecord.id}` }"
				tag="div"
				v-for="clientRecord in filteredClientRecords"
				:key="clientRecord.id"
				class="box entry-box p-1 my-1 is-flex is-flex-direction-column"
			>
				<article class="media">
					<div class="media-left m-2">
						<b-icon icon="file-document" size="is-large"> </b-icon>
					</div>
					<div
						class="media-content p-3"
						v-on:click="setFocusedRecordId(clientRecord)"

					>
						<ColoredTag
							v-if=" clientRecord && clientRecord.attributes.category && clientRecord.attributes.category.attributes
                                    .color"
							:tag-name="
                                clientRecord.attributes.category.attributes.name
                            "
							:tag-color="
                                clientRecord.attributes.category.attributes
                                    .color
                            "
							:is-right="true"
						/>
						<ColoredTag
							v-if=" clientRecord && !clientRecord.attributes.category"
							:tag-name="
                                'Ohne Kategorie'
                            "
							:tag-color="
                                '#000000CC'
                            "
							:is-right="true"
						/>
						<small class="is-font-small">
							{{
								clientRecord.attributes.creator
							}}
							<time>
								{{
									clientRecord.createdAt.toLocaleDateString(
										"de-DE",
										{
											year: "numeric",
											month: "2-digit",
											day: "2-digit",
										}
									) +
									" - " +
									clientRecord.createdAt.toLocaleTimeString(
										"de-DE"
									)
								}}
							</time>
						</small>
						<div class="is-title">
							{{ clientRecord.attributes.title }}
						</div>
						<p class="text-box" v-if="clientRecord.attributes.text">
							{{
								clientRecord.attributes.text.replace(
									htmlRegex,
									""
								)
							}}
						</p>
						<p class="text-box" v-else>Keine Vorschau verfügbar!</p>
						<div
							style="height: 27px"
							class="is-flex is-justify-content-flex-end"
						>
							<CommentIcon
								:number-of-comments="
                                    numberOfComments(clientRecord)
                                "
							/>
							<ReaderIcon
								:readers="clientRecord.attributes.readers"
							/>
						</div>
					</div>
				</article>
			</router-link>
			<div v-if="focusedClient.attributes.entry" class="article has-darker-grey-color white-text m-2 p-3">
				Zugang am
				{{
					focusedClient.attributes.entry.toLocaleDateString("de-DE", {
						year: "numeric",
						month: "2-digit",
						day: "2-digit",
					})
				}}
			</div>
		</div>
		<div v-else class="is-flex is-justify-content-center">
			Keine Dokumente vorhanden!
		</div>
	</div>
</template>

<script>
/**
 * Module displaying the records associated with the focusedClient.
 * Records are represented with a h-ref to the pdf document.
 * @displayName ClientRecord
 */

import { mapGetters } from "vuex";
import ColoredTag from "@/components/tags/ColoredTag";
import CommentIcon from "@/components/icons/CommentIcon";
import ReaderIcon from "@/components/icons/ReaderIcon";

export default {
	name: "ClientRecordEntries",
	components: { ColoredTag, CommentIcon, ReaderIcon },
	data() {
		return {
			htmlRegex: /<\/?[^>]+(>|$)/g,
			dateoptions: { year: 'numeric', month: '2-digit', day: '2-digit' },
		};
	},
	computed: {
		categoryInput: {
			get(){
				return this.$store.getters["docfilter/getCategoryInput"];
			}, 
			set(newValue){
				this.$store.dispatch("docfilter/setCategoryInput", newValue);
			}
		},
		searchTerm: {
			get(){
				return this.$store.getters["docfilter/getSearchTerm"];
			}, 
			set(newValue){
				this.$store.dispatch("docfilter/setSearchTerm", newValue);
			},
		},
		startDate: {
			get(){
				return this.$store.getters["docfilter/getStartDate"];
			}, 
			set(newValue){
				this.$store.dispatch("docfilter/setStartDate", newValue);
			},
		},
		endDate: {
			get(){
				return this.$store.getters["docfilter/getEndDate"];
			}, 
			set(newValue){
				this.$store.dispatch("docfilter/setEndDate", newValue);
			},
		},
		selectedCategory: {
			get(){
				return this.$store.getters["docfilter/getSelectedCategory"];
			}, 
			set(newValue){
				this.$store.dispatch("docfilter/setSelectedCategory", newValue);
			},
		},
		...mapGetters({
			focusedClient: "clients/getFocusedClient",
			clientRecords: "clientrecords/getFocusedClientRecords",
			// documentCreators: "documentCreators/getfocusedDocumentCreators",
			clientRecordCategories:
				"clientRecordsCategories/getfocusedClientRecordCategory",
			numberOfComments: "recordcomments/getNumberOfRecordComments",
			isLoaded: "clientrecords/getLoaded",

		}),
		filteredDataArray() {
            return this.clientRecordCategories.filter((option) => {
                return option.attributes.name
                    .toString()
                    .toLowerCase()
                    .indexOf(this.categoryInput.toLowerCase()) >= 0
            })
        },
		filteredClientRecords() {
			return this.clientRecords.filter((record) => {
				let IS_SEARCHTERM = true;
				let IS_CATEGORY = true;
				let IS_DATE = true;
				if (this.searchTerm.length > 0) {
					if (record.attributes.text) {
						IS_SEARCHTERM = record.attributes.text.toLowerCase().includes(
							this.searchTerm.toLowerCase()
						) || record.attributes.creator.toLowerCase().includes(
							this.searchTerm.toLowerCase()
						);
					}
				}
				if (record.attributes.category && this.selectedCategory) {
					IS_CATEGORY =
						record.attributes.category.id ===
						this.selectedCategory.id;
				}
				if (this.startDate) {
					IS_DATE = record.createdAt > this.startDate && IS_DATE;
				}
				if (this.endDate) {
					IS_DATE = record.createdAt < this.endDate && IS_DATE;
				}

				return IS_SEARCHTERM && IS_CATEGORY && IS_DATE;
			});
		},
	},
	methods: {
		setFocusedRecordId(record) {
			this.$store.dispatch("setFocusedRecordId", record.id);
		},
		isMobile() {
			return this.$store.getters.isMobile;
		},
		clearStartDate () {
            this.startDate = null
        },
		clearEndDate () {
            this.endDate = null
        },
		dateFormatter(dt){
			return dt.toLocaleDateString('de-DE', this.dateoptions);
		},
		clearAllFilter() {
			this.startDate = null
			this.endDate = null
			this.categoryInput = ""
			this.searchTerm = ""
			this.selectedCategory = null
		}
	},
};
</script>

<style scoped>
.scrollable {
	overflow: auto;
	height: 100%;
	width: 100%;
	padding-bottom: 1.5rem;
}

.entry-box {
	margin-right: 0.5em;
	margin-left: 0.5em;
	cursor: pointer;
	padding-bottom: 15px;
}

.entry-box:hover {
	background-color: whitesmoke;
}

.article {
	border-radius: 6px;
}

.white-text {
	color: white;
}

.text-box {
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
	overflow: hidden;
}

@media (max-width: 1026px) {
	.scrollable {
		overflow: auto;
		height: 100%;
		width: 100%;
	}
}

@media (max-width: 769px) {
	.tabs:not(:last-child),
	.pagination:not(:last-child),
	.message:not(:last-child),
	.level:not(:last-child),
	.breadcrumb:not(:last-child),
	.block:not(:last-child),
	.title:not(:last-child),
	.subtitle:not(:last-child),
	.table-container:not(:last-child),
	.table:not(:last-child),
	.progress:not(:last-child),
	.progress-wrapper.is-not-native:not(:last-child),
	.notification:not(:last-child),
	.content:not(:last-child),
	.box:not(:last-child) {
		margin-bottom: 0;
	}
	.scrollable {
		height: 100%;
		width: 95vw;
	}
}
</style>
