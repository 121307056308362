<template>
    <small class="" v-if="authorMetaClass">
        {{
            authorMetaClass.attributes.first_name +
            " " +
            authorMetaClass.attributes.last_name
        }},
        <CreationTime :object="object" v-if="!group && object" />
        <span v-if="group">{{ group.attributes.group_name }}</span>
    </small>
	<div v-else class="skeleton skeleton-author"></div>
</template>

<script>
/**
 * Component for display of entry author and entry creation date.
 * @displayName AuthorNameAndCreationTime
 */

import CreationTime from "@/components/helper/CreationTime";
export default {
    name: "AuthorNameAndCreationTime",
    components: { CreationTime },
    props: ["authorMetaClass", "object", "group"],
};
</script>

<style scoped>
@media (max-width: 769px) {
    small {
        margin-right: 2px;
    }
}
</style>
