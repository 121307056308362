<template>
	<div v-if="ratingsummary" class="box entry-box p-3 mx-1 mb-1">
		<div class="is-font-extra-large is-font-bold">Bewertungswoche</div>
		<div>KW {{ ratingsummary.createdAt.getWeek() }}</div>
		<div class="is-font-extra-large is-font-bold">
			Gesammelte Bewertungen
		</div>
		<div class="is-flex is-flex-direction-row is-items-align-center mx-4">
			<Progress :value="ratingsSum" :max="maxValue" :tooltip="false" :key="ratingsSum" indicator format="raw"
				type="is-success" size="is-small" class="mr-5">
				<ProgressStep v-for="(goalStep, index) in goal.attributes
					.ratingSeperators" :key="index" :value="goalStep">
					{{ index + 1 }}
				</ProgressStep>
			</Progress>
		</div>
		<div class="is-flex is-flex-direction-column is-justify-content-center" v-if="goalLevel >= 0">
			<div class="is-font-extra-large is-font-bold">Level</div>
			<template v-if="goalLevel === 0">
				<b-icon class="mt-1" size="is-medium" icon="numeric-0-box">
				</b-icon>
			</template>
			<template v-if="goalLevel === 1">
				<b-icon class="mt-1" size="is-medium" icon="numeric-1-box">
				</b-icon>
			</template>
			<template v-if="goalLevel === 2">
				<b-icon class="mt-1" size="is-medium" icon="numeric-2-box">
				</b-icon>
			</template>
			<template v-if="goalLevel === 3">
				<b-icon class="mt-1" size="is-medium" icon="numeric-3-box">
				</b-icon>
			</template>
			<template v-if="goalLevel === 4">
				<b-icon class="mt-1" size="is-medium" icon="numeric-4-box">
				</b-icon>
			</template>
		</div>
		<div>
			<div class="is-font-extra-large is-font-bold">
				Durchschnittliche Bewertungen
			</div>
			<div style="
                    display: flex;
                    align-items: center;
                    position: relative;
                    width: 97%;
                    border-radius: 0.5rem;
                " class="m-3 p-1">
				<canvas :id="numberUuid" />
			</div>
		</div>
	</div>
</template>

<script>
import Progress from "@/components/progress/Progress";
import ProgressStep from "@/components/progress/ProgressStep";
import {
	Chart as ChartJS,
	Title,
	Tooltip,
	Legend,
	BarElement,
	CategoryScale,
	LinearScale,
	registerables,
} from "chart.js";
import { v4 as uuid } from "uuid";
import { mapGetters } from "vuex";

Date.prototype.getWeek = function () {
	var d = new Date(
		Date.UTC(this.getFullYear(), this.getMonth(), this.getDate())
	);
	var dayNum = d.getUTCDay() || 7;
	d.setUTCDate(d.getUTCDate() + 4 - dayNum);
	var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
	return Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
};

export default {
	name: "RatingSummary",
	components: {
		ProgressStep,
		Progress,
	},
	props: ["goal", "ratingsummary"],
	data() {
		return {
			barChart: undefined,
		}
	},
	computed: {
		...mapGetters({
			weekDays: "weekDays/getWeekDays"
		}),
		avgRatings() {
			if (this.ratingsummary.attributes.ratings) {
				var dayRatings = [];
				var labels = [];
				this.weekDays.forEach((day) => {
					dayRatings.push(
						this.ratingsummary.attributes.ratings[day.id]
					);
					labels.push(day.label);
				});
				return {
					labels: labels,
					datasets: [
						{
							label: "Durchschnittliche Bewertung",
							data: dayRatings,
							backgroundColor: "rgba(54, 162, 235, 0.2)",
							borderColor: "rgb(54, 162, 235)",
							borderWidth: 2,
							borderRadius: 5,
						},
					],
				};
			}
			return undefined;
		},
		ratingsSum() {
			if (this.ratingsummary.attributes.ratings) {
				var sum = this.ratingsummary.attributes.ratings.reduce(
					(total, rating) => {
						return (total += rating);
					},
					0
				);
				return sum;
			}
			return 0;
		},
		maxValue() {
			let ratingArray = this.ratingsummary.attributes.ratings
			let multiplikator = this.goal.attributes.maxRating
			let relevantRatings = ratingArray.filter((rating) => {
				return (
					rating > 0
				);
			});
			return relevantRatings.length * multiplikator;
		},
		goalLevel() {
			if (this.goal) {
				var seps = this.goal.attributes.ratingSeperators;
				if (seps.length > 0) {
					var sortedSeps = seps.sort(function (a, b) {
						return a - b;
					});
					var level = 0;
					while (sortedSeps[level]/100 * this.maxValue <= this.ratingsSum) {
						level++;
					}
					return level;
				} else {
					return undefined;
				}
			}
			return undefined;
		},
		numberUuid() {
			return uuid().concat(this.clientId).concat("_numbers"); //combine attr id and client id
		},
	},
	watch: {
		avgRatings(newValue) {
			if (this.barChart) {
				
				this.barChart.data.labels = newValue.labels;
				this.barChart.data.datasets = newValue.datasets;
				this.barChart.update();
			}
		},
	},
	mounted() {
		const canvasNumberObject = document.getElementById(this.numberUuid);

		this.barChart = new ChartJS(canvasNumberObject, {
			type: "bar",
			data: this.avgRatings,
			options: {
				spanGaps: true,
				maintainAspectRatio: false,
				responsive: true,

				scales: {
					y: {
						type: "linear",
						min: 0,
						ticks: {
							stepSize: 1,
						},
						display: true,
						grid: {
							display: false,
						},
					},
					x: {
						display: true,
						grid: {
							display: false,
						},
					},
				},
				plugins: {
					legend: {
						display: false,
						onClick: (e) => e.stopPropagation(),
					},
				},
			},
		});
	},
};
</script>

<style scoped></style>