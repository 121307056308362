import {ToastProgrammatic as Toast} from "buefy";

export const positiveFeedBack = message =>{
    Toast.open({
        message: message,
        type: "is-success",
        position: "is-bottom",
    });
}

export const negativeFeedBack = message =>{
    Toast.open({
        message: message,
        type: "is-danger",
        position: "is-bottom",
    });
}

export const warnFeedBack = message =>{
    Toast.open({
        message: message,
        type: "is-warning",
        position: "is-bottom",
    });
}
