var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-field',{attrs:{"expanded":"","message":_vm.enableValidation ? ((_vm.$v.selectedValue.$invalid) ? _vm.validationMessage : '') : '',"type":_vm.enableValidation ? ((_vm.$v.selectedValue.$invalid) ? 'is-danger' : 'is-success') : ''},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"align-content-center",staticStyle:{"display":"inline-flex"}},[_c('p',[_vm._v(" "+_vm._s(_vm.labelText))])])]},proxy:true}])},[_c('b-autocomplete',{staticClass:"autocomplete_break",attrs:{"open-on-focus":"","clearable":"","data":_vm.orderedFilteredDataArray,"icon":"magnify","custom-formatter":option => (option?.attributes.medication.attributes.displayName + '(' + 
	(option?.attributes.beginDate ? option.attributes.beginDate.toLocaleDateString('de-DE',
		{
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
		}) : '') + ' - ' +
	(option?.attributes.endDate ? option.attributes.endDate.toLocaleDateString('de-DE',
		{
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
		}) : '') + ')'
)},on:{"select":option => _vm.set(option)},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',{staticClass:"media"},[_c('div',{staticClass:"media-content"},[_vm._v(" "+_vm._s(props?.option?.attributes.medication.attributes.displayName)+" ("),(props?.option?.attributes.beginDate)?_c('span',[_vm._v(_vm._s(props.option.attributes.beginDate.toLocaleDateString("de-DE", { year: "numeric", month: "2-digit", day: "2-digit", })))]):_vm._e(),_vm._v(" - "),(props?.option?.attributes.endDate)?_c('span',[_vm._v(" "+_vm._s(props.option.attributes.endDate.toLocaleDateString("de-DE", { year: "numeric", month: "2-digit", day: "2-digit", })))]):_vm._e(),_vm._v(") ")])])]}},{key:"empty",fn:function(){return [_vm._v("Keine Ergebnisse gefunden.")]},proxy:true}]),model:{value:(_vm.inputString),callback:function ($$v) {_vm.inputString=$$v},expression:"inputString"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }