
<template>
	<div :class="['medicationEntryActive' + entryLink.active, entryLink.attributes.hasMedicationLog ? '' : 'hasNotMedicationLog']" class="box entry-box p-0 mt-1 mb-1">
		<article class="media p-3 article">
			<div class="media-left">
				<AuthorImage v-if="entryLink.attributes.medication" :author="entryLink.attributes.medication.attributes.contactUser
					" />
				<AuthorImage v-else :author="undefined" />
			</div>
			<div class="media-content" style="overflow: hidden">
				<div class="mb-0">
					<div class="mt-0 mb-0">
						<div class="
								pt-0
								authorTagContainer
								is-font-medium
							">
							<small v-if="entryLink.attributes.medication">
								{{
									entryLink.attributes.medication.attributes.contactUser.attributes?.dependency.attributes.first_name
									+
									" " +
									entryLink.attributes.medication.attributes.contactUser.attributes?.dependency.attributes.last_name
								}},
								<time>
									Einnahme vom {{
										entryLink.attributes.medication.attributes.startDate.toLocaleDateString("de-DE",
											{
												year: "numeric",
												month: "2-digit",
												day: "2-digit",
											}) +
										" bis zum " +
										entryLink.attributes.medication.attributes.endDate.toLocaleDateString("de-DE",
											{
												year: "numeric",
												month: "2-digit",
												day: "2-digit",
											})
									}}</time>
								<time v-if="entryLink.attributes.medication.attributes.dropDate">, abgesetzt am
									{{
										entryLink.attributes.medication.attributes.dropDate.toLocaleDateString("de-DE",
											{
												year: "numeric",
												month: "2-digit",
												day: "2-digit",
											})
									}}</time>
								<time v-if="entryLink.attributes.medication.attributes.expirationDate">, haltbar
									bis
									{{
										entryLink.attributes.medication.attributes.expirationDate.toLocaleDateString("de-DE",
											{
												year: "numeric",
												month: "2-digit",
												day: "2-digit",
											})
									}}</time>
							</small>
						</div>
					</div>
					<p v-if="entryLink.attributes.medication" class="is-font-extra-large is-font-bold mb-1">
						M{{ entryLink.attributes.medication.attributes.number }}: {{
							entryLink.attributes.medication.attributes.medication.attributes?.displayName }}
					</p>

					<div class="mt-0 mb-0">
						<div class="
								pt-0
								authorTagContainer
								is-font-medium
							">
							<small v-if="entryLink.attributes.medicationPlan">
								Medikationsplan vorhanden
							</small>
						</div>
					</div>

					<p v-if="entryLink.attributes.medicationPlan" class="is-font-extra-large is-font-bold mb-1">
						P{{ entryLink.attributes.medicationPlan.attributes.number }}:
						<span v-if="entryLink.attributes.medicationPlan.attributes.art == 1">Standard</span>
						<span v-if="entryLink.attributes.medicationPlan.attributes.art == 2">Zu besonderen
							Zeiten</span>
						<span v-if="entryLink.attributes.medicationPlan.attributes.art == 3">Selbstmedikation</span>
					</p>

					<div class="mt-0 mb-0">
						<div class="
								pt-0
								authorTagContainer
								is-font-medium
							">
							<small v-if="entryLink.attributes.medicationPrescription">
								<time>
									Rezept ausgestellt am {{
										entryLink.attributes.medicationPrescription.attributes.date.toLocaleDateString("de-DE",
											{
												year: "numeric",
												month: "2-digit",
												day: "2-digit",
											})
									}}</time>
								von
								{{
									prescriptionDoctor?.attributes?.name
								}}
							</small>
						</div>
					</div>

					<p v-if="entryLink.attributes.medicationPrescription" class="is-font-extra-large is-font-bold mb-1">
						R{{ entryLink.attributes.medicationPrescription.attributes.number }}:
						{{ entryLink.attributes.medicationPrescription.attributes.name }}
					</p>

					<div class="mt-0 mb-0">
						<div class="
								pt-0
								authorTagContainer
								is-font-medium
							">
							<small v-if="entryLink.attributes.medicationReport">
								Bericht ausgestellt am
								<time>
									{{
										entryLink.attributes.medicationReport.attributes.date.toLocaleDateString("de-DE",
											{
												year: "numeric",
												month: "2-digit",
												day: "2-digit",
											})
									}}</time>
								von
								{{ reportDoctor?.attributes?.name
								}}<time
									v-if="entryLink.attributes.medicationReport.attributes.treatmentBegin || entryLink.attributes.medicationReport.attributes.treatmentEnd">,
									Behandlungszeitraum
									<span v-if="entryLink.attributes.medicationReport.attributes.treatmentBegin">
										vom {{
											entryLink.attributes.medicationReport.attributes.treatmentBegin.toLocaleDateString("de-DE",
												{
													year: "numeric",
													month: "2-digit",
													day: "2-digit",
												})
										}}
									</span>

									<span v-if="entryLink.attributes.medicationReport.attributes.treatmentEnd">
										bis {{
											entryLink.attributes.medicationReport.attributes.treatmentEnd.toLocaleDateString("de-DE",
												{
													year: "numeric",
													month: "2-digit",
													day: "2-digit",
												})
										}}
									</span>

								</time>
							</small>
						</div>
					</div>
					<p v-if="entryLink.attributes.medicationReport" class="is-font-extra-large is-font-bold mb-1">
						B{{ entryLink.attributes.medicationReport.attributes.number }}:
						{{ diagnose?.attributes?.name }}<span
							v-if="entryLink.attributes.medicationReport.attributes.icd_10_code_array && entryLink.attributes.medicationReport.attributes.icd_10_code_array.length != 0">
							<span :key="option.id"
								v-for="option in entryLink.attributes.medicationReport.attributes.icd_10_code_array">, {{
									option.attributes.icd_10_code
								}} ({{ option.attributes.name
}})</span>

						</span>
					</p>

					<br />
				</div>
				<div style="margin-top: -15px" class="
						is-flex is-justify-content-flex-end
						iconContainer
					" v-if="entryLink.attributes.medication || entryLink.attributes.medicationPlan || entryLink.attributes.medicationPrescription || entryLink.attributes.medicationReport">
					<MedicationIcon v-if="entryLink.attributes.medication" icon="pill" label="Medikation vorhanden" />
					<MedicationIcon v-if="entryLink.attributes.medicationPlan" icon="clipboard-text-clock"
						label="Medikationsplan vorhanden" />
					<MedicationIcon v-if="entryLink.attributes.medicationPrescription" icon="checkbook"
						label="Rezept vorhanden" />
					<MedicationIcon v-if="entryLink.attributes.medicationReport" icon="clipboard-plus-outline"
						label="Bericht vorhanden" />
					<MedicationIcon v-if="entryLink.attributes.hasMedicationLog" icon="clipboard-list-outline"
						label="Medikationslog vorhanden" />
				</div>

			</div>
		</article>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import AuthorImage from "@/components/helper/AuthorImage";
import MedicationIcon from "@/components/icons/medication/MedicationIcon";
// import ColoredTag from "@/components/tags/ColoredTag";
import Parse from "parse/dist/parse.min.js";

export default {
	name: "MedicationEntriesData",
	props: ["entryLink"],
	components: {
		AuthorImage,
		MedicationIcon,
		// ColoredTag
	},
	data() {
		return {

		};
	},
	beforeCreate() { },
	mounted() {
	
	},
	updated() {
		
	},
	watch: {

	},
	computed: {
		...mapGetters({
			doctorArray: "doctors/getData",
			diagnoseArray: "diagnoses/getData",
			icd10CodeArray: "icd_10_codes/getData",
		}),
		diagnose()
		{
			let diagnoseValue = this.entryLink.attributes.medicationReport.attributes.diagnose;
			if (this.entryLink.attributes.medicationReport.attributes.diagnose && this.entryLink.attributes.medicationReport.attributes.diagnose.__type == 'Pointer') {
				for(var i= 0; i<this.diagnoseArray.length; i++)
				{
					if(this.diagnoseArray[i].id == this.entryLink.attributes.medicationReport.attributes.diagnose.objectId)
					{
						diagnoseValue = this.diagnoseArray[i];
					}
				}
				
			} 
			return diagnoseValue;
			
		},
		reportDoctor()
		{
			let doctorValue = this.entryLink.attributes.medicationReport.attributes.doctor;
			if (this.entryLink.attributes.medicationReport.attributes.doctor && this.entryLink.attributes.medicationReport.attributes.doctor.__type == 'Pointer') {
				for(var i= 0; i<this.doctorArray.length; i++)
				{
					if(this.doctorArray[i].id == this.entryLink.attributes.medicationReport.attributes.doctor.objectId)
					{
						doctorValue = this.doctorArray[i];
					}
				}
				
			} 
			return doctorValue;
			
		},
		prescriptionDoctor()
		{
			let doctorValue = this.entryLink.attributes.medicationPrescription.attributes.doctor;
			if (this.entryLink.attributes.medicationPrescription.attributes.doctor && this.entryLink.attributes.medicationPrescription.attributes.doctor.__type == 'Pointer') {
				for(var i= 0; i<this.doctorArray.length; i++)
				{
					if(this.doctorArray[i].id == this.entryLink.attributes.medicationPrescription.attributes.doctor.objectId)
					{
						doctorValue = this.doctorArray[i];
					}
				}
				
			} 
			return doctorValue;
			
		}
	},
	methods: {

	},
}
</script>

<style scoped>

</style>
