<template>
    <b-steps :class="isMobile ? 'pr-2 pl-2' : 'pr-3 pl-3'" :size="getSize">
        <b-step-item label="Bericht" icon="lead-pencil" clickable>
            <div class="step-box scrollable" :class="isMobile ? 'pt-3' : ''">
                <section>
                    <div>
                        <DiaryTitle
                            :diary-title="entryRequest.entryTitle"
                            :set-title="setTitle"
                            :edit-entry="editEntry"
                        />
                        <EntryText
                            :set-text="setText"
                            :text="entryRequest.entryInput"
                            :edit-entry="editEntry"
                        />
                        <hr />
                        <FileInput
                            :drop-files-modified="dropFilesModified"
                            :add-modified-drop-files="addModifiedDropFiles"
                            :set-modified-drop-files="setModifiedDropFiles"
							:allowData="true"
                        />
                        <EditAttachements
                            :focused-entry="focusedEntry"
                            :entry-request="entryRequest"
                            :remove-picture="removePicture"
							:remove-file="removeFile"
                        />
                    </div>
                </section>
            </div>
        </b-step-item>

        <b-step-item label="Zuordnung & Bewertung" icon="account" clickable>
            <div
                class="scrollable"
                :style="this.$store.state.openedKeyBoard ? 'height: 100%' : ''"
            >
<!--				<EntryVisibility :visibility="entryRequest.visibility" :set-visibility="val => entryRequest.visibility= val"/>-->
                <entry-rating
                    class="mt-3"
                    :ratings="filteredRatings"
                ></entry-rating>
                <hr />
                <ClientSearch
                    :clients="selectedClients"
                    :ratings="selectedRating"
                    :set-clients="setSelectedClients"
                    :set-ratings="setSelectedRatings"
                    :filteredRatings="filteredRatings"
                />
                <hr />
                <ClientAssessment
                    :clients="selectedClients"
                    :ratings="selectedRating"
                    :set-clients="setSelectedClients"
                    :set-ratings="setSelectedRatings"
                />
                <div style="text-align: center">
                    <div
                        class="tag is-danger is-light padded dangerTag"
                        v-if="$v.$invalid"
                    >
                        Unvollständiger Bericht
                    </div>
                    <hr />
                    <div style="display: flex; justify-content: center">
                        <b-button
                            class="mt-3 button"
                            type="is-primary"
                            v-on:click="saveEntry"
                            v-bind:loading="isLoading"
                            v-bind:disabled="$v.$invalid"
                            :size="isMobile ? 'is-small' : ''"
                            >Speichern</b-button
                        >
                    </div>
                </div>
            </div>
        </b-step-item>
    </b-steps>
</template>

<script>
/**
 * Module allowing the editing of an entry.
 * @displayName EntryEdit
 */

import Parse from "parse/dist/parse.min.js";
import EntryRating from "@/components/helper/EntryRating";
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import DiaryTitle from "@/components/helper/DiaryTitle";
import EntryText from "@/components/helper/EntryText";
import FileInput from "@/components/helper/FileInput";
import ClientSearch from "@/components/helper/ClientSearch";
import ClientAssessment from "@/components/helper/ClientAssessment";
import EditAttachements from "@/components/helper/EditAttachements";
import { saveEditedEntry } from "@/lib/saveNewEntry";

export default {
    name: "DiaryEditPanel",
    components: {
        EntryText,
        EditAttachements,
        ClientAssessment,
        ClientSearch,
        FileInput,
        DiaryTitle,
        EntryRating,
    },
    data() {
        return {
            isLoading: false,

            dropFiles: [],
            dropFilesModified: [],

            name: "",
            selectedRating: [],
            selectedClients: [],
            selectedClientsInitial: [],

            filteredRatings: [],

            entryRequest: {
                entryInput: undefined,
                entryTitle: undefined,
                pictures: [],
				visibility: "Alle",
				otherFiles: [],
            },

            isEntryEdit: false,
        };
    },
    validations: {
        selectedRating: {
            $each: {
                required,
            },
        },
        entryRequest: {
            entryInput: { required },
            entryTitle: { required },
        },
    },
    async created() {
        this.isEntryEdit = false;
        const EntryLink = Parse.Object.extend("EntryLink");
        const query = new Parse.Query(EntryLink);
        var entryPointer = {
            __type: "Pointer",
            className: "Entry",
            objectId: this.focusedEntry.id,
        };
        query.equalTo("entry", entryPointer);
        this.filteredRatings = await query.find();
    },
    computed: {
        ...mapGetters({
            focusedEntry: "entrylinks/getFocusedEntry",
            filteredClients: "clients/getOrderedClients",
            focusedClient: "clients/getFocusedClient",
        }),
        getSize() {
            return this.$store.getters.isMobile ? "is-small" : "";
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
    },
    mounted() {
        this.entryRequest.pictures = [];
		const pictures = this.focusedEntry.attributes.pictures ? this.focusedEntry.attributes.pictures : []
		const otherFiles = this.focusedEntry.attributes.otherFiles ? this.focusedEntry.attributes.otherFiles : []
        for (const picture of pictures) {
            this.entryRequest.pictures.push(picture);
        }
		for (const file of otherFiles) {
            this.entryRequest.otherFiles.push(file);
        }
        this.entryRequest.entryInput = this.focusedEntry.attributes.text;
        this.entryRequest.entryTitle = this.focusedEntry.attributes.title;
		this.entryRequest.isPrivate = this.focusedEntry.attributes.isPrivate;
        this.$v.entryRequest.entryTitle.$touch;
        this.$v.entryRequest.entryInput.$touch;
    },
    methods: {
        setSelectedRatings(value) {
            this.selectedRating = value;
        },
        setSelectedClients(value) {
            this.selectedClients = value;
        },
        setAttributes(value) {
            this.entryRequest.attributes = value;
        },
        addAttributes(value) {
            this.entryRequest.attributes.push(value);
        },
        setEntryType(value) {
            this.entryRequest.entry_type = value;
        },
        addModifiedDropFiles(value) {
            this.dropFilesModified.push(value);
        },
        setModifiedDropFiles(value) {
            this.dropFilesModified = value;
        },
        setText(value) {
            this.entryRequest.entryInput = value;
        },
        setTitle(value) {
            this.entryRequest.entryTitle = value;
        },
        setIsLoading(value) {
            this.isLoading = value;
        },
        async routeToDiary() {
            await this.$router.push({ name: "Diary" });
        },
        saveEntry() {
            const data = {
                entryRequest: this.entryRequest,
                dropFilesModified: this.dropFilesModified,
                selectedClients: this.selectedClients,
                selectedRating: this.selectedRating,
                focusedEntry: this.focusedEntry,
                isEntryEdit: this.isEntryEdit,
            };
            saveEditedEntry(this.setIsLoading, data, this.routeToDiary);
        },
        editEntry() {
            this.isEntryEdit = true;
        },
        removePicture(index) {
            this.isEntryEdit = true;
            this.entryRequest.pictures.splice(index, 1);
        },
		removeFile(index) {
            this.isEntryEdit = true;
            this.entryRequest.otherFiles.splice(index, 1);
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .pagination-previous[disabled] {
    display: none;
}
::v-deep .pagination-next[disabled] {
    display: none;
}

.step-box {
    height: 80%;
}

.scrollable {
    overflow: auto;
    height: 100%;
}

.scrollable-box {
    overflow: auto;
    height: 40%;
}

.scrollable::-webkit-scrollbar {
    display: none;
}

.scrollable-box::-webkit-scrollbar {
    display: none;
}

button {
    margin: 10px;
}

.padded {
    margin: 12px 0;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollable {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
hr {
    margin: 0.5rem;
}
@media (max-width: 769px) {
    ::v-deep .step-link {
        padding-bottom: 5px !important;
    }
    ::v-deep .icon {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .columns:not(:last-child) {
        margin-bottom: 0;
    }
    .padded {
        padding: 0.25rem;
        margin: 0;
    }
    .attribute_titel {
        margin: 0.5rem 0 !important;
    }

    hr {
        margin: 0.5rem;
    }
    .scrollable {
        height: 68%;
        width: 95vw;
    }

    .columns {
        display: flex;
        align-items: center;
        flex-direction: row;
        padding: 0px;
        margin: 0px;
    }

    .column {
        padding: 0;
    }
}

@media (max-width: 600px) {
    .column {
        padding: 0;
        margin: 0;
    }
}

@media (max-width: 450px) {
    .button.is-small:not(.is-rounded) {
        margin: 0;
    }
}
::v-deep .step-content {
    padding: 0px !important;
    height: calc(100% - 50px) !important;
}

::v-deep .step-item {
    padding: 0px !important;
    height: 100% !important;
}
::v-deep nav.steps {
    display: none;
}
@media (min-width: 770px) {
    ::v-deep .step-content {
        height: calc(100% - 60px) !important;
    }
    ::v-deep .step-item {
        height: 99% !important;
    }
}
</style>

<style>
@media (min-width: 770px) {
    nav {
        height: 72px;
    }

    .diaryContainer .step-navigation,
    #mobileDiaryEntries .step-navigation {
        border-top: 2px solid whitesmoke;
        padding-top: 15px;
    }
}

.diaryContainer .step-navigation,
#mobileDiaryEntries .step-navigation {
    text-align: center;
}

.diaryContainer .step-navigation .icon,
#mobileDiaryEntries .step-navigation .icon {
    height: 1rem;
    width: 1rem;
}

.diaryContainer .pagination-previous,
.diaryContainer .pagination-next,
#mobileDiaryEntries .pagination-previous,
#mobileDiaryEntries .pagination-next {
    padding: 5px;
}

.diaryContainer .pagination-previous,
.diaryContainer .pagination-next,
.diaryContainer .pagination-link,
#mobileDiaryEntries .pagination-previous,
#mobileDiaryEntries .pagination-next,
#mobileDiaryEntries .pagination-link {
    min-width: 2rem;
    height: 2rem;
}

.diaryContainer .b-steps .steps + .step-content,
#mobileDiaryEntries .b-steps .steps + .step-content {
    padding: 0px;
    height: calc(100% - 250px);
}

.diaryContainer .b-steps .steps + .step-content .step-item,
#mobileDiaryEntries .b-steps .steps + .step-content .step-item {
    height: 100%;
}

.diaryContainer .b-steps .steps + .step-content .scrollable,
#mobileDiaryEntries .b-steps .steps + .step-content .scrollable {
    height: 100%;
}

.diaryContainer .step-link,
#mobileDiaryEntries .step-link {
    border-bottom: 2px solid whitesmoke;
    padding-bottom: 15px;
}

#mobileDiaryEntries h2,
#mobileDiaryEntries h3,
#mobileDiaryEntries nav,
#mobileDiaryEntries .b-steps,
#mobileDiaryEntries section,
#mobileDiaryEntries .step-box,
#mobileDiaryEntries .scrollable,
.diaryContainer h2,
.diaryContainer h3,
.diaryContainer nav,
.diaryContainer .b-steps,
.diaryContainer section,
.diaryContainer .step-box,
.diaryContainer .scrollable {
    width: 100%;
}
</style>
