import Parse from 'parse/dist/parse.min.js';

const state = {
	data: [],
	focusedClientId: undefined,
	focusedClientUuId: undefined,
	focusedClientAllIds: undefined,
	deleteClientPicture: false,
};

const getters = {
	areClientsLoaded(state){
		if(state.data.length === 0) return undefined
		return true
	},
	getFocusedClientId(state) {
		if (state.focusedClientId) {
			return state.focusedClientId;
		}
		return undefined;
	},
	getFocusedClient(state) {
		if (state.focusedClientId) {
			return state.data.filter((client) => {
				return client.id === state.focusedClientId
			})[0];
		}
		return undefined
	},
	getFocusedClientName(state, getters) {
		if (getters.getFocusedClient) {
			return (
				getters.getFocusedClient.attributes.first_name +
				" " +
				getters.getFocusedClient.attributes.last_name
			)
		}
		return undefined;
	},
	getFocusedClientPicture(state, getters) {
		if (state.deleteClientPicture) {
			return undefined
		}
		if (getters.getFocusedClient && getters.getFocusedClient.attributes
			.profile_picture) {
			return getters.getFocusedClient.attributes
				.profile_picture._url;
		}
		return undefined;
	},
	getOrderedClients(state, getters, rootState) {
		const results = []
		const group_results = []
		const other_results = []
		const contact_results = []

		if (state.data.length > 0 && rootState.groups.data.length > 0 && rootState.usermetas.data.length > 0) {

			state.data.forEach((item) => {
				results.push(item)
			})
			results.sort((a, b) => a.attributes.group.attributes.group_name.localeCompare(b.attributes.group.attributes.group_name))

			if (rootState.currentUser.attributes.dependency.attributes.groups) {
				let groupIds = rootState.currentUser.attributes.dependency.attributes.groups.map((group) => group.id)
				for(const client of results){
					const contactClientIds = rootState.currentUser.attributes.dependency.attributes.clients.map(c => c.id)
					if (contactClientIds.includes(client.id)) {
						contact_results.push(client)
						continue;
					}
					if (groupIds.includes(client.attributes.group.id)) {
						group_results.push(client)
					} else {
						other_results.push(client)
					}
				}

				const timeOrderedContactClients = contact_results.sort((a, b) =>{
					const aLastEntryDate = a.attributes.lastEntryDate ? a.attributes.lastEntryDate : new Date(1970, 1,1)
					const bLastEntryDate = b.attributes.lastEntryDate ? b.attributes.lastEntryDate : new Date(1970, 1,1)
					return bLastEntryDate.getTime() - aLastEntryDate.getTime()
				})
				const timeOrderedGroupClients = group_results.sort((a, b) =>{
					const aLastEntryDate = a.attributes.lastEntryDate ? a.attributes.lastEntryDate : new Date(1970, 1,1)
					const bLastEntryDate = b.attributes.lastEntryDate ? b.attributes.lastEntryDate : new Date(1970, 1,1)
					return bLastEntryDate.getTime() - aLastEntryDate.getTime()
				})
				const groupAndTimeOrderedOtherResults = other_results.sort((a, b) => {
					const groupComparison = a.attributes.group.attributes.group_name.localeCompare(b.attributes.group.attributes.group_name)
					if(groupComparison === 0){
						const aLastEntryDate = a.attributes.lastEntryDate ? a.attributes.lastEntryDate : new Date(1970, 1,1)
						const bLastEntryDate = b.attributes.lastEntryDate ? b.attributes.lastEntryDate : new Date(1970, 1,1)
						return bLastEntryDate.getTime() - aLastEntryDate.getTime()
					}
					return groupComparison
				})
				const groupedGroupResults = timeOrderedGroupClients.sort((a, b) => a.attributes.group.attributes.group_name.localeCompare(b.attributes.group.attributes.group_name))
				return [...timeOrderedContactClients,...groupedGroupResults, ...groupAndTimeOrderedOtherResults]
			}
			return results
		}
		return undefined
	},
	getFilteredClients(state, getters, rootState) {
		const orderedClients = getters.getOrderedClients
		if (state.data.length > 0 && rootState.groups.data.length > 0 && rootState.usermetas.data.length > 0) {
			if (rootState.searchQuery) {
				return orderedClients.filter((item) => {
					const query = rootState.searchQuery.toLowerCase()
					const isQueryInGroupName = item.attributes.group.attributes.group_name.toLowerCase().includes(query)
					const isQueryInName = `${item.attributes.first_name} ${item.attributes.last_name}`.toLowerCase().includes(query)
					return isQueryInName || isQueryInGroupName
				});
			}
			else {
				return orderedClients;
			}
		}
		return undefined;
	},
	getOnlyContactClients(state, getters, rootState) {
		if (state.data.length > 0 && rootState.groups.data.length > 0 && rootState.usermetas.data.length > 0 && rootState.currentUser.attributes.dependency.attributes.clients) {
			let clientIds = rootState.currentUser.attributes.dependency.attributes.clients.map((group) => group.id)
			const filteredClients = getters.getFilteredClients
			return filteredClients.filter((client) => {
				return clientIds.includes(client.id)
			})
		}
	}
};

const mutations = {
	SET(state, objects) {
		state.data = objects
	},
	INSERT(state, object) {
		state.data.push(object)
	},
	DELETE(state, object) {
		state.data = state.data.filter((elem) => {
			return elem.id != object.id
		})
	},
	REPLACE(state, object) {
		const oldObject = state.data.filter(item => item.id == object.id)
		const index = state.data.indexOf(oldObject[0]);
		if (index > -1) {
			state.data.splice(index, 1, object);
		}
	},
	SET_FOCUSED_CLIENT_ID(state, data) {

		let clientQuery = new Parse.Query('Client');
		clientQuery.equalTo("objectId", data);
		clientQuery.notEqualTo("deleted", true);
		clientQuery.find().then(response => {
			if(response.length != 0)
			{
				if(!response[0].attributes.inactive)
				{
					state.focusedClientUuId = response[0].attributes.uuid;

					let clientQuery2 = new Parse.Query('Client');
					clientQuery2.equalTo("uuid", state.focusedClientUuId);
					clientQuery2.notEqualTo("deleted", true);
					clientQuery2.find().then(response => state.focusedClientAllIds = response);

					state.focusedClientId = data;
				}else
				{
					state.focusedClientUuId = undefined;
					state.focusedClientId = undefined;
					state.focusedClientAllIds = undefined;
				}
				
			}else
			{
				state.focusedClientUuId = undefined;
				state.focusedClientId = undefined;
				state.focusedClientAllIds = undefined;
			}
			
		});


		//state.focusedClientId = data
	},
	SET_DELETE_CLIENT_PICTURE(state, data) {
		this.state.deleteClientPicture = data
	},
};

const actions = {
	set({ commit }, objects) {
		commit('SET', objects)
	},
	insert({ commit }, object) {
		commit('INSERT', object)
	},
	delete({ commit }, object) {
		commit('DELETE', object)
	},
	replace({ commit }, object) {
		commit('REPLACE', object)
	},
	setFocusedClientId({ commit }, query) {
		commit('SET_FOCUSED_CLIENT_ID', query)
	},
	setDeleteClientPicture({ commit }, query) {
		commit("SET_DELETE_CLIENT_PICTURE", query)
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
