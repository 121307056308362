<template>
	<div style="
            height: 100%;
            width: 100%;
            margin: 0 auto;
            overflow: hidden;
            position: relative;
        ">
		<TopBar v-if="user" style="height: 64px; width: 100%"></TopBar>
		<router-view style="height: calc(100% - 52px - 64px); width: 100%" />
		<BottomBar style="height: 52px" v-if="user" />
		<b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
	</div>
</template>

<script>
/**
 * Entrypoint to the KJHZ application.
 * @displayName App
 */
import TopBar from "@/components/bars/topBar/TopBar";
import BottomBar from "@/components/bars/bottomBar/BottomBar";
import { mapGetters } from "vuex";

export default {
	name: "App",
	computed: {
		...mapGetters({
			user: "users/getUserName",
			isLoading: "isLoading"
		}),
	},
	methods: {
		setWindowWidth() {
			this.$store.dispatch("setWindowWidth", window.innerWidth);
		},
	},
	components: {
		BottomBar,
		TopBar,
	},
	mounted() {
		// log <-- error <-- warn <-- info <-- debug
		// wenig inklusive - sehr inklusiv
		this.$log.apply({ level: 'error' }) 
		window.addEventListener("resize", this.setWindowWidth);
	},
	unmounted() {
		window.removeEventListener("resize", this.setWindowWidth);
	},
};
</script>

<style></style>
