const state = {
	dismissUpdate : false,
	apiVersion : undefined
};

const getters = {
	getdismissUpdate(state){
		return state.dismissUpdate
	},
	getApiVersion(state){
		return state.apiVersion
	}
};

const mutations = {
	SETDISMISSUPDATE(state, objects) {
		state.dismissUpdate = objects
	},
	SETAPIVERSION(state, object){
		state.apiVersion = object
	}
};

const actions = {
	setDismissUpdate({ commit }, objects) {
		commit('SETDISMISSUPDATE', objects)
	},
	setApiVersion({commit}, object){
		commit("SETAPIVERSION", object)
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
