<template>
	<b-field expanded :message="enableValidation ? (($v.selectedValue.$invalid) ? validationMessage : '') : ''"
		:type="enableValidation ? (($v.selectedValue.$invalid) ? 'is-danger' : 'is-success') : ''">
		<template #label>
			<div class="align-content-center" style="display: inline-flex">
				<p> {{ labelText }}</p>
			</div>
		</template>
		<b-autocomplete class="autocomplete_break" expanded open-on-focus clearable v-model="inputString" :data="orderedFilteredDataArray" icon="magnify"
			:custom-formatter="option => (option?.attributes.name + 
			(option?.attributes?.specialization?.attributes? (' (' + option?.attributes?.specialization?.attributes?.specialization + ')') : ' (-)'))"
			@select="option => set(option)">

			<template slot-scope="props">
				<div class="media">
					<div class="media-content">
						{{ props?.option?.attributes.name + (props?.option?.attributes?.specialization? (' (' +
		props?.option?.attributes?.specialization?.attributes.specialization + ')') : ' (-)') }}
					</div>
				</div>
			</template>

			<template #empty>Keine Ergebnisse gefunden.</template>
		</b-autocomplete>
		<b-tooltip position="is-left" label="Neuen Arzt hinzufügen">
			<b-button class="buttonNew" type="is-primary" @click="openModal" icon-left="plus" />
		</b-tooltip>
	</b-field>
</template>

<script>

import { required, requiredIf } from "vuelidate/lib/validators";

export default {
	name: "DoctorSearchableSelect",
	props: [
		"editObject",
		"select",
		"dataOptions",
		"enableValidation",
		"validationMessage",
		"labelText",
		"enableOrder",
	],
	data() {
		return {
			inputString: '',
			selectedValue: undefined
		}
	},
	validations: {
		selectedValue: {
			required: requiredIf(function (enableValidation) {
				return enableValidation
			}),
		},
	},
	mounted() { },
	computed: {
		orderedFilteredDataArray() {
			if (this.enableOrder) {
				let sortedArray = this.filteredDataArray
				return sortedArray.sort((a, b) =>
					(a.attributes.name.toLowerCase() > b.attributes.name.toLowerCase()) ? 1
						: ((b.attributes.name.toLowerCase() > a.attributes.name.toLowerCase()) ? -1 : 0));
			}
			else {
				return this.filteredDataArray
			}
		},
		filteredDataArray() {
			let returnVal = this.dataOptions.filter((element) => {
				let searchString = element.attributes.name.toLowerCase() + (element.attributes.specialization ? " (" +
					element.attributes.specialization?.attributes?.specialization.toLowerCase() + ")" : "")
				return searchString.indexOf((this.inputString.toLowerCase())) >= 0
			})
			return returnVal
		}
	},
	methods: {
		set(option) {
			if (option) {
				this.select(option.toPointer())
			}
			if (option == null){
				this.selectedValue = undefined
			}
		},
		openModal()
		{
			this.$emit("emit-new-doctor-modal-is-open", true)
		}
	},
	watch: {
		editObject(newValue) {
			if (newValue) {
				this.selectedValue = this.orderedFilteredDataArray.find((element) => this.editObject.objectId == element.id)
				if(!this.selectedValue){
					this.selectedValue = newValue
				}
				this.inputString = this.selectedValue? (this.selectedValue.attributes.name + (this.selectedValue.attributes.specialization ? " (" +
					this.selectedValue.attributes.specialization?.attributes?.specialization + ")" : "")) : ""
			}
		},
	},
};
</script>

<style lang="scss">

.autocomplete_break.autocomplete .dropdown-item, 
.autocomplete_break.autocomplete .dropdown .dropdown-menu .has-link a
{
	white-space: wrap;
}

.autocomplete_break.autocomplete .dropdown-item .media-content span
{
	display:inline;
}
</style>