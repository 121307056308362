<template>
	<div class="is-flex is-flex-direction-column" style="width:100%;">

		<MedicationPrescriptionInputSelect v-if="!prescriptionObjectNumber || prescriptionObjectNumber == -1"
			@emit-existing-prescription-object-selected="val => prescriptionObjectExists = val" />

		<template v-if="mask == 'new' || mask == 'edit'">
			<b-field expanded :message="(!$v.prescriptionName.$model) ? 'Bitte geben Sie einen Bezeichner ein!' : ''"
				:type="(!$v.prescriptionName.$model) ? 'is-danger' : 'is-success'">
				<template #label>
					<div class="align-content-center" style="display: inline-flex">
						<p>Bezeichner</p>
					</div>
				</template>
				<b-input expanded type="text" v-model="prescriptionName" />
			</b-field>
		</template>

		<template v-if="mask == 'view'">
			<b-field expanded>
				<template #label>
					<div class="align-content-center" style="display: inline-flex">
						<p>Bezeichner</p>
					</div>
				</template>
				{{ prescriptionName }}
			</b-field>
		</template>

		<template v-if="mask == 'new' || mask == 'edit'">
			<DoctorSearchableSelect :data-options="doctorArray" :editObject="prescriptionDoctor"
				:enable-validation="true" validationMessage="Bitte wählen Sie einen Arzt aus !" label-text="Arzt"
				:enable-order="true" :select="(object) => (prescriptionDoctor = object)"
				@emit-new-doctor-modal-is-open="val => isModalActive = val" />
		</template>

		<template v-if="mask == 'view' && prescriptionDoctor">
			<b-field expanded grouped class="bfield_w_margin_label">
				<template #label>
					<div class="align-content-center" style="display: inline-flex">
						<p> Arzt </p>
					</div>
				</template>
				<span>{{ prescriptionDoctor.attributes?.name }}</span>
				<span v-if="prescriptionDoctor.attributes?.specialization">, {{
			prescriptionDoctor.attributes?.specialization.attributes.specialization }}</span>
				<span v-if="prescriptionDoctor.attributes?.street">, {{ prescriptionDoctor.attributes?.street }}</span>
				<span v-if="prescriptionDoctor.attributes?.street">, {{ prescriptionDoctor.attributes?.plz }}</span>
				<span v-if="prescriptionDoctor.attributes?.street">

					<template v-if="!prescriptionDoctor.attributes?.street">,</template>

					<template v-else>&nbsp;</template>{{ prescriptionDoctor.attributes?.location }}</span>
			</b-field>
		</template>

		<template v-if="mask == 'new' || mask == 'edit'">
			<b-field expanded :message="(!$v.prescriptionDate.$model) ? 'Bitte geben Sie ein Datum an!' : ''"
				:type="(!$v.prescriptionDate.$model) ? 'is-danger' : 'is-success'">
				<template #label>
					<div class="align-content-center" style="display: inline-flex">
						<p> Datum </p>
					</div>
				</template>
				<b-datepicker expanded :date-formatter="dateFormatter" trap-focus editable locale="de-DE"
					v-model="prescriptionDate">
				</b-datepicker>
			</b-field>
		</template>

		<template v-if="mask == 'view' && prescriptionDate">
			<b-field expanded>
				<template #label>
					<div class="align-content-center" style="display: inline-flex">
						<p> Datum </p>
					</div>
				</template>
				{{ prescriptionDate.toLocaleDateString("de-DE",
			{
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
			}) }}
			</b-field>
		</template>

		<template v-if="mask == 'new' || mask == 'edit'">
			<b-field expanded>
				<template #label>
					<div class="align-content-center" style="display: inline-flex">
						<p>Anmerkungen</p>
					</div>
				</template>
				<b-input expanded type="textarea" v-model="prescriptionHint" />
			</b-field>
		</template>

		<template v-if="mask == 'view' && prescriptionHint">
			<b-field expanded>
				<template #label>
					<div class="align-content-center" style="display: inline-flex">
						<p>Anmerkungen</p>
					</div>
				</template>
				{{ prescriptionHint }}
			</b-field>
		</template>

		<template v-if="mask == 'new' || mask == 'edit'">
			<b-field expanded :message="($v.imageCounter.$invalid) ? 'Bitte laden Sie einen Anhang hoch!' : ''"
				:type="($v.imageCounter.$invalid) ? 'is-danger' : 'is-success'">
				<template #label>
					<div class="align-content-center" style="display: inline-flex">
						<p>Anhang </p>
					</div>
				</template>
				<FileInput style="width:100%;" expanded :drop-files-modified="dropFilesModified"
					:add-modified-drop-files="addModifiedDropFiles" :set-modified-drop-files="setModifiedDropFiles"
					:flushDropFiles="flushDropFiles" :allowData="true" />
			</b-field>
			<b-field>
				<MedicationEditAttachements style="width:100%;" expanded
					:attachment="existingFiles?.length != 0 || existingOtherFiles?.length != 0"
					:pictures="existingFiles" :remove-picture="removePicture"
					:otherFiles="this.prescriptionObject?.attributes?.otherFiles" :remove-files="removeFile" />
			</b-field>
		</template>

		<template v-if="mask == 'view'">
			<b-field class="bfield_w_margin_label">
				<template #label>
					<div class="align-content-center" style="display: inline-flex">
						<p>Anhang </p>
					</div>
				</template>
				<div class="content mb-2 ml-1">
					<div v-if="images" class="columns imagesContainer mb-1" style="flex-wrap: wrap">
						<div v-for="(image, i) in images" class="column is-one-third imageContainer" :key="i">
							<img class="image" :src="image" @click="index = i" />
						</div>
						<vue-gallery-slideshow :images="images" :index="index"
							@close="index = null"></vue-gallery-slideshow>
					</div>
				</div>
			</b-field>
			<div v-if="otherFiles.length">
				<hr style="height: 1px; background-color: black" />
				<div v-for="(file, i) in otherFiles" :key="i">
					<div class="fileItem">
						<b-icon icon="file-pdf-box" size="is-medium" />
						<a tag="a" :href="file.attributes.file._url" target="_blank"
							style="padding-top: 4px; margin-left: 6px">
							{{ file.attributes.fileTitle }}
						</a>
					</div>
				</div>
			</div>
		</template>

		<template v-if="mask == 'new' || mask == 'edit'">
			<div style="display: flex; justify-content: center">
				<b-button class="mt-3 button mb-5" type="is-primary" @click="savePrescription"
					v-bind:loading="isLoading" v-bind:disabled="$v.prescriptionName.$invalid || $v.prescriptionDoctor.$invalid
			|| $v.prescriptionDate.$invalid || $v.imageCounter.$invalid" :size="isMobile ? 'is-small' : ''">Speichern
				</b-button>
			</div>
			<NewDoctorModal v-if="isModalActive" @emit-new-doctor="val => prescriptionDoctor = val"
				v-model="isModalActive">
			</NewDoctorModal>
		</template>

	</div>
</template>

<script>
/**
 * Sub-Module allowing the creation of a new prescription.
 * @displayName PrescriptionInput
 */
//TODO Das InputFeld für Bilder für PDFs erweitern
import { mapGetters } from "vuex";
import { required, minLength } from "vuelidate/lib/validators";
import NewDoctorModal from "@/components/modals/NewDoctorModal.vue";
import FileInput from "@/components/helper/FileInput";
import Parse from "parse/dist/parse.min.js";
import { v4 as uuid } from "uuid";
import VueGallerySlideshow from "vue-gallery-slideshow";
import MedicationEditAttachements from "@/components/helper/medicationHelper/MedicationEditAttachements.vue";
import MedicationPrescriptionInputSelect from "@/components/helper/medicationHelper/MedicationPrescriptionInputSelect.vue";
import { negativeFeedBack, positiveFeedBack } from "@/lib/Toast";
import DoctorSearchableSelect from "@/components/helper/SearchableSelects/DoctorSearchableSelect.vue";

export default {
	name: "PrescriptionInput",
	components: {
		NewDoctorModal,
		FileInput,
		VueGallerySlideshow,
		MedicationEditAttachements,
		MedicationPrescriptionInputSelect,
		DoctorSearchableSelect,
	},
	props: ["proppedPrescriptionObject", "proppedPrescriptionObjectNumber", "proppedPrescriptionIsLoading", "mask", "proppedHasLog"],
	emits: ['emit-data-object'],
	validations: {
		prescriptionName: {
			required,
			minLength: minLength(1)
		},
		prescriptionDoctor: {
			required,
		},
		prescriptionDate: {
			required,
		},
		// dropFilesModified: {
		// 	required,
		// 	minLength: minLength(1),
		// },
		imageCounter: {
			required,
			validator: function (value) {
				return value > 0
			}
		}
	},
	data() {
		return {
			isModalActive: false,
			prescriptionNumber: -1,
			prescriptionName: "",
			prescriptionDoctor: undefined,
			prescriptionDate: undefined,
			prescriptionHint: "",
			dropFilesModified: [],
			existingFiles: [],
			existingOtherFiles: [],
			flushDropFiles: false,
			index: null,

			imageCounter: 0,
			prescriptionObjectExists: undefined,

		};
	},
	mounted() {
		if (this.prescriptionObject) {
			this.prescriptionName = this.prescriptionObject.attributes.name;

			this.prescriptionDoctor = this.calculatePointerData(this.prescriptionObject.attributes.doctor, this.doctorArray);

			// this.prescriptionDoctor = this.prescriptionObject.attributes.doctor;
			this.prescriptionDate = this.prescriptionObject.attributes.date;
			this.prescriptionHint = this.prescriptionObject.attributes.hint;
			this.prescriptionNumber = this.prescriptionObject.number;

			if (this.prescriptionObject.attributes.files) {
				for (var i = 0; i < this.prescriptionObject.attributes.files.length; i++) {
					if (this.prescriptionObject.attributes.files[i]._url) {
						this.existingFiles.push(this.prescriptionObject.attributes.files[i]);
					} else {
						this.dropFilesModified.push(this.prescriptionObject.attributes.files[i]);
					}
					this.imageCounter++;
				}
			}

			if (this.prescriptionObject.attributes.otherFiles) {
				for (var k = 0; k < this.prescriptionObject.attributes.otherFiles.length; k++) {
					this.existingOtherFiles.push(this.prescriptionObject.attributes.otherFiles[k]);
					this.imageCounter++;
				}
			}
		}
	},
	computed: {
		getSize() {
			return this.$store.getters.isMobile ? "is-small" : "";
		},
		isMobile() {
			return this.$store.getters.isMobile;
		},
		...mapGetters({
			doctorArray: "doctors/getData",
			focusedClient: "clients/getFocusedClient",
			prescriptionArray: "prescriptions/getData",
		}),
		isLoading: {
			get() {
				return this.proppedPrescriptionIsLoading
			},
			set(val) {
				this.$emit("emit-save-prescription-input", val)
			}
		},
		prescriptionObject: {
			get() {
				return this.proppedPrescriptionObject
			},
			set(val) {
				this.$emit("emit-prescription-object", val)
			}
		},
		prescriptionObjectNumber() {
			return this.proppedPrescriptionObjectNumber
		},
		images() {
			if (this.existingFiles) {
				return this.existingFiles.map(
					(pic) => pic._url
				);
			}
			return undefined;
		},
		otherFiles() {
			if (this.prescriptionObject.attributes.otherFiles) {
				return this.prescriptionObject.attributes.otherFiles
			}
			return undefined;
		}
	},
	methods: {
		addModifiedDropFiles(value) {
			this.dropFilesModified.push(value)
		},
		setModifiedDropFiles(value) {
			this.dropFilesModified = value
		},
		dateFormatter(dt) {
			return dt.toLocaleDateString("de-DE", {
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
			});
		},
		async savePrescription() {
			this.isLoading = true;
			await positiveFeedBack("Rezept gespeichert!")
		},
		removePicture(index) {
			this.existingFiles.splice(index, 1);
		},
		removeFile(index) {
			this.existingOtherFiles.splice(index, 1);
		},
		calculatePointerData(value, array) {
			//Edit-Maske braucht einen Pointer für das Drop-Down
			if (this.mask == "edit" || this.mask == "new") {
				if (value && value.__type != 'Pointer') {
					return value.toPointer();
				} else {
					return value;
				}
			} else if (this.mask == "view") {
				//View-Maske benötogt die reinen Daten und keinen Pointer
				if (value && value.__type == 'Pointer') {
					for (var l = 0; l < array.length; l++) {
						if (array[l].id == value.objectId) {
							return array[l];
						}
					}
				} else {
					return value;
				}
			}

		}
	},
	watch: {
		prescriptionName(newVal) {
			this.prescriptionObject.set("name", newVal)
		},
		prescriptionNumber(newVal) {
			if (newVal) {
				this.prescriptionObject.set("number", newVal)
			}
		},
		prescriptionDoctor(newVal) {
			this.prescriptionObject.set("doctor", newVal)
		},
		prescriptionDate(newVal) {
			this.prescriptionObject.set("date", newVal)
		},
		prescriptionHint(newVal) {
			this.prescriptionObject.set("hint", newVal)
		},
		dropFilesModified(newVal) {
			this.prescriptionObject.set("files", newVal.concat(this.existingFiles))
			if (this.prescriptionObject?.attributes?.files?.length) {
				this.imageCounter = this.prescriptionObject?.attributes?.files?.length
			}
			if (this.prescriptionObject?.attributes?.otherFiles?.length) {
				this.imageCounter = this.prescriptionObject?.attributes?.otherFiles?.length
			}
			if (this.prescriptionObject?.attributes?.otherFiles?.length && this.prescriptionObject?.attributes?.files?.length) {
				this.imageCounter = this.prescriptionObject?.attributes?.otherFiles?.length + this.prescriptionObject?.attributes?.files?.length
			}
		},
		existingFiles(newVal) {
			this.prescriptionObject.set("files", newVal.concat(this.dropFilesModified))
			if (this.prescriptionObject?.attributes?.files?.length) {
				this.imageCounter = this.prescriptionObject?.attributes?.files?.length
			}
			if (this.prescriptionObject?.attributes?.otherFiles?.length) {
				this.imageCounter = this.prescriptionObject?.attributes?.otherFiles?.length
			}
			if (this.prescriptionObject?.attributes?.otherFiles?.length && this.prescriptionObject?.attributes?.files?.length) {
				this.imageCounter = this.prescriptionObject?.attributes?.otherFiles?.length + this.prescriptionObject?.attributes?.files?.length
			}
		},
		existingOtherFiles(newVal) {
			this.prescriptionObject.set("otherFiles", newVal.concat(this.dropFilesModified))
			if (this.prescriptionObject?.attributes?.files?.length) {
				this.imageCounter = this.prescriptionObject?.attributes?.files?.length
			}
			if (this.prescriptionObject?.attributes?.otherFiles?.length) {
				this.imageCounter = this.prescriptionObject?.attributes?.otherFiles?.length
			}
			if (this.prescriptionObject?.attributes?.otherFiles?.length && this.prescriptionObject?.attributes?.files?.length) {
				this.imageCounter = this.prescriptionObject?.attributes?.otherFiles?.length + this.prescriptionObject?.attributes?.files?.length
			}
		},
		prescriptionObjectExists(newVal) {
			if (newVal) {
				this.prescriptionObject = newVal;
				this.prescriptionName = newVal.attributes.name;

				if (newVal.attributes.doctor.__type != 'Pointer') {
					this.prescriptionDoctor = newVal.attributes.doctor.toPointer();
				} else {
					this.prescriptionDoctor = newVal.attributes.doctor;
				}


				// this.prescriptionDoctor = this.prescriptionObject.attributes.doctor;
				this.prescriptionDate = newVal.attributes.date;
				this.prescriptionHint = newVal.attributes.hint;

				this.prescriptionNumber = newVal.attributes.number;

				if (newVal.attributes.files) {
					for (var i = 0; i < newVal.attributes.files.length; i++) {
						if (newVal.attributes.files[i]._url) {
							this.existingFiles.push(newVal.attributes.files[i]);
						} else {
							this.dropFilesModified.push(newVal.attributes.files[i]);
						}
						this.imageCounter++;
					}
				}

				if (newVal.attributes.otherFiles) {
					for (var k = 0; k < newVal.attributes.otherFiles.length; k++) {
						this.existingOtherFiles.push(newVal.attributes.otherFiles[k]);
						this.imageCounter++;
					}
				}
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.buttonRemove,
.buttonNew {
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
}
</style>

<style lang="scss">
.bfield_w_margin_label .label {
	margin-bottom: 0px !important;
}
</style>