<template>
	<div class="scrollable is-flex is-flex-direction-column m-3">
		<h3 class="is-subtitle" v-if="!isMobile">Ziel anlegen</h3>
		<b-input maxlength="80" placeholder="Ziel" id="entryTitle" v-model="goalTitle" v-on:input="$v.goalTitle.$touch"
			autocomplete="off" type="search" :size="isMobile ? 'is-small' : ''"></b-input>
		<div class="tag is-danger is-light" v-if="!$v.goalTitle.required">
			Titel benötigt!
		</div>
		<h3 class="is-subtitle mb-3 mt-3" v-if="!isMobile">
			Maximale Punktzahl pro Tag
		</h3>
		<b-field>
			<b-numberinput v-model="maxGoalRatingPoints" min="1" max="100">
			</b-numberinput>
		</b-field>
		<div class="is-flex is-flex-direction-row is-align-items-center">
			<b-button size="is-small" @click="goalPointSeparators.push(0)" v-if="goalPointSeparators.length < 4"
				icon-left="plus" class="mr-1">
				Hinzufügen
			</b-button>
			<h3 class="is-subtitle mb-3 mt-3" v-if="!isMobile">
				Punktschwellen in %
			</h3>
		</div>
		<div class="is-flex is-flex-direction-row is-align-items-center my-1" :key="index"
			v-for="(separator, index) in goalPointSeparators">
			<b-field>
				<b-numberinput v-model="goalPointSeparators[index]" step="5" aria-minus-label="Decrement by 5"
					aria-plus-label="Increment by 5">
				</b-numberinput>
			</b-field>
			<div class="is-flex is-align-items-center" @click="goalPointSeparators.splice(index, 1)">
				<b-icon icon="close"></b-icon>
			</div>
		</div>
		<div class="tag is-danger is-light" v-if="!$v.goalPointSeparators.isUnique">
			Punktschwellen müssen einzigartig sein!
		</div>
		<h3 class="is-subtitle" v-if="!isMobile">Zielbild</h3>
		<div class="
                my-2
                is-flex is-flex-direction-column is-justify-content-center
            ">
			<b-upload v-model="goalPicture" accept="image/*" drag-drop expanded>
				<div class="has-text-centered">
					<template v-if="goalPictureModified">
						<img class="image" :src="goalPictureModified" alt="Keine Vorschau verfügbar" />
					</template>
					<template v-else>
						<p>
							<b-icon icon="upload" size="is-medium"></b-icon>
						</p>
						Bild für das Ziel einfügen!
					</template>
				</div>
			</b-upload>
			<button v-if="goalPictureModified" class="delete is-medium mt-2" type="button"
				@click="removePicture()"></button>
		</div>
		<div class="tag is-danger is-light" v-if="!$v.goalPictureModified.required">
			Bild benötigt!
		</div>
		<h3 class="is-subtitle mb-3 mt-3" v-if="!isMobile && entryAttributes && entryAttributes.length > 0">Attribute
		</h3>
		<div class="is-flex is-flex-direction-row is-flex-wrap-wrap">
			<b-checkbox v-model="attributes" v-for="attribute in undeletedAttributes" :key="attribute.id"
				:native-value="attribute.id" class="m-2">
				{{ attribute.attributes.name }}
			</b-checkbox>
		</div>
		<div class="is-flex is-justify-content-center">
			<b-button class="mt-3 button" type="is-primary" @click="saveGoal" v-bind:loading="isLoading"
				v-bind:disabled="$v.$invalid" :size="isMobile ? 'is-small' : ''">Speichern
			</b-button>
		</div>
	</div>
</template>

<script>
/**
 * Module allowing the creation of a new goal.
 * @displayName GoalInput
 */

import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import Parse from "parse/dist/parse.min.js";
import { getModifiedImage } from "@/lib/imageFunctions.js";
import { v4 as uuid } from "uuid";

export default {
	name: "GoalInput",
	validations: {
		goalTitle: { required },
		goalPictureModified: { required },
		goalPointSeparators: {
			isUnique(value) {
				if (value === []) return true;

				var distinct = value.filter((val, index, self) => {
					return self.indexOf(val) === index;
				});
				return distinct.length === value.length;
			},
		},
	},
	data() {
		return {
			goalTitle: "",
			isLoading: false,
			goalPicture: undefined,
			goalPictureModified: undefined,
			maxGoalPoints: 10,
			maxGoalRatingPoints: 1,
			goalPointSeparators: [],
			attributes: [],
		};
	},
	mounted() { },
	computed: {
		getSize() {
			return this.$store.getters.isMobile ? "is-small" : "";
		},
		isMobile() {
			return this.$store.getters.isMobile;
		},
		...mapGetters({
			focusedClient: "clients/getFocusedClient",
			entryAttributes: "attributes/getAttributes",
		}),
		getGoalPictureURL() {
			if (this.goalPictureModified) {
				return URL.createObjectURL(this.goalPictureModified);
			}
			return undefined;
		},
		undeletedAttributes() {
			return this.entryAttributes.filter(e => !e.attributes.deleted)
		}
	},
	methods: {
		async saveGoal() {
            this.isLoading = true;
            const currentUser = Parse.User.current();

            const imageFile = new Parse.File(
                uuid(),
                { base64: this.goalPictureModified },
                "image/jpeg"
            );
            await imageFile.save();
            var sortedSeparators = this.goalPointSeparators.sort(function (a, b) {
                return a - b;
            });
            const goalParams = {
                authorId: currentUser.id,
                title: this.goalTitle,
                picture: imageFile,
                maxGoalPoints: this.maxGoalRatingPoints * 7,
                maxGoalRatingPoints: this.maxGoalRatingPoints,
                goalPointSeparators: sortedSeparators,
                clientId: this.focusedClient.id,
                attributeIds: this.attributes,
            };
            await Parse.Cloud.run("saveGoal", goalParams);
            this.isLoading = false;
            await this.$router.push({ name: "Goal" });
        },
		setModifiedDropFiles(value) {
			this.dropFilesModified = value;
		},
		removePicture() {
			this.goalPicture = undefined;
			this.goalPictureModified = undefined;
		},
	},
	watch: {
		goalPicture: {
			handler: function (fileArray) {
				if (fileArray) {
					this.goalPictureModified = undefined;
					if (fileArray.type.match(/image.*/)) {
						getModifiedImage(fileArray, 150)
							.then((response) => {
								this.goalPictureModified = response;
							})
							.catch(function (error) {
								console.error(error);
							});
					}
				}
			},
			deep: true,
		},
	},
};
</script>

<style lang="scss" scoped>
.scrollable {
	overflow: auto;
	height: 100%;
	width: 100%;
	padding-bottom: 1.5rem;
}

.image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
</style>
