<template>
    <div
        class="
            is-flex is-flex-direction-row is-justify-content-space-between
            px-4
            py-1
        "
        style="border: thin solid whitesmoke"
    >
        <div class="is-subtitle">{{ title }}</div>
        <a v-if="href" :href="href">
            <div>{{ value }}</div>
        </a>
        <div v-else>{{ value }}</div>
    </div>
</template>

<script>
export default {
    name: "Row",
    components: {},
    props: ["title", "value", "href"],
    computed: {},
    methods: {},
};
</script>
