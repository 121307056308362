var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.entryLinks &&
		this.$store.state.usermetas.data.length > 0 &&
		this.$store.state.users.data.length > 0
		)?_c('div',{ref:"entryPanel",staticClass:"scrollable is-flex is-flex-direction-column",staticStyle:{"user-select":"none"},attrs:{"id":"entryContainer"},on:{"scroll":_vm.handleScroll}},[_vm._l((_vm.searchedEntryLinks),function(entryLink,index){return _c('div',{key:entryLink.id,staticClass:"pr-1",on:{"click":function($event){return _vm.navigateEntryDetail(entryLink)}}},[(((index < _vm.maxViewPortIndex && index >= _vm.maxViewPortIndex - 10) || _vm.entrySearchQuery.length > 0) &&
				entryLink.attributes.entry &&
				entryLink.attributes.entry.attributes.author
				)?[_c('div',{staticClass:"box entry-box p-0 mt-1 mb-1",class:{ 'box_disabled' : !_vm.check_group_matching(entryLink) , 'box_private': _vm.check_is_private(entryLink) }},[_c('article',{staticClass:"media p-3 article"},[_c('div',{staticClass:"media-left"},[_c('AuthorImage',{attrs:{"author":entryLink.attributes.entry.attributes.author}})],1),_c('div',{staticClass:"media-content",staticStyle:{"overflow":"hidden"}},[_c('div',{staticClass:"mb-0"},[_c('div',{staticClass:"mt-0 mb-0"},[_c('div',{staticClass:"pt-0 authorTagContainer is-font-medium"},[_c('AuthorNameAndCreationTime',{attrs:{"object":entryLink.attributes.entry,"author-meta-class":entryLink.attributes.entry
											.attributes.author
											.attributes.dependency}}),(_vm.check_is_private(entryLink))?_c('ColoredTag',{staticClass:"mr-1",attrs:{"tag-name":'Privat',"tag-color":_vm.check_is_private(entryLink) ? '#7b2ec8' : '#313131',"is-right":true}}):_vm._e(),(!_vm.check_group_matching(entryLink))?_c('ColoredTag',{staticClass:"mr-1",attrs:{"tag-name":entryLink.attributes.client.attributes.group.attributes.group_name,"tag-color":!_vm.check_group_matching(entryLink) ? '#808080' : '#313131',"is-right":true}}):_vm._e(),_c('ColoredTag',{staticClass:"mr-1",attrs:{"tag-name":entryLink.attributes.entry.attributes.type.attributes.name,"tag-color":entryLink.attributes.entry.attributes.type.attributes.color,"is-right":true}}),_c('RatingTag',{attrs:{"entry-link":entryLink}}),(entryLink.attributes.entry.attributes.entryCounter)?_c('span',{staticClass:"mr-2",staticStyle:{"float":"right","margin-top":"1px"}},[_vm._v(" Eintrag: "+_vm._s(entryLink.attributes.entry.attributes.entryCounter)+" ")]):_vm._e()],1)]),_c('EntryTitle',{attrs:{"entry":entryLink.attributes.entry}}),_c('EntryContent',{attrs:{"entry-link":entryLink}}),_c('br')],1),(_vm.hasInfo(entryLink))?_c('div',{staticClass:"is-flex is-justify-content-flex-end iconContainer",staticStyle:{"margin-top":"-15px"}},[_c('CommentIcon',{attrs:{"number-of-comments":_vm.numberOfComments(
									entryLink.attributes.entry
								)}}),_c('ClientsOnEntryInfo',{attrs:{"entry":entryLink,"number":entryLink.attributes.entry.attributes.numberOfAssociatedClients}}),_c('AttachementInfo',{attrs:{"entry-link":entryLink}}),_c('ReaderIcon',{attrs:{"readers":entryLink.attributes.readers}})],1):_vm._e()])])])]:_vm._e()],2)}),(_vm.entrySearchQuery.length == 0)?_c('b-pagination',{staticClass:"p-0 mt-1 mb-1 ml-1 mr-1 pagination-dark",attrs:{"total":_vm.total,"range-before":_vm.rangeBefore,"range-after":_vm.rangeAfter,"order":_vm.order,"size":_vm.size,"simple":_vm.isSimple,"rounded":_vm.isRounded,"per-page":_vm.perPage,"icon-prev":_vm.prevIcon,"icon-next":_vm.nextIcon,"aria-next-label":"Next page","aria-previous-label":"Previous page","aria-page-label":"Page","aria-current-label":"Current page","page-input":_vm.hasInput,"page-input-position":_vm.inputPosition,"debounce-page-input":_vm.inputDebounce},on:{"change":function($event){return _vm.onPageChange(_vm.current)}},model:{value:(_vm.current),callback:function ($$v) {_vm.current=$$v},expression:"current"}}):_vm._e(),(_vm.focusedClient && _vm.entryLinks)?_c('div',[_c('div',{staticClass:"entry-box-entry p-0 box"},[(_vm.focusedClient.attributes.entry)?_c('article',{staticClass:"p-3 article has-darker-grey-color white-text"},[_vm._v(" Zugang am "+_vm._s(_vm.focusedClient.attributes.entry.toLocaleDateString( "de-DE", { year: "numeric", month: "2-digit", day: "2-digit", } ))+" ")]):_c('article',{staticClass:"client-entry-box p-3 article has-darker-grey-color white-text"},[_vm._v(" kein Zugangsdatum definiert ")])])]):_vm._e()],2):_c('div',[_c('EntryInfo')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }