<template>
	<div style="width: 100%">
		<h2 style="font-weight: bold" v-if="isDetailed">Durchschnittliche Bewertung</h2>
		<div style="display: flex; align-items: center;position: relative; width: 100%;" class="p-1"
		:style="{height: isDetailed? '30vh' : '8vh'}">
			<canvas :id="uuid" :data="data"/>
		</div>
		<hr style="height: 1px; background-color: black" v-if="isDetailed"/>
		<h2 style="font-weight: bold" v-if="isDetailed">Anzahl Einträge pro Tag</h2>
		<div v-if="isDetailed" style="display: flex; align-items: center;position: relative; width: 100%;" class="p-1"
			:style="{height: isDetailed? '20vh' : '3vh'}">
			<canvas :id="numberUuid" :data="data"/>
		</div>
	</div>
</template>

<script>

import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, LineElement, registerables} from 'chart.js'
import { v4 as uuid } from 'uuid';
import {mapGetters} from "vuex";
ChartJS.register(...registerables)
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)
export default {
	name: "AttributeChart",
	props:["id", "data", "clientId", "isDetailed", "numberStats", "maxNumberStats", "type"],
	computed:{
		uuid(){
			return uuid().concat(this.clientId)//combine attr id and client id
		},
		numberUuid(){
			return uuid().concat(this.clientId).concat("_numbers")//combine attr id and client id
		},
	},
	data(){
		return{
			chart: undefined,
			numberChart: undefined
		}
	},
	async mounted(){
		this.updateDiagrams()
	},
	updated() {
		this.updateDiagrams()
	},
	methods:{
		updateDiagrams(){
			if(this.chart) this.chart.destroy()
			if(this.numberChart) this.numberChart.destroy()
			const canvasObject = document.getElementById(this.uuid);
			this.chart = new ChartJS(canvasObject, {
				type:"line",
				data:this.data,
				options: {
					spanGaps: true,
					maintainAspectRatio: false,
					responsive: true,
					onClick: this.handleTypeOnClick,

					scales: {
						y: {
							type: 'linear',
							max: this.isDetailed? 0 : .5,
							min: -6,
							ticks:{
								callback: function(value, index, ticks){
									return `${-value}`
								}
							},
							display: !!this.isDetailed
						},
						x: {
							display: !!this.isDetailed
						}
					},
					plugins:{
						legend: {
							display: !!this.isDetailed,
							onClick: (e) => {return}
						},
						tooltip: {
							callbacks: {
								label: function(context) {
									if(!context || !context.dataset || !context.dataset.label) return ""
									let label = context.dataset.label || '';

									if (label) {
										label += ': ';
									}
									if (context.parsed.y !== null) {
										label += -context.parsed.y;
									}
									return label;
								},
							}
						}
					}
				}
			})

			if(!this.isDetailed) return;
			const canvasNumberObject = document.getElementById(this.numberUuid);
			this.numberChart = new ChartJS(canvasNumberObject, {
				type:"bar",
				data:this.numberStats,
				options: {
					spanGaps: true,
					maintainAspectRatio: false,
					responsive: true,
					onClick: this.handleNumberOnClick,

					scales: {
						y: {
							type: 'linear',
							max: this.maxNumberStats,
							min: 0,
							ticks:{
								stepSize: 1
							},
							display: !!this.isDetailed
						},
						x: {
							display: !!this.isDetailed
						}
					},
					plugins:{
						legend: {
							display: !!this.isDetailed,
							onClick: (e) => e.stopPropagation()
						},
					},
				}
			})
		},
		handleTypeOnClick(evt){
			var activePoints = this.chart.getElementsAtEventForMode(evt, 'nearest', { intersect: true }, true);
			
			if (activePoints.length > 0){
				let data = evt.chart.tooltip.title
				let type = this.type
				this.$router.push({
					name: "Diary", 
					params: { data, type }
				});
			} 
		},
		handleNumberOnClick(evt){
			var activePoints = this.numberChart.getElementsAtEventForMode(evt, 'nearest', { intersect: true }, true);
			
			if (activePoints.length > 0){
				let data = evt.chart.tooltip.title
				this.$router.push({
					name: "Diary", 
					params: { data }
				});
			} 
		},
	}
}
</script>

<style scoped>

</style>
