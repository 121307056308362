import Parse from 'parse/dist/parse.min.js';
import { handleParseError } from '../../../errorhandling';

let localSubscription = undefined;

// User subscription
function createSubscription(store) {
		let userQuery = new Parse.Query('UserMetaClass');
		userQuery.limit(100000)
		userQuery.include("widgets")
		userQuery.notEqualTo("deleted", true)
			.find()
			.then((results) => {
				store.dispatch('usermetas/set', results)
			})
			.catch((error) => {
				handleParseError(error);
			
			});
		userQuery.subscribe().then((subscription) => {
			localSubscription = subscription;
			
			subscription.on('create', (object) => {
				
				store.dispatch('usermetas/insert', object)
			});
			subscription.on('update', (object) => {
				
				store.dispatch('usermetas/replace', object)
			});
			subscription.on('enter', (object) => {
				
				store.dispatch('usermetas/insert', object)
			});
			subscription.on('leave', (object) => {
				
				store.dispatch('usermetas/delete', object)
			});
			subscription.on('delete', (object) => {
				
				store.dispatch('usermetas/delete', object)
			});
			subscription.on('close', () => {
				
			});
		})
}

export default function createUserMetaSubscription() {
	return store => {
		//Erzeuge einmalig eine neue Subscription für den Datensatz. 
		//Fall "Bereits in der Anwendung"
		if(store.state.currentUser && !localSubscription) {
			createSubscription(store)
		}
		//Erzeuge einmalig eine neue Subscription für den Datensatz. 
		//Fall "Normaler Login"
		store.subscribe(mutation => {
			if (mutation.type === 'SET_CURRENT_USER') {
				if (!localSubscription) {
					createSubscription(store)
				}
			}
		})
	}
}