<template>
	<b-modal v-model="isActive" has-modal-card @close="closeModalEntryLink()">
		<div
			class="modal-card modalCardContainer"
			style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%);
			background-color: rgba(40, 40, 40, 1)"
			v-if="entryLinks.length>0"
		>
				<p class="panel-heading" style="text-align: center; background-color: rgba(40, 40, 40, 1); color: white; width: 100%">Weitere Klienten</p>
				<div class="is-centered" style="background-color: white">
					<div v-for="entryLink in entryLinks" :key="entryLink.id">
					<article class="media p-1 article" style="cursor: pointer" @click="routeToEntryLink(entryLink)">
						<div class="media-content" style="overflow: hidden">
							<div class="mb-0 clientSelectionContainer">
								<div class="clientSelectionInnerContainer">
								<figure
									class="user_image image is-48x48"
									style="display: flex; justify-content: center"
								>
									<img
										class="is-rounded"
										:src="getPicture(entryLink.attributes.client)"
										alt="Profilbild"

									/>
								</figure>
								<p style="text-align: left; display: flex;align-items: center"><b>{{entryLink.attributes.client.attributes.first_name}} {{entryLink.attributes.client.attributes.last_name}}</b></p>
								</div>
							</div>
						</div>
					</article>
			</div>
				</div>
				<div class="modal-card-foot buttons" style="display: flex; justify-content: center; background-color: rgba(40, 40, 40, 1)">
					<b-button type="is-danger" @click="closeModalEntryLink()">Schließen</b-button>
				</div>
			</div>
	</b-modal>
</template>

<script>
/**
 * Modal for ClientEntryLinksModal
 * @displayName ClientEntryLinksModal
 */

import {getClientPicture} from "@/lib/imageFunctions";
import {mapGetters} from "vuex";
import Parse from 'parse/dist/parse.min.js';

export default {
	name: "ClientEntryLinksModal",
	data(){
		return{
			entryLinks:[]
		}
	},
	props: ["value"],
	computed:{
	...mapGetters({
		entry: "entrylinks/getFocusedEntry",
		focusedClient: "clients/getFocusedClient"
	}),
		isActive: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit("input", val);
			},
		},
	},
	updated() {

	},
	async created() {
		const queryEntryLinks = new Parse.Query("EntryLink")
		queryEntryLinks.notEqualTo("deleted", true)
		queryEntryLinks.equalTo("entry", this.entry)
		queryEntryLinks.notEqualTo("client", this.focusedClient)
		try{
			this.entryLinks = await queryEntryLinks.find()
		}catch (e){
			console.error("couldnt find entrylinks in entrylink modal " + e)
		}
	},
	methods:{
		async routeToEntryLink(entryLink){
			const client = entryLink.attributes.client
			this.isActive = false
			await this.$store.dispatch("setSidePanelOpen", false)
			await this.$store.dispatch("entrylinks/setFocusedEntryLinkId", entryLink.id)
			await this.$store.dispatch("clients/setFocusedClientId", client.id)

		},
		getPicture(client){
			const pictureObject = {
				newProfilePicture: undefined,
				clientPicture: client.attributes.profile_picture ? client.attributes.profile_picture._url : undefined,
				clientGender:client.attributes.gender
			}
			return getClientPicture(pictureObject)
		},
		closeModalEntryLink(){
			this.isActive = false
		},
	}

}
</script>

<style scoped>
.clientSelectionContainer{
	background-color: white;
	margin-right: 0.25rem;
	margin-left: 0.25rem;
	border-radius: 6px;
	cursor: pointer;
	color: black;
}
.clientSelectionInnerContainer{
	display: grid;
	grid-template-columns: 1fr 9fr;
	padding: 0.5rem;
}

.clientSelectionContainer:hover{
	background-color: whitesmoke;
}


</style>
