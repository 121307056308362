<template>
	<div class="is-flex is-flex-direction-column is-align-items-center" v-if="goalsLoaded">
		<h1 class="is-title mb-0">Bewertung</h1>
		<div v-if="newValue">
			<b-select placeholder="Wochentag" v-model="selectedDay" class="mx-1">
				<option v-for="(item) in weekDays" :value="item.id" :key="item.id">
					{{ item.label }}
				</option>
			</b-select>
		</div>
		<b-rate v-model="ratingValue" :max="focusedGoal.attributes.maxRating" size="is-large" :show-score="true">
		</b-rate>
		<b-button :disabled="disableButton" @click="confirmCustom"> Speichern </b-button>
	</div>
</template>

<script>
/**
 * Modal for the management of a goal.
 * @displayName GoalRatingModal
 */

import { mapGetters } from "vuex";
import Parse from "parse/dist/parse.min.js";

export default {
	name: "GoalRating",
	props: {
		id: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			ratingValue: 0,
			selectedDay: 0,
			newValue: false,
			disableButton: true
		};
	},
	mounted() {
		this.newValue = this.$route.params.newVal
		if (this.newValue) {
			if (this.currentDay == -1) {
				this.selectedDay = new Date().getDay();
			}
			else {
				this.selectedDay = this.currentDay
			}
		} else {
			this.selectedDay = this.$route.params.rating.attributes.rating_day
		}
		/*
		if (typeof (this.$route.params.rating) != "undefined") {
			this.ratingValue = this.$route.params.rating.attributes.rating
			this.disableButton = false;
		}
		*/
	},
	updated() { },
	computed: {
		...mapGetters({
			goals: "goals/getGoals",
			goalsLoaded: "goals/areGoalsLoaded",
			ratingDay: "ratingDay",
			focusedClient: "clients/getFocusedClient",
			ratings: "ratings/getRatings",
			weekDays: "weekDays/getWeekDays",
			currentDay: "weekDays/getCurrentDay"
		}),
		focusedGoal() {
			if (this.goalsLoaded) {
				return this.goals.filter((goal) => {
					return goal.id === this.id;
				})[0];
			}

			return undefined;
		},
		focusedRatings() {
			const currentUser = Parse.User.current();
			if (this.ratings) {
				let focusedRatings = this.ratings.filter(rating => {
					return rating.attributes.goal.id === this.focusedGoal.id && rating.attributes.author.id === currentUser.id
				})
				return focusedRatings
			}
			return undefined
		},
		focusedRatingsDaily() {
			if (this.focusedRatings) {
				let focusedRatingsDaily = this.focusedRatings.filter(rating => {
					return rating.attributes.rating_day === this.selectedDay
				})
				return focusedRatingsDaily
			}
			return 0
		}
	},
	watch: {
		selectedDay() {
			let element = this.focusedRatingsDaily
			if (element.length > 0) {
				this.ratingValue = this.focusedRatingsDaily[0].attributes.rating
			} else {
				this.ratingValue = 0
			}
		},
		ratingValue() {
			if (this.ratingValue <= 0) {
				this.disableButton = true;
			} else {
				this.disableButton = false;
			}
		}
	},
	methods: {
		confirmCustom() {
			this.$buefy.dialog.confirm({
				title: 'Speicherbestätigung',
				message: 'Sicher, dass Sie die Änderung speichern möchten?',
				cancelText: 'Zurück',
				confirmText: 'Speichern',
				type: 'is-success',
				onConfirm: () => {
					this.$buefy.toast.open('Änderung wird gespeichert.')
					this.save()
				}
			})
		},
		async save() {
			const currentUser = Parse.User.current();

			const goalRatingParams = {
				authorId: currentUser.id,
				clientId: this.focusedClient.id,
				goalId: this.id,
				rating: this.ratingValue,
				rating_day: this.newValue ? this.selectedDay : this.ratingDay,
			};

			await Parse.Cloud.run("saveGoalRating", goalRatingParams);

			await this.$router.back();
		},
	},
	components: {},
};
</script>

<style scoped>
.rate {
	flex-wrap: wrap
}
</style>
