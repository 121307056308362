var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.focusedEntry && _vm.focusedEntryLink && this.$store.state.entrylinks.loaded
		)?_c('div',{ref:"entryDetailPanel",staticClass:"scrollable p-3"},[_c('div',{staticClass:"detailContainer"},[_c('div',{staticClass:"mb-0 headContainer"},[_c('div',{staticClass:"entryHead"},[_c('h1',{staticClass:"is-title mb-0"},[_vm._v(" "+_vm._s(_vm.focusedEntry.attributes.title)+" ")]),_c('div',{staticClass:"is-font-medium"},[_vm._v(" "+_vm._s(_vm.focusedEntry.attributes.author.attributes.dependency .attributes.first_name + " " + _vm.focusedEntry.attributes.author.attributes.dependency .attributes.last_name)+", "),_c('time',[_vm._v(" "+_vm._s(_vm.focusedEntry.createdAt.toLocaleDateString( "de-DE", { year: "numeric", month: "2-digit", day: "2-digit", } ) + " - " + _vm.focusedEntry.createdAt.toLocaleTimeString( "de-DE" ))+" ")]),(_vm.focusedEntry.attributes.entryCounter)?_c('span',{staticClass:"mr-1",staticStyle:{"float":"right"}},[_vm._v(" Eintrag: "+_vm._s(_vm.focusedEntry.attributes.entryCounter)+" ")]):_vm._e()])]),_c('div',{staticClass:"entryTypeTag"},[_c('small',{staticStyle:{"float":"right","text-align":"right"}},[(_vm.focusedEntry.attributes.type)?_c('div',{staticStyle:{"float":"right"}},[_c('span',{staticClass:"tag m-1 is-dark is-rounded entryTag is-font-small",style:({
								'background-color': _vm.focusedEntry.attributes.type.attributes.color,
								color: _vm.getColor(_vm.focusedEntry.attributes.type.attributes.color),
							})},[_vm._v(" "+_vm._s(_vm.focusedEntry.attributes.type.attributes.name))]),(_vm.focusedEntry.attributes.isPrivate)?_c('span',{staticClass:"tag m-1 is-dark is-rounded entryTag is-font-small",style:({
								'background-color': '#7b2ec8',
								color: '#ffffff',
							})},[_vm._v(" Privat")]):_vm._e()]):_vm._e(),(_vm.focusedEntryLink.attributes.entry.attributes.numberOfAssociatedClients &&
							_vm.focusedEntryLink.attributes.entry.attributes.numberOfAssociatedClients > 1)?_c('div',{staticClass:"mr-2",staticStyle:{"float":"right","margin-top":"6px"}},[_c('b-tooltip',{attrs:{"label":"Klienten","position":"is-bottom"}},[_c('div',{staticStyle:{"display":"grid","grid-template-columns":"repeat(2, 0.1fr)","grid-template-areas":"'icon num'","cursor":"pointer"},on:{"click":function($event){return _vm.openClientEntryLinkModal()}}},[_c('div',{staticStyle:{"grid-area":"icon"}},[_c('b-icon',{attrs:{"icon":"account-multiple"}})],1),_c('div',{staticStyle:{"grid-area":"num"}},[_vm._v(" "+_vm._s(_vm.focusedEntryLink.attributes.entry.attributes.numberOfAssociatedClients)+" ")])])]),_c('client-entry-links-modal',{model:{value:(_vm.isClientEntryLinkModalOpen),callback:function ($$v) {_vm.isClientEntryLinkModalOpen=$$v},expression:"isClientEntryLinkModalOpen"}})],1):_vm._e()])])]),_vm._l((_vm.focusedEntry.attributes.attributes),function(entry_attribute){return _c('span',{key:entry_attribute.id,staticClass:"tag is-dark is-rounded entryTag mr-1 mb-2 is-font-small",style:({
					'background-color':
						entry_attribute.attributes.attribute_config.attributes
							.color,
					color: _vm.getColor(
						entry_attribute.attributes.attribute_config.attributes
							.color
					),
				})},[_vm._v(_vm._s(entry_attribute.attributes.name))])}),(_vm.focusedEntry)?_c('div',{staticClass:"content mb-2 ml-1"},[_c('div',{staticClass:"mb-2",domProps:{"innerHTML":_vm._s(_vm.focusedEntry.attributes.text)}}),(_vm.focusedEntry.attributes.attachment)?_c('div',{staticClass:"columns imagesContainer mb-1",staticStyle:{"flex-wrap":"wrap"}},[_vm._l((_vm.images),function(image,i){return _c('div',{key:i,staticClass:"column is-one-third imageContainer"},[_c('img',{staticClass:"image",attrs:{"src":image},on:{"click":function($event){_vm.index = i}}})])}),_c('vue-gallery-slideshow',{attrs:{"images":_vm.images,"index":_vm.index},on:{"close":function($event){_vm.index = null}}})],2):_vm._e(),(_vm.otherFiles)?_c('div',[_c('hr',{staticStyle:{"height":"1px","background-color":"black"}}),_vm._l((_vm.otherFiles),function(file,i){return _c('div',{key:i},[_c('div',{staticClass:"fileItem"},[_c('b-icon',{attrs:{"icon":"file-pdf-box","size":"is-medium"}}),_c('a',{staticStyle:{"padding-top":"4px","margin-left":"6px"},attrs:{"tag":"a","href":file.attributes.file._url,"target":"_blank"}},[_vm._v(" "+_vm._s(file.attributes.fileTitle)+" ")])],1)])})],2):_vm._e()]):_vm._e(),_c('entry-rating',{attrs:{"ratings":_vm.focusedRatings}}),_c('hr',{staticClass:"mb-2 mt-2"}),(!_vm.focusedEntry.attributes.isPrivate)?_c('entry-comment',{ref:"commentPanel"}):_vm._e(),_c('entry-reader',{attrs:{"readers":_vm.focusedEntryLink.attributes.readers
				? _vm.focusedEntryLink.attributes.readers
				: [],"timestamps":_vm.focusedEntryLink.attributes.reader_timestamps
		? _vm.focusedEntryLink.attributes.reader_timestamps
		: []}})],2)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }