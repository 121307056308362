<template>
	<div class="is-flex is-flex-direction-column" style="width:100%;">
		<template v-if="mask == 'new' || mask == 'edit'">
			<MedicationSearchableSelect :disabled="proppedHasLog" :data-options="medication"
				:editObject="medicationMedication" :enable-validation="true"
				validationMessage="Bitte wählen Sie ein Medikament aus!" label-text="Medikament" :enable-order="true"
				:select="(object) => (medicationMedication = object)"
				@emit-medication-modal-is-open="val => isModalActive = val" />
		</template>

		<template v-if="mask == 'view' && medicationMedication">
			<b-field expanded>
				<template #label>
					<div class="align-content-center" style="display: inline-flex">
						<p>Medikament</p>
					</div>
				</template>
				{{ medicationMedication.attributes?.displayName }}
			</b-field>
		</template>

		<template v-if="mask == 'new' || mask == 'edit'">
			<b-field expanded :message="($v.medicationMin.$invalid) ? 'Bitte geben sie eine Mindestmenge!' : ''"
				:type="($v.medicationMin.$invalid) ? 'is-danger' : 'is-success'">
				<template #label>
					<div class="align-content-center" style="display: inline-flex">
						<p>Mindestmenge</p>
					</div>
				</template>
				<b-input type="number" min="0" v-model="medicationMin" />
			</b-field>
		</template>

		<template v-if="mask == 'view'">
			<b-field>
				<template #label>
					<div class="align-content-center" style="display: inline-flex">
						<p>Mindestmenge</p>
					</div>
				</template>
				{{ medicationMin }}
			</b-field>
		</template>

		<template v-if="mask == 'new' || mask == 'edit'">
			<b-field expanded>
				<template #label>
					<div class="align-content-center" style="display: inline-flex">
						<p>Ablaufdatum</p>
					</div>
				</template>
				<b-datepicker :first-day-of-week=1 :date-formatter="dateFormatter" trap-focus editable locale="de-DE"
					v-model="medicationExpirationDate">
				</b-datepicker>
			</b-field>
			<hr class="line">
		</template>

		<template v-if="mask == 'view' && medicationExpirationDate">
			<b-field expanded>
				<template #label>
					<div class="align-content-center" style="display: inline-flex">
						<p>Ablaufdatum</p>
					</div>
				</template>
				{{ medicationExpirationDate.toLocaleDateString("de-DE",
			{
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
			}) }}
			</b-field>
		</template>

		<template v-if="mask == 'new' || mask == 'edit'">
			<b-field expanded grouped>
				<b-field expanded
					:message="($v.medicationStartDate.$invalid) ? 'Bitte geben Sie ein Startdatum an! Das Startdatum muss vor dem Enddatum liegen!' : ''"
					:type="($v.medicationStartDate.$invalid) ? 'is-danger' : 'is-success'">
					<template #label>
						<div class="align-content-center" style="display: inline-flex">
							<p>Einnahme - Beginn</p>
						</div>
					</template>
					<b-datepicker :first-day-of-week=1 :disabled="proppedHasLog" expanded
						:date-formatter="dateFormatter" trap-focus editable locale="de-DE"
						v-model="medicationStartDate">
					</b-datepicker>
				</b-field>
				<b-field expanded
					:message="($v.medicationEndDate.$invalid) ? 'Bitte geben Sie ein Enddatum an! Das Enddatum muss nach dem Startdatum liegen!' : ''"
					:type="($v.medicationEndDate.$invalid) ? 'is-danger' : 'is-success'">

					<template #label>
						<div class="align-content-center" style="display: inline-flex">
							<p>Einnahme - Ende</p>
						</div>
					</template>
					<b-datepicker :first-day-of-week=1 :disabled="proppedHasLog" expanded
						:date-formatter="dateFormatter" trap-focus editable locale="de-DE" v-model="medicationEndDate">
					</b-datepicker>
				</b-field>
			</b-field>
		</template>

		<template v-if="mask == 'view' && medicationStartDate">
			<b-field expanded grouped>
				<b-field expanded>
					<template #label>
						<div class="align-content-center" style="display: inline-flex">
							<p>Einnahmezeitraum</p>
						</div>
					</template>
					{{ medicationStartDate.toLocaleDateString("de-DE",
			{
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
			}) }} -
					{{ medicationEndDate.toLocaleDateString("de-DE",
			{
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
			}) }}
				</b-field>
			</b-field>
		</template>

		<template v-if="mask == 'new' || mask == 'edit'">
			<b-field expanded>
				<template #label>
					<div class="align-content-center" style="display: inline-flex">
						<p>Abgesetzt seit</p>
					</div>
				</template>
				<b-datepicker :first-day-of-week=1 expanded :date-formatter="dateFormatter" trap-focus editable
					locale="de-DE" v-model="medicationDropDate">
				</b-datepicker>
			</b-field>
			<hr class="line">
		</template>

		<template v-if="mask == 'view' && medicationDropDate">
			<b-field expanded>
				<template #label>
					<div class="align-content-center" style="display: inline-flex">
						<p>Abgesetzt seit</p>
					</div>
				</template>
				{{ medicationDropDate.toLocaleDateString("de-DE",
			{
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
			}) }}
			</b-field>
		</template>

		<template v-if="mask == 'new' || mask == 'edit'">
			<b-field expanded>
				<template #label>
					<div class="align-content-center" style="display: inline-flex">
						<p>Grund der Medikation</p>
					</div>
				</template>
				<b-input type="text" v-model="medicationReason" />
			</b-field>
		</template>

		<template v-if="mask == 'view' && medicationReason">
			<b-field expanded>
				<template #label>
					<div class="align-content-center" style="display: inline-flex">
						<p>Grund der Medikation</p>
					</div>
				</template>
				{{ medicationReason }}
			</b-field>
		</template>

		<template v-if="mask == 'new' || mask == 'edit'">
			<b-field expanded>
				<template #label>
					<div class="align-content-center" style="display: inline-flex">
						<p>Anmerkungen</p>
					</div>
				</template>
				<b-input expanded type="textarea" v-model="medicationHint" />
			</b-field>
		</template>

		<template v-if="mask == 'view' && medicationHint">
			<b-field expanded>
				<template #label>
					<div class="align-content-center" style="display: inline-flex">
						<p>Anmerkungen</p>
					</div>
				</template>
				{{ medicationHint }}
			</b-field>
		</template>

		<template v-if="mask == 'new' || mask == 'edit'">
			<UserSearchableSelect :data-options="filteredUsers" :editObject="medicationContactUser"
				:enable-validation="true" validationMessage="Bitte wählen Sie einen Verantwortlichen aus!"
				label-text="Verantwortlicher" :enable-order="true"
				:select="(object) => (medicationContactUser = object)" />
		</template>

		<template v-if="mask == 'view' && medicationContactUser">
			<b-field expanded>
				<template #label>
					<div class="align-content-center" style="display: inline-flex">
						<p>Verantwortlicher</p>
					</div>
				</template>
				{{ medicationContactUser.attributes?.dependency.attributes.first_name + " " +
			medicationContactUser.attributes?.dependency.attributes.last_name }}
			</b-field>
		</template>

		<template v-if="mask == 'new' || mask == 'edit'">
			<div style="display: flex; justify-content: center">
				<b-button class="mt-3 button mb-5" type="is-primary" @click="saveMedication" v-bind:loading="isLoading"
					v-bind:disabled="$v.medicationMedication.$invalid || $v.medicationStartDate.$invalid || $v.medicationMin.$invalid
			|| $v.medicationEndDate.$invalid || $v.medicationContactUser.$invalid" :size="isMobile ? 'is-small' : ''">Speichern
				</b-button>
			</div>
			<MedicationEntryModal v-if="isModalActive" @emit-medication-medication="val => medicationMedication = val"
				v-model="isModalActive"></MedicationEntryModal>
		</template>
	</div>
</template>

<script>
/**
 * Sub-Module allowing the creation of a new medication.
 * @displayName MedicationInput
 */

import { mapGetters } from "vuex";
import Parse from "parse/dist/parse.min.js";
import { required, helpers } from "vuelidate/lib/validators";
import MedicationEntryModal from "@/components/modals/MedicationEntryModal.vue";
import { negativeFeedBack, positiveFeedBack } from "@/lib/Toast";
import UserSearchableSelect from "@/components/helper/SearchableSelects/UserSearchableSelect.vue";
import MedicationSearchableSelect from "@/components/helper/SearchableSelects/MedicationSearchableSelect.vue";

export default {
	name: "MedicationInput",
	components: {
		MedicationEntryModal,
		UserSearchableSelect,
		MedicationSearchableSelect,
	},
	props: ["value", "proppedMedicationObject", "mask", "proppedHasLog"],
	emits: ['emit-data-object'],
	validations: {
		medicationMedication: {
			required
		},
		medicationMin: {
			required,
			validator: function (value) {
				let numberValue = Number(value)
				return numberValue >= 0
			}
		},
		medicationStartDate: {
			required,
			validator: function (value) {
				if (this.medicationEndDate) {
					return value.getTime() <= this.medicationEndDate.getTime()
				}
				return false
			}
		},
		medicationEndDate: {
			required,
			validator: function (value) {
				if (this.medicationStartDate) {
					return value.getTime() >= this.medicationStartDate.getTime()
				}
				return false
			}
		},
		medicationContactUser: {
			required,
		},
	},
	data() {
		return {
			isModalActive: false,
			medicationMedication: undefined,
			medicationMin: 0,
			medicationExpirationDate: undefined,
			medicationReason: "",
			medicationStartDate: undefined,
			medicationEndDate: undefined,
			medicationDropDate: undefined,
			medicationHint: "",
			medicationContactUser: undefined
		};
	},
	mounted() {
		if (this.medicationObject) {

			if (this.medicationObject.attributes.medication && this.mask == "edit" && this.medicationObject.attributes.medication.__type != 'Pointer') {
				this.medicationMedication = this.medicationObject.attributes.medication.toPointer();
			} else {
				this.medicationMedication = this.medicationObject.attributes.medication;
			}

			this.medicationMin = this.medicationObject.attributes.min;
			this.medicationExpirationDate = this.medicationObject.attributes.expirationDate;
			this.medicationReason = this.medicationObject.attributes.reason;
			this.medicationStartDate = this.medicationObject.attributes.startDate;
			this.medicationEndDate = this.medicationObject.attributes.endDate;
			this.medicationDropDate = this.medicationObject.attributes.dropDate;
			this.medicationHint = this.medicationObject.attributes.hint;
			// this.medicationContactUser = this.medicationObject.attributes.contactUser;

			if (this.medicationObject.attributes.contactUser && this.mask != "view" && this.medicationObject.attributes.contactUser.__type != 'Pointer') {
				this.medicationContactUser = this.medicationObject.attributes.contactUser.toPointer();
			} else {
				this.medicationContactUser = this.medicationObject.attributes.contactUser;
			}
		}
	},
	computed: {
		getSize() {
			return this.$store.getters.isMobile ? "is-small" : "";
		},
		isMobile() {
			return this.$store.getters.isMobile;
		},
		...mapGetters({
			medication: "medicationMedication/getMedication",
			users: "users/getFilteredUsers",
		}),
		filteredUsers() {
			let filteredClients = []
			if (this.users) {
				this.users.forEach(element => {
					if (element.attributes.dependency.attributes.first_name ||
						element.attributes.dependency.attributes.last_name)
						filteredClients.push(element)
				});
			}
			return filteredClients
		},
		medicationObject: {
			get() {
				return this.proppedMedicationObject
			},
			set(val) {
				this.$emit("emit-medication-object", val)
			}
		},
		isLoading: {
			get() {
				return this.proppedMedicationIsLoading
			},
			set(val) {
				this.$emit("emit-save-medication-input", val)
			}
		}
	},
	methods: {
		dateFormatter(dt) {
			return dt.toLocaleDateString("de-DE", {
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
			});
		},
		// openModal() {
		// 	this.isModalActive = true;
		// },
		async saveMedication() {
			this.isLoading = true;
			await positiveFeedBack("Medikation gespeichert!")
		},
		checkIfValid() {
			let is_valid = this.$v.medicationMedication.$invalid || this.$v.medicationStartDate.$invalid || this.$v.medicationMin.$invalid
				|| this.$v.medicationEndDate.$invalid || this.$v.medicationContactUser.$invalid;
			this.$emit("emit-medication-is-valid", !is_valid)
		}
	},
	watch: {
		medicationMedication(newVal) {
			this.medicationObject.set("medication", newVal)
			this.checkIfValid();
		},
		medicationMin(newVal) {
			this.medicationObject.set("min", Number(newVal))
			this.checkIfValid();
		},
		medicationExpirationDate(newVal) {
			this.medicationObject.set("expirationDate", newVal)
			this.checkIfValid();
		},
		medicationReason(newVal) {
			this.medicationObject.set("reason", newVal)
			this.checkIfValid();
		},
		medicationStartDate(newVal) {
			this.medicationObject.set("startDate", newVal)
			this.checkIfValid();
		},
		medicationEndDate(newVal) {
			this.medicationObject.set("endDate", newVal)
			this.checkIfValid();
		},
		medicationDropDate(newVal) {
			this.medicationObject.set("dropDate", newVal)
			this.checkIfValid();
		},
		medicationHint(newVal) {
			this.medicationObject.set("hint", newVal)
			this.checkIfValid();
		},
		medicationContactUser(newVal) {
			this.medicationObject.set("contactUser", newVal)
			this.checkIfValid();
		}
	}
};
</script>

<style lang="scss" scoped>
.line {
	border-bottom: 1px solid gray;
	margin-top: 12px;
}

.buttonNew {
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
}
</style>
