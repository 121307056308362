import { render, staticRenderFns } from "./VersionModal.vue?vue&type=template&id=28331896&scoped=true&"
import script from "./VersionModal.vue?vue&type=script&lang=js&"
export * from "./VersionModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28331896",
  null
  
)

export default component.exports