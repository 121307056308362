import { v4 as uuid } from "uuid";
import Parse from "parse/dist/parse.min.js";

/**
 * @param {String}          parseObjectName Der Name der Tabelle, in der die PDFs gespeichert werden.
 * @param {Array<File>}     pdfFiles Die zu speichernden PDF-Dateien.
 * @param {Dictionary}      optionalData Eine Liste von optionalen Attributen in Form von Key-Value. Diese werden in jedes PDF-Objekt eingefügt.
 */
export const savePdfFiles = async (parseObjectName, pdfFiles, optionalData = []) => {
	
	const savedFiles = [];
	for (const file of pdfFiles) {
		const pdfFile = new Parse.File(uuid(), file);
		const parseObjekt = Parse.Object.extend(parseObjectName)
		const parseDataObjekt = new parseObjekt()
		parseDataObjekt.set("fileTitle", pdfFile._source.file.name);
		parseDataObjekt.set("file", pdfFile);
		parseDataObjekt.set("fileLastModifiedDate", pdfFile._source.file.lastModifiedDate);
		parseDataObjekt.set("fileSize", pdfFile._source.file.size);

		for(var key in optionalData){
			parseDataObjekt.set(key, optionalData[key])
		}

		await pdfFile.save()
		await parseDataObjekt.save()
		savedFiles.push(parseDataObjekt)
	}
	
	return savedFiles
}