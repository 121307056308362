<template>
    <span
        class="tag mr-1 is-dark is-rounded is-font-small"
        style="
            float: right;
            width: fit-content;
            background-color: black;
            color: white;
        "
        >Bewertung: {{ rating }}
    </span>
</template>

<script>
/**
 * Tag displaying the rating of a certain entryLink.
 * @displayName RatingTag
 */

export default {
    name: "RatingTag",
    props: ["entryLink"],
	computed:{
		rating(){
			return this.entryLink.attributes.rating ? this.entryLink.attributes.rating :"neutral"
		}
	}
};
</script>

<style scoped>
</style>
