<template style="height: 100%">
	<div class="commentTrees" v-if="comment" style="height: 100%; width: 100%">
		<template v-if="comment.length"> <!-- received array -->
			<div v-for="(c) in comment" :key="id(c)">
				<CommentTree :style="commentPadding"
							:comment="c" :reply="reply" :padded="true" style="height: 100%"/>
			</div>
		</template>
		<template v-else > <!-- received comment -->
				<div style="position: relative; height: 100%; width: 100%; display: flex" :style="padded && !isMobile? 'border-left: 1px solid rgba(0,0,0,0.3);':''">
					<div style="height: 15px;width: 20px; border-bottom: 1px solid rgba(0,0,0,.3)" v-if="!isMobile && padded"></div>
					<CommentDetail :comment="comment" :key="comment.id" style="width: 100%"/>
					<div
						v-on:click="reply(comment)"
						style="position: absolute; bottom:1rem; right: 1rem">
						<small v-if="!isMobile" class="pr-3" style="font-weight: bold; cursor:pointer;">Antworten</small>
						<b-icon v-else style="cursor: pointer" icon="reply"></b-icon>
					</div>
				</div>
		</template>

	</div>
</template>

<script>
import {mapGetters} from "vuex";
import CommentDetail from "@/components/helper/CommentDetail";
import {v4 as uuid} from "uuid";

export default {
	name: "CommentTree",
	components: {CommentDetail},
	props: ["comment", "reply", "padded"],
	computed: {
		...mapGetters({
			comments: "comments/getFilteredComments",
			isMobile: "isMobile"
		}),
		commentPadding(){
			if(this.padded && !this.isMobile){
				return "padding-left: 1.5rem;border-left: 1px solid rgba(0,0,0,0.3);"
			}
			if(this.padded && !this.isMobile){
				return "padding-left: 3.5rem;border-left: 1px solid rgba(0,0,0,0.3);"
			}
			if(this.isMobile && this.padded){
				return "padding-left: 1rem"
			}
			return ""
		}
	},
	methods: {
		id(c){
			return uuid()
		},
		getRefCommentStyles(index) {
			let styles = "border-radius: .5rem; padding-top: .5rem;"
			styles = styles.concat(`padding-left: ${index * 0.75}rem;`)
			styles = styles.concat(`background-color: rgba(0,0,0,${.1 * (this.previousComments.length - 1 - index) / (this.previousComments.length - 1)});`)
			return styles;
		},
	}
}
</script>

<style scoped>

</style>
