<template>
	<b-field expanded :message="enableValidation ? (($v.selectedValue.$invalid) ? validationMessage : '') : ''"
		:type="enableValidation ? (($v.selectedValue.$invalid) ? 'is-danger' : 'is-success') : ''">
		<template #label>
			<div class="align-content-center" style="display: inline-flex">
				<p> {{ labelText }}</p>
			</div>
		</template>
		<b-autocomplete class="autocomplete_break" open-on-focus clearable v-model="inputString" :data="orderedFilteredDataArray" icon="magnify"
			:custom-formatter="option => (option?.attributes.medication.attributes.displayName + '(' + 
		(option?.attributes.beginDate ? option.attributes.beginDate.toLocaleDateString('de-DE',
			{
				year: 'numeric',
				month: '2-digit',
				day: '2-digit',
			}) : '') + ' - ' +
		(option?.attributes.endDate ? option.attributes.endDate.toLocaleDateString('de-DE',
			{
				year: 'numeric',
				month: '2-digit',
				day: '2-digit',
			}) : '') + ')'
	)" @select="option => set(option)">

			<template slot-scope="props">
				<div class="media">
					<div class="media-content">
						{{ props?.option?.attributes.medication.attributes.displayName }}
						(<span v-if="props?.option?.attributes.beginDate">{{
		props.option.attributes.beginDate.toLocaleDateString("de-DE",
			{
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
			}) }}</span> - <span v-if="props?.option?.attributes.endDate">
							{{
		props.option.attributes.endDate.toLocaleDateString("de-DE",
			{
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
			}) }}</span>)
					</div>
				</div>
			</template>

			<template #empty>Keine Ergebnisse gefunden.</template>
		</b-autocomplete>
	</b-field>
</template>

<script>

import { required, requiredIf } from "vuelidate/lib/validators";

export default {
	name: "InstantMedicationSearchableSelect",
	props: [
		"editObject",
		"select",
		"dataOptions",
		"enableValidation",
		"validationMessage",
		"labelText",
		"enableOrder",
	],
	data() {
		return {
			inputString: '',
			selectedValue: undefined
		}
	},
	validations: {
		selectedValue: {
			required: requiredIf(function (enableValidation) {
				return enableValidation
			}),
		},
	},
	mounted() { },
	computed: {
		orderedFilteredDataArray() {
			if (this.enableOrder) {
				let sortedArray = this.filteredDataArray
				return sortedArray?.sort((a, b) =>
					(a.attributes.medication.attributes.displayName.toLowerCase() > b.attributes.medication.attributes.displayName.toLowerCase()) ? 1
						: ((b.attributes.medication.attributes.displayName.toLowerCase() > a.attributes.medication.attributes.displayName.toLowerCase()) ? -1 : 0));
			}
			else {
				return this.filteredDataArray
			}
		},
		filteredDataArray() {
			let returnVal = this.dataOptions?.filter((element) => {
				let searchString = element.attributes.medication.attributes.displayName.toLowerCase() + "(" + 
					(element.attributes.beginDate ? element.attributes.beginDate.toLocaleDateString("de-DE",
						{
							year: "numeric",
							month: "2-digit",
							day: "2-digit",
						}) : '') + " - " +
					(element.attributes.endDate ? element.attributes.endDate.toLocaleDateString("de-DE",
						{
							year: "numeric",
							month: "2-digit",
							day: "2-digit",
						}) : '') + ")"
				return searchString.indexOf((this.inputString.toLowerCase())) >= 0
			})
			return returnVal
		}
	},
	methods: {
		set(option) {
			if (option) {
				this.select(option.toPointer())
			}
			if (option == null){
				this.selectedValue = undefined
			}
		}
	},
	watch: {
		editObject(newValue) {
			if (newValue) {
				this.selectedValue = this.orderedFilteredDataArray.find((element) => this.editObject.objectId == element.id)
				this.inputString = this.selectedValue.attributes.medication.attributes.displayName + "(" + 
					(this.selectedValue.attributes.beginDate ? this.selectedValue.attributes.beginDate.toLocaleDateString("de-DE",
						{
							year: "numeric",
							month: "2-digit",
							day: "2-digit",
						}) : '') + " - " +
					(this.selectedValue.attributes.endDate ? this.selectedValue.attributes.endDate.toLocaleDateString("de-DE",
						{
							year: "numeric",
							month: "2-digit",
							day: "2-digit",
						}) : '') + ")"
			}
		},
	},
};
</script>

<style lang="scss">

.autocomplete_break.autocomplete .dropdown-item, 
.autocomplete_break.autocomplete .dropdown .dropdown-menu .has-link a
{
	white-space: wrap;
}

.autocomplete_break.autocomplete .dropdown-item .media-content span
{
	display:inline;
}

</style>