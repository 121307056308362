import imageCompression from 'browser-image-compression';

/*
function resizeImage(file, MAX_WIDTH, MAX_HEIGHT) {
	return new Promise((resolve) => {
		var img = document.createElement("img");
		img.src = window.URL.createObjectURL(file);

		img.onload = function () {
			var width = img.width;
			var height = img.height;

			if (width > height) {
				if (width > MAX_WIDTH) {
					height *= MAX_WIDTH / width;
					width = MAX_WIDTH;
				}
			} else {
				if (height > MAX_HEIGHT) {
					width *= MAX_HEIGHT / height;
					height = MAX_HEIGHT;
				}
			}
			var canvas = document.createElement("canvas");
			canvas.width = width;
			canvas.height = height;
			var ctx = canvas.getContext("2d");
			ctx.drawImage(img, 0, 0, width, height);

			var dataurl = canvas.toDataURL("image/jpeg");

			var base64 = dataurl.split("base64,")[1];
			resolve(base64);
		};
	});
}
*/

const blobToBase64 = blob => {
	const reader = new FileReader();
	reader.readAsDataURL(blob);
	return new Promise(resolve => {
		reader.onloadend = () => {
			resolve(reader.result);
		};
	});
};

async function resizeImage(file, MAX) {
	const options = {
		maxSizeMB: 1,
		maxWidthOrHeight: MAX,
		useWebWorker: true
	}
	const compressedFile = await imageCompression(file, options);
	
	var base64 = await blobToBase64(compressedFile)
	return base64;
}

export async function getModifiedImage(file, MAX) {
	var base64 = await resizeImage(file, MAX);
	return base64;
}

export const getClientPicture = ({ newProfilePicture, clientPicture, clientGender }) => {
	if (newProfilePicture) {
		return URL.createObjectURL(newProfilePicture);
	}
	if (clientPicture) {
		return clientPicture;
	}
	if (clientGender === "weiblich") {
		return require("../assets/client/client_female.svg");
	}
	else if (clientGender === "männlich") {
		return require("../assets/client/client_male.svg");
	} else {
		return require("../assets/client/client_diverse.svg");
	}
}

export const getUserPicture = ({ newProfilePicture, userPicture, userGender }) => {
	if (newProfilePicture) {
		return URL.createObjectURL(newProfilePicture);
	}
	if (userPicture) {
		return userPicture;
	}
	if (userGender === "weiblich") {
		return require("../assets/user/user_female.svg");
	}
	else if (userGender === "männlich") {
		return require("../assets/user/user_male.svg");
	} else {
		return require("../assets/user/user_diverse.svg");
	}
}