<template>
	<b-modal v-model="isActive" has-modal-card @close="closeModal()" :can-cancel="false">
		<div class="modal-card modalCardContainer"
			style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%);">
			<header class="modal-card-head" style="background-color: rgba(40, 40, 40, 1); color: white">
				Erfassung Bedarfsmedikation für den {{ logObject.attributes.date.toLocaleDateString("de-DE",
					{
						year: "numeric",
						month: "2-digit",
						day: "2-digit",
					}) }}
			</header>
			<section class="modal-card-body" style="padding: 0.5em; color: #313131">
				<InstantMedicationSearchableSelect :data-options="filteredMedication" :editObject="medicationInstantMedication"
				:enable-validation="true" validationMessage="Bitte wählen Sie ein Bedarfsmedikament aus!" label-text="Bedarfsmedikament"
				:enable-order="true" :select="(object) => (medicationInstantMedication = object)" />
				<div class="is-flex is-flex-direction-column">
					<b-field expanded>
						<template #label>
							<div class="align-content-center">
								<p>Verabreichungszeitraum</p>
							</div>
						</template>
						<div class="block mb-1 mt-2">
							<b-radio v-model="time" name="time" class="mr-4" native-value="Morgens">
								Morgens
							</b-radio>
							<b-radio v-model="time" name="time" class="mr-4" native-value="Mittags">
								Mittags
							</b-radio>
							<b-radio v-model="time" name="time" native-value="Abends">
								Abends
							</b-radio>
							<b-radio v-model="time" name="time" native-value="Nachts">
								Nachts
							</b-radio>
						</div>
					</b-field>
					<b-field expanded :message="($v.amount.$invalid) ? 'Bitte geben Sie die verabreichte Menge ein!' : ''"
						:type="($v.amount.$invalid) ? 'is-danger' : 'is-success'">
						<template #label>
							<div class="align-content-center" style="display: inline-flex">
								<p>Verabreichte Menge</p>
							</div>
						</template>
						<b-input type="text" v-model="amount" />
					</b-field>
					<UserSearchableSelect :data-options="filteredUsers" :editObject="medicationContactUser"
						:enable-validation="true" validationMessage="Bitte wählen Sie einen Verantwortlichen aus!"
						label-text="Verabreicht von" :enable-order="true"
						:select="(object) => (medicationContactUser = object)" />
					<div>
						<b-field expanded>
							<template #label>
								<div class="align-content-center" style="display: inline-flex">
									<p>Anmerkungen</p>
								</div>
							</template>
							<b-input type="text" v-model="hintOptional" />
						</b-field>
					</div>
					<div class="mt-2">
						<b-field expanded>
							<template #label>
								<div class="align-content-center" style="display: inline-flex">
									<p>Bestand vor Verabreichung</p>
								</div>
							</template>
							{{ String(amountOfInstantMedicationBefore).replace(".", ",") }} {{
								medicationInstantMedicationObject ?
								medicationInstantMedicationObject.attributes.medication.attributes.medicationUnit.attributes.symbol
								:
								"" }}
						</b-field>
					</div>
					<div class="mt-2">
						<b-field expanded>
							<template #label>
								<div class="align-content-center" style="display: inline-flex">
									<p>Bestand nach Verabreichung</p>
								</div>
							</template>
							{{ String(amountOfInstantMedicationAfter).replace(".", ",") }} {{
								medicationInstantMedicationObject ?
								medicationInstantMedicationObject.attributes.medication.attributes.medicationUnit.attributes.symbol
								:
								"" }}
						</b-field>
					</div>

				</div>
			</section>
			<footer class="modal-card-foot footerContainer" style=" color: white">
				<b-button @click="closeModal()" type="is-primary">
					Abbrechen
				</b-button>
				<b-button @click="saveEditInstantMedication()" type="is-primary" v-bind:loading="isLoading"
					v-bind:disabled="$v.medicationContactUser.$invalid || $v.medicationInstantMedication.$invalid || $v.amount.$invalid">
					Speichern
				</b-button>
			</footer>
		</div>
	</b-modal>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import { negativeFeedBack, positiveFeedBack } from "@/lib/Toast";
import Parse from "parse/dist/parse.min.js";
import UserSearchableSelect from "@/components/helper/SearchableSelects/UserSearchableSelect.vue";
import InstantMedicationSearchableSelect from "@/components/helper/SearchableSelects/InstantMedicationSearchableSelect.vue";

export default {
	name: "EditInstantMedicationModal",
	components: {
		UserSearchableSelect,
		InstantMedicationSearchableSelect,
	},
	props: ["value", "logObject"],
	data() {
		return {
			isLoading: false,
			medicationContactUser: Parse.User.current().toPointer(),
			hintOptional: "",
			medicationInstantMedication: undefined,
			medicationInstantMedicationObject: undefined,
			medication: undefined,
			filteredMedication: undefined,
			time: "Morgens",
			amount: 0
		};
	},
	validations: {
		medicationContactUser: {
			required,
		},
		medicationInstantMedication: {
			required,
		},
		amount: {
			validator: function (value) {
				if (value) {
					let isValidTimePlan = true;
					let count_positiv_values = 0;
					let count_invalid_values = 0;
					let numberValue = String(value);

					if (numberValue.includes(".")) {
						isValidTimePlan = false;
					}

					numberValue = numberValue.replace(",", ".")

					numberValue = Number(numberValue);

					if (isNaN(numberValue)) {
						isValidTimePlan = false;
					}

					if (numberValue > 0) {
						count_positiv_values++;
					}

					if (isNaN(parseFloat(numberValue))) {
						count_invalid_values++;
					}

					return count_positiv_values > 0 && isValidTimePlan && count_invalid_values == 0;
				} else {
					return false;
				}

			}
		}
	},
	watch: {
		medication() {
			let filteredData = []
			let akt_date = new Date();
			this.medication.forEach(element => {
				if (!element.attributes.expirationDate || akt_date < element.attributes.expirationDate) {
					// Kein Begin- und Enddate
					if (!element.attributes.beginDate && !element.attributes.endDate) {
						filteredData.push(element)
					}
					//Wenn beides gesetzt ist, prüfe ob "heute" dazwischen liegt
					if (element.attributes.beginDate && element.attributes.endDate) {
						if (akt_date > element.attributes.beginDate && akt_date < element.attributes.endDate) {
							filteredData.push(element)
						}
					}
					//Enddatum ist nicht gesetzt, aber das Begindatum liegt vor "heute"
					if (element.attributes.beginDate && !element.attributes.endDate) {
						if (akt_date > element.attributes.beginDate) {
							filteredData.push(element)
						}
					}
					//Enddatum ist nicht getzt, aber das Begindatum liegt vor "heute"
					if (!element.attributes.beginDate && element.attributes.endDate) {
						if (akt_date < element.attributes.endDate) {
							filteredData.push(element)
						}
					}
				}
			});
			// Wenn das bereits ausgewälte Medikament nicht aufgezählt wird, weil es mittlerweile ausgelaufen ist, wird es hier wieder hinzugefügt
			if (!filteredData.some(element => element.id == this.logObject.attributes.instantMedication.id)) {
				filteredData.push(this.logObject.attributes.instantMedication)
			}

			this.filteredMedication = filteredData
		}
	},
	async mounted() {
		let dataQuery = new Parse.Query('MedicationInstantMedication');
		dataQuery.include("medication")
		dataQuery.notEqualTo("deleted", true);
		await dataQuery.find().then(response => this.medication = response);
		this.medicationContactUser = this.logObject.attributes.givingUser.toPointer();
		this.time = this.logObject.attributes.time
		this.amount = (this.logObject.attributes.amount).replace(".", ",");
		this.medicationInstantMedication = this.logObject.attributes.instantMedication.toPointer();
		this.medicationInstantMedicationObject = this.logObject.attributes.instantMedication;
		this.hintOptional = this.logObject.attributes.hint ? this.logObject.attributes.hint : ""
	},
	computed: {
		...mapGetters({
			users: "users/getFilteredUsers",
			focusedClient: "clients/getFocusedClient",
			instantMedicationLogs: "medicationLogEntries/getInstantMedicationLogs"
		}),
		isActive: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit("input", val);
			},
		},
		filteredUsers() {
			let filteredClients = []
			if (this.users) {
				this.users.forEach(element => {
					if (element.attributes.dependency.attributes.first_name ||
						element.attributes.dependency.attributes.last_name)
						filteredClients.push(element)
				});
			}
			return filteredClients
		},
		amountOfInstantMedicationBefore() {
			let medicationInstantMedicationPointer = this.medicationInstantMedication

			this.filteredMedication?.forEach(element => {
				if (medicationInstantMedicationPointer?.objectId == element.id) {
					return this.medicationInstantMedicationObject = element
				}
			});

			let retValue = this.medicationInstantMedicationObject?.attributes?.medication?.attributes?.max;

			let filteredLogs = []
			this.instantMedicationLogs.forEach(element => {
				if (this.medicationInstantMedicationObject?.id == element.attributes?.instantMedication?.id && element?.id != this.logObject?.id) {
					filteredLogs.push(element)
				}
			});

			filteredLogs.forEach(element => {
				retValue = retValue - element.attributes.amount
			});
			retValue = Math.round(retValue * 1000) / 1000
			return retValue;
		},
		amountOfInstantMedicationAfter() {
			let retValue = 0;
			let beforeValue = this.amountOfInstantMedicationBefore
			if (beforeValue >= 0) {
				let amount = this.amount.replace(",", ".");
				retValue = beforeValue - amount
				retValue = Math.round(retValue * 1000) / 1000
			}
			return retValue
		},
	},
	methods: {
		closeModal() {
			this.hintOptional = "";
			this.medicationContactUser = undefined;
			this.isActive = false;
		},
		async saveEditInstantMedication() {
			this.isLoading = true;

			const dataObject = this.logObject

			let amount = this.amount.replace(",", ".");

			dataObject.set("instantMedication", this.medicationInstantMedication);
			dataObject.set("time", this.time);
			dataObject.set("amount", amount);
			dataObject.set("state", 1);
			dataObject.set("hint", this.hintOptional)
			dataObject.set("givingUser", this.medicationContactUser)
			dataObject.set("client", this.focusedClient)
			await dataObject.save()
			this.isLoading = false;
			this.closeModal()
		},
	}
}

</script>



<style scoped></style>
  