var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.attributeConfigs)?_c('div',{staticStyle:{"width":"100%"}},_vm._l((_vm.$v.entryRequestAttributes.$each.$iter),function(v,index){return _c('div',{key:index},[(_vm.attributeConfigs[index].attributes.entryAttributes)?[_c('h3',{staticClass:"attribute_titel mb-3 mt-3"},[_vm._v(" "+_vm._s(_vm.attributeConfigs[index].attributes.name)+" ")]),_c('div',{staticClass:"columns is-flex is-flex-wrap-wrap is-justify-content-flex-start",staticStyle:{"width":"100%"}},[(
                        _vm.attributeConfigs[index].attributes.isSingleSelection
                    )?_c('div',_vm._l((_vm.attributeConfigs[
                            index
                        ].attributes.entryAttributes.filter(
                            (att) => att.attributes.deleted !== true
                        )),function(entryAttribute){return _c('b-radio',{key:entryAttribute.id,staticClass:"column is-flex-grow-0",staticStyle:{"flex-basis":"initial"},attrs:{"native-value":entryAttribute},on:{"input":_vm.$v.entryRequestAttributes.$touch},model:{value:(_vm.entryRequestAttributes[index]),callback:function ($$v) {_vm.$set(_vm.entryRequestAttributes, index, $$v)},expression:"entryRequestAttributes[index]"}},[_vm._v(" "+_vm._s(entryAttribute.attributes.name)+" ")])}),1):_c('div',_vm._l((_vm.attributeConfigs[
                            index
                        ].attributes.entryAttributes.filter(
                            (att) => att.attributes.deleted !== true
                        )),function(entryAttribute){return _c('b-checkbox',{key:entryAttribute.id,staticClass:"column is-flex-grow-0",staticStyle:{"flex-basis":"initial"},attrs:{"native-value":entryAttribute},on:{"input":_vm.$v.entryRequestAttributes.$touch},model:{value:(_vm.entryRequestAttributes[index]),callback:function ($$v) {_vm.$set(_vm.entryRequestAttributes, index, $$v)},expression:"entryRequestAttributes[index]"}},[_vm._v(" "+_vm._s(entryAttribute.attributes.name)+" ")])}),1)]),(!v.required)?_c('div',{staticClass:"tag is-danger is-light padded dangerTag"},[_vm._v(" "+_vm._s(_vm.attributeConfigs[index].attributes.name)+" benötigt! ")]):_vm._e()]:_vm._e()],2)}),0):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }