<template>
	<div class="pt-3">
			<div v-for="(refComment, index) in previousComments" :key="refComment.id">
				<div style="position: relative">
					<CommentDetail :comment="refComment" :key="comment.id"
								:style="getRefCommentStyles(index)"/>
					<div
						v-on:click="reply(refComment)"
						style="position: absolute; bottom: 0; right: 0"
					>
						<b-icon style="cursor: pointer" icon="reply"></b-icon>
					</div>
				</div>
			</div>
	</div>
</template>

<script>
import CommentDetail from "@/components/helper/CommentDetail";
import {mapGetters} from "vuex";
import {v4 as uuid} from "uuid";

export default {
	name: "CommentTreePrevious",
	components: {CommentDetail},
	props: ["comment", "reply", "padded"],
	computed: {
		...mapGetters({
			comments: "comments/getFilteredComments",
			isMobile: "isMobile"
		}),
		previousComments() {
			if (!this.comment) return []
			const previousComments = [this.comment]
			let refComment = this.comment.attributes.reference
			while (refComment !== undefined) {
				previousComments.unshift(refComment)
				refComment = refComment.attributes.reference

			}
			return previousComments;
		},
	},
	methods: {
		getRefCommentStyles(index) {
			
			let styles = "border-radius: .5rem; padding-top: .5rem;"
			styles = styles.concat(`padding-left: ${index * 3.5}rem !important;`)
			styles = styles.concat(`background-color: rgba(0,0,0,${.1 * (this.previousComments.length - 1 - index) / (this.previousComments.length - 1)});`)
			
			return styles;
		},
	}
}
</script>

<style scoped>

</style>
