import Parse from 'parse/dist/parse.min.js';
import { handleParseError } from '../../../errorhandling';
import router from '../../../router/router';
import store from "@/lib/store/store";

let localSubscription = undefined;
let focusedClient_temp = undefined;

async function createSubscription(store, clientId) {

	let client_uuid = "";

	let clientQuery = new Parse.Query('Client');
	clientQuery.equalTo("objectId", clientId);
	clientQuery.notEqualTo("deleted", true);
	await clientQuery.find().then(response => client_uuid = response[0].attributes.uuid);

	let all_clients = "";

	let clientQuery2 = new Parse.Query('Client');
	clientQuery2.equalTo("uuid", client_uuid);
	clientQuery2.notEqualTo("deleted", true);
	await clientQuery2.find().then(response => all_clients = response);

	store.dispatch("entrylinks/load", false)

	//Erste Query - alle nicht privaten Einträge holen
	let entryLinkQuery = new Parse.Query('EntryLink');

	entryLinkQuery.notEqualTo("deleted", true)
	entryLinkQuery.notEqualTo("isPrivate", true)
	entryLinkQuery.containedIn("client", all_clients)

	//Zweite Query - angemeldeten User bestimmen
	let user_id = store.getters["users/getUserId"];
	
	var userPointer = {
		__type: 'Pointer',
		className: '_User',
		objectId: user_id
	}

	//Zweite Query - Alle privaten Einträge, wo der angemeldete Nutzer der Autor ist, holen
	let entryLinkQuery2 = new Parse.Query('EntryLink');

	entryLinkQuery2.notEqualTo("deleted", true)
	entryLinkQuery2.equalTo("isPrivate", true)
	entryLinkQuery2.equalTo("author", userPointer)
	entryLinkQuery2.containedIn("client", all_clients)

	var mainEntryLinkQuery = Parse.Query.or(entryLinkQuery, entryLinkQuery2);
	mainEntryLinkQuery.descending("createdAt")
	mainEntryLinkQuery.include(["entry", "entry.author.dependency", "entry.otherFiles", "client", "client.group", "entry.attributes", "entry.attributes.attribute_config"])

	var results = undefined;

	try {
		//results = await entryLinkQuery.equalTo("client", clientPointer).find()
		results = await mainEntryLinkQuery.find()
	}
	catch (error) {
		handleParseError(error);

	}

	store.dispatch('entrylinks/set', results)
	store.dispatch("entrylinks/load", true)

	mainEntryLinkQuery.subscribe().then((subscription) => {

		localSubscription = subscription

		subscription.on('create', object => {

			store.dispatch('entrylinks/insert', object)
		});
		subscription.on('update', object => {

			store.dispatch('entrylinks/replace', object)
		});
		subscription.on('enter', object => {

			store.dispatch('entrylinks/insert', object)
		});
		subscription.on('leave', object => {

			store.dispatch('entrylinks/delete', object)
		});
		subscription.on('delete', object => {

			store.dispatch('entrylinks/delete', object)
		});
		subscription.on('close', () => {

		});
		subscription.on('error', error => {

		})
	})
}

export default function createEntryLinkSubscription() {
	return store => {
		store.subscribe(mutation => {
			if (mutation.type === 'clients/SET_FOCUSED_CLIENT_ID' && (router.currentRoute.name == "Diary" || router.currentRoute.name == "EntryDetail" ||
				router.currentRoute.name == "EntryInput" || router.currentRoute.name == "EntryEdit" || router.currentRoute.name == "Notifications" || router.currentRoute.name == "Charts")) {
				
				if (mutation.payload && (focusedClient_temp != mutation.payload)) {
					
					focusedClient_temp = mutation.payload;	
					if (localSubscription) {
						localSubscription.unsubscribe();
					}
					if(mutation.payload)
					{				
						createSubscription(store, mutation.payload)
					}
				}else
				{
					store.dispatch("entrylinks/load", true)
				}
				
			}
		}),
			store.subscribe(mutation => {
				if (mutation.type === 'ADD_LAST_ROUTE' && (router.currentRoute.name == "Diary" || router.currentRoute.name == "Notifications" || router.currentRoute.name == "Charts")) {
					
					if (focusedClient_temp != store.state.clients.focusedClientId) {
						
						focusedClient_temp = store.state.clients.focusedClientId;

						if (localSubscription) {
							localSubscription.unsubscribe();
						}
						if(store.state.clients.focusedClientId)
						{
							createSubscription(store, store.state.clients.focusedClientId)
						}
						
					}else
					{
						store.dispatch("entrylinks/load", true)
					}
				}
			})
	}
}
