<template>
    <p class="text-box" v-if="entryLink">
        {{ text }}
    </p>
	<div v-else>
			<div class="text-box skeleton-text skeleton"></div>
			<div class="text-box skeleton-text skeleton"></div>
	</div>
</template>

<script>
/**
 * Component for the display of the content in an entry.
 * @displayName EntryContent
 * isCharts is only for the skeleton
 */

export default {
    name: "EntryContent",
    props: ["entryLink"],
    data() {
        return {
            htmlRegex: /<\/?[^>]+(>|$)/g,
        };
    },
    computed: {
        text() {
            return this.entryLink.attributes.entry.attributes.text
                .replace(this.htmlRegex, " ")
                .replace(/&Uuml;/g, "Ü")
                .replace(/&uuml;/g, "ü")
                .replace(/&Ouml;/g, "Ö")
                .replace(/&ouml;/g, "ö")
                .replace(/&Auml;/g, "Ä")
                .replace(/&auml;/g, "ä")
                .replace(/&szlig;/g, "ß")
				.replace(/&gt;/g, ">")
				.replace(/&lt;/g, "<");
        },
    },
};
</script>

<style scoped>
.text-box {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
}
</style>
