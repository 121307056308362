var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"barContainer is-flex is-flex-direction-column"},[_c('div',{staticClass:"scrollDiv is-flex is-flex-direction-row is-justify-content-center is-align-content-center is-flex-wrap-wrap",staticStyle:{"height":"100%","overflow-y":"hidden"},style:(_vm.getScrollDivStyle())},_vm._l((_vm.routeObjects),function(routeObject,index){return _c('div',{key:index,staticClass:"routeContainer",class:[
			{ disabledRoute: !routeObject.isActivated },
			{
				notificationRoute:
					routeObject.isNotification &&
					_vm.numberOfNotifications > 0,
			},
		],on:{"click":function($event){return _vm.route(routeObject)}}},[_c('div',{staticClass:"route m-1"},[(routeObject.isActivated)?[_c('b-button',{class:[
							{
								selectedRoute: _vm.selectedRoute(
									routeObject.route
								),
							},
							{
								disabledRouteIcon: !routeObject.isActivated,
							},
						],attrs:{"icon-left":routeObject.icon,"size":_vm.isMobile() ? 'is-small' : ''},on:{"click":_vm.unselectClient}},[_vm._v(_vm._s(routeObject.text))])]:[_c('b-button',{key:_vm.activeRoute,class:[
							{
								selectedRoute: _vm.selectedRoute(
									routeObject.route
								),
							},
							{ disabledRouteIcon: !routeObject.isActivated },
						],attrs:{"icon-left":routeObject.icon,"size":_vm.isMobile() ? 'is-small' : ''}},[_vm._v(_vm._s(routeObject.text))])]],2)])}),0),_c('div',{staticClass:"is-flex is-flex-direction-column is-justify-content-center is-align-content-center m-1 text-center"},[_c('figure',{staticClass:"mb-5 is-align-self-center is-flex is-flex-direction-column is-justify-content-center",staticStyle:{"width":"150px"}},[_c('small',{staticClass:"is-align-self-center",staticStyle:{"font-size":"10px","color":"#313131","margin-bottom":"-5px"}},[_c('b',[_vm._v(_vm._s(_vm.applicationVersion))])]),_c('img',{attrs:{"src":require('../../assets/logo/' + this.app_logo_copyright)}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }