<template>
	<div class="scrollable" style="height: 100%; width: 100%">
		<div style="height: 100%; position: relative" v-if="isLoaded && focusedClient">
			<b-tabs v-model="isMedicationReiter" class="medicationtabs" type="is-toggle" expanded>
				<b-tab-item label="Medikationslog">
					<MedicationLogEntries />
				</b-tab-item>
				<b-tab-item label="Medikamente">
					<MedicationEntries />
				</b-tab-item>
			</b-tabs>


		</div>
		<div v-if="!isLoaded && focusedClient" style="height: 100%">
			<DiarySkeleton />
		</div>
		<div v-if="!focusedClient" style="text-align: center">
			Kein Klient ausgewählt!
		</div>

	</div>
</template>

<script>
/**
 * Module displaying the entries associated with the focusedClient.
 * Entries are represented with a router-link which routes the view to the EntryDetail.
 * @displayName Diary
 */

import { mapGetters } from "vuex";
import DiarySkeleton from "@/components/modules/diary/DiarySkeleton";
// import DiaryEntries from "@/components/modules/diary/DiaryEntries";
import MedicationEntries from "@/components/modules/medication/MedicationEntries";
import MedicationLogEntries from "@/components/modules/medication/MedicationLogEntries";

export default {
	name: "MedicationEntryPanel",
	components: {
		MedicationEntries,
		MedicationLogEntries,
		DiarySkeleton
	},
	data() {
		return {

		};
	},
	mounted() {
		
	},
	destroyed() {
		this.$store.dispatch("setEntrySearchQuery", '');
	},
	computed: {
		...mapGetters({
			focusedClient: "clients/getFocusedClient",
			isLoaded: "entrylinks/getLoaded",
			// isMedicationReiter: "medicationEntries/getFocusedMedicationReiter",
		}),
		isMedicationReiter: {
			get: function () {
				return this.$store.getters["medicationEntries/getFocusedMedicationReiter"]
			},
			set: function (val) {
				this.$store.dispatch(
					"medicationEntries/setFocusedMedicationReiter",
					val
				);
			}
		}
	},
	methods: {

	},
	watch: {
		focusedClient(newData) {
			if (!newData) {
				this.$store.dispatch("setEntrySearchQuery", '');
			}
		}

	},
};
</script>

<style lang="scss">
.medicationtabs .tabs.is-toggle li.is-active a,
.medicationtabs.b-tabs .tabs.is-toggle li a:focus,
.medicationtabs.b-tabs .tabs.is-toggle li a:focus {
	background-color: #313131;
	border-color: #313131;
}

.medicationtabs .tabs.is-toggle ul {
	padding-left: 8px;
	padding-right: 8px;
}

.medicationtabs .b-tabs .tabs {
	height: auto;
}

.medicationtabs.b-tabs .tab-content {
	padding: 0px;
}

.medicationtabs .tabs.is-toggle li:last-child a {
	border-top-right-radius: 0.5rem;
	border-bottom-right-radius: 0.5rem;

}

.medicationtabs .tabs.is-toggle li:first-child a {
	border-top-left-radius: 0.5rem;
	border-bottom-left-radius: 0.5rem;

}

.medicationtabs nav {
	height: auto;
}

@media (max-width: 769px) {
	.medicationtabs nav {
		margin-top: 10px;
	}
}
</style>

<style lang="scss" scoped>


.white-text {
	color: white;
}

.entry-box {
	margin-right: 0.5em;
	margin-left: 0.5em;
	cursor: pointer;
	padding-bottom: 15px;
}

.entry-box:hover {
	background-color: whitesmoke;
}

.scrollable {
	overflow: auto;
	height: 100%;
	width: 100%;
	padding-bottom: 1.5rem;
}

.article {
	width: 100%;
	border-radius: 0.5rem;
}

.entry-box-entry {
	margin-right: 0.5em;
	margin-left: 0.5em;
	padding-bottom: 15px;
	text-align: center;
}

@media (max-width: 1026px) {
	.scrollable {
		overflow: auto;
		height: 100%;
		width: 100%;
	}
}

@media (max-width: 769px) {

	.tabs:not(:last-child),
	.pagination:not(:last-child),
	.message:not(:last-child),
	.level:not(:last-child),
	.breadcrumb:not(:last-child),
	.block:not(:last-child),
	.title:not(:last-child),
	.subtitle:not(:last-child),
	.table-container:not(:last-child),
	.table:not(:last-child),
	.progress:not(:last-child),
	.progress-wrapper.is-not-native:not(:last-child),
	.notification:not(:last-child),
	.content:not(:last-child),
	.box:not(:last-child) {
		margin-bottom: 0;
	}

	.scrollable {
		height: 100%;
		width: 95vw;
	}

	.entry-box {
		display: flex;
		width: 96%;
	}
}

@media (max-width: 400px) {
	div.media-left {
		margin: 0.1em 0 0 0;
	}
}

@media (max-width: 450px) {
	.authorTagContainer {
		display: flex;
		flex-direction: row;
		align-items: start;
	}

	.iconContainer {
		justify-content: start !important;
		display: flex !important;
		flex-direction: row !important;
	}
}
</style>
