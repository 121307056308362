import Parse from 'parse/dist/parse.min.js';
import { handleParseError } from '../../../errorhandling';
import router from '../../../router/router';

let localSubscription = undefined;

function createSubscription(store) {
	let dataQuery = new Parse.Query('MedicationClass');
	dataQuery.find()
		.then((results) => {
			store.dispatch('medicationClass/set', results)
		})
		.catch((error) => {
			handleParseError(error);

		});
	dataQuery.subscribe().then((subscription) => {
		localSubscription = subscription;

		subscription.on('create', object => {

			store.dispatch('medicationClass/insert', object)
		});
		subscription.on('update', object => {

			store.dispatch('medicationClass/replace', object)
		});
		subscription.on('enter', object => {

			store.dispatch('medicationClass/insert', object)
		});
		subscription.on('leave', object => {

			store.dispatch('medicationClass/delete', object)
		});
		subscription.on('delete', object => {

			store.dispatch('medicationClass/delete', object)
		});
		subscription.on('close', () => {

		});
		subscription.on('error', error => {

		})
	})
}

export default function createMedicationClassSubscription() {
	return store => {
		store.subscribe(mutation => {
			//1: Check - Wird die richtige Mutation ausgelöst? Diese wird im Router bei jedem Routing-Vorgang ausgelöst.
			if (mutation.type === 'ADD_LAST_ROUTE') {
				//2: Check - Bin ich aktuell in der Medikation?
				if (router.currentRoute.name == "Medication" || router.currentRoute.name == "MedicationEntry") {
					//3: Check - Prüfe, ob bereits eine lokale Subscription vorliegt.
					if (!localSubscription) {
						createSubscription(store)
					}
				}
			}
		})
	}
}

