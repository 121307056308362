<template>
    <div class="hero">
        <div
            class="
                hero-head
                is-flex is-justify-content-center is-align-content-center
            "
        >
            <b-button @click="routeBack()" class="m-1" icon-left="chevron-left">
                Zurück
            </b-button>
        </div>
        <div class="hero-body container scrollable content" v-html="this.data_protection[this.app_data_protection]">
            
        </div>
    </div>
</template>

<script>

import { APP_DATA_PROTECTION } from "@/configuration/AppConfigurations";
import DATA_PROTECTION from '../../../assets/texts/data_protection.json'

export default {
    name: "DataProtection",
    data() {
        return {
			data_protection : DATA_PROTECTION,
			app_data_protection : APP_DATA_PROTECTION
		};
    },
    computed: {},
    methods: {
        async routeBack() {
            return await this.$router.back();
        },
    },
};
</script>

<style scoped>
.scrollable {
    overflow: auto;
    height: 93%;
}
</style>