<template>
	<div style="height: 100%" class="pb-4">
		<template v-if="clientId">
			<div style="height: 100%; padding: 0; margin: 0" class="scrollable mt-1">
				<div class="is-flex is-justify-content-center" v-if="!hasNotifications">Keine Benachrichtigungen</div>

				<div v-if="entryNotificationsFiltered &&
					entryNotificationsFiltered.length > 0
					" style="background-color:transparent;" class="entry-box-entry p-0 box mb-1 mt-1 ">
					<div class="has-text-centered article has-darker-grey-color white-text p-3">
						Neue Einträge
					</div>
				</div>
				<NotificationItems :notifications="entryNotificationsFiltered" type="diary" />

				<div v-if="commentNotificationsFiltered &&
				commentNotificationsFiltered.length > 0
					" style="background-color:transparent;" class="entry-box-entry p-0 box mb-1 mt-1 ">
					<div class="has-text-centered article has-darker-grey-color white-text p-3">
						Neue Kommentare
					</div>
				</div>
				<NotificationItems :notifications="commentNotificationsFiltered" type="diary" />

				<div v-if="documentNotificationsFiltered &&
				documentNotificationsFiltered.length > 0
					" style="background-color:transparent;" class="entry-box-entry p-0 box mb-1 mt-1 ">
					<div class="has-text-centered article has-darker-grey-color white-text p-3">
						Neue Dokumente
					</div>
				</div>
				<NotificationItems :notifications="documentNotificationsFiltered" type="document" />
				<hr v-if="documentNotificationsFiltered &&
					documentNotificationsFiltered.length > 0
					" />
			</div>
		</template>
		<template v-else>
			<div ref="entryPanel" class="scrollable is-flex is-flex-direction-column">
				<article class="p-3 article" style="text-align: center">
					Kein Klient ausgewählt!
				</article>
			</div>
		</template>
	</div>
</template>

<script>
/**
 * Module displaying the notification categories.
 * @displayName Notification
 */

import { mapGetters } from "vuex";
import NotificationItems from "@/components/helper/NotificationItems";

export default {
	name: "Notifications",
	computed: {
		hasNotifications() {
			if (this.entryNotificationsFiltered !== undefined && this.entryNotificationsFiltered.length > 0) return true
			if (this.commentNotificationsFiltered !== undefined && this.commentNotificationsFiltered.length > 0) return true
			if (this.documentNotificationsFiltered !== undefined && this.documentNotificationsFiltered.length > 0) return true
			return false
		},
		...mapGetters({
			clients: "clients/getFilteredClients",
			numberOfNotification: "notifications/getNumberOfNotifications",
			clientId: "clients/getFocusedClientId",
			entryNotificationsFiltered: "notifications/getFilteredEntryNotifications",
			commentNotificationsFiltered: "notifications/getFilteredCommentNotifications",
			areEntryLinksLoaded: "entrylinks/areEntryLinksLoaded",
			isAuthorLoaded: "users/isAuthorLoaded",
			isAuthorMetaClassLoaded: "usermetas/isAuthorMetaClassLoaded",
			documentNotificationsFiltered: "notifications/getFilteredDocumentNotifications",
		}),
	},
	components: {
		NotificationItems,
	},
	methods: {
		setIsActive() { },
	},
};
</script>

<style scoped>
.scrollable {
	overflow: auto;
	height: 80%;
}

.entry-box-entry {
	margin-right: 0.5em;
	margin-left: 0.5em;
	padding-bottom: 15px;
	text-align: center;
}

.article {
	width: 100%;
	border-radius: 0.5rem;
}
</style>
