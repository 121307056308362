<template>
	<div style="user-select: none" :style="this.$store.state.openedKeyBoard ? 'display:none' : 'width: 100%'
		" class="
            is-flex
            is-flex-direction-row
            is-align-items-center
            is-align-content-center
            is-justify-content-space-between
        ">
		<template v-if="clientId">
			<div class="is-flex is-flex-direction-row is-align-items-center">
				<div @click="routeBack()" class="mx-1">
					<b-icon style="cursor: pointer" size="is-medium" icon="chevron-left">
					</b-icon>
				</div>

				<div @click="isClientEditModalActive = true" style="cursor: pointer"
					class="is-flex is-flex-direction-row is-align-items-center">
					<ClientFigure :client="client" />
					<ClientGroupAndName :client="client" :topBar="true" />
				</div>
			</div>
			<div
				class="is-flex is-flex-direction-row is-align-items-center is-align-content-center is-justify-content-space-between">
				<div v-if="diaryDetailView">
					<b-button style="width:40px; background-color:transparent; color:white; outline:none; box-shadow:none;"
						class="p-0 mr-3" :size="isMobile ? 'is-medium' : 'is-big'" icon-left="download" @click="printDiary"
						type="is-text" id="printButton">
					</b-button>
				</div>
				<router-link :to="{ name: 'EntryEdit' }" tag="div" :style="this.$route.name == 'EntryDetail' &&
					focusedEntry &&
					currentUserId == focusedEntry.attributes.author.id
					? 'display: flex'
					: 'display: none!important'
					">
					<b-icon style="cursor: pointer" :size="isMobile ? 'is-small' : ''" icon="pencil" class="p-1 pr-5">
					</b-icon>
				</router-link>
			</div>
			<div v-if="diaryView || notificationView" class="is-flex is-flex-direction-row is-align-items-center">
				<b-input :placeholder="notificationView ? 'Suchen...' : 'Einträge suchen...'
					" icon="magnify" :class="notificationView ? 'pr-1' : ''" :size="mobile ? 'is-small' : ''" v-model="searchQuery"
					autocomplete="off" class="searchQuery" expanded :lazy="true" icon-right="close-circle"
					icon-right-clickable @icon-right-click="searchQuery = ''">
					></b-input>
				<router-link :to="{ name: 'EntryInput' }" tag="div" class="plusIcon" v-if="diaryView">
					<b-icon style="cursor: pointer" size="is-medium" icon="plus" class="mx-2">
					</b-icon>
				</router-link>
			</div>
			<div v-if="goalView" class="is-flex is-flex-direction-row is-align-items-center">
				<router-link :to="{ name: 'GoalInput' }" tag="div" class="plusIcon" v-if="goalView">
					<b-icon style="cursor: pointer" size="is-medium" icon="plus" class="mx-2">
					</b-icon>
				</router-link>
			</div>
			<div v-if="goalDetailView" class="is-flex is-flex-direction-row is-align-items-center">
				<router-link :to="{ name: 'GoalRating', params: { newVal: true } }" tag="div" class="plusIcon"
					v-if="goalDetailView">
					<b-icon style="cursor: pointer" size="is-medium" icon="plus" class="mx-2">
					</b-icon>
				</router-link>
			</div>
			<div v-if="medicationView" class="is-flex is-flex-direction-row is-align-items-center">
				<!-- 
					<b-button	style="width:40px; background-color:transparent; color:white; outline:none; box-shadow:none;" 
					class="p-0 mr-3" :size="isMobile ? 'is-small' : 'is-medium'" icon-left="printer" @click="printLogData"					
					type="is-text" id="printButton">
					</b-button> 
				-->
				<div @click="openMedicationEntry('new')" tag="div" class="plusIcon" v-if="medicationView">
					<b-icon style="cursor: pointer" size="is-medium" icon="plus" class="mx-2">
					</b-icon>
				</div>
			</div>
			<div v-if="medicationEntry" class="is-flex is-flex-direction-row is-align-items-center">
				<div @click="openMedicationEntry('edit')" tag="div" class="editIcon mr-3">
					<b-icon style="cursor: pointer" :size="isMobile ? 'is-small' : ''" icon="pencil" class="p-1 pr-5">
					</b-icon>
				</div>
			</div>
		</template>
		<client-modal v-model="isClientEditModalActive"></client-modal>
	</div>
</template>

<script>
/**
 * TopBar focused client section displaying the focused client.
 * @displayName ClientHeader
 */

import ClientModal from "@/components/modals/ClientModal";
import { mapGetters } from "vuex";
import { getClientPicture } from "@/lib/imageFunctions";
import { HEX2RGB } from "@/lib/Color";
import ClientFigure from "@/components/bars/sideBar/ClientFigure";
import ClientGroupAndName from "@/components/bars/sideBar/ClientGroupAndName";
import router from "../../../lib/router/router";

export default {
	name: "ClientHeader",
	components: {
		ClientGroupAndName,
		ClientFigure,
		ClientModal,
	},
	data() {
		return {
			isClientEditModalActive: false,
			isRecordFilterModalActive: false,
		};
	},
	watch: {
		client() {
			this.searchQuery = ""
			this.$store.dispatch("docfilter/clearFilterData");
		},
	},
	computed: {
		mobile() {
			return this.$store.getters.isMobile;
		},
		diaryView() {
			return this.$route.name === "Diary";
		},
		diaryDetailView() {
			return this.$route.name === "EntryDetail";
		},
		notificationView() {
			return this.$route.name === "Notifications";
		},
		goalView() {
			return this.$route.name === "Goal";
		},
		goalDetailView() {
			return this.$route.name === "GoalDetail";
		},
		medicationView() {
			return this.$route.name === "Medication";
		},
		medicationEntry() {
			return (this.$route.name === "MedicationEntry" && this.medicationEntryMask == "view");
		},
		user() {
			return this.$store.state.currentUser;
		},
		...mapGetters({
			clientId: "clients/getFocusedClientId",
			clientPicture: "clients/getFocusedClientPicture",
			clientName: "clients/getFocusedClientName",
			client: "clients/getFocusedClient",
			userProfilePicture: "usermetas/getUserProfilePicture",
			focusedEntry: "entrylinks/getFocusedEntry",
			focusedMedicationEntry: "medicationEntries/getFocusedMedicationEntry",
			medicationEntryMask: "medicationEntries/getFocusedMedicationEntryMask",
			currentUserId: "users/getUserId",
			notifications: "notifications/getEntryNotifications",
			isMobile: "isMobile",
		}),
		searchQuery: {
			set(searchQuery) {
				this.$store.dispatch("setEntrySearchQuery", searchQuery);
			},
			get() {
				// Or remove mapState and use this.$store.state.values.example
				return this.$store.state.entrySearchQuery;
			},
		},
		getPicture() {
			const pictureObject = {
				newProfilePicture: undefined,
				clientPicture: this.clientPicture,
				clientGender: this.client.attributes.gender,
			};
			return getClientPicture(pictureObject);
		},
	},
	methods: {
		printDiary() {
			this.$root.$emit('printDiaryEntry')
		},
		printLogData() {
			
		},
		async routeBack() {
			//console.log(this.$route.name);
			if (this.$route.name === "EntryDetail" ||
				// this.$route.name === "EntryEdit" ||
				/* INFO:
					das habe ich getrennt betrachtet, weil es nicht mit einem simplen Router-Back gelöst werden kann,
					weil wir dann einen inkonsistenten Zustand erreichen, vgl. Medikation
				*/
				this.$route.name === "ChartDetails" ||
				this.$route.name === "ClientRecordDetail" ||
				this.$route.name === "GoalInput" ||
				this.$route.name === "GoalDetail" ||
				this.$route.name === "GoalRating" ||
				this.$route.name === "GoalEdit" ||
				this.$route.name === "MedicationEntry" || 
				this.$route.name === "EntryInput"
			) {

				await this.$router.back();
			}
			else if (this.$route.name === "EntryEdit") {
				await this.$router.go(-2); // wir gehen zwei Mal zurück, zurück auf die View-Maske verursacht einen Fehler
			}
			else {
				// Full reset, aktuellen Klienten abwählen, 
				await this.$store.dispatch("clients/setFocusedClientId", undefined);
				await this.$store.dispatch("setSidePanelOpen", true);
			}
		},
		getColor(hex) {
			let number = 0;
			HEX2RGB(hex).forEach((num) => (number += num));
			if (number > 393) return "black";
			return "white";
		},

		openMedicationEntry(mask) {


			if (mask == "new") {

				this.$store.dispatch(
					"medicationEntries/setFocusedMedicationEntry",
					undefined
				);
				this.$store.dispatch(
					"medicationEntries/setFocusedMedicationEntryMask",
					'new'
				);
				this.$store.dispatch(
					"medicationEntries/setFocusedMedicationEntryId",
					undefined
				);

				this.$router.push({
					name: 'MedicationEntry',
				})
			} else {
				this.$store.dispatch(
					"medicationEntries/setFocusedMedicationEntryMask",
					mask
				);

			}
		}
	},
};
</script>

<style scoped>
::v-deep .mdi {
	color: whitesmoke;
}

::v-deep .input {
	border-radius: 6px;
}

.columns.is-gapless:not(:last-child) {
	margin-bottom: 0em;
}

@media (max-width: 769px) {
	.columns {
		display: flex;
		flex-direction: row;
	}

	div.mr-3 {
		margin-right: 0 !important;
	}

	::v-deep .has-icons-left>span {
		display: flex;
		align-items: start;
		justify-content: start;
		padding-left: 0.25rem;
	}
}

@media (max-width: 400px) {
	.searchQuery {
		max-width: 120px;
	}
}

::v-deep span.is-left {
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>
