<template>
    <div
        class="ml-2 readerContainer"
        v-if="
            readers &&
            readers.length > 0
        "
    >
        <b-tooltip label="Leser" position="is-bottom">
            <div
                style="
                    display: grid;
                    grid-template-columns: repeat(2, 0.1fr);
                    grid-template-areas: 'icon num';
                "
            >
                <div style="grid-area: icon">
                    <b-icon icon="account"></b-icon>
                </div>
                <div class="readNumber" style="grid-area: num">
                    {{
                        readers &&
                        readers.length
                    }}
                </div>
            </div>
        </b-tooltip>
    </div>
</template>

<script>
/**
 * Icon displaying the reader count of an entry.
 * @displayName ReaderIcon
 */

export default {
    name: "ReaderIcon",
    props: ["readers"],
};
</script>

<style scoped>
@media (max-width: 450px) {
    .ml-2 {
        margin-left: 0 !important;
        margin-right: 0.5rem !important;
    }
}
</style>
