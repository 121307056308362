<template>
	<div ref="entryPanel" v-if="entryLinks &&
		this.$store.state.usermetas.data.length > 0 &&
		this.$store.state.users.data.length > 0
		" style="user-select: none" class="scrollable is-flex is-flex-direction-column" @scroll="handleScroll"
		id="entryContainer">
		<div class="pr-1" @click="navigateEntryDetail(entryLink)" v-for="(entryLink, index) in searchedEntryLinks"
			:key="entryLink.id">
			<template v-if="((index < maxViewPortIndex && index >= maxViewPortIndex - 10) || entrySearchQuery.length > 0) &&
				entryLink.attributes.entry &&
				entryLink.attributes.entry.attributes.author
				">
				<div  :class="{ 'box_disabled' : !check_group_matching(entryLink) , 'box_private': check_is_private(entryLink) }" class="box entry-box p-0 mt-1 mb-1">
					<article class="media p-3 article">
						<div class="media-left">
							<AuthorImage :author="entryLink.attributes.entry.attributes.author
								" />
						</div>
						<div class="media-content" style="overflow: hidden">
							<div class="mb-0">
								<div class="mt-0 mb-0">
									<div class="
                                            pt-0
                                            authorTagContainer
                                            is-font-medium
                                        ">
										<AuthorNameAndCreationTime :object="entryLink.attributes.entry" :author-meta-class="entryLink.attributes.entry
											.attributes.author
											.attributes.dependency
											" />

										<ColoredTag v-if="check_is_private(entryLink)" class="mr-1"
											:tag-name="'Privat'"
											:tag-color="check_is_private(entryLink) ? '#7b2ec8' : '#313131'"
											:is-right="true" />
										<ColoredTag v-if="!check_group_matching(entryLink)" class="mr-1"
											:tag-name="entryLink.attributes.client.attributes.group.attributes.group_name"
											:tag-color="!check_group_matching(entryLink) ? '#808080' : '#313131'"
											:is-right="true" />

										<ColoredTag class="mr-1"
											:tag-name="entryLink.attributes.entry.attributes.type.attributes.name"
											:tag-color="entryLink.attributes.entry.attributes.type.attributes.color"
											:is-right="true" />
										<RatingTag :entry-link="entryLink" />
										<span class="mr-2" style="float:right; margin-top:1px; "
											v-if="entryLink.attributes.entry.attributes.entryCounter">
											Eintrag: {{ entryLink.attributes.entry.attributes.entryCounter }} </span>
									</div>
								</div>
								<EntryTitle :entry="entryLink.attributes.entry" />
								<EntryContent :entry-link="entryLink" />
								<br />
							</div>
							<div style="margin-top: -15px" class="
                                    is-flex is-justify-content-flex-end
                                    iconContainer
                                " v-if="hasInfo(entryLink)">
								<CommentIcon :number-of-comments="numberOfComments(
									entryLink.attributes.entry
								)
									" />
								<ClientsOnEntryInfo :entry="entryLink"
									:number="entryLink.attributes.entry.attributes.numberOfAssociatedClients" />
								<AttachementInfo :entry-link="entryLink" />
								<ReaderIcon :readers="entryLink.attributes.readers" />
							</div>
						</div>
					</article>
				</div>
			</template>
		</div>
		<b-pagination v-if="entrySearchQuery.length == 0" class="p-0 mt-1 mb-1 ml-1 mr-1 pagination-dark" :total="total"
			v-model="current" :range-before="rangeBefore" :range-after="rangeAfter" :order="order" :size="size"
			:simple="isSimple" :rounded="isRounded" :per-page="perPage" :icon-prev="prevIcon" :icon-next="nextIcon"
			aria-next-label="Next page" aria-previous-label="Previous page" aria-page-label="Page"
			aria-current-label="Current page" :page-input="hasInput" :page-input-position="inputPosition"
			:debounce-page-input="inputDebounce" @change="onPageChange(current)">
		</b-pagination>
		<div v-if="focusedClient && entryLinks">
			<div class="entry-box-entry p-0 box">
				<article class="p-3 article has-darker-grey-color white-text" v-if="focusedClient.attributes.entry">
					Zugang am
					{{
						focusedClient.attributes.entry.toLocaleDateString(
							"de-DE",
							{
								year: "numeric",
								month: "2-digit",
								day: "2-digit",
							}
						)
					}}
				</article>
				<article v-else class="
                        client-entry-box
                        p-3
                        article
                        has-darker-grey-color
                        white-text 
                    ">
					kein Zugangsdatum definiert
				</article>
			</div>
		</div>
	</div>
	<div v-else>
		<EntryInfo />
	</div>
</template>

<script>
import EntryTitle from "@/components/helper/EntryTitle";
import EntryInfo from "@/components/helper/EntryInfo";
import ReaderIcon from "@/components/icons/ReaderIcon";
import AttachementInfo from "@/components/icons/AttachementIcon";
import ClientsOnEntryInfo from "@/components/icons/ClientsOnEntryIcon";
import CommentIcon from "@/components/icons/CommentIcon";
import EntryContent from "@/components/helper/EntryContent";
import RatingTag from "@/components/tags/RatingTag";
import ColoredTag from "@/components/tags/ColoredTag";
import AuthorNameAndCreationTime from "@/components/helper/AuthorNameAndCreationTime";
import AuthorImage from "@/components/helper/AuthorImage";
import { mapGetters } from "vuex";

export default {
	name: "DiaryEntries",
	components: {
		EntryTitle,
		EntryInfo,
		ReaderIcon,
		AttachementInfo,
		ClientsOnEntryInfo,
		CommentIcon,
		EntryContent,
		RatingTag,
		ColoredTag,
		AuthorNameAndCreationTime,
		AuthorImage,
	},
	data() {
		return {
			isLoading: false,
			maxViewPortIndex: 10,
			viewPortIncreaseIteration: 1,
			scrollPosition: 0,
			total: 200,
			current: 1,
			perPage: 10,
			rangeBefore: 3,
			rangeAfter: 1,
			order: '',
			size: 'is-small',
			isSimple: false,
			isRounded: false,
			hasInput: false,
			prevIcon: 'chevron-left',
			nextIcon: 'chevron-right',
			inputPosition: '',
			inputDebounce: ''
		};
	},
	beforeCreate() { },
	mounted() {
		if (this.entryLinks) {
			this.total = this.entryLinks.length
		} else {
			this.total = 0;
		}

		if (this.page != undefined) {
			this.current = this.page
			this.onPageChange()
		}
	},
	updated() {
	},
	watch: {
		focusedClient() {
			this.maxViewPortIndex = 10
			this.scrollPosition = 0
			this.viewPortIncreaseIteration = 1
		},
		entryLinks(newValue) {
			this.maxViewPortIndex = 10
			this.scrollPosition = 0
			this.viewPortIncreaseIteration = 1
		}
	},
	computed: {
		...mapGetters({
			entryLinks: "entrylinks/getFilteredEntryLinks",
			numberOfComments: "comments/getNumberOfComments",
			focusedClient: "clients/getFocusedClient",
			entrySearchQuery: "getEntrySearchQuery",
			isLoaded: "entrylinks/getLoaded",
			page: "pagination/getPage"
		}),
		searchedEntryLinks() {
			this.calculate_search_parameter();
			if (this.entrySearchQuery.length > 0) {
				let searchString = ""
				if (this.entrySearchQuery.indexOf('Bewertung: ') > -1) {
					let temp = this.entrySearchQuery.split(" ")[1]
					searchString = "00" + temp
				} else if (this.entrySearchQuery.indexOf('Bewertung ') > -1) {
					let temp = this.entrySearchQuery.split(" ")[1]
					searchString = "00" + temp
				} else if (this.entrySearchQuery.indexOf('Bewertung:') > -1) {
					let temp = this.entrySearchQuery.split(":")[1]
					searchString = "00" + temp
				} else {
					searchString = this.entrySearchQuery
				}

				let searchStringSplit = searchString.split(" ");
				let results = [];

				if (this.entryLinks) {
					for (var i = 0; i < this.entryLinks.length; i++) {
						if (this.entryLinks[i].searchParameter) {
							let matching = 1;
							for (var j = 0; j < searchStringSplit.length; j++) {
								if (!this.entryLinks[i].searchParameter.toLowerCase().includes(searchStringSplit[j].toLowerCase())) {
									matching = 0;
								}
							}

							if (matching == 1) {
								results.push(this.entryLinks[i].id);
							}
						}
					}
				}
				return this.entryLinks.filter((elem) => results.indexOf(elem.id) > -1)
			}
			return this.entryLinks;
		}
	},
	methods: {
		calculate_search_parameter() {
			if (this.entryLinks) {
				for (var i = 0; i < this.entryLinks.length; i++) {
					let element = this.entryLinks[i];
					let entryText = element.attributes.entry.attributes.title;
					entryText += element.attributes.entry.attributes.text
						.replace(/<\/?[^>]+(>|$)/g, " ")
						.replace(/&Uuml;/g, "Ü")
						.replace(/&uuml;/g, "ü")
						.replace(/&Ouml;/g, "Ö")
						.replace(/&ouml;/g, "ö")
						.replace(/&Auml;/g, "Ä")
						.replace(/&auml;/g, "ä")
						.replace(/&szlig;/g, "ß");
					entryText += " ";
					element.attributes.entry.attributes.attributes.forEach(
						(attr) => {
							entryText += attr.attributes.name;
							entryText += " ";
						}
					);
					entryText += element.attributes.entry.attributes.entryCounter;
					entryText += " ";
					entryText += element.attributes.entry.attributes.type.attributes.name;
					entryText += " ";
					if (element.attributes.entry.attributes.author) {
						entryText += element.attributes.entry.attributes.author.attributes.dependency.attributes.first_name;
						entryText += " ";
						entryText += element.attributes.entry.attributes.author.attributes.dependency.attributes.last_name;
						entryText += " ";
					}
					let temp = element.attributes.rating ? element.attributes.rating : "neutral"
					entryText += "00" + temp
					entryText += " ";
					entryText += element.attributes.entry.createdAt.toLocaleDateString("de-DE", {
						year: "numeric",
						month: "2-digit",
						day: "2-digit",
					});

					this.entryLinks[i].searchParameter = entryText;
				}
			}

		},
		onPageChange() {
			// const entryContainer = document.getElementById("entryContainer")
			// this.scrollPosition = entryContainer.scrollTop
			this.maxViewPortIndex = 10 * this.current;
		},
		handleScroll(e) {
			// const entryContainer = document.getElementById("entryContainer")
			// this.scrollPosition = entryContainer.scrollTop
			// if(this.scrollPosition >= 800*this.viewPortIncreaseIteration && this.entrySearchQuery.length === 0){
			// this.maxViewPortIndex += 50
			// this.viewPortIncreaseIteration++
			// }
		},
		hasInfo(entryLink) {
			return (
				this.numberOfComments(entryLink.attributes.entry) > 0 ||
				entryLink.attributes.entry.attributes.attachment ||
				(entryLink.attributes.readers &&
					entryLink.attributes.readers.length > 0) ||
				entryLink.attributes.entry.attributes.numberOfAssociatedClients > 1
			);
		},
		//Wird nie gecalled - zumindest sehe ich nie einen Aufruf.
		matchesSearchQuery(entry) {
			if (this.entrySearchQuery.length > 0) {
				let entryText = entry.attributes.title;
				entry.attributes.attributes.forEach(
					(attr) => (entryText += attr.attributes.name)
				);
				entryText += entry.attributes.type.attributes.name;
				entryText +=
					entry.attributes.author.attributes.dependency.attributes
						.first_name;
				entryText += " ";
				entryText +=
					entry.attributes.author.attributes.dependency.attributes
						.last_name;
				entryText += entry.createdAt.toLocaleDateString("de-DE", {
					year: "numeric",
					month: "2-digit",
					day: "2-digit",
				});
				return entryText
					.toLowerCase()
					.includes(this.entrySearchQuery.toLowerCase());
			}
			return true;
		},
		navigateEntryDetail(entryLink) {
			this.$store.dispatch(
				"setFocusedEntry",
				entryLink.attributes.entry
			);
			this.$store.dispatch(
				"setFocusedEntryId",
				entryLink.attributes.entry.id
			);
			this.$store.dispatch(
				"entrylinks/setFocusedEntryLinkId",
				entryLink.id
			);
			this.$store.dispatch(
				"pagination/setPage",
				this.current
			);
			this.$router.push({
				name: 'EntryDetail',
				params: {
					focusedEntry: entryLink.attributes.entry,
					focusedEntryLink: entryLink
				}
			})
		},
		check_group_matching(entryLink) {
			return this.focusedClient.attributes.group == entryLink.attributes.client.attributes.group;
		},
		check_is_private(entryLink) {
			return entryLink.attributes.entry.attributes.isPrivate;
		}
	},
}
</script>

<style scoped>
.white-text {
	color: white;
}

.entry-box {
	margin-right: 0.5em;
	margin-left: 0.5em;
	cursor: pointer;
	padding-bottom: 15px;
}

.entry-box:hover {
	background-color: whitesmoke;
}

.entry-box.box_private {
	background-color: #dab5f9
}

.entry-box.box_disabled {
	background-color: #f5f5f5;
}

.entry-box.box_disabled.box_private {
	background-color: #c6bbd0;
}



.scrollable {
	overflow: auto;
	height: 100%;
	width: 100%;
	padding-bottom: 1.5rem;
}

.article {
	width: 100%;
	border-radius: 0.5rem;
}

.entry-box-entry {
	margin-right: 0.5em;
	margin-left: 0.5em;
	padding-bottom: 15px;
	text-align: center;
}

@media (max-width: 1026px) {
	.scrollable {
		overflow: auto;
		height: 100%;
		width: 100%;
	}
}

@media (max-width: 769px) {

	.tabs:not(:last-child),
	.pagination:not(:last-child),
	.message:not(:last-child),
	.level:not(:last-child),
	.breadcrumb:not(:last-child),
	.block:not(:last-child),
	.title:not(:last-child),
	.subtitle:not(:last-child),
	.table-container:not(:last-child),
	.table:not(:last-child),
	.progress:not(:last-child),
	.progress-wrapper.is-not-native:not(:last-child),
	.notification:not(:last-child),
	.content:not(:last-child),
	.box:not(:last-child) {
		margin-bottom: 0;
	}

	.scrollable {
		height: 100%;
		width: 95vw;
	}

	.entry-box {
		display: flex;
		width: 96%;
	}
}

@media (max-width: 400px) {
	div.media-left {
		margin: 0.1em 0 0 0;
	}
}

@media (max-width: 450px) {
	.authorTagContainer {
		display: flex;
		flex-direction: row;
		align-items: start;
	}

	.iconContainer {
		justify-content: start !important;
		display: flex !important;
		flex-direction: row !important;
	}
}
</style>
