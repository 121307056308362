var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pb-4",staticStyle:{"height":"100%"}},[(_vm.clientId)?[_c('div',{staticClass:"scrollable mt-1",staticStyle:{"height":"100%","padding":"0","margin":"0"}},[(!_vm.hasNotifications)?_c('div',{staticClass:"is-flex is-justify-content-center"},[_vm._v("Keine Benachrichtigungen")]):_vm._e(),(_vm.entryNotificationsFiltered &&
				_vm.entryNotificationsFiltered.length > 0
				)?_c('div',{staticClass:"entry-box-entry p-0 box mb-1 mt-1",staticStyle:{"background-color":"transparent"}},[_c('div',{staticClass:"has-text-centered article has-darker-grey-color white-text p-3"},[_vm._v(" Neue Einträge ")])]):_vm._e(),_c('NotificationItems',{attrs:{"notifications":_vm.entryNotificationsFiltered,"type":"diary"}}),(_vm.commentNotificationsFiltered &&
			_vm.commentNotificationsFiltered.length > 0
				)?_c('div',{staticClass:"entry-box-entry p-0 box mb-1 mt-1",staticStyle:{"background-color":"transparent"}},[_c('div',{staticClass:"has-text-centered article has-darker-grey-color white-text p-3"},[_vm._v(" Neue Kommentare ")])]):_vm._e(),_c('NotificationItems',{attrs:{"notifications":_vm.commentNotificationsFiltered,"type":"diary"}}),(_vm.documentNotificationsFiltered &&
			_vm.documentNotificationsFiltered.length > 0
				)?_c('div',{staticClass:"entry-box-entry p-0 box mb-1 mt-1",staticStyle:{"background-color":"transparent"}},[_c('div',{staticClass:"has-text-centered article has-darker-grey-color white-text p-3"},[_vm._v(" Neue Dokumente ")])]):_vm._e(),_c('NotificationItems',{attrs:{"notifications":_vm.documentNotificationsFiltered,"type":"document"}}),(_vm.documentNotificationsFiltered &&
				_vm.documentNotificationsFiltered.length > 0
				)?_c('hr'):_vm._e()],1)]:[_c('div',{ref:"entryPanel",staticClass:"scrollable is-flex is-flex-direction-column"},[_c('article',{staticClass:"p-3 article",staticStyle:{"text-align":"center"}},[_vm._v(" Kein Klient ausgewählt! ")])])]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }