<template>
	<b-modal v-model="isActive" has-modal-card @close="closeModal()" :can-cancel="false">
		<div class="modal-card modalCardContainer"
			style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%);">
			<header class="modal-card-head" style="background-color: rgba(40, 40, 40, 1); color: white">
				Neues Medikament anlegen
			</header>
			<section class="modal-card-body" style="padding: 0.5em; color: black">
				<div class="is-flex is-flex-direction-column">

					<b-field expanded :message="($v.medicationName.$invalid) ? 'Bitte geben Sie den Namen des Medikaments ein!' : ''"
						:type="($v.medicationName.$invalid) ? 'is-danger' : 'is-success'">
						<template #label>
							<div class="align-content-center" style="display: inline-flex">
								<p>Name</p>
							</div>
						</template>
						<b-input expanded type="text" v-model="medicationName" />
					</b-field>

					<b-field expanded>
						<template #label>
							<div class="align-content-center" style="display: inline-flex">
								<p>Wirkstoff</p>
							</div>
						</template>
						<b-input expanded type="text" v-model="medicationDrug" />
					</b-field>

					<b-field expanded
						:message="($v.medicationPotency.$invalid) ? 'Bitte geben Sie die Stärke des Medikaments ein!' : ''"
						:type="($v.medicationPotency.$invalid) ? 'is-danger' : 'is-success'">
						<template #label>
							<div expanded class="align-content-center" style="display: inline-flex">
								<p>Stärke</p>
							</div>
						</template>
						<b-input type="text" v-model="medicationPotency" />
					</b-field>

					<b-field expanded
						:message="($v.medicationMax.$invalid) ? 'Bitte geben Sie die maximale Menge des Medikaments ein!' : ''"
						:type="($v.medicationMax.$invalid) ? 'is-danger' : 'is-success'">
						<template #label>
							<div class="align-content-center" style="display: inline-flex">
								<p>Anzahl/(Füll-)Menge</p>
							</div>
						</template>
						<b-input expanded type="number" min="0" v-model="medicationMax" />
					</b-field>

					<MedicationFormSearchableSelect :data-options="medicationFormArray" :editObject="medicationForm"
					:enable-validation="true" validationMessage="Bitte wählen Sie die Darreichungsform (Art) aus!"
					label-text="Darreichungsform (Art)" :enable-order="true" :select="(object) => (medicationForm = object)" />
				
					<MedicationUnitSearchableSelect :data-options="medicationUnitArray" :editObject="medicationUnit"
					:enable-validation="true" validationMessage="Bitte wählen Sie die Darreichungsform (Einheit) aus!"
					label-text="Darreichungsform (Einheit)" :enable-order="true" :select="(object) => (medicationUnit = object)" />
				
					<MedicationClassSearchableSelect :data-options="medicationClassArray" :editObject="medicationClass"
					:enable-validation="true" validationMessage="Bitte wählen Sie die Klasse des Medikaments aus!"
					label-text="Klasse" :enable-order="true" :select="(object) => (medicationClass = object)" />
			
					<b-field expanded>
						<template #label>
							<div class="align-content-center" style="display: inline-flex">
								<p>Hinweise</p>
							</div>
						</template>
						<b-input expanded type="textarea" v-model="medicationHint"
							placeholder="Beispiel: Zur Anwendung bei Kindern ab 20kg, Jugendlichen und Erwachsenen." />
					</b-field>
				</div>
				<div class="has-text-centered mt-3 mb-3">
					<b-field expanded label="Anzeigename">
						<p class="content">
							{{ displayName }}
						</p>
					</b-field>
					<p class="content">
						<small> (Bezeichner, Art, Stärke, Menge Einheit) </small>
					</p>
				</div>
			</section>
			<footer class="modal-card-foot footerContainer" style="color: white">
				<b-button @click="closeModal()" type="is-primary">
					Abbrechen
				</b-button>
				<b-button @click="saveMedication()" type="is-primary" v-bind:loading="isLoading"
					v-bind:disabled="$v.medicationName.$invalid || $v.medicationPotency.$invalid
						|| $v.medicationMax.$invalid || $v.medicationForm.$invalid || $v.medicationUnit.$invalid || $v.medicationClass.$invalid">
					Speichern
				</b-button>
			</footer>
		</div>
	</b-modal>
</template>

<script>

import { mapGetters } from "vuex";
import { minLength, required, minValue } from "vuelidate/lib/validators";
import Parse from "parse/dist/parse.min.js";
import MedicationClassSearchableSelect from "@/components/helper/SearchableSelects/MedicationClassSearchableSelect.vue";
import MedicationUnitSearchableSelect from "@/components/helper/SearchableSelects/MedicationUnitSearchableSelect.vue";
import MedicationFormSearchableSelect from "@/components/helper/SearchableSelects/MedicationFormSearchableSelect.vue";

export default {
	name: "MedicationEntryModal",
	components: {
		MedicationClassSearchableSelect,
		MedicationUnitSearchableSelect,
		MedicationFormSearchableSelect,
	},
	props: ["value", "proppedMedicationMedication"],
	data() {
		return {
			isLoading: false,
			medicationName: "",
			medicationDrug: "",
			medicationPotency: "",
			medicationMax: "",
			medicationForm: undefined,
			medicationUnit: undefined,
			medicationHint: "",
			medicationClass: undefined,
		};
	},
	validations: {
		medicationName: {
			required,
			minLength: minLength(1),
		},
		medicationPotency: {
			required,
			minLength: minLength(1),
		},
		medicationMax: {
			required,
			minValue: minValue(0),
		},
		medicationForm: {
			required
		},
		medicationUnit: {
			required
		},
		medicationClass: {
			required
		}
	},
	mounted() { 
		
	},
	computed: {
		...mapGetters({
			medicationClassArray: "medicationClass/getData",
			medicationFormArray: "medicationForm/getData",
			medicationUnitArray: "medicationUnit/getData",
		}),
		isActive: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit("input", val);
			},
		},
		displayName: function () {
			let medicationName = this.medicationName ? this.medicationName + ", " : "-, "
			let medicationPotency = this.medicationPotency ? this.medicationPotency + ", " : "-, "
			let medicationMax = this.medicationMax ? this.medicationMax + " " : "- "
			let medicationForm = this.medicationForm ? (this.medicationFormArray.find((element) => this.medicationForm.objectId == element.id)).attributes.name + ", " : "-, "
			let medicationUnit = this.medicationUnit ? (this.medicationUnitArray.find((element) => this.medicationUnit.objectId == element.id)).attributes.name : "-"
			let displayName = medicationName + medicationForm + medicationPotency + medicationMax + medicationUnit
			return displayName
		},
	},
	methods: {
		closeModal() {
			this.medicationName = ""
			this.medicationDrug = ""
			this.medicationPotency = ""
			this.medicationMax = ""
			this.medicationForm = undefined
			this.medicationUnit = undefined
			this.medicationHint = ""
			this.medicationClass = undefined
			this.isActive = false;
		},
		async saveMedication() {
			this.isLoading = true;
			const parseObject = Parse.Object.extend("MedicationMedication")
			const dataObject = new parseObject()
			dataObject.set("name", this.medicationName)
			dataObject.set("drug", this.medicationDrug)
			dataObject.set("potency", this.medicationPotency)
			dataObject.set("max", Number(this.medicationMax))
			dataObject.set("medicationForm", this.medicationForm)
			dataObject.set("medicationUnit", this.medicationUnit)
			dataObject.set("hint", this.medicationHint)
			dataObject.set("medicationClass", this.medicationClass)
			dataObject.set("displayName", this.displayName)
			dataObject.set("deleted", false)
			let emitObject = await dataObject.save()
			this.$emit("emit-medication-medication", emitObject)
			this.isLoading = false;
			this.closeModal()
		},
	}
}

</script>

<style scoped></style>
