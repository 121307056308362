import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';


export const getPlzLocation = (plz, location)=>{
    if(plz && plz.length > 0 && (location === undefined || location.length === 0)){
        return plz
    }
    if(location && location.length > 0 && (plz === undefined || plz.length === 0)){
        return location
    }
    if((location === undefined || location.length === 0) && (location === undefined || location.length === 0)){
        return ''
    }
    if(plz && plz.length > 0 && location && location.length > 0){
        return `${plz}, ${location}`
    }
    return ''
}
export const printUserPdf = (user) =>{
    const doc = new jsPDF()
    const highlightedLines = [0]

    const {first_name, last_name, street, email, phone1, phone2, info2, info1} = user.attributes
    const userName = `${first_name} ${last_name}`
    const plz = user.attributes.plz ? user.attributes.plz : ''
    const location = user.attributes.location ? user.attributes.location : ''

    autoTable(doc, {
        styles: { 1: {fillColor: [255, 0, 0]} },
        columnStyles: { 0: { halign: 'center',valign:"middle", fontStyle:"bold",cellWidth:'wrap' } }, // Cells in first column centered and green
        margin: { top: 12 },
        body: [
            ['Name, Vorname', `${last_name}, ${first_name}`, ],
            ['Straße', street],
            ['PLZ, Ort', getPlzLocation(plz, location)],
            ['E-Mail', email],
            ['Telefon 1', phone1],
            ['Info 1', info1],
            ['Telefon 2', phone2],
            ['Info 2', info2],

        ],
        didParseCell: function (data) {
            data.cell.styles.borders = "b"; data.cell.styles.lineWidth = 0.1;
            data.cell.styles.textColor = "black";
            if (highlightedLines.includes(data.row.index)) {
                data.cell.styles.fillColor = [220, 220, 220]

            }else{
                // data.cell.styles.fillColor = [255, 255, 255]
            }
        },
        didDrawPage: function(data) {
            doc.text(`Stammdaten ${userName}`, 15, 10);
        }
    })


    doc.save(`${userName}.pdf`)
}
