<template>
    <b-field
        style="width: 100%"
        v-if="selectedClients.length > 0"
        label="Risikobewertung"
    >
        <ul style="height: auto">
            <li
                style="cursor: pointer"
                class="media"
                v-for="(v, index) in $v.selectedRating.$each.$iter"
                :key="index"
            >
                <div class="media-left">
                    <ClientFigure :client="selectedClients[index]" />
                </div>

                <div class="media-content">
                    <div
                        class="
                            is-flex
                            is-flex-direction-row
                            is-justify-content-space-between
                        "
                    >
                        <ClientGroupAndName
                            :client="selectedClients[index]"
                        ></ClientGroupAndName>
                        <div
                            class="
                                is-flex
                                is-flex-direction-row
                                is-align-items-center
                            "
                        >
                            <div
                                class="my-1 tag is-danger is-light"
                                v-show="!v.required"
                            >
                                Bewertung benötigt!
                            </div>
                            <div @click="removeClient(index)">
                                <b-icon
                                    v-show="
                                        selectedClients[index] !== focusedClient
                                    "
                                    icon="close"
                                    size="is-small"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="is-flex is-flex-direction-column">
                        <b-radio
                            v-model="selectedRating[index]"
                            :native-value="rating"
                            v-for="rating in ['neutral']"
                            :key="rating"
                            class="m-2 is-small"
                        >
                            {{ rating }}
                        </b-radio>
                        <div class="is-flex">
                            <div class="gradient-background">
                                <b-radio
                                    v-model="selectedRating[index]"
                                    :native-value="rating"
                                    v-for="rating in [1, 2, 3, 4, 5, 6]"
                                    :key="rating"
                                    class="m-2 is-small"
                                >
                                    {{ rating }}
                                </b-radio>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </b-field>
</template>

<script>
/**
 * Component for the rating of clients on an entry.
 * @displayName ClientAssessment
 */

import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import { getClientPicture } from "@/lib/imageFunctions";
import ClientGroupAndName from "@/components/bars/sideBar/ClientGroupAndName";
import ClientFigure from "@/components/bars/sideBar/ClientFigure";

export default {
    name: "ClientAssessment",
    props: ["ratings", "clients", "setRatings", "setClients"],
    validations: {
        selectedRating: {
            required,
            $each: {
                required,
            },
        },
    },
    computed: {
        ...mapGetters({
            focusedClient: "clients/getFocusedClient",
        }),
        selectedRating: {
            set(value) {
                this.setRatings(value);
            },
            get() {
                return this.ratings;
            },
        },
        selectedClients: {
            set(value) {
                this.setClients(value);
            },
            get() {
                return this.clients;
            },
        },
    },
    methods: {
        removeClient(clientIndex) {
            this.selectedClients.splice(clientIndex, 1);
            this.selectedRating.splice(clientIndex, 1);
        },
        getPicture(selectedClient) {
            const pictureObject = {
                newProfilePicture: undefined,
                clientPicture:
                    selectedClient && selectedClient.attributes.profile_picture
                        ? selectedClient.attributes.profile_picture._url
                        : undefined,
                clientGender: selectedClient
                    ? selectedClient.attributes.gender
                    : "divers",
            };
            return getClientPicture(pictureObject);
        },
    },
    components: {
        ClientGroupAndName,
        ClientFigure,
    },
};
</script>

<style lang="scss" scoped>
.image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.gradient-background {
    background-image: linear-gradient(
        to right,
        rgba(239, 110, 136, 0.06),
        rgba(239, 110, 136, 0.9)
    );
    border-radius: 9999px;
}

@media (max-width: 920px) {
}
@media (max-width: 769px) {
    hr {
        margin: 0.5rem;
    }

    .column {
        padding: 0;
        margin: 0;
    }
}

@media (max-width: 450px) {
    .b-radio.radio + .radio {
        margin: 0 3px 0 2px;
    }
}
</style>
