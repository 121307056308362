const state = {
	page : undefined,
};

const getters = {
	getPage(state){
		return state.page
	}
};

const mutations = {
	SETPAGE(state, objects) {
		state.page = objects
	}
};

const actions = {
	setPage({ commit }, objects) {
		commit('SETPAGE', objects)
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
