<template>
    <figure class="user_image image is-48x48" id="clientFigure" v-if="author||client">
        <img
            class="is-rounded"
            :src="getPicture()"
            id="clientProfilePicture"
            alt="Profilbild"
        />
    </figure>
	<div v-else class="user_image image is-48x48 skeleton">
	</div>
</template>

<script>
/**
 * Component for the display of author profile picture.
 * @displayName AuthorImage
 */

import { getClientPicture, getUserPicture } from "@/lib/imageFunctions";

export default {
    name: "AuthorImage",
    props: ["author", "client"],
    methods: {
        getPicture() {
            if (this.author) {
                const pictureObject = {
                    userPicture: this.author.attributes?.dependency.attributes
                        .profile_picture
                        ? this.author.attributes?.dependency.attributes
                              .profile_picture._url
                        : undefined,
                    userGender:
                        this.author.attributes?.dependency.attributes.gender,
                };
                return getUserPicture(pictureObject);
            }
            if (this.client) {
				let profilePictureUrl = undefined
				if(this.$store.state.clients.data.length === 0) return undefined
				const associatedClients = this.$store.state.clients.data.filter(c => this.client.attributes.uuid === c.attributes.uuid)
				associatedClients.forEach(c =>{
					const picture = c.attributes.profile_picture
					if(picture) profilePictureUrl = picture._url
				})
                const pictureObject = {
                    newProfilePicture: undefined,
                    clientPicture: profilePictureUrl,
                    clientGender: this.client.attributes.gender,
                };
                return getClientPicture(pictureObject);
            }
            return undefined;
        },
    },
};
</script>

<style scoped>
.user_image img {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

@media (max-width: 400px) {
    #clientProfilePicture {
        height: 80%;
        width: 80%;
        object-fit: cover;
    }

    #clientFigure {
        margin: 0 !important;
    }
}
</style>
