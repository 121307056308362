<template>
    <div
        :class="[
            { disabledTileContainer: isDisabled },
            { tileContainer: !isDisabled },
        ]"
    >
        <template v-if="!isDisabled">
            <router-link :to="{ name: route }" tag="div">
                <div
                    class="
                        m-2
                        box
                        is-flex
                        is-flex-direction-column
                        is-justify-content-start
                        has-light-grey-color
                    "
                >
                    <b-icon :icon="icon" size="is-large" class="tileIcon">
                    </b-icon>
                    <div class="tileName is-font-large is-font-bold">
                        {{ text }}
                    </div>
                </div>
            </router-link>
        </template>
        <template v-else>
            <div>
                <div
                    class="
                        m-2
                        box
                        is-flex
                        is-flex-direction-column
                        is-justify-content-start
                        has-light-grey-color
                    "
                >
                    <b-icon :icon="icon" size="is-large" class="tileIcon">
                    </b-icon>
                    <div class="tileName is-font-large is-font-bold">
                        {{ text }}
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
/**
 * Tile containing a router-link for linking to certain routes with a label.
 * @displayName ModuleTile
 */

export default {
    name: "module-tile",
    props: ["icon", "text", "route", "isDisabled"],
    methods: {},
};
</script>

<style scoped>
.box {
    cursor: pointer;
}

.disabledTileContainer .box {
    cursor: default;
    background-color: lightgrey;
}

.tileName {
    text-align: start;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
	height:50px;
}

.tileIcon {
    margin-bottom: 1.5em;
}

.icon {
    justify-content: start;
}
@media (max-width: 769px) {
    .box {
        margin-right: 3px;
        margin-left: 3px;
    }
    .tileIcon {
        margin-bottom: 0.25em;
    }
}
</style>
