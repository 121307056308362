<template>
	<div>
		<template v-if="attachment">
			<hr class="mt-2 mb-3" style="height: 1px; background-color: black"/>
			Bestehende Anhänge:
			<div v-if="pictures" class="columns imagesContainer" style="flex-wrap: wrap">
				<div v-for="(image, i) in pictures.map((pic) => pic._url)" :class=isImageFile(image) :key="i"
					class="imageContainer">
					<img class="image" :src="image" alt="Keine Vorschau verfügbar" />
					<b-icon class="imageDelete" @click.native="removePicture(i)" icon="close" />
				</div>
			</div>
			<div v-if="otherFiles" style="flex-wrap: wrap">
				<hr style="height: 1px; background-color: black" />
				<div v-for="(file, i) in otherFiles" :key="i">
					<div class="fileItem">
						<b-icon icon="file-pdf-box" size="is-medium" />
						<a tag="a" :href="file.attributes.file._url" target="_blank"
							style="padding-top: 4px; margin-left: 6px">
							{{ file.attributes.fileTitle }}
						</a>
						<b-icon class="fileDelete" @click.native="removeFiles(i)" icon="close" />
					</div>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
/**
 * Component for the editting of attachements on an entry.
 * @displayName EditAttachements
 */

export default {
	name: "MedicationEditAttachements",
	props: ["attachment", "pictures", "removePicture", "otherFiles", "removeFiles"],
	mounted() { },
	methods: {
		isImageFile(file) {
			if (file.type != 'application/pdf') {
				return "column is-one-third";
			} else {
				return "";
			}
		}
	}
};
</script>

<style lang="scss">
.imageContainer {
	position: relative !important;
	margin-top: 0.25rem !important;
}

.columns {
	display: flex;
}

.fileItem {
	flex-wrap: wrap !important;
	display: flex !important;
	align-items: flex-start !important;
	flex-direction: row !important;
	align-content: flex-start !important;
	justify-content: flex-start !important;
}

.fileItem span {
	width: auto !important;
}

.imageDelete {
	background-color: rgb(255 255 255) !important;
	border-radius: 0.375rem !important;
	position: absolute !important;
	top: 0.5rem !important;
	right: 0.5rem !important;
	cursor: pointer !important;
	width: auto !important;
}

.fileDelete {
	border-radius: 0.375rem !important;
	cursor: pointer !important;
	width: auto !important;
	padding-top: 10px !important;
	margin-left: 10px !important;
}
</style>

<style lang="scss" scoped>
.uploader {
	height: 8rem;
}

::v-deep .upload-draggable {
	display: flex;
	justify-content: center;
	align-items: center;
}

span.icon {
	display: flex;
	justify-content: center;
	width: 100%;
}

.image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.imagesContainer {
	margin-top: 0.25rem;
	width: calc(100% - 1.5rem);
}

@media (max-width: 769px) {


	.columns:not(:last-child) {
		margin-bottom: 0;
	}

	.column {
		padding: 0;
		margin: 0;
	}

	::v-deep .icon {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.imagesContainer {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 0.25rem;
		margin-top: 0.25rem;
	}
}
</style>