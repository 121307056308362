<template>
    <div class="barContainer">
        <div
            class="
                scrollDiv
                is-flex
                is-flex-direction-row
                is-justify-content-center
                is-align-content-center
            "
            :style="getScrollDivStyle()"
            style="height: 100%; overflow-y: hidden"
        >
            <div
                v-for="(routeObject, index) in routeObjects"
                :key="index"
                class="routeContainer"
                :class="[
                    { disabledRoute: !routeObject.isActivated },
                    { notificationRoute: routeObject.isNotification && numberOfNotifications > 0 },
                ]"
                @click="route(routeObject)"
            >
                <div class="route m-1">
                    <template v-if="routeObject.isActivated">
                        <b-tooltip
                            :label="routeObject.text"
                            position="is-right"
                        >
                            <b-icon
                                :icon="routeObject.icon"
                                :size="isMobile() ? 'is-small' : ''"
                                :class="[
                                    {
                                        selectedRoute: selectedRoute(routeObject.route),
                                    },
                                    {
                                        disabledRouteIcon:
                                            !routeObject.isActivated,
                                    },
                                    { icon: routeObject.isActivated },
                                    { zoom: routeObject.isActivated },
                                ]"
                            />
                        </b-tooltip>
                    </template>
                    <template v-else>
                        <b-icon
                            :icon="routeObject.icon"
                            :size="isMobile() ? 'is-small' : ''"
							:key="activeRoute"
                            :class="[
                                {
                                    selectedRoute: selectedRoute(routeObject.route),
                                },
                                { disabledRouteIcon: !routeObject.isActivated },
                                { icon: routeObject.isActivated },
                                { zoom: routeObject.isActivated },
                            ]"
                        />
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/**
 * BottomBar for fast and easy module navigation.
 * @displayName BottomBar
 */
import { mapGetters } from "vuex";
import { APP_USE_DIARY, APP_USE_MEDICATION, APP_USE_NOTIFICATION, APP_USE_RECORD, APP_USE_CHART, APP_USE_GOAL } from "@/configuration/AppConfigurations";

export default {
    name: "BottomBar",
    data() {
        const routeObjects = [
            {
                text: "Modulauswahl",
                icon: "home",
                route: "/",
                isActivated: true,
            },
            {
                text: "Tagebuch",
                icon: "book-open-variant",
                route: "/module/diary",
                isActivated: (APP_USE_DIARY == 'true' ? true : false),
            },
			{
                text: "Medikation",
                icon: "medication",
                route: "/module/medication",
                isActivated: (APP_USE_MEDICATION == 'true' ? true : false),
            },
            {
                text: "Benachrichtigungen",
                icon: "bell",
                route: "/module/notifications",
                isActivated: (APP_USE_NOTIFICATION == 'true' ? true : false),
                isNotification: true,
            },
            {
                text: "Kinderakte",
                icon: "text-box",
                route: "/module/record",
                isActivated: (APP_USE_RECORD == 'true' ? true : false),
            },
			{
				text: "Datenanalyse",
				icon: "chart-areaspline",
				route: "/module/charts",
				isActivated: (APP_USE_CHART == 'true' ? true : false),
			},
			{
				text: "Punkteplan",
				icon: "star",
				route: "/module/goal",
				isActivated: (APP_USE_GOAL == 'true' ? true : false),
			},
        ];
        return {
            routeObjects: routeObjects,
        };
    },
    methods: {
        async route(object) {
			this.$store.dispatch("docfilter/clearFilterData");
            const { route, isActivated } = object;
            if (!this.$store.state.focusedClientId)
                await this.$store.dispatch("setSidePanelOpen", true);
            if (this.selectedRoute(route) || !isActivated) return;
			this.$store.dispatch("pagination/setPage", undefined);
            await this.$router.push({ path: route });
        },
        selectedRoute(route) {
			if(route === "/") return this.activeRoute === route
            return this.activeRoute.includes(route);
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
        getScrollDivStyle() {
            let style = "";
            style += `min-width: ${(this.routeObjects.length + 2) * 40}px;`;
            return style;
        },
    },
    computed: {
        activeRoute() {
            return this.$route.path;
        },
        ...mapGetters({
            numberOfNotifications: "notifications/getNumberOfNotifications",
        }),
    },
};
</script>

<style scoped>
.scrollDiv {
    height: 100%;
}
.routeText {
    text-align: center;
}

.barContainer {
    position: absolute;
    width: 100%;
    min-height: 30px;
    overflow: auto;
}

.routeContainer {
    cursor: pointer;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.route {
    min-height: 2.5rem;
    display: flex;
    align-items: center;
}

.icon {
    border-radius: 2rem;
    padding: 1rem;
}

.selectedRoute {
    background-color: rgba(0, 0, 0, 0.8);
}

::v-deep .selectedRoute .mdi {
    color: whitesmoke;
}

::v-deep .notificationRoute .mdi {
    color: red;
}

.disabledRouteIcon {
    padding: 1rem;
    cursor: default;
    background-color: lightgrey;
}

.disabledRoute {
    cursor: default;
}

.zoom {
    -webkit-transition: -webkit-transform 0.2s ease-out;
    -moz-transition: -moz-transform 0.2s ease-out;
    -o-transition: -o-transform 0.2s ease-out;
    -ms-transition: -ms-transform 0.2s ease-out;
    transition: transform 0.2s ease-out;
}

.zoom:hover {
    -webkit-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
}

@media (max-width: 769px) {
    .bar {
        max-width: 95%;
    }
}
</style>
