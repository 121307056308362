<template>
	<div
		class="scrollable p-3"
		v-if="
            chart && focusedClient &&entryLinks &&	areAuthorMetaClassesLoaded && areAuthorsLoaded"
		ref="entryDetailPanel"
	>
		<div class="detailContainer">
			<div class="mb-0 headContainer">
				<div class="entryHead">
					<h1 class="is-title mb-0">
						{{ chart.attrName }}
					</h1>

					<div class="is-font-medium">
						{{
							chart.attrConfig
						}},
						<time>
							{{
								chart.start.toLocaleDateString(
									"de-DE",
									{
										year: "numeric",
										month: "2-digit",
										day: "2-digit",
									}
								) +
								" - " +
								chart.end.toLocaleDateString(
									"de-DE",
									{
										year: "numeric",
										month: "2-digit",
										day: "2-digit",
									}
								)
							}}
						</time>
					</div>
				</div>

				<div class="entryTypeTag" style="margin-bottom: 1.75rem">
					<ColoredTag
						:tag-name="avgLabel(chart.avg.all)"
						tag-color="#000000CC"
						:is-right="true"/>
					<ColoredTag
						class="mr-1"
						v-if="chart.data.all.slope !== undefined"
						:tag-name="slopeLabel(chart.data.all.newSlope)"
						:tag-color="slopeColor(chart.data.all.newSlope)"
						:is-right="true"/>
				</div>
			</div>

			<div class="card mt-3 ml-2 mr-2 mb-4 pt-1">
				<div class="is-flex is-flex-wrap-wrap m-2" >
					<b-button class="m-1" :class="getButtonClass(undefined)" @click="choseAll()">Alle</b-button>
					<b-button class="m-1" :class="getButtonClass(12)" @click="choseTwelve()">12 Monate</b-button>
					<b-button class="m-1" :class="getButtonClass(6)" @click="choseSix()">6 Monate</b-button>
					<b-button class="m-1" :class="getButtonClass(3)" @click="choseThree()" id="defaultMonthButton">3 Monate</b-button>
				</div>
				<div v-if="authors !== undefined" class="m-2">
					<b-checkbox
						v-model="chosenAuthors"
						v-for="(chartAuthor ) in authors"
						:key="chartAuthor.id"
						:native-value="chartAuthor"
						class="column is-flex-grow-0"
						:class="isMobile? 'is-small' : '' "
						v-on:input="chosenAuthors.$touch"
					>
						<div :style="{color: getAuthorSlopeColor(chartAuthor.id)}">
						<template v-if="chartAuthor.id === 'all'">
							Alle
						</template>
						<template v-else>
							{{ chartAuthor.attributes.dependency.attributes.first_name }}
							{{ chartAuthor.attributes.dependency.attributes.last_name }}
						</template>
						<template >(Trend: {{getAuthorSlope(chartAuthor.id)}})</template>
						</div>
					</b-checkbox>
				</div>
			</div>

			<div class="ml-3" :key="focusedClient.id" v-if="data">
				<AttributeChart :id="chart.id" :data="data.ratingData" :clientId="focusedClient.id" :is-detailed="true"
				:number-stats="data.numberData" :type="chart.attrName"/>
			</div>
		</div>
		<b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import ColoredTag from "@/components/tags/ColoredTag";
import AttributeChart from "@/components/charts/AttributeChart";
import Parse from "parse/dist/parse.min"
import {BEAUTIFUL_COLORS, COLORS} from "@/lib/constants";

const createDates = (minDate) =>{
	const today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()+1)
	const dates = []
	const minDateCopy = new Date(minDate.getTime())
	for (let newDateTime = minDateCopy.getTime(); newDateTime <= today.getTime(); newDateTime += 1000 * 60 * 60 * 24) {
		dates.push(new Date(newDateTime).toLocaleDateString("de-DE", {
			year: "numeric",
			month: "2-digit",
			day: "2-digit",
		}))
	}
	return dates
}

export default {
	name: "ChartDetails",
	components: {
		AttributeChart,
		ColoredTag
	},
	computed:{
		...mapGetters({
			focusedClient: "clients/getFocusedClient",
			entryLinks: "entrylinks/getFilteredEntryLinks",
			areAuthorMetaClassesLoaded: "usermetas/isAuthorMetaClassLoaded",
			areAuthorsLoaded:"users/isAuthorLoaded",
			chart: "getChartData",
			isMobile: "isMobile",
			months: "getChartMonths"
		}),
		authors(){
			const authors =[{id: "all"}]
			for(const authorId in this.chart.data) {
				const author = authorId !== "all" ? this.$store.state.users.data.find(u => u.id === authorId) : undefined
				const authorData = this.chart.data[authorId]
				const filteredData = authorData.ratings.filter(d => d !== 0)
				if(author && filteredData.length > 0) authors.push(author)
			}
			return authors
		},
		colors(){
			const colors = {}
			let iterator = 0
			for(const authorId in this.chart.data) {
				// const chosenAuthorIds = this.chosenAuthors.map(a => a.id)
				// if (!chosenAuthorIds.includes(authorId)) continue
				const color = COLORS[iterator]
				colors[authorId] = color
				iterator++;
			}
			colors["all"] = "rgb(0,0,0)"
			return colors
		},
		data(){
			if(!this.chart) return undefined
			if(!this.areAuthorMetaClassesLoaded) return undefined
			const labels = this.chart.data["all"].newDates

			const datasets =[{
				label: "Bewertungsgrenze",
				data: labels.map(d => -2.5),
				borderColor: "rgba(0,0,0,.8)",
				borderWidth: .5,
				pointRadius: 0,
				borderDash: [10, 5]
			}]
			const numberSets = []

			let iterator = 0
			for(const authorId in this.chart.data){

				const authorData = this.chart.data[authorId]
				const data = authorData.newRatings.map(d =>{
					if(d===0) return null
					return d
				})

				const author = authorId !== "all"? this.$store.state.users.data.find(u => u.id === authorId) : undefined
				const chosenAuthorIds = this.chosenAuthors.map(a => a.id)
				if(!chosenAuthorIds.includes(authorId)) continue
				const authorName = author? `${author.attributes.dependency.attributes.first_name} ${author.attributes.dependency.attributes.last_name}` : "Alle"

				const color = this.colors[authorId]
				datasets.push({
					label: authorName,
					data: data,
					backgroundColor: color,
					borderColor: color,
					borderWidth: 2,
				})
				numberSets.push({
					label: authorName,
					data: authorData.newNumbers,
					backgroundColor: color,
					borderColor: color,
					borderWidth: 2,
				})
				iterator++
			}

			return {
				ratingData:{
					labels: labels,
					datasets: datasets
				},
				numberData:{
					labels: labels,
					datasets: numberSets
				}
			}
		}
	},
	data(){
		return{
			isLoading: false,
			chosenAuthors: [{id:"all"}],
		}
	},
	methods:{
		async updateData(){
			this.isLoading = true;
			//this.message = "Daten laden!"
			this.charts = []
			const params = {clientId: this.focusedClient.id, months: this.months}
			const clientMonthsData= await Parse.Cloud.run("newChartAll", params)

			const entryLinks= this.entryLinks
			const lastDate = entryLinks.length > 0 ? entryLinks[entryLinks.length-1].attributes.createdAt: new Date()
			let minDate = undefined
			if(this.months){
				const today = new Date()
				minDate = new Date(today.getFullYear(), today.getMonth()-this.months, today.getDate())
				if(lastDate>minDate) minDate = lastDate
			}else{
				minDate = new Date(lastDate.getFullYear(), lastDate.getMonth(), lastDate.getDate())
			}
			const dates = createDates(minDate)
			for(const chart of clientMonthsData){
				if(chart.id !== this.chart.id) continue

				for(const authorId in chart.data){
					const newRatings = new Array(dates.length).fill(0)
					const newNumbers = new Array(dates.length).fill(0)
					const newDates = dates
					let i = 0
					let startDate = new Date(minDate.getTime())
					let endDate = new Date()
					for(const date of chart.data[authorId]["months"]){
						if(startDate <  new Date(+date) ) {
							startDate =  new Date(+date)
						}
						if(endDate >  new Date(+date) ) {
							endDate =  new Date(+date)
						}
						const dateString = new Date(+date).toLocaleDateString("de-DE", {
							year: "numeric",
							month: "2-digit",
							day: "2-digit",
						})
						const index = dates.indexOf(dateString)
						newRatings[index] = chart.data[authorId]["ratings"][i]
						newNumbers[index] = chart.data[authorId]["numbers"][i]
						i++
					}
					const numberOfRatedDays = (startDate.getTime() - endDate.getTime()) / (1000*60*60*24)

					const slope = chart.data[authorId]["slope"]*numberOfRatedDays
					chart.data[authorId]["newDates"] = newDates
					chart.data[authorId]["newNumbers"] = newNumbers
					chart.data[authorId]["newRatings"] = newRatings
					chart.data[authorId]["newSlope"] = Math.round(slope*10)/10
				}
			}

			if(!clientMonthsData) {
				//this.message = "Keine Daten gefunden!"
				this.isLoading = false
				return this.charts = []

			}

			for(const attrData of clientMonthsData){
				if(this.chart.id !== attrData.id) continue
				const labels =attrData.data["all"].newDates

				attrData.showChart = false
				const data = attrData.data["all"].newRatings.map(d =>{
					if(d===0) return null
					attrData.showChart = true
					return d
				})

				const colorsData = data.map(d => {
					if(attrData.data["all"]["slope"] === 0) return "#000000"
					if(attrData.data["all"]["slope"] > 0 ) return "#178a1f"
					if(attrData.data["all"]["slope"] < 0 ) return "#a11014"
				})

				attrData.data["all"].dataset = {
					labels: labels,
					datasets: [{
						label: "Durchschnitt",
						data: data,
						backgroundColor: colorsData,
						borderColor: colorsData,
						borderWidth: 2,
						pointBackgroundColor: colorsData,
						pointBorderColor: colorsData,
						pointRadius: 0.1,
					},
						{
							label: "Bewertungsgrenze",
							data: data.map(d => -2.5),
							borderColor: "rgba(0,0,0,.8)",
							borderWidth: .5,
							pointRadius: 0,
							borderDash: [10, 5]
						}]
				}

				const maxEntryLinks = Math.max(...attrData.data["all"].newNumbers)
				if(maxEntryLinks>this.maxNumberStats) this.maxNumberStats = maxEntryLinks
				attrData.data["all"].numberStats = {
					labels: labels,
					datasets:[
						{
							label: "Anzahl Einträge",
							data: attrData.data["all"].newNumbers,
							backgroundColor: colorsData,
							borderColor: colorsData,
							color: colorsData,
							borderWidth: 2,
						}
					]
				}
			}
			const chart = clientMonthsData.find(chart => chart.id === this.chart.id)
			await this.$store.dispatch("setChartDetails", chart)
			this.isLoading = false;
		},
		getAuthorSlope(authorId){
			if(this.chart === undefined) return 0
			return this.chart.data[authorId].newSlope
		},
		getAuthorSlopeColor(authorId){
			if(this.chart === undefined) return "#000000CC"
			if(this.chart.data[authorId].newSlope >0) return "#178a1f"
			if(this.chart.data[authorId].newSlope === 0) return "#000000CC"
			return "#a11014"

		},
		getButtonClass(month){
			if(month === this.months && this.isMobile) return"monthSelectionFocus is-small"
			if(month === this.months) return "monthSelectionFocus"
			if(month !== this.months && this.isMobile) return "monthSelection is-small"
			if(month !== this.months) return "monthSelection"
			return ""
		},
		slopeLabel(slope){
			return `Trend: ${slope}`
		},
		slopeColor(slope){
			if(slope >0) return "#178a1f"
			if(slope === 0) return "#000000CC"
			return "#a11014"
		},
		avgLabel(avg){
			return `durchschnittliche Bewertung: ${-Math.round(avg*10)/10}`
		},
		async choseAll(){
			await this.$store.dispatch("setChartMonths", undefined)
			await this.updateData()
		},
		async choseTwelve(){
			await this.$store.dispatch("setChartMonths", 12)
			await this.updateData()
		},
		async choseSix(){
			await this.$store.dispatch("setChartMonths", 6)
			await this.updateData()
		},
		async choseThree(){
			await this.$store.dispatch("setChartMonths", 3)
			await this.updateData()
		},
	}
}
</script>

<style scoped>
.monthSelection{
	background-color: rgba(0,0,0,.8);
	color: white;
	margin-left: .1rem;
	border: 0px;
}

.monthSelection:hover{
	background-color: rgba(0,0,0,.6);
	color: white;
	margin: .15rem 0 0 0.1rem;
}

.monthSelectionFocus:focus{
	color: white;
	box-shadow: none!important;
}

.monthSelectionFocus{
	background-color: rgba(0,0,0,.4);
	margin-left: .1rem;
	color: white;
	border: 0px;
	box-shadow: none!important;
}

.scrollable{
	overflow: auto;
	height: 100%;
}
</style>
