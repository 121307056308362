<template>
    <p class="is-font-extra-large is-font-bold mb-1" v-if="entry">
        {{ entry.attributes.title }}
    </p>
	<div v-else class="skeleton skeleton-title">
	</div>
</template>

<script>
/**
 * Component for the display of entry title.
 * @displayName EntryTitle
 */

export default {
    name: "EntryTitle",
    props: ["entry"],
};
</script>

<style scoped>

</style>
