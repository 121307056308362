<template>
    <div
        class="b-slider-tick"
        :class="{ 'is-tick-hidden': hidden }"
        :style="getStepStyle(position)">
        <span v-if="$slots.default" class="b-slider-tick-label">
            <slot/>
        </span>
    </div>
</template>

<script>
export default {
    name: 'ProgressStep',
    props: {
        value: {
            type: Number,
            default: 0
        }
    },
    computed: {
		//ToDo: Remove, wenn Review passt
        position() {
            const pos = this.$parent.max * (this.value/100)
            return (pos >= 0 && pos <= 100) ? pos : 0
        },
        hidden() {
            return this.value === this.$parent.min || this.value === this.$parent.max
        }
    },
    methods: {
		//ToDo: Remove position, wenn Review passt
        getStepStyle(position) {
            return { 'left': this.value + '%' }
        }
    },
    created() {
        if (!this.$parent.$data.isProgress) {
            this.$destroy()
            throw new Error('You should wrap bSliderTick on a bSlider')
        }
    }
}
</script>

<style scoped>
.b-slider .b-slider-tick {
	background: black !important
}

.b-slider.is-small .b-slider-tick-label
{
	top:calc(0.375rem * 0.5 + 8px)
}
</style>