<template>
    <div style="width: 100%">
        <h3 class="attribute_titel mb-3">Eintragstyp</h3>
        <div class="columns mb-0" id="entryTypeContainer" style="width: 100%">
            <b-radio
                v-model="entry_type"
                v-for="entryType in entryTypes"
                v-on:input="$v.entry_type.$touch"
                :key="entryType.id"
                :native-value="entryType"
                v-bind:style="{
                    color: entryType.attributes.color,
                }"
                class="column is-flex-shrink-0"
            >
                <span
                    class="tag m-0 is-dark is-rounded tagContainer"
                    style="float: right; width: fit-content"
                    v-bind:style="{
                        'background-color': entryType.attributes.color,
                        color: getColor(entryType.attributes.color),
                    }"
                >
                    {{ entryType.attributes.name }}
                </span>
            </b-radio>
        </div>
        <div
            class="tag is-danger is-light padded dangerTag"
            v-if="!$v.entry_type.required"
        >
            Eintragstyp benötigt!
        </div>
    </div>
</template>

<script>
/**
 * Component for the display of entry types.
 * @displayName EntryTypes
 */

import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import { HEX2RGB } from "@/lib/Color";

export default {
    name: "EntryTypes",
    props: ["diaryEntryType", "setEntryType"],
    validations: {
        entry_type: {
            required,
        },
    },
    computed: {
        ...mapGetters({
            unfilteredEntryTypes: "entrytypes/getEntryType",
        }),
        entryTypes() {
            return this.unfilteredEntryTypes.filter(
                (config) => config.attributes.deleted !== true
            );
        },
        entry_type: {
            set(value) {
                this.setEntryType(value);
            },
            get() {
                return this.diaryEntryType;
            },
        },
    },
    methods: {
        getColor(hex) {
            let number = 0;
            HEX2RGB(hex).forEach((num) => (number += num));
            if (number > 393) return "black";
            return "white";
        },
    },
    mounted() {
        this.entryTypes.forEach((item) => {
            if (item.attributes.isStandardEntryType) {
                this.entry_type = item;
            }
        });
    },
};
</script>

<style lang="scss" scoped>
.attribute_titel {
    margin-top: 12px;
    font-weight: bold;
}

.tagContainer {
    align-items: center;
    grid-area: tag;
    align-self: center;
    justify-self: end;
}

@media (max-width: 920px) {
    .tagContainer {
        align-items: center;
        grid-area: tag;
        justify-self: center;
    }
}

@media (max-width: 769px) {
    .columns:not(:last-child) {
        margin-bottom: 0;
    }

    .attribute_titel {
        margin: 0.5rem 0 !important;
    }

    .columns {
        display: flex;
        align-items: center;
        flex-direction: row;
        padding: 0px;
        margin: 0px;
    }

    .tagContainer {
        align-items: center;
        grid-area: tag;
        margin-right: 1em;
    }

    .column {
        padding: 0;
        margin: 0;
    }
}

@media (max-width: 450px) {
    .b-radio.radio + .radio {
        margin-right: 3px;
        margin-left: 2px;
    }
}

@media (max-width: 500px) {
    #entryTypeContainer {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 0.5em;
    }
}

@media (max-width: 400px) {
    #entryTypeContainer {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 0.5em;
    }
}
</style>
