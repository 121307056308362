var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"barContainer"},[_c('div',{staticClass:"scrollDiv is-flex is-flex-direction-row is-justify-content-center is-align-content-center",staticStyle:{"height":"100%","overflow-y":"hidden"},style:(_vm.getScrollDivStyle())},_vm._l((_vm.routeObjects),function(routeObject,index){return _c('div',{key:index,staticClass:"routeContainer",class:[
                    { disabledRoute: !routeObject.isActivated },
                    { notificationRoute: routeObject.isNotification && _vm.numberOfNotifications > 0 },
                ],on:{"click":function($event){return _vm.route(routeObject)}}},[_c('div',{staticClass:"route m-1"},[(routeObject.isActivated)?[_c('b-tooltip',{attrs:{"label":routeObject.text,"position":"is-right"}},[_c('b-icon',{class:[
                                    {
                                        selectedRoute: _vm.selectedRoute(routeObject.route),
                                    },
                                    {
                                        disabledRouteIcon:
                                            !routeObject.isActivated,
                                    },
                                    { icon: routeObject.isActivated },
                                    { zoom: routeObject.isActivated },
                                ],attrs:{"icon":routeObject.icon,"size":_vm.isMobile() ? 'is-small' : ''}})],1)]:[_c('b-icon',{key:_vm.activeRoute,class:[
                                {
                                    selectedRoute: _vm.selectedRoute(routeObject.route),
                                },
                                { disabledRouteIcon: !routeObject.isActivated },
                                { icon: routeObject.isActivated },
                                { zoom: routeObject.isActivated },
                            ],attrs:{"icon":routeObject.icon,"size":_vm.isMobile() ? 'is-small' : ''}})]],2)])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }