import {setArray} from "@/lib/Array";

const mainState = {
	data: [], //fetch all because we dont have current user before login andso we can only filter after login; store is initialized before login
	entries: [],
	entryLinks: [],
	comments: []
};

const getters = {
	getDiaryNotifications(state, getters, rootState) {
		return getDiaryNotifications(rootState)
	},
	getNumberOfDiaryNotifications(state, getters) {
		if (!getters.getDiaryNotifications) return undefined
		return getters.getDiaryNotifications.length
	},
	getNumberOfNotifications(state, getters) {
		if (getters.getDiaryNotifications) return getters.getDiaryNotifications.length
		return undefined
	},
	getNotifications(state, getters, rootState) {
		return getNotifications(rootState.currentUser, rootState.notificationContactChildren)
	},
	getEntryNotifications(state, getters, rootState) {
		return getDiaryNotificationsByType("newEntry", rootState)
	},
	getFilteredEntryNotifications(state, getters, rootState) {
		return getFilteredDiaryNotificationsByType(rootState,"newEntry", rootState.clients.focusedClientId, rootState.clients.focusedClientAllIds)
	},
	getCommentNotifications(state, getters, rootState) {
		return getDiaryNotificationsByType("newComment", rootState)
	},
	getFilteredCommentNotifications(state, getters, rootState) {
		return getFilteredDiaryNotificationsByType(rootState, "newComment", rootState.clients.focusedClientId, rootState.clients.focusedClientAllIds)
	},
	getDocumentNotifications(state, getters, rootState) {
		return getDiaryNotificationsByType( "newDocument", rootState)
	},
	getFilteredDocumentNotifications(state, getters, rootState) {
		return getFilteredDiaryNotificationsByType(rootState, "newDocument", rootState.clients.focusedClientId, rootState.clients.focusedClientAllIds)
	}

};

export const getDiaryNotifications = (rootState) =>{
	if(rootState.users.data.length === 0 || rootState.currentUser === undefined) return undefined
	const userNotifications = mainState.data.filter(notification => notification.attributes.user.id === rootState.currentUser.id)
	const diaryNotifications = userNotifications.filter(notification => notification.attributes.type === "newEntry"
		|| notification.attributes.type === "newComment"
		||  notification.attributes.type === "newDocument")
	return setArray(diaryNotifications)
}

export const getDiaryNotificationsByType = (type, rootState) => {
	if(rootState.currentUser === undefined) return undefined
	const diaryNotifications = getDiaryNotifications( rootState)
	if (diaryNotifications && diaryNotifications.length > 0 ) {
		return diaryNotifications.filter(notification=>notification.attributes.type === type);
	}
	return undefined
}

export const getFilteredDiaryNotificationsByType = (mainState, type, focusedClientId, focusedClientAllIds) =>{
	const diaryTypeNotifications = getDiaryNotificationsByType(type, mainState)
	if(!diaryTypeNotifications) return undefined
	if(!mainState.entrySearchQuery) return diaryTypeNotifications.filter(notification => {
		if(focusedClientAllIds && focusedClientAllIds.length > 1)
		{
			let contains_id = false;
			for(var i=0; i< focusedClientAllIds.length; i++)
			{
				if(focusedClientAllIds[i].id == notification.attributes.client.id)
				{
					return true;
				}
			}
			return false;
			//return focusedClientAllIds.includes(notification.attributes.client.id)
		}else
		{
			if(!focusedClientId) return false
			return notification.attributes.client.id === focusedClientId
		}
	})

	const lowerFilter = mainState.entrySearchQuery.toLowerCase();
	return diaryTypeNotifications.filter((entryNotification) => {
		const {dependencyEntryLink} = entryNotification.attributes
		const {entry, client} = dependencyEntryLink.attributes
		const author = entry.attributes.author.attributes.dependency;
		const entryTitle = entry.attributes.title;
		const clientName =`${client.attributes.first_name} ${client.attributes.last_name}`
		const authorName =`${author.attributes.first_name} ${author.attributes.last_name}`
		const clientGroupName =client.attributes.group.attributes.group_name

		const isFilterInClientName =clientName.toLowerCase().includes(lowerFilter);
		const isFilterInAuthorName = authorName.toLowerCase().includes(lowerFilter);
		const isFilterInTitle = entryTitle.toLowerCase().includes(lowerFilter);
		const isFilterInGroup = clientGroupName.toLowerCase().includes(lowerFilter);
		const isFocusedClient = client.id === focusedClientId
		return (
			(isFilterInAuthorName ||isFilterInClientName ||
			isFilterInTitle ||isFilterInGroup) && isFocusedClient);
	});
}

export const getNotifications = (currentUser, notificationContactChildren) =>{
	if(mainState.data.length === 0)return undefined
	if(!notificationContactChildren) return mainState.data
	return mainState.data.filter(	(notification) => {
		const client = notification.attributes.client;
		const contactChildren = currentUser.attributes.dependency.attributes.clients
			? currentUser.attributes.dependency.attributes.clients
			: [];
		const contactChildrenIds = contactChildren.map(contactChildren => contactChildren.id);
		return contactChildrenIds.includes(client.id)
	});
}

const mutations = {
	SET(state, objects) {
		state.data = objects
	},
	SET_ENTRIES(state, objects) {
		state.entries = objects
	},
	SET_ENTRYLINKS(state, objects) {
		state.entryLinks = objects
	},
	SET_COMMENTS(state, objects) {
		state.comments = objects
	},
	INSERT(state, object) {
		state.data.unshift(object)
	},
	DELETE(state, object) {
		state.data = state.data.filter((elem) => {
			return elem.id !== object.id
		})
	},
	REPLACE(state, object) {
		const oldObject = state.data.filter(item => item.id === object.id)
		const index = state.data.indexOf(oldObject[0]);
		if (index > -1) {
			state.data.splice(index, 1, object);
		}
	
	},
};

const actions = {
	set({ commit }, objects) {
		commit('SET', objects)
	},
	setEntries({ commit }, objects) {
		commit('SET_ENTRIES', objects)
	},
	setEntryLinks({ commit }, objects) {
		commit('SET_ENTRYLINKS', objects)
	},
	setComments({ commit }, objects) {
		commit('SET_COMMENTS', objects)
	},
	insert({ commit }, object) {
		commit('INSERT', object)
	},
	delete({ commit }, object) {
		commit('DELETE', object)
	},
	replace({ commit }, object) {
		commit('REPLACE', object)
	}
};

export default {
	namespaced: true,
	state: mainState,
	getters,
	actions,
	mutations
};
