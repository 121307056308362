<template>
	<div class="is-flex is-flex-direction-column" style="width:100%;">

		<MedicationReportInputSelect v-if="!reportObjectNumber || reportObjectNumber == -1"
			@emit-existing-report-object-selected="val => reportObjectExists = val" />

		<template v-if="mask == 'new' || mask == 'edit'">
			<DoctorSearchableSelect :data-options="doctorArray" :editObject="reportDoctor" :enable-validation="true"
				validationMessage="Bitte wählen Sie einen Arzt aus !" label-text="Arzt" :enable-order="true"
				:select="(object) => (reportDoctor = object)"
				@emit-new-doctor-modal-is-open="val => isDoctorModalActive = val" />
		</template>

		<template v-if="mask == 'view' && reportDoctor">
			<b-field expanded grouped class="bfield_w_margin_label">
				<template #label>
					<div class="align-content-center" style="display: inline-flex">
						<p>Arzt</p>
					</div>
				</template>
				<span>{{ reportDoctor.attributes?.name }}</span>
				<span v-if="reportDoctor.attributes?.specialization">, {{
			reportDoctor.attributes?.specialization.attributes.specialization }}</span>
				<span v-if="reportDoctor.attributes?.street">, {{ reportDoctor.attributes?.street }}</span>
				<span v-if="reportDoctor.attributes?.street">, {{ reportDoctor.attributes?.plz }}</span>
				<span v-if="reportDoctor.attributes?.street">

					<template v-if="!reportDoctor.attributes?.street">,</template>

					<template v-else>&nbsp;</template>{{ reportDoctor.attributes?.location }}</span>
			</b-field>
		</template>

		<template v-if="mask == 'new' || mask == 'edit'">
			<b-field expanded :message="(!$v.reportDate.$model) ? 'Bitte geben Sie ein Datum an!' : ''"
				:type="(!$v.reportDate.$model) ? 'is-danger' : 'is-success'">
				<template #label>
					<div class="align-content-center" style="display: inline-flex">
						<p> Datum </p>
					</div>
				</template>
				<b-datepicker :first-day-of-week=1 expanded :date-formatter="dateFormatter" trap-focus editable
					locale="de-DE" v-model="reportDate">
				</b-datepicker>
			</b-field>
		</template>

		<template v-if="mask == 'view' && reportDate">
			<b-field expanded>
				<template #label>
					<div class="align-content-center" style="display: inline-flex">
						<p> Datum </p>
					</div>
				</template>
				{{ reportDate.toLocaleDateString("de-DE",
			{
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
			}) }}
			</b-field>
		</template>

		<template v-if="mask == 'new' || mask == 'edit'">
			<DiagnoseSearchableSelect :data-options="diagnoseArray" :editObject="reportDiagnose"
				:enable-validation="true" validationMessage="Bitte wählen Sie eine Diagnose aus !"
				label-text="Diagnose" :enable-order="true" :select="(object) => (reportDiagnose = object)"
				@emit-new-diagnosis-modal-is-open="val => isDiagnoseModalActive = val" />
		</template>

		<template v-if="mask == 'view' && reportDiagnose">
			<b-field expanded>
				<template #label>
					<div class="align-content-center" style="display: inline-flex">
						<p>Diagnose</p>
					</div>
				</template>
				{{ reportDiagnose.attributes?.name }}
			</b-field>
		</template>

		<template v-if="mask == 'new' || mask == 'edit'">
			<b-field expanded grouped>
				<template #label>
					<div class="align-content-center" style="display: inline-flex">
						<p>ICD-10-Code</p>
					</div>
				</template>
				<b-button class="buttonAddICD" type="is-primary" icon-left="magnify"
					@click="openSearchICDCodeModal">ICD-Code
					suchen</b-button><br>
			</b-field>
			<b-field expanded grouped>
				<ul expanded v-if="reportICDCodeTemp.length != 0">
					<li :key="rICDt.id" v-for="(rICDt, index) in reportICDCodeTemp"><b-button size="is-small"
							type="is-primary mr-5 mb-1" @click="removeICD10Code(index)" icon-left="minus" /><span>{{
			rICDt.bezeichner }}</span></li>
				</ul>
			</b-field>
		</template>

		<template v-if="mask == 'view' && reportICDCodeTemp && reportICDCodeTemp.length != 0">
			<b-field expanded grouped class="bfield_w_margin_label">
				<template #label>
					<div class="align-content-center" style="display: inline-flex">
						<p>ICD-10-Code</p>
					</div>
				</template>
				<ul v-if="reportICDCodeTemp.length != 0">
					<li :key="rICDt.id" v-for="(rICDt) in reportICDCodeTemp">{{ rICDt.bezeichner }}</li>
				</ul>
			</b-field>
		</template>

		<template v-if="mask == 'new' || mask == 'edit'">
			<b-field>
				<template #label>
					<div class="align-content-center" style="display: inline-flex">
						<p> Beginn der Behandlung </p>
					</div>
				</template>
				<b-datepicker :first-day-of-week=1 :date-formatter="dateFormatter" trap-focus editable locale="de-DE"
					v-model="reportBeginDate">
				</b-datepicker>
			</b-field>
		</template>

		<template v-if="mask == 'view' && reportBeginDate">
			<b-field>
				<template #label>
					<div class="align-content-center" style="display: inline-flex">
						<p> Beginn der Behandlung </p>
					</div>
				</template>
				{{ reportBeginDate.toLocaleDateString("de-DE",
			{
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
			}) }}
			</b-field>
		</template>

		<template v-if="mask == 'new' || mask == 'edit'">
			<b-field>
				<template #label>
					<div class="align-content-center" style="display: inline-flex">
						<p> Ende der Behandlung </p>
					</div>
				</template>
				<b-datepicker :first-day-of-week=1 :date-formatter="dateFormatter" trap-focus editable locale="de-DE"
					v-model="reportEndDate">
				</b-datepicker>
			</b-field>
		</template>

		<template v-if="mask == 'view' && reportEndDate">
			<b-field>
				<template #label>
					<div class="align-content-center" style="display: inline-flex">
						<p> Ende der Behandlung </p>
					</div>
				</template>
				{{ reportEndDate.toLocaleDateString("de-DE",
			{
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
			}) }}
			</b-field>
		</template>

		<template v-if="mask == 'new' || mask == 'edit'">
			<b-field>
				<template #label>
					<div class="align-content-center" style="display: inline-flex">
						<p>Anmerkungen</p>
					</div>
				</template>
				<b-input type="textarea" v-model="reportHint" />
			</b-field>
		</template>

		<template v-if="mask == 'view' && reportHint">
			<b-field>
				<template #label>
					<div class="align-content-center" style="display: inline-flex">
						<p>Anmerkungen</p>
					</div>
				</template>
				{{ reportHint }}
			</b-field>
		</template>

		<template v-if="mask == 'new' || mask == 'edit'">
			<b-field expanded :message="($v.imageCounter.$invalid) ? 'Bitte laden Sie einen Anhang hoch!' : ''"
				:type="($v.imageCounter.$invalid) ? 'is-danger' : 'is-success'">
				<template #label>
					<div class="align-content-center" style="display: inline-flex">
						<p> Anhang </p>
					</div>
				</template>
				<FileInput style="width:100%;" expanded :drop-files-modified="dropFilesModified"
					:add-modified-drop-files="addModifiedDropFiles" :set-modified-drop-files="setModifiedDropFiles"
					:flushDropFiles="flushDropFiles" :allowData="true" /><br>
			</b-field>
			<b-field>
				<MedicationEditAttachements style="width:100%;" expanded
					:attachment="existingFiles?.length != 0 || existingOtherFiles?.length != 0"
					:pictures="existingFiles" :remove-picture="removePicture" :otherFiles="existingOtherFiles"
					:remove-files="removeFile" />
			</b-field>
		</template>

		<template v-if="mask == 'view'">
			<b-field class="bfield_w_margin_label">
				<template #label>
					<div class="align-content-center" style="display: inline-flex">
						<p>Anhang </p>
					</div>
				</template>
				<div class="content mb-2 ml-1">
					<div v-if="images" class="columns imagesContainer mb-1" style="flex-wrap: wrap">
						<div v-for="(image, i) in images" class="column is-one-third imageContainer" :key="i">
							<img class="image" :src="image" @click="index = i" />
						</div>
						<vue-gallery-slideshow :images="images" :index="index"
							@close="index = null"></vue-gallery-slideshow>
					</div>
					<div v-if="otherFiles.length > 0">
						<hr style="height: 1px; background-color: black" />
						<div v-for="(file, i) in otherFiles" :key="i">
							<div class="fileItem">
								<b-icon icon="file-pdf-box" size="is-medium" />
								<a tag="a" :href="file.attributes.file._url" target="_blank"
									style="padding-top: 4px; margin-left: 6px">
									{{ file.attributes.fileTitle }}
								</a>
							</div>
						</div>
					</div>
				</div>
			</b-field>
		</template>

		<template v-if="mask == 'new' || mask == 'edit'">
			<div style="display: flex; justify-content: center">
				<b-button class="mt-3 button mb-5" type="is-primary" @click="saveReport" v-bind:loading="isLoading"
					v-bind:disabled="$v.reportDoctor.$invalid || $v.reportDate.$invalid
			|| $v.reportDiagnose.$invalid || $v.imageCounter.$invalid" :size="isMobile ? 'is-small' : ''">Speichern
				</b-button>
			</div>
			<NewDoctorModal v-if="isDoctorModalActive" @emit-new-doctor="val => reportDoctor = val"
				v-model="isDoctorModalActive"></NewDoctorModal>
			<NewDiagnoseModal v-if="isDiagnoseModalActive" @emit-new-diagnose="val => reportDiagnose = val"
				v-model="isDiagnoseModalActive"></NewDiagnoseModal>
			<SearchICDCodeModal v-if="isSearchICDCodeModalActiv"
				@emit-new-icd-10-code="val => reportICD10Code.push(val)" v-model="isSearchICDCodeModalActiv">
			</SearchICDCodeModal>
		</template>

	</div>
</template>

<script>
/**
 * Sub-Module allowing the creation of a new  medication report.
 * @displayName ReportInput
 */
import { mapGetters } from "vuex";
import { required, minLength } from "vuelidate/lib/validators";
import NewDoctorModal from "@/components/modals/NewDoctorModal.vue";
import NewDiagnoseModal from "@/components/modals/NewDiagnoseModal.vue";
import SearchICDCodeModal from "@/components/modals/SearchICDCodeModal.vue";
import FileInput from "@/components/helper/FileInput";
import Parse from "parse/dist/parse.min.js";
import { v4 as uuid } from "uuid";
import VueGallerySlideshow from "vue-gallery-slideshow";
import MedicationEditAttachements from "@/components/helper/medicationHelper/MedicationEditAttachements.vue";
import MedicationReportInputSelect from "@/components/helper/medicationHelper/MedicationReportInputSelect.vue";
import { negativeFeedBack, positiveFeedBack } from "@/lib/Toast";
import DoctorSearchableSelect from "@/components/helper/SearchableSelects/DoctorSearchableSelect.vue";
import DiagnoseSearchableSelect from "@/components/helper/SearchableSelects/DiagnoseSearchableSelect.vue";

export default {
	name: "ReportInput",
	props: ["proppedReportObject", "proppedReportObjectNumber", "proppedReportIsLoading", "mask", "proppedHasLog"],
	components: {
		NewDiagnoseModal,
		NewDoctorModal,
		FileInput,
		SearchICDCodeModal,
		VueGallerySlideshow,
		MedicationEditAttachements,
		MedicationReportInputSelect,
		DoctorSearchableSelect,
		DiagnoseSearchableSelect,
	},
	emits: ['emit-data-object'],
	validations: {
		reportDoctor: {
			required,
		},
		reportDate: {
			required,
		},
		reportDiagnose: {
			required,
		},
		// dropFilesModified: {
		// 	required,
		// 	minLength: minLength(1),
		// },
		imageCounter: {
			required,
			validator: function (value) {
				return value > 0
			}
		}
	},
	data() {
		return {
			isDoctorModalActive: false,
			isDiagnoseModalActive: false,
			isSearchICDCodeModalActiv: false,

			reportNumber: -1,
			reportDoctor: undefined,
			reportDate: undefined,
			reportDiagnose: undefined,
			reportICD10Code: [],
			reportBeginDate: undefined,
			reportEndDate: undefined,
			reportHint: "",
			dropFilesModified: [],
			existingFiles: [],
			existingOtherFiles: [],
			flushDropFiles: false,

			reportICDCodeTemp: [],
			index: null,

			imageCounter: 0,
			reportObjectExists: undefined,
		};
	},
	mounted() {
		if (this.reportObject) {
			this.reportNumber = this.reportObject.number;
			this.reportDate = this.reportObject.attributes.date;
			this.reportICD10Code = this.reportObject.attributes.icd_10_code_array;
			this.reportBeginDate = this.reportObject.attributes.treatmentBegin;
			this.reportEndDate = this.reportObject.attributes.treatmentEnd;
			this.reportHint = this.reportObject.attributes.hint;
			if (this.reportObject.attributes.files) {
				for (var i = 0; i < this.reportObject.attributes.files.length; i++) {
					if (this.reportObject.attributes.files[i]._url) {
						this.existingFiles.push(this.reportObject.attributes.files[i]);
					} else {
						this.dropFilesModified.push(this.reportObject.attributes.files[i]);
					}
					this.imageCounter++;
				}
			}

			if (this.reportObject.attributes.otherFiles) {
				for (var k = 0; k < this.reportObject.attributes.otherFiles.length; k++) {
					this.existingOtherFiles.push(this.reportObject.attributes.otherFiles[k]);
					this.imageCounter++;
				}
			}

			this.reportDoctor = this.calculatePointerData(this.reportObject.attributes.doctor, this.doctorArray);
			this.reportDiagnose = this.calculatePointerData(this.reportObject.attributes.diagnose, this.diagnoseArray);

			this.reportICDCodeTemp = [];
			if (this.reportICD10Code) {

				for (var j = 0; j < this.reportICD10Code.length; j++) {
					this.reportICDCodeTemp.push({ "element": this.reportICD10Code[j], "bezeichner": this.reportICD10Code[j].attributes.icd_10_code + " " + this.reportICD10Code[j].attributes.name });
				}

			} else if (this.mask != "view") {
				this.reportICDCodeTemp = [];
			} else {
				this.reportICDCodeTemp = [];
			}

		}
	},
	computed: {
		getSize() {
			return this.$store.getters.isMobile ? "is-small" : "";
		},
		isMobile() {
			return this.$store.getters.isMobile;
		},
		...mapGetters({
			doctorArray: "doctors/getData",
			diagnoseArray: "diagnoses/getData",
			icd10CodeArray: "icd_10_codes/getData",
		}),
		isLoading: {
			get() {
				return this.proppedReportIsLoading
			},
			set(val) {
				this.$emit("emit-save-report-input", val)
			}
		},
		reportObject: {
			get() {

				return this.proppedReportObject
			},
			set(val) {
				this.$emit("emit-report-object", val)
			}
		},
		reportObjectNumber() {
			return this.proppedReportObjectNumber
		},
		images() {
			if (this.existingFiles) {
				return this.existingFiles.map(
					(pic) => pic._url
				);
			}
			return undefined;
		},
		otherFiles() {
			if (this.reportObject.attributes.otherFiles) {
				return this.reportObject.attributes.otherFiles
			}
			return [];
		}
	},
	methods: {
		addModifiedDropFiles(value) {
			this.dropFilesModified.push(value)
		},
		setModifiedDropFiles(value) {
			this.dropFilesModified = value
		},
		dateFormatter(dt) {
			return dt.toLocaleDateString("de-DE", {
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
			});
		},
		openSearchICDCodeModal() {
			this.isSearchICDCodeModalActiv = true;
		},
		async saveReport() {
			this.isLoading = true;
			await positiveFeedBack("Bericht gespeichert!")
		},
		removePicture(index) {
			this.existingFiles.splice(index, 1);
		},
		removeFile(index) {
			this.existingOtherFiles.splice(index, 1);
		},
		removeICD10Code(index) {
			this.reportICD10Code.splice(index, 1);
		},
		calculatePointerData(value, array) {
			//Edit-Maske braucht einen Pointer für das Drop-Down
			if (this.mask == "edit" || this.mask == "new") {
				if (value && value.__type != 'Pointer') {
					return value.toPointer();
				} else {
					return value;
				}
			} else if (this.mask == "view") {
				//View-Maske benötogt die reinen Daten und keinen Pointer
				if (value && value.__type == 'Pointer') {
					for (var l = 0; l < array.length; l++) {
						if (array[l].id == value.objectId) {
							return array[l];
						}
					}
				} else {
					return value;
				}
			}

		}
	},
	watch: {
		reportNumber(newVal) {
			if (newVal) {
				this.reportObject.set("number", newVal)
			}
		},
		reportDoctor(newVal) {
			this.reportObject.set("doctor", newVal)
		},
		reportDate(newVal) {
			this.reportObject.set("date", newVal)
		},
		reportDiagnose(newVal) {
			this.reportObject.set("diagnose", newVal)
		},
		reportICD10Code(newVal) {

			if (newVal) {
				this.reportICDCodeTemp = [];
				for (var i = 0; i < newVal.length; i++) {
					this.reportICDCodeTemp.push({ "id": newVal[i].objectId, "element": newVal[i], "bezeichner": newVal[i].attributes.icd_10_code + " " + newVal[i].attributes.name });
				}

			}

			this.reportObject.set("icd_10_code_array", newVal)
		},
		reportBeginDate(newVal) {
			this.reportObject.set("treatmentBegin", newVal)
		},
		reportEndDate(newVal) {
			this.reportObject.set("treatmentEnd", newVal)
		},
		reportHint(newVal) {
			this.reportObject.set("hint", newVal)
		},
		dropFilesModified(newVal) {
			this.reportObject.set("files", newVal.concat(this.existingFiles))
			if (this.reportObject?.attributes?.files?.length) {
				this.imageCounter = this.reportObject?.attributes?.files?.length
			}
			if (this.reportObject?.attributes?.otherFiles?.length) {
				this.imageCounter = this.reportObject?.attributes?.otherFiles?.length
			}
			if (this.reportObject?.attributes?.otherFiles?.length && this.reportObject?.attributes?.files?.length) {
				this.imageCounter = this.reportObject?.attributes?.otherFiles?.length + this.reportObject?.attributes?.files?.length
			}
		},
		existingFiles(newVal) {
			this.reportObject.set("files", newVal.concat(this.dropFilesModified))
			if (this.reportObject?.attributes?.files?.length) {
				this.imageCounter = this.reportObject?.attributes?.files?.length
			}
			if (this.reportObject?.attributes?.otherFiles?.length) {
				this.imageCounter = this.reportObject?.attributes?.otherFiles?.length
			}
			if (this.reportObject?.attributes?.otherFiles?.length && this.reportObject?.attributes?.files?.length) {
				this.imageCounter = this.reportObject?.attributes?.otherFiles?.length + this.reportObject?.attributes?.files?.length
			}
		},
		existingOtherFiles(newVal) {
			this.reportObject.set("otherFiles", newVal.concat(this.dropFilesModified))
			if (this.reportObject?.attributes?.files?.length) {
				this.imageCounter = this.reportObject?.attributes?.files?.length
			}
			if (this.reportObject?.attributes?.otherFiles?.length) {
				this.imageCounter = this.reportObject?.attributes?.otherFiles?.length
			}
			if (this.reportObject?.attributes?.otherFiles?.length && this.reportObject?.attributes?.files?.length) {
				this.imageCounter = this.reportObject?.attributes?.otherFiles?.length + this.reportObject?.attributes?.files?.length
			}
		},
		reportObjectExists(newVal) {
			if (newVal) {
				this.reportObject = newVal;
				this.reportNumber = newVal.attributes.number;
				this.reportDate = newVal.attributes.date;
				this.reportICD10Code = newVal.attributes.icd_10_code_array;
				this.reportBeginDate = newVal.attributes.treatmentBegin;
				this.reportEndDate = newVal.attributes.treatmentEnd;
				this.reportHint = newVal.attributes.hint;

				if (newVal.attributes.files) {
					for (var i = 0; i < newVal.attributes.files.length; i++) {
						if (newVal.attributes.files[i]._url) {
							this.existingFiles.push(newVal.attributes.files[i]);
						} else {
							this.dropFilesModified.push(newVal.attributes.files[i]);
						}
						this.imageCounter++;
					}
				}

				if (newVal.attributes.otherFiles) {
					for (var k = 0; k < newVal.attributes.otherFiles.length; k++) {
						this.existingOtherFiles.push(newVal.attributes.otherFiles[k]);
						this.imageCounter++;
					}
				}

				if (newVal.attributes.doctor.__type != 'Pointer') {
					this.reportDoctor = newVal.attributes.doctor.toPointer();
				} else {
					this.reportDoctor = newVal.attributes.doctor;
				}

				if (newVal.attributes.diagnose.__type != 'Pointer') {
					this.reportDiagnose = newVal.attributes.diagnose.toPointer();
				} else {
					this.reportDiagnose = newVal.attributes.diagnose;
				}
				this.reportICD10Code = newVal.attributes.icd_10_code_array;
				this.reportICDCodeTemp = [];

				if (this.reportICD10Code) {

					for (var j = 0; j < this.reportICD10Code.length; j++) {
						this.reportICDCodeTemp.push({ "element": this.reportICD10Code[j], "bezeichner": this.reportICD10Code[j].attributes.icd_10_code + " " + this.reportICD10Code[j].attributes.name });
					}
				} else if (this.mask != "view") {
					this.reportICDCodeTemp = [];
				} else {
					this.reportICDCodeTemp = [];
				}
			}
		}
	}
};
</script>

<style scoped>
.buttonRemove,
.buttonNew {
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
}

.buttonAddICD {
	/*border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;*/
}
</style>
