<template>
    <div>
        <editor-content :editor="editor" />
    </div>
</template>

<script>
/**
 * Editor for creation of text in an entry.
 * @displayName Editor
 */

import { Editor, EditorContent } from "@tiptap/vue-2";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import Placeholder from "@tiptap/extension-placeholder";
import BlockQuote from "@tiptap/extension-blockquote";

export default {
    components: {
        EditorContent,
    },

    props: {
        value: {
            type: String,
            default: "",
        },
    },

    data() {
        return {
            editor: null,
        };
    },

    mounted() {
        this.editor = new Editor({
            extensions: [
                Document,
                Paragraph,
                Text,
                BlockQuote,
                Placeholder.configure({ placeholder: "Schreib etwas..." }),
            ],

            content: this.value,
            onUpdate: () => {
                // HTML
                this.html = this.editor.getHTML();
                if (this.html === "<p></p>") this.html = "";
                this.$emit("input", this.html);
                // JSON
                // this.$emit('input', this.editor.getJSON())
            },
        });
    },

    watch: {
        value(value) {
            // HTML
            const isSame = this.editor.getHTML() === value;

            // JSON
            // const isSame = this.editor.getJSON().toString() === value.toString()

            if (isSame) {
                return;
            }

            this.editor.commands.setContent(this.value, false);
        },
    },

    beforeDestroy() {
        this.editor.destroy();
    },
};
</script>

<style lang="scss">
.ProseMirror {
    border-radius: 6px;
}

.editor_body {
    border-radius: 6px;
}

/* Basic editor styles */
.ProseMirror {
    > * + * {
        margin-top: 0.75em;
    }

    ul,
    ol {
        padding: 0 1rem;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        line-height: 1.1;
    }

    code {
        background-color: rgba(#616161, 0.1);
        color: #616161;
    }

    pre {
        background: #0d0d0d;
        color: #fff;
        font-family: "JetBrainsMono", monospace;
        padding: 0.75rem 1rem;
        border-radius: 0.5rem;

        code {
            color: inherit;
            padding: 0;
            background: none;
        }
    }

    img {
        max-width: 100%;
        height: auto;
    }

    blockquote {
        padding-left: 1rem;
        border-left: 2px solid rgba(#0d0d0d, 0.1);
    }

    hr {
        border: none;
        border-top: 2px solid rgba(#0d0d0d, 0.1);
        margin: 2rem 0;
    }
}

.ProseMirror p.is-editor-empty:first-child::before {
    content: attr(data-placeholder);
    float: left;
    color: #ced4da;
    pointer-events: none;
    height: 0;
}

.ProseMirror {
    min-height: 5rem;
}
.editor_body {
    border: 1px solid lightgrey;
    padding: 10px;
}

@media (max-width: 769px) {
    .editor_body {
        padding: 0.25rem;
    }
}
</style>
