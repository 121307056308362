<template>
	<div>
		<div ref="entryPanel" v-if="medicationLogEntries &&
			this.$store.state.usermetas.data.length > 0 &&
			this.$store.state.users.data.length > 0
			" style="user-select: none" class="scrollable is-flex is-flex-direction-column" id="entryContainer">
			<div class="pr-1" @click="giveMedication(entryLink, entryLink.attributes.medication)"
				v-for="(entryLink) in activeArray" :key="'active_' + entryLink.id">
				<template v-if="entryLink.attributes.medication">
					<div
						v-if="(entryLink.attributes.medication.attributes.medication.attributes.dropDate ? entryLink.attributes.medication.attributes.medication.attributes.dropDate.setHours(0, 0, 0, 0) > entryLink.attributes.date.setHours(0, 0, 0, 0) : true)">
						<MedicationLogEntriesData v-if="entryLink.active == 1" :entryLink="entryLink"
							:style="{ 'background-color': entryLink.attributes.state == -1 ? '#feecef' : entryLink.attributes.state == 1 ? '#e2f7f1' : '' }" />
					</div>
				</template>
				<template v-else>
					<div>
						<MedicationLogEntriesData v-if="entryLink.active == 1" :entryLink="entryLink"
							:style="{ 'background-color': entryLink.attributes.state == -1 ? '#feecef' : entryLink.attributes.state == 1 ? '#e2f7f1' : '' }" />
					</div>
				</template>
			</div>

			<div class="pr-1"
				v-for="(entryLink) in medicationSelfLogEntries" :key="'self_' + entryLink.id" @click="giveSelfMedication(entryLink)">
				<template>
					<div >
						<MedicationSelfLogEntryData :entryLink="entryLink"/>
					</div>
				</template>
			</div>

			<div class="pr-1" @click="giveInstantMedication()">
				<div>
					<MedicationInstantLogEntryData />
				</div>
			</div>


			<div style="background-color:transparent;" class="entry-box-entry p-0 box mb-0 mt-1 mb-1">
				<div class="has-text-centered article has-darker-grey-color white-text p-3">
					Historie
				</div>
			</div>

			<div class="pr-1" @click="giveMedication(entryLink, entryLink.attributes.medication)"
				v-for="(entryLink, index) in inactiveArray" :key="'inactive_' + entryLink.id">
				<template v-if="(index < maxViewPortIndex && index >= maxViewPortIndex - 10)">
					<template v-if="entryLink.attributes.medication">
						<div
							v-if="(entryLink.attributes.medication.attributes.medication.attributes.dropDate ? entryLink.attributes.medication.attributes.medication.attributes.dropDate.setHours(0, 0, 0, 0) > entryLink.attributes.date.setHours(0, 0, 0, 0) : true)">
							<MedicationLogEntriesData v-if="entryLink.active == 0" :entryLink="entryLink"
								:style="{ 'background-color': entryLink.attributes.state == -1 ? '#E1C2C7' : entryLink.attributes.state == 1 ? '#B5D2CE' : '#f5f5f5' }" />
						</div>
					</template>
					<template v-else>
						<MedicationLogEntriesData v-if="entryLink.active == 0" :entryLink="entryLink"
							:style="{ 'background-color': entryLink.attributes.state == -1 ? '#E1C2C7' : entryLink.attributes.state == 1 ? '#B5D2CE' : '#f5f5f5' }" />
					</template>
				</template>
			</div>
			<b-pagination class="p-0 mt-1 mb-1 ml-1 mr-1 pagination-dark" :total="total" v-model="current"
				:range-before="rangeBefore" :range-after="rangeAfter" :order="order" :size="size" :simple="isSimple"
				:rounded="isRounded" :per-page="perPage" :icon-prev="prevIcon" :icon-next="nextIcon"
				aria-next-label="Next page" aria-previous-label="Previous page" aria-page-label="Page"
				aria-current-label="Current page" :page-input="hasInput" :page-input-position="inputPosition"
				:debounce-page-input="inputDebounce" @change="onPageChange(current)">
			</b-pagination>
		</div>
		<div v-else>
			<EntryInfo />
		</div>
		<GiveMedicationModal v-if="isClientEditModalActive" :logObject="focusedLog" v-model="isClientEditModalActive" />
		<EditInstantMedicationModal v-if="isInstantEditModalActive" :logObject="focusedLog"
			v-model="isInstantEditModalActive" />
		<AddInstantMedicationModal v-if="isInstantAddModalActive" v-model="isInstantAddModalActive" />
		<AddSelfMedicationModal v-if="isSelfAddModalActive" :selfMedication="focusedSelfMedication" v-model="isSelfAddModalActive" />
		<EditSelfMedicationModal v-if="isSelfEditModalActive" :logObject="focusedLog"
			v-model="isSelfEditModalActive" />
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import MedicationLogEntriesData from "@/components/modules/medication/MedicationLogEntriesData";
import MedicationInstantLogEntryData from "@/components/modules/medication/MedicationInstantLogEntryData";
import MedicationSelfLogEntryData from "@/components/modules/medication/MedicationSelfLogEntryData";
import GiveMedicationModal from "@/components/modals/GiveMedicationModal.vue";
import AddInstantMedicationModal from "@/components/modals/AddInstantMedicationModal.vue";
import AddSelfMedicationModal from "@/components/modals/AddSelfMedicationModal.vue";
import EditInstantMedicationModal from "@/components/modals/EditInstantMedicationModal.vue";
import EditSelfMedicationModal from "@/components/modals/EditSelfMedicationModal.vue";

export default {
	name: "MedicationLogEntries",
	components: {
		MedicationLogEntriesData,
		MedicationInstantLogEntryData,
		MedicationSelfLogEntryData,
		GiveMedicationModal,
		AddInstantMedicationModal,
		EditInstantMedicationModal,
		AddSelfMedicationModal,
		EditSelfMedicationModal,
	},
	data() {
		return {
			current: 1,
			perPage: 10,
			rangeBefore: 3,
			rangeAfter: 1,
			order: '',
			size: 'is-small',
			isSimple: false,
			isRounded: false,
			hasInput: false,
			prevIcon: 'chevron-left',
			nextIcon: 'chevron-right',
			inputPosition: '',
			inputDebounce: '',

			isClientEditModalActive: false,
			isInstantEditModalActive: false,
			isInstantAddModalActive: false,
			isSelfAddModalActive: false,
			isSelfEditModalActive: false,
			focusedLog: undefined,
			focusedSelfMedication: undefined,
			isLoading: false,
			maxViewPortIndex: 10,
			viewPortIncreaseIteration: 1,
			scrollPosition: 0,
		};
	},
	beforeCreate() { },
	mounted() {
		this.onPageChange()
	},
	updated() { },
	watch: {
		focusedClient() {
			this.maxViewPortIndex = 10
			this.scrollPosition = 0
			this.viewPortIncreaseIteration = 1
		},
	},
	computed: {
		...mapGetters({
			medicationLogEntries: "medicationLogEntries/getFilteredMedicationLogEntries",
			medicationSelfLogEntries: "medicationEntries/getSelfMedicationEntries",
			focusedClient: "clients/getFocusedClient"
		}),
		activeArray() {
			let retArray = []
			this.medicationLogEntries.forEach(element => {
				if (element.active == 1 && element.dropped == 0) {
					retArray.push(element)
				}
			});
			return retArray
		},
		inactiveArray() {
			let retArray = []
			this.medicationLogEntries.forEach(element => {
				if (element.active == 0 && element.dropped == 0) {
					retArray.push(element)
				}
			});
			return retArray
		},
		total() {
			let retValue = this.inactiveArray.length ? this.inactiveArray.length : 0
			return retValue
		}
	},
	methods: {
		onPageChange() {
			this.maxViewPortIndex = 10 * this.current;
		},
		//FIXME Das hier könnte noch zusammengefasst werden. Quasi ein Modal anstelle von 2. Dazu sollte dann allerdings der Unterschied bei der Mounted Methode beachtet werden.
		giveMedication(logObject, medicationExists) {
			this.focusedLog = logObject

			if (medicationExists) {
				if(medicationExists.attributes.medicationPlan.attributes.art == 1)
				{
					this.isClientEditModalActive = true;
				}else if(medicationExists.attributes.medicationPlan.attributes.art == 2)
				{
					this.isClientEditModalActive = true;
				}else if(medicationExists.attributes.medicationPlan.attributes.art == 3)
				{
					this.isSelfEditModalActive = true;
				}
			} else {
				this.isInstantEditModalActive = true;
			}

		},
		giveInstantMedication() {
			this.isInstantAddModalActive = true;
		},
		giveSelfMedication(logObject) {
			this.focusedSelfMedication = logObject;
			this.isSelfAddModalActive = true;
		},
		navigateMedicationLogEntryDetail(entry) {
			this.$store.dispatch(
				"medicationLogEntries/setFocusedMedicationLogEntry",
				entry
			);
			this.$store.dispatch(
				"medicationLogEntries/setFocusedMedicationLogEntryId",
				entry.id
			);
			this.$router.push({
				name: 'MedicationLogEntry',
			})
		}
	},
}
</script>

<style scoped>
::v-deep .skeleton-title {
	width: 30%;
	height: 1.15rem;
	margin-bottom: .7rem;
	border-radius: .125rem;
}

::v-deep .skeleton-author {
	width: 20%;
	height: .6rem;
	margin-bottom: .5rem;
	margin-top: .25rem;
	border-radius: .25rem;
}

.white-text {
	color: white;
}

.entry-box {
	margin-right: 0.5em;
	margin-left: 0.5em;
	cursor: pointer;
	padding-bottom: 15px;
}

.entry-box:hover {
	background-color: whitesmoke;
}

.scrollable {
	overflow: auto;
	height: 100%;
	width: 100%;
	padding-bottom: 1.5rem;
}

.article {
	width: 100%;
	border-radius: 0.5rem;
}

.entry-box-entry {
	margin-right: 0.5em;
	margin-left: 0.5em;
	padding-bottom: 15px;
	text-align: center;
}

@media (max-width: 1026px) {
	.scrollable {
		overflow: auto;
		height: 100%;
		width: 100%;
	}
}

@media (max-width: 769px) {

	.tabs:not(:last-child),
	.pagination:not(:last-child),
	.message:not(:last-child),
	.level:not(:last-child),
	.breadcrumb:not(:last-child),
	.block:not(:last-child),
	.title:not(:last-child),
	.subtitle:not(:last-child),
	.table-container:not(:last-child),
	.table:not(:last-child),
	.progress:not(:last-child),
	.progress-wrapper.is-not-native:not(:last-child),
	.notification:not(:last-child),
	.content:not(:last-child),
	.box:not(:last-child) {
		margin-bottom: 0;
	}

	.scrollable {
		height: 100%;
		width: 95vw;
	}

	.entry-box {
		display: flex;
		width: 96%;
	}
}

@media (max-width: 400px) {
	div.media-left {
		margin: 0.1em 0 0 0;
	}
}

@media (max-width: 450px) {
	.authorTagContainer {
		display: flex;
		flex-direction: row;
		align-items: start;
	}

	.iconContainer {
		justify-content: start !important;
		display: flex !important;
		flex-direction: row !important;
	}
}
</style>
