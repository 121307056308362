<template>
    <div class="ml-2">
        <b-tooltip :label="label" position="is-bottom">
            <b-icon :icon="icon"> </b-icon>
        </b-tooltip>
    </div>
</template>

<script>
/**
 * Icon if attechements are associated with an entry.
 * @displayName AttachementIcon
 */

export default {
    name: "MedicationIcon",
    props: ["icon", "label"],
};
</script>

<style scoped>
@media (max-width: 450px) {
    .ml-2 {
        margin-left: 0 !important;
        margin-right: 0.5rem !important;
    }
}
</style>
