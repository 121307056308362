import Vue from 'vue'
import App from './App.vue'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'

require('@/assets/styles.scss');

import Vuelidate from 'vuelidate'
import VueLogger from 'vue-logger-plugin'

import store from './lib/store/store';
import router from './lib/router/router';

Vue.config.productionTip = false

// Buefy include
Vue.use(Buefy)
//Vuelidate include
Vue.use(Vuelidate);
Vue.use(VueLogger)

new Vue({
	store,
	router,
	render: h => h(App),
	mounted: function () {}
}).$mount('#app')
