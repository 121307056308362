import Parse from "parse/dist/parse.min.js";
import store from './store/store';

function handleParseError(err) {
	switch (err.code) {
		case Parse.Error.INVALID_SESSION_TOKEN:
			store.dispatch('logoutUser')
			break;
	}
}

export { handleParseError };