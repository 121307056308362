<template>
    <div style="width: 100%">
        <div v-if="focusedClient" style="width: 100%">
            <article class="p-3 article" v-if="!entryLinks">
                Keine Einträge!
            </article>
            <div class="entry-box-entry p-0 box">
                <article
                    class="
                        client-entry-box
                        p-3
                        article
                        has-darker-grey-color
                        white-text white-text
                    "
					v-if="focusedClient.attributes.entry"
                >
                    Zugang am
                    {{
                        focusedClient.attributes.entry.toLocaleDateString(
                            "de-DE",
                            {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                            }
                        )
                    }}
                </article>
				<article
					v-else
					class="
                        client-entry-box
                        p-3
                        article
                        has-darker-grey-color
                        white-text white-text
                    "
				>
					kein Zugangsdatum definiert
				</article>
            </div>
        </div>
        <div
            ref="entryPanel"
            v-else
            class="scrollable is-flex is-flex-direction-column"
        >
            <article class="p-3 article" style="text-align: center">
                Kein Klient ausgewählt!
            </article>
        </div>
    </div>
</template>

<script>
/**
 * Component for the display of the date the focusedClient joined the institution.
 * @displayName EntryInfo
 */

import { mapGetters } from "vuex";

export default {
    name: "EntryInfo",
    computed: {
        ...mapGetters({
            entryLinks: "entrylinks/getFilteredEntryLinks",
            focusedClient: "clients/getFocusedClient",
        }),
    },
};
</script>

<style scoped>
.entry-box-entry {
    margin-right: 0.5em;
    margin-left: 0.5em;
    background-color: rgb(185, 205, 216);
    padding-bottom: 15px;
}

.white-text {
    color: white;
}

.article {
    width: 100%;
    text-align: center;
    border-radius: 0.5rem;
}

.scrollable {
    overflow: auto;
    height: 100%;
    width: 100%;
    padding-bottom: 1.5rem;
}

@media (max-width: 1026px) {
    .scrollable {
        overflow: auto;
        height: 100%;
        width: 95%;
    }
}

@media (max-width: 769px) {
    .scrollable {
        height: 100%;
        width: 95vw;
    }
}
</style>
