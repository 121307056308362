<template>
	<div class="is-flex mb-1" :class="isMobile ? '' : 'm-2 pt-1' ">
		<div v-for="(option) in options" class="is-flex is-align-items-centers mainContainer pr-2" :key="option.id" @click="select(option)"
		:class="isMobile? '' : 'ml-1' " style="align-items: center">
			<div class="is-flex" :class="option.id === chosenId? 'radioContainerFocussed' : 'radioContainer'" >
				<div v-if="chosenId=== option.id" class="radio"></div>
			</div>
			<div class="pl-1 is-flex" style="align-items: center">{{option.attributes.name}}</div>
		</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
	name: "RadioCheckbox",
	props: {
		"options": Array,
		"setSelectedOption": Function
	},
	computed:{
		...mapGetters({
			isMobile: "isMobile"
		})
	},
	data(){
		return{
			chosenId: undefined
		}
	},
	methods:{
		select(option){
			if(this.chosenId === option.id) {
				this.setSelectedOption(undefined)
				return this.chosenId = undefined
			}
			this.chosenId = option.id
			this.setSelectedOption(option)
		}
	}
}
</script>

<style scoped>

.radio{

	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: .65rem;
	background-color: black;
	height: .6rem;
	border-radius: 50%;
}

.radioContainer{
	align-items: center;
	position: relative;
	border: 2px solid rgba(0,0,0,.5);
	border-radius: 50%;
	width: 1.25rem;
	height: 1.25rem;
}

div:hover>.radioContainer{
	align-items: center;
	position: relative;
	border: 2px solid rgba(0,0,0,0.8);
	border-radius: 50%;
	width: 1.25rem;
	height: 1.25rem;
}

.radioContainerFocussed{
	align-items: center;
	position: relative;
	border: 2px solid rgba(0,0,0,0.8);
	border-radius: 50%;
	width: 1.25rem;
	height: 1.25rem;
}
div{
	user-select: none;
}
.mainContainer{
	cursor: pointer;
}
</style>
