<template>
	<b-modal v-model="isActive" has-modal-card @close="closeModal()" :can-cancel="false">
		<div class="modal-card modalCardContainer"
			style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%);">
			<header class="modal-card-head" style="background-color: rgba(40, 40, 40, 1); color: white">
				Erfassung Medikamenteneinnahme für den {{ logObject.attributes.date.toLocaleDateString("de-DE",
					{
						year: "numeric",
						month: "2-digit",
						day: "2-digit",
					}) }}, {{ logObject.attributes.time }}
			</header>
			<section class="modal-card-body" style="padding: 0.5em; color: #313131">
				<span> <b>{{ logObject.attributes.time }}: {{ logObject.attributes.amount + " " +
					logObject.attributes.medication.attributes.medication.attributes.medication.attributes.medicationUnit.attributes.symbol
				}}</b>
				</span>
				<div class="is-flex is-flex-direction-column">
					<div class="block mb-1 mt-2">
						<b-radio v-model="radioStatus" name="status" class="mr-4" :native-value="1">
							Genommen
						</b-radio>
						<b-radio v-model="radioStatus" name="status" class="mr-4" :native-value="-1">
							Verweigert
						</b-radio>
						<b-radio v-model="radioStatus" name="status" :native-value="0">
							Keine Angabe
						</b-radio>
					</div>
					<div>
						<b-field>
							<b-checkbox class="has-text-left" v-model="externalMedication">
								Fremdvergabe
							</b-checkbox>
						</b-field>
					</div>
					<UserSearchableSelect :data-options="filteredUsers" :editObject="medicationContactUser"
						:enable-validation="true" validationMessage="Bitte wählen Sie einen Verantwortlichen aus!"
						label-text="Verabreicht von" :enable-order="true"
						:select="(object) => (medicationContactUser = object)" />
					<div v-if="radioStatus == 0 || radioStatus == 1">
						<b-field expanded>
							<template #label>
								<div class="align-content-center" style="display: inline-flex">
									<p>Anmerkungen</p>
								</div>
							</template>
							<b-input type="text" v-model="hintOptional" />
						</b-field>
					</div>
					<div v-if="radioStatus == -1">
						<b-field
							:message="(!$v.hintRequired.$model) ? 'Bitte geben Sie den Grund der Verweigerung ein!' : ''"
							:type="(!$v.hintRequired.$model) ? 'is-danger' : 'is-success'">
							<template #label>
								<div class="align-content-center" style="display: inline-flex">
									<p>Grund für die Verweigerung</p>
								</div>
							</template>
							<b-input type="text" v-model="hintRequired" />
						</b-field>
					</div>
				</div>
			</section>
			<footer class="modal-card-foot footerContainer" style=" color: white">
				<b-button @click="closeModal()" type="is-primary">
					Abbrechen
				</b-button>
				<b-button @click="saveGiveMedication()" type="is-primary" v-bind:loading="isLoading"
					v-bind:disabled="$v.medicationContactUser.$invalid || $v.hintRequired.$invalid">
					Speichern
				</b-button>
			</footer>
		</div>
	</b-modal>
</template>

<script>
import { required, requiredUnless } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import { negativeFeedBack, positiveFeedBack } from "@/lib/Toast";
import Parse from "parse/dist/parse.min.js";
import UserSearchableSelect from "@/components/helper/SearchableSelects/UserSearchableSelect.vue";

export default {
	name: "GiveMedicationModal",
	components: {
		UserSearchableSelect,
	},
	props: ["value", "logObject"],
	data() {
		return {
			isLoading: false,
			radioStatus: this.logObject.attributes.state ? this.logObject.attributes.state : 0,
			medicationContactUser: undefined,
			hintOptional: "",
			hintRequired: "",
			externalMedication: false,
		};
	},
	validations: {
		medicationContactUser: {
			required,
		},
		hintRequired: {
			required: requiredUnless('isReqired')
		},
	},
	watch: {
		radioStatus(newValue) {
			this.hintOptional = "";
			this.hintRequired = "";
		}
	},
	mounted() {
		if (this.logObject.attributes.givingUser) {
			this.medicationContactUser = this.logObject.attributes.givingUser.toPointer();
		} else {
			this.medicationContactUser = this.logObject.attributes.medication.attributes.medication.attributes.contactUser.toPointer();
		}

		if (this.logObject.attributes.hint) {
			if (this.radioStatus == 0 || this.radioStatus == 1) {
				this.hintOptional = this.logObject.attributes.hint
			} else {
				this.hintRequired = this.logObject.attributes.hint
			}
		}

		if (this.logObject.attributes.externalMedication) {
			this.externalMedication = this.logObject.attributes.externalMedication
		}
	},
	computed: {
		...mapGetters({
			users: "users/getFilteredUsers",
		}),
		isActive: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit("input", val);
			},
		},
		filteredUsers() {
			let filteredClients = []
			if (this.users) {
				this.users.forEach(element => {
					if (element.attributes.dependency.attributes.first_name ||
						element.attributes.dependency.attributes.last_name)
						filteredClients.push(element)
				});
			}
			return filteredClients
		},
		isReqired() {
			return this.radioStatus != -1
		},
	},
	methods: {
		closeModal() {
			this.hintOptional = "";
			this.radioStatus = 0;
			this.medicationContactUser = undefined;
			this.isActive = false;
		},
		async saveGiveMedication() {
			this.isLoading = true;
			const dataObject = this.logObject
			dataObject.set("state", this.radioStatus)
			dataObject.set("hint", this.hintOptional ? this.hintOptional : this.hintRequired)
			dataObject.set("externalMedication", this.externalMedication)
			
			for(let i=0; i<this.users.length; i++)
			{
				if(this.users[i].id == this.medicationContactUser.objectId)
				{
					dataObject.set("givingUser", this.users[i])
				}
			}
			

			await dataObject.save();

			this.isLoading = false;
			this.closeModal()
			await positiveFeedBack("Verabreichung dokumentiert!")
		},
	}
}

</script>



<style scoped></style>
  