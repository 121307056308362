import Vue from 'vue'
import VueRouter from 'vue-router'
import Parse from 'parse/dist/parse.min.js';

import Login from "@/components/modules/Login.vue";
import Diary from "@/components/modules/diary/Diary.vue";
import Goal from "@/components/modules/goal/Goal.vue";
import GoalInput from "@/components/modules/goal/GoalInput.vue";
import GoalDetail from "@/components/modules/goal/GoalDetail.vue";
import GoalRating from "@/components/modules/goal/GoalRating.vue";
import GoalEdit from "@/components/modules/goal/GoalEdit.vue";
import EntryDetail from "@/components/modules/diary/EntryDetail.vue";
import EntryInput from "@/components/modules/diary/EntryInput.vue";
import EntryEdit from "@/components/modules/diary/EntryEdit.vue";
import Selection from "@/components/modules/Selection.vue";
import Module from "@/components/containers/Module.vue"
import Profile from "@/components/modules/profile/Profile.vue"
import ClientRecord from "@/components/modules/record/ClientRecord.vue"
import ClientRecordDetail from "@/components/modules/record/ClientRecordDetail.vue"
import Charts from "@/components/modules/charts/Charts";
import Notifications from "@/components/modules/notification/Notification";
import Imprint from "@/components/modules/about/Imprint";
import DataProtection from "@/components/modules/about/DataProtection";
import NewSelection from "@/components/modules/NewSelection"
import EditWidget from "@/components/modules/EditSelection"
import store from '@/lib/store/store';
import Medication from "@/components/modules/medication/Medication.vue";
import MedicationEntry from "@/components/modules/medication/MedicationEntry.vue";

import { ToastProgrammatic as Toast } from 'buefy'
import ChartDetails from "@/components/charts/ChartDetails";
import { warnFeedBack } from "@/lib/Toast";

// Router include
Vue.use(VueRouter)

// Router initilization
const router = new VueRouter({
	mode: 'history',
	base: __dirname,
	routes: [
		{
			name: 'Root',
			path: '/',
			component: Selection,
			meta: {
				requiresAuth: true
			}
		},
		{
			name: 'Profile',
			path: '/profile',
			component: Profile,
			meta: {
				requiresAuth: true
			}
		},
		{
			name: 'NewWidget',
			path: '/addWidget',
			component: NewSelection,
			meta: {
				requiresAuth: true
			}
		},
		{
			name: 'EditWidget',
			path: '/editWidget',
			component: EditWidget,
			meta: {
				requiresAuth: true
			}
		},
		// login route
		{
			name: 'Login', path: '/login', component: Login, meta: {
				requiresAuth: false
			}
		},
		{
			name: 'Imprint', path: '/imprint', component: Imprint, meta: {
				requiresAuth: false
			}
		},
		{
			name: 'DataProtection', path: '/dataprotection', component: DataProtection, meta: {
				requiresAuth: false
			}
		},
		{
			path: '/module',
			component: Module,
			children: [
				{
					// route with entrypanel, requires login
					name: 'Diary', path: 'diary', component: Diary, meta: {
						requiresAuth: true
					}
				}, // Pass route.params to props
				{
					// entry detail page, requires login
					name: 'EntryDetail', path: 'diary/entry', component: EntryDetail, meta: {
						requiresAuth: true, requiresFocus: true
					}, props: (route) => ({
						...route.params
					})
				},
				{
					// entry input pages, requires login
					name: 'EntryInput', path: 'diary/input', component: EntryInput, meta: {
						requiresAuth: true, requiresFocus: true
					}
				},
				{
					// entry input pages, requires login
					name: 'EntryEdit', path: 'diary/edit', component: EntryEdit, meta: {
						requiresAuth: true, requiresFocus: true
					}
				},
				{
					// route with entrypanel, requires login
					name: 'ClientRecord', path: 'record', component: ClientRecord, meta: {
						requiresAuth: true
					}
				},
				{
					// route with entrypanel, requires login
					name: 'ClientRecordDetail', path: 'record/:id', components: { default: ClientRecordDetail },
					props: { default: true },
					meta: {
						requiresAuth: true, requiresFocus: true
					}
				},
				{
					name: 'Notifications', path: 'notifications', component: Notifications, meta: {
						requiresAuth: true
					}
				},
				{
					name: 'Charts', path: 'charts', component: Charts, meta: {
						requiresAuth: true
					}
				},
				{
					name: 'ChartDetails', path: 'charts/details', component: ChartDetails, meta: {
						requiresAuth: true, requiresFocus: true
					}
				},
				{
					name: 'Goal', path: 'goal', component: Goal, meta: {
						requiresAuth: true
					}
				},
				{
					name: 'GoalDetail', path: 'goal/:id', component: GoalDetail, props: true, meta: {
						requiresAuth: true, requiresFocus: true
					}
				},
				{
					name: 'GoalEdit', path: 'goal/:id/edit', component: GoalEdit, props: true, meta: {
						requiresAuth: true, requiresFocus: true
					}
				},
				{
					name: 'GoalInput', path: 'goal/input', component: GoalInput, meta: {
						requiresAuth: true, requiresFocus: true
					}
				},
				{
					name: 'GoalRating', path: 'goal/rating/:id', component: GoalRating, props: true, meta: {
						requiresAuth: true, requiresFocus: true
					}
				},
				{
					name: 'Medication', path: 'medication', component: Medication, meta: {
						requiresAuth: true
					}
				}, 
				{
					name: 'MedicationEntry', path: 'medication_entry', component: MedicationEntry, meta: {
						requiresAuth: true, requiresFocus: true
					}, props: (route) => ({
						...route.params
					})
				}, 
			]
		}
	]
})
router.beforeEach((to, from, next) => {
	if (to.name === 'Login' && Parse.User.current()) {
		return next({ path: '/' });
	}
	store.commit("SET_IS_LOADING", true);


	if (to.matched.some(record => record.meta.requiresAuth)) {


		// this route requires auth, check if logged in
		// if not, redirect to login page.

		if (!Parse.User.current()) {
			
			next({ name: 'Login' })
		}

		// else if (to.path.includes("detail")) { //for push notifications
		
		// 	next()
		// }
		else if (!store.state.clients.focusedClientId && to.matched.some(record => record.meta.requiresFocus)) {
			next({ name: 'Root' })
		} else {
			next() // go to wherever I'm going
		}
	} else {
		next() // does not require auth, make sure to always call next()!
	}
})


router.afterEach((to, from, next) => {
	store.commit("ADD_LAST_ROUTE", from)
	
	store.commit("SET_IS_LOADING", false);
})
export default router;
