<template>
    <div v-if="client" class="is-flex is-align-items-center">
        <div>
            <p
                class="clamped-box is-font-large is-font-bold"
                :class="{ selectedtext: clientId === client.id }"
                :style="topBar ? 'color: whitesmoke' : ''"
            >
                {{
                    client?.attributes.first_name +
                    " " +
                    client?.attributes.last_name
                }}
            </p>
            <p
                class="clamped-box-single-line is-font-medium"
                :class="{ selectedtext: clientId === client?.id }"
                :style="topBar ? 'color: whitesmoke' : ''"
            >
                {{ client?.attributes.group.attributes.group_name }}
            </p>
        </div>
    </div>
</template>

<script>
/**
 * Compoent for the display of client information in the sidebar.
 * @displayName ClientGroupAndName
 */

import { mapGetters } from "vuex";

export default {
    name: "ClientGroupAndName",
    props: ["client", "topBar"],
    computed: {
        ...mapGetters({
            clientId: "clients/getFocusedClientId",
        }),
    },
};
</script>

<style scoped>
.selectedtext {
    color: black;
}

.clamped-box {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
}

.clamped-box-single-line {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
}
</style>
