<template>
	<b-steps :class="isMobile ? 'pl-2 pr-2' : 'pl-3 pr-3'" :size="getSize" style="height: 100%">
		<b-step-item label="Bericht" icon="lead-pencil" clickable>
			<div class="step-box scrollable" :class="isMobile ? 'pt-3' : ''">
				<section>

					<div>
						<DiaryTitle :diary-title="entryRequest.entryTitle" :set-title="setTitle" />
						<EntryText :set-text="setText" :text="entryRequest.entryInput" />
						<hr />
						<FileInput :drop-files-modified="dropFilesModified" :add-modified-drop-files="addModifiedDropFiles"
							:set-modified-drop-files="setModifiedDropFiles" :allowData="true" />
					</div>
					<hr />
					<EntryTypes :diary-entry-type="entryRequest.entry_type" :set-entry-type="setEntryType" />
					<EntryAttributes :entry-attributes="entryRequest.attributes" :add-entry-attributes="addAttributes"
						:set-entry-attributes="setAttributes" />
					<OptionalEntryAttributes :entry-attributes="entryRequest.optAttributes"
						:add-entry-attributes="addOptAttributes" :set-entry-attributes="setOptAttributes"
						:remove-attribute="removeAttributes" />
					<hr />
					<EntryPrivate v-if="app_private_diary_entries == 1" :diary-entry-private="entryRequest.entry_private"
						:set-entry-private="setEntryPrivate" />
				</section>
			</div>
		</b-step-item>

		<b-step-item label="Zuordnung & Bewertung" icon="account" clickable>
			<div class="step-box scrollable" :style="this.$store.state.openedKeyBoard ? 'height: 100%' : ''">
				<!--EntryVisibility v-if="false" :visibility="entryRequest.visibility" :set-visibility="value => entryRequest.visibility = value"/-->
				<ClientSearch :clients="selectedClients" :ratings="selectedRating" :set-clients="setSelectedClients"
					:set-ratings="setSelectedRatings" :filteredRatings="[]" />
				<hr />
				<ClientAssessment :clients="selectedClients" :ratings="selectedRating" :set-clients="setSelectedClients"
					:set-ratings="setSelectedRatings" />
				<div style="text-align: center">
					<hr />
					<div class="tag is-danger is-light padded dangerTag" v-if="$v.$invalid">
						Unvollständiger Bericht
					</div>
					<br />
					<div style="display: flex; justify-content: center">
						<b-button class="mt-3 button" type="is-primary" @click="saveEntry" v-bind:loading="isLoading"
							v-bind:disabled="$v.$invalid" :size="isMobile ? 'is-small' : ''">Speichern
						</b-button>
					</div>
				</div>
			</div>
		</b-step-item>
	</b-steps>
</template>

<script>
/**
 * Module allowing the creation of an entry.
 * @displayName EntryInput
 */

import { required } from "vuelidate/lib/validators";
import DiaryTitle from "@/components/helper/DiaryTitle";
import EntryText from "@/components/helper/EntryText";
import FileInput from "@/components/helper/FileInput";
import EntryTypes from "@/components/helper/EntryTypes";
import EntryAttributes from "@/components/helper/EntryAttributes";
import OptionalEntryAttributes from "@/components/helper/OptionalEntryAttributes";
import ClientSearch from "@/components/helper/ClientSearch";
import ClientAssessment from "@/components/helper/ClientAssessment";
import { saveNewEntry } from "@/lib/saveNewEntry";
import { mapGetters } from "vuex";
import EntryPrivate from "@/components/helper/EntryPrivate";
import { APP_PRIVATE_DIARY_ENTRIES } from "@/configuration/AppConfigurations";

export default {
	name: "DiaryEntryPanel",
	components: {
		EntryText,
		ClientAssessment,
		ClientSearch,
		EntryAttributes,
		EntryTypes,
		FileInput,
		DiaryTitle,
		OptionalEntryAttributes,
		EntryPrivate,
	},
	data() {
		return {
			selectedRating: [],
			selectedClients: [],
			isLoading: false,
			name: "",

			dropFilesModified: [],

			entryRequest: {
				entryInput: "",
				entryTitle: "",
				attributes: [],
				entry_type: undefined,
				visibility: "Alle",
				optAttributes: []
			},

			app_private_diary_entries: APP_PRIVATE_DIARY_ENTRIES,
		};
	},
	mounted() {
		this.selectedRating = [undefined, ...this.selectedRating];
		this.selectedClients = [this.focusedClient, ...this.selectedClients];
		this.$store.dispatch("pagination/setPage", undefined);
	},
	validations: {
		selectedRating: {
			required,
			$each: {
				required,
			},
		},
		entryRequest: {
			entryInput: { required },
			entryTitle: { required },
			attributes: {
				// required,
				$each: {
					required,
				},
			},
			entry_type: {
				required
			},
		},
	},
	computed: {
		getSize() {
			return this.$store.getters.isMobile ? "is-small" : "";
		},
		isMobile() {
			return this.$store.getters.isMobile;
		},
		...mapGetters({
			unfilteredEntryTypes: "entrytypes/getEntryType",
			focusedClient: "clients/getFocusedClient"
		})
	},
	methods: {
		setSelectedRatings(value) {
			this.selectedRating = value
		},
		setSelectedClients(value) {
			this.selectedClients = value
		},
		setAttributes(value) {
			this.entryRequest.attributes = value
		},
		addAttributes(value) {
			this.entryRequest.attributes.push(value)
		},
		setOptAttributes(value, index) {
			if (index !== undefined) return this.entryRequest.optAttributes[index] = value
			this.entryRequest.optAttributes = value

		},
		addOptAttributes(value) {
			this.entryRequest.optAttributes.push(value)
		},
		setEntryType(value) {
			this.entryRequest.entry_type = value
		},
		setEntryPrivate(value) {
			this.entryRequest.entry_private = value
		},
		addModifiedDropFiles(value) {
			this.dropFilesModified.push(value)
		},
		setModifiedDropFiles(value) {
			this.dropFilesModified = value
		},
		setText(value) {
			this.entryRequest.entryInput = value
		},
		setTitle(value) {
			this.entryRequest.entryTitle = value
		},
		setIsLoading(value) {
			this.isLoading = value
		},
		async routeToDiary() {
			await this.$router.push({ name: "Diary" });
		},
		async saveEntry() {
			const data = {
				entryRequest: this.entryRequest,
				dropFilesModified: this.dropFilesModified,
				selectedClients: this.selectedClients,
				selectedRating: this.selectedRating,
			}
			await saveNewEntry(this.setIsLoading, data, this.routeToDiary)
		},
		removeAttributes(index, id) {
			const optEntryAttributes = this.entryRequest.optAttributes[index]
			if (optEntryAttributes.length > 1) {
				this.entryRequest.optAttributes[index] = optEntryAttributes.filter((item, index) => index === 0)
			}
		}
	},
};
</script>

<style lang="scss" scoped>
::v-deep .pagination-previous[disabled] {
	display: none;
}

::v-deep .pagination-next[disabled] {
	display: none;
}

hr {
	margin: 0.5rem;
}

.step-box {
	height: 80%;
}

::v-deep nav.steps {
	display: none;
}

.scrollable {
	overflow: auto;
	height: 100%;
}

.scrollable-box {
	overflow: auto;
	height: 40%;
}

.scrollable::-webkit-scrollbar {
	display: none;
}

.scrollable-box::-webkit-scrollbar {
	display: none;
}

button {
	margin: 10px;
}

.padded {
	margin: 12px 0;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollable {
	-ms-overflow-style: none;
	/* IE and Edge */
	scrollbar-width: none;
	/* Firefox */
}

@media (max-width: 769px) {
	.imageDelete {
		margin: 0.25rem 0 0 0 !important;
		padding: 0rem 0.5rem !important;
		height: auto !important;
	}

	::v-deep .step-link {
		padding-bottom: 5px !important;
	}

	::v-deep .icon {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.columns:not(:last-child) {
		margin-bottom: 0;
	}

	.padded {
		padding: 0.25rem;
		margin: 0;
	}

	.attribute_titel {
		margin: 0.5rem 0 !important;
	}

	hr {
		margin: 0.5rem;
	}

	.scrollable {
		height: 68%;
		width: 95vw;
	}

	.columns {
		display: flex;
		align-items: center;
		flex-direction: row;
		padding: 0px;
		margin: 0px;
	}

	.column {
		padding: 0;
	}
}

@media (max-width: 600px) {
	.column {
		padding: 0;
		margin: 0;
	}
}

@media (max-width: 450px) {
	.button.is-small:not(.is-rounded) {
		margin: 0;
	}
}

::v-deep .step-content {
	padding: 0px !important;
	height: calc(100% - 50px) !important;
}

::v-deep .step-item {
	padding: 0px !important;
	height: 100% !important;
}

@media (min-width: 770px) {
	::v-deep .step-content {
		height: calc(100% - 60px) !important;
	}

	::v-deep .step-item {
		height: 99% !important;
	}

	::v-deep nav.steps {
		position: absolute;
		top: 0.5rem;
		right: 10%;
		width: 50%;
	}
}
</style>
