<template>
	<div :key="mask" class="scrollable">
		<b-collapse aria-id="medicationCollapseable" class="panel m-1" animation="slide" v-model="medicationIsOpen">
			<template #trigger>
				<div class="panel-heading coloring" :style="!isMedicationValid ? 'background-color: #f01f49;' : ''"
					role="button" aria-controls="medicationCollapseable" :aria-expanded="medicationIsOpen">
					Medikation <span v-if="medicationObject && medicationObject.attributes.number != -1">-
						M{{ medicationObject.attributes.number }}</span>
				</div>
			</template>
			<div class="panel-block is-flex-wrap-wrap is-justify-content-center">
				<MedicationInput v-if="medicationObject && ((mask != 'view') || medicationObject.attributes.number != -1)"
					style="width:100%;" :mask="mask" :propped-has-log="dataObject && dataObject.attributes.hasMedicationLog"
					:propped-medication-object="medicationObject" :propped-medication-is-loading="medicationIsLoading"
					@emit-medication-is-valid="val => isMedicationValid = val"
					@emit-medication-object="val => medicationObject = val"
					@emit-save-medication-input="saveMedicationInput();" />
				<span v-else>Keine Medikation vorhanden.</span>
			</div>
		</b-collapse>
		<b-collapse aria-id="medicationPlanCollapseable" class="panel m-1" animation="slide" v-model="planIsOpen">
			<template #trigger>
				<div class="panel-heading coloring" :style="!isPlanValid ? 'background-color: #f01f49;' : ''" role="button"
					aria-controls="medicationPlanCollapseable" :aria-expanded="planIsOpen">
					Medikationsplan <span v-if="planObject && planObject.attributes.number != -1">-
						P{{ planObject.attributes.number }}</span>
				</div>
			</template>

			<div class="panel-block is-flex-wrap-wrap is-justify-content-center">
				<MedicationPlanInput v-if="planObject && ((mask != 'view') || planObject.attributes.number != -1)"
					:key="medicationObjectChangedCounter" :mask="mask"
					:propped-has-log="dataObject && dataObject.attributes.hasMedicationLog"
					:propped-medication-object="medicationObject" :propped-plan-object="planObject"
					:propped-plan-is-loading="planIsLoading" @emit-plan-is-valid="val => isPlanValid = val"
					@emit-plan-object="val => planObject = val" @emit-save-plan-input="saveMedicationPlan();" />
				<span v-else>Kein Medikationsplan vorhanden.</span>
			</div>
		</b-collapse>
		<b-collapse aria-id="prescriptionCollapseable" class="panel m-1" animation="slide" v-model="prescriptionIsOpen">
			<template #trigger>
				<div class="panel-heading coloring" role="button" aria-controls="mprescriptionCollapseable"
					:aria-expanded="prescriptionIsOpen">
					Rezept <span v-if="prescriptionObject && prescriptionObject.attributes.number != -1">-
						R{{ prescriptionObject.attributes.number }}</span>
				</div>
			</template>
			<div class="panel-block is-flex-wrap-wrap is-justify-content-center">
				<MedicationPrescriptionInput
					v-if="prescriptionObject && ((mask != 'view') || prescriptionObject.attributes.number != -1)"
					:mask="mask" :propped-has-log="dataObject && dataObject.attributes.hasMedicationLog"
					:propped-prescription-object="prescriptionObject"
					:propped-prescription-object-number="prescriptionObject.attributes.number"
					:propped-prescription-is-loading="prescriptionIsLoading"
					@emit-prescription-object="val => prescriptionObject = val"
					@emit-save-prescription-input="saveMedicationPrescription();" />
				<span v-else>Kein Rezept vorhanden.</span>
			</div>
		</b-collapse>
		<b-collapse aria-id="reportCollapseable" class="panel m-1" animation="slide" v-model="reportIsOpen">
			<template #trigger>
				<div class="panel-heading coloring" role="button" aria-controls="reportCollapseable"
					:aria-expanded="reportIsOpen">
					Bericht <span v-if="reportObject && reportObject.attributes.number != -1">-
						B{{ reportObject.attributes.number }}</span>
				</div>
			</template>
			<div class="panel-block is-flex-wrap-wrap is-justify-content-center">
				<MedicationReportInput v-if="reportObject && ((mask != 'view') || reportObject.attributes.number != -1)"
					:mask="mask" :propped-has-log="dataObject && dataObject.attributes.hasMedicationLog"
					:propped-report-object="reportObject" :propped-report-object-number="reportObject.attributes.number"
					:propped-report-is-loading="reportIsLoading" @emit-report-object="val => reportObject = val"
					@emit-save-report-input="saveMedicationReport();" />
				<span v-else>Kein Bericht vorhanden.</span>
			</div>
		</b-collapse>
		<b-collapse v-if="dataObject && dataObject.attributes.hasMedicationLog" aria-id="logCollapseable" class="panel m-1"
			animation="slide" v-model="logIsOpen">
			<template #trigger>
				<div class="panel-heading coloring" role="button" aria-controls="logCollapseable"
					:aria-expanded="logIsOpen">
					Log
				</div>
			</template>
			<div class="panel-block is-flex-wrap-wrap is-justify-content-center">
				<MedicationLog :propped-medication-object="medicationObject" />
			</div>
		</b-collapse>

		<div v-if="(mask == 'edit' || mask == 'new') && !dataObject?.attributes.hasMedicationLog"
			style="display: flex; justify-content: center">
			<b-button class="mt-3 button" type="is-primary" @click="createMedicationLog()" v-bind:loading="isLoading"
				v-bind:disabled="!isMedicationValid || !isPlanValid || medicationNumber == -1 || planNumber == -1"
				:size="isMobile ? 'is-small' : ''">
				Medikationslog erstellen
			</b-button>
		</div>

		<LogConfirmationModal v-if="isLogConfirmationModalActive" :propped-medication-entry="focusedMedicationEntry"
			:propped-medication-log-art="focusedMedicationEntry.attributes.medicationPlan.attributes.art"
			:propped-medication-log="medicationLog" @emit-create-medication-log="saveMedicationLog()"
			v-model="isLogConfirmationModalActive" />
	</div>
</template>

<script>
/**
 * Module allowing the creation of an entry.
 * @displayName MedicationEntryInput
 */
import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import Parse from "parse/dist/parse.min.js";
import { v4 as uuid } from "uuid";

import MedicationInput from "@/components/helper/medicationHelper/MedicationInput.vue";
import MedicationPlanInput from "@/components/helper/medicationHelper/MedicationPlanInput.vue";
import MedicationPrescriptionInput from "@/components/helper/medicationHelper/MedicationPrescriptionInput.vue";
import MedicationReportInput from "@/components/helper/medicationHelper/MedicationReportInput.vue";
import MedicationLog from "@/components/helper/medicationHelper/MedicationLog.vue";
import LogConfirmationModal from "@/components/modals/LogConfirmationModal.vue";
import store from '@/lib/store/store';
import { savePdfFiles } from "../../../lib/saveFiles";

export default {
	name: "MedicationEntry",
	components: {
		MedicationInput,
		MedicationPlanInput,
		MedicationPrescriptionInput,
		MedicationReportInput,
		LogConfirmationModal,
		MedicationLog
	},
	data() {
		return {
			displayNewLog: false,
			medicationLog: {},

			isLogConfirmationModalActive: false,
			dataObject: undefined,
			// reload: 1,

			medicationIsOpen: false,
			planIsOpen: false,
			prescriptionIsOpen: false,
			reportIsOpen: false,
			logIsOpen: false,
			isLoading: false,

			//Medikationsobjektdaten
			isMedicationValid: false,
			isMedicationSaved: false,
			medicationIsLoading: false,

			medicationNumber: -1,
			medicationObject: undefined,

			medicationStartDate: undefined,
			medicationEndDate: undefined,
			medicationSymbol: "",
			medicationObjectChangedCounter: 1,

			//Rezeptobjektdaten
			isPrescriptionSaved: false,
			prescriptionIsLoading: false,

			prescriptionNumber: -1,
			prescriptionObject: undefined,

			//Reportobjektdaten
			isReportSaved: false,
			reportIsLoading: false,

			reportNumber: -1,
			reportObject: undefined,

			//Planobjektdaten
			isPlanSaved: false,
			isPlanValid: false,
			planIsLoading: false,

			planNumber: -1,
			planObject: undefined,
			weekArray: ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag']
		};
	},
	mounted() {
		if (this.focusedMedicationEntry) {
			this.dataObject = this.focusedMedicationEntry;
			this.medicationObject = this.create_medication_object(this.focusedMedicationEntry.attributes.medication);
			this.medicationNumber = this.medicationObject.attributes.number;
			this.prescriptionObject = this.create_prescription_object(this.focusedMedicationEntry.attributes.medicationPrescription);
			this.prescriptionNumber = this.prescriptionObject.attributes.number;
			this.reportObject = this.create_report_object(this.focusedMedicationEntry.attributes.medicationReport);
			this.reportNumber = this.reportObject.attributes.number;
			this.planObject = this.create_plan_object(this.focusedMedicationEntry.attributes.medicationPlan);
			this.planNumber = this.planObject.attributes.number;

			this.medicationIsOpen = true;
			this.prescriptionIsOpen = true;
			this.reportIsOpen = true;
			this.planIsOpen = true;
			this.logIsOpen = true;
		} else {
			this.medicationObject = this.create_medication_object({});

			this.medicationStartDate = undefined;
			this.medicationEndDate = undefined;
			this.medicationSymbol = "";

			this.planObject = this.create_plan_object({});
			this.prescriptionObject = this.create_prescription_object({});
			this.reportObject = this.create_report_object({});
		}
	},
	validations: {
		medicationObject: {
			required
		},
		planObject: {
			required
		}
	},
	watch: {
		mask(newValue) {
			if (this.displayNewLog) {
				this.medicationIsOpen = false;
				this.prescriptionIsOpen = false;
				this.reportIsOpen = false;
				this.planIsOpen = false;
				this.logIsOpen = true;
			} else {
				if (newValue == 'view') {
					this.medicationIsOpen = true;
					this.prescriptionIsOpen = true;
					this.reportIsOpen = true;
					this.planIsOpen = true;
					this.logIsOpen = true;
				} else {
					this.medicationIsOpen = false;
					this.prescriptionIsOpen = false;
					this.reportIsOpen = false;
					this.planIsOpen = false;
					this.logIsOpen = false;
				}
			}
		}
	},
	computed: {
		getSize() {
			return this.$store.getters.isMobile ? "is-small" : "";
		},
		isMobile() {
			return this.$store.getters.isMobile;
		},
		...mapGetters({
			usermetas: "usermetas/getUserMetas",
			medication: "medicationMedication/getMedication",
			focusedClient: "clients/getFocusedClient",
			users: "users/getFilteredUsers",
			focusedMedicationEntry: "medicationEntries/getFocusedMedicationEntry",
			mask: "medicationEntries/getFocusedMedicationEntryMask"
		}),
		contactUser() {
			if (this.usermetas) {
				for (const user of this.usermetas) {
					if (
						user.attributes.clients.filter((client) => {
							return client.id === this.focusedClient.id;
						}).length > 0
					) {
						return user;
					}
				}
			}
			return undefined;
		},
		filteredUsers() {
			let filteredClients = []
			if (this.users) {
				this.users.forEach(element => {
					if (element.attributes.dependency.attributes.first_name ||
						element.attributes.dependency.attributes.last_name)
						filteredClients.push(element)
				});
			}
			return filteredClients
		},

	},
	methods: {
		filterUser(contactUser) {
			let temp = undefined
			this.users.forEach(element => {
				if (element.attributes.dependency.id === contactUser.id) {
					temp = element;
				}
			});
			return temp;
		},
		setIsLoading(value) {
			this.isLoading = value
		},
		async saveMedicationInput() {
			this.isMedicationSaved = false;

			this.medicationObject = await this.set_medication_object(0);

			await this.medicationObject.save().then(function (result) {
				let dataQuery = new Parse.Query('MedicationClientMedication');
				dataQuery.equalTo("objectId", result.id)
				dataQuery.include("medication", "contactUser.dependency");

				dataQuery.find().then(function (result) {

				});
			});

			this.isMedicationSaved = true;
			this.medicationObjectChangedCounter++;

			this.saveMedication("medication");
		},
		async saveMedicationPlan() {
			this.isPlanSaved = false;

			this.planObject = await this.set_plan_object(0);

			this.planObject = await this.planObject.save()
			this.isPlanSaved = true;

			this.saveMedication("medicationPlan");

		},
		async saveMedicationPrescription() {
			this.isPrescriptionSaved = false;

			this.prescriptionObject = await this.set_prescription_object(0);

			await this.prescriptionObject.save().then(function (result) {
				let dataQuery = new Parse.Query('MedicationPrescription');
				dataQuery.equalTo("objectId", result.id)
				dataQuery.include("otherFiles")
				dataQuery.find().then(function (result) {

				});
			});

			this.prescriptionObject = await this.prescriptionObject.save()
			this.isPrescriptionSaved = true;

			this.saveMedication("medicationPrescription");

		},
		async saveMedicationReport() {
			this.isReportSaved = false;

			this.reportObject = await this.set_report_object(0);

			await this.reportObject.save().then(function (result) {
				let dataQuery = new Parse.Query('MedicationReport');
				dataQuery.equalTo("objectId", result.id)
				dataQuery.include("diagnose");

				dataQuery.find().then(function (result) {

				});
			});

			this.isReportSaved = true;

			this.saveMedication("medicationReport");

		},
		async saveMedication(comp) {
			this.isLoading = true;

			const parseObject = Parse.Object.extend("MedicationClientMedicationConnector")

			const dataObject = this.dataObject ? this.dataObject : new parseObject()
			const edit = this.dataObject ? 1 : 0

			dataObject.set("client", this.focusedClient);

			if (this.medicationObject.attributes.number != -1 && comp == "medication") {
				dataObject.set("medication", (this.medicationObject))
			}
			if (this.planObject.attributes.number != -1 && comp == "medicationPlan") {
				dataObject.set("medicationPlan", (this.planObject))
			}
			if (this.prescriptionObject.attributes.number != -1 && comp == "medicationPrescription") {
				dataObject.set("medicationPrescription", (this.prescriptionObject))
			}
			if (this.reportObject.attributes.number != -1 && comp == "medicationReport") {
				dataObject.set("medicationReport", (this.reportObject))
			}

			if (edit == 0) {
				dataObject.set("hasMedicationLog", false);
			}

			await dataObject.save().then(function (result) {
				let dataQuery = new Parse.Query('MedicationClientMedicationConnector');
				dataQuery.equalTo("objectId", result.id)
				dataQuery.include(["client", "medication", "medication.contactUser", "medication.contactUser.dependency", "medication.medication", "medication.medication.medicationUnit", "medicationPrescription", "medicationReport", "medicationPlan", "medicationReport.diagnose", "medicationReport.icd_10_code_array"]);

				dataQuery.find().then(function (result) {

				});
			});

			this.dataObject = dataObject;

			this.$store.dispatch(
				"medicationEntries/setFocusedMedicationEntry",
				this.dataObject
			);
			this.$store.dispatch(
				"medicationEntries/setFocusedMedicationEntryId",
				this.dataObject.id
			);

			await this.$store.dispatch("clients/setFocusedClientId", this.focusedClient.id);

			this.isLoading = false;

		},
		async calculateNextMedicationNumber(dbTable) {
			const medicationQuery_temp = new Parse.Query(dbTable);
			medicationQuery_temp.notEqualTo("deleted", true);
			medicationQuery_temp.descending("number");
			const results = await medicationQuery_temp.find();

			let number_counter = 1;

			if (results.length > 0) {
				number_counter = parseInt(results[0].attributes.number) + 1
			}

			return number_counter;
		},
		create_medication_object(data) {

			let tempUser = this.contactUser
			let medicationContactUser = tempUser ? this.filterUser(tempUser) : undefined

			let medicationObjektTemp = {};

			if (this.mask == "new" || !data) {

				const parseObject = Parse.Object.extend("MedicationClientMedication")
				medicationObjektTemp = new parseObject();
				medicationObjektTemp.set("medication", undefined)
				medicationObjektTemp.set("number", Number(-1))
				medicationObjektTemp.set("min", Number(0))
				medicationObjektTemp.set("reason", "")
				medicationObjektTemp.set("expirationDate", undefined)
				medicationObjektTemp.set("startDate", undefined)
				medicationObjektTemp.set("endDate", undefined)
				medicationObjektTemp.set("dropDate", undefined)
				medicationObjektTemp.set("hint", "")
				if (medicationContactUser) {
					medicationObjektTemp.set("contactUser", medicationContactUser)
				} else {
					medicationObjektTemp.set("contactUser", undefined)
				}

				medicationObjektTemp.set("client", this.focusedClient)
				medicationObjektTemp.set("deleted", false)
			} else {

				const parseObject = Parse.Object.extend("MedicationClientMedication")
				medicationObjektTemp = new parseObject();
				medicationObjektTemp.set("medication", data.attributes.medication)
				medicationObjektTemp.set("number", data.attributes.number)
				medicationObjektTemp.set("min", data.attributes.min)
				medicationObjektTemp.set("reason", data.attributes.reason)
				medicationObjektTemp.set("expirationDate", data.attributes.expirationDate)
				medicationObjektTemp.set("startDate", data.attributes.startDate)
				medicationObjektTemp.set("endDate", data.attributes.endDate)
				medicationObjektTemp.set("dropDate", data.attributes.dropDate)
				medicationObjektTemp.set("hint", data.attributes.hint)
				medicationObjektTemp.set("contactUser", data.attributes.contactUser)

				medicationObjektTemp.set("client", data.attributes.client)
				medicationObjektTemp.set("deleted", data.attributes.deleted)
			}

			return medicationObjektTemp;

		},
		create_plan_object(data) {

			let planObjektTemp = {};

			if (this.mask == "new" || !data) {

				const dataObjectP = Parse.Object.extend("MedicationPlan")
				planObjektTemp = new dataObjectP()
				planObjektTemp.set("number", Number(-1))

				let timePlan = {};
				for (let i = 0; i < this.weekArray.length; i++) {
					timePlan[this.weekArray[i]] = {
						"Morgens": "0",
						"Mittags": "0",
						"Abends": "0",
						"Nachts": "0"
					}
				}

				let timePlanSpecial = [{
					'von': undefined,
					'bis': undefined,
					'Morgens': "0",
					'Mittags': "0",
					'Abends': "0",
					'Nachts': "0"
				}];

				planObjektTemp.set("timePlanSpecial", timePlanSpecial)
				planObjektTemp.set("timePlan", timePlan)
				planObjektTemp.set("einzeldosis", 0)

				planObjektTemp.set("hint")
				planObjektTemp.set("art", undefined)
				planObjektTemp.set("client", this.focusedClient)
				planObjektTemp.set("deleted", false)

			} else {

				const dataObjectP = Parse.Object.extend("MedicationPlan")
				planObjektTemp = new dataObjectP()
				planObjektTemp.set("number", data.attributes.number)

				planObjektTemp.set("timePlanSpecial", data.attributes.timePlanSpecial)
				planObjektTemp.set("timePlan", data.attributes.timePlan)
				planObjektTemp.set("einzeldosis", data.attributes.einzeldosis)

				planObjektTemp.set("hint", data.attributes.hint)
				planObjektTemp.set("art", data.attributes.art)
				planObjektTemp.set("client", data.attributes.client)
				planObjektTemp.set("deleted", data.attributes.deleted)

			}

			return planObjektTemp;
		},
		create_prescription_object(data) {

			let prescriptionObjektTemp = {};

			if (this.mask == "new" || !data) {
				const parseObjectP = Parse.Object.extend("MedicationPrescription")
				prescriptionObjektTemp = new parseObjectP()
				prescriptionObjektTemp.set("number", Number(-1))
				prescriptionObjektTemp.set("name", "")
				prescriptionObjektTemp.set("doctor", undefined)
				prescriptionObjektTemp.set("date", undefined)
				prescriptionObjektTemp.set("hint", "")
				prescriptionObjektTemp.set("files", undefined)
				prescriptionObjektTemp.set("client", this.focusedClient)
				prescriptionObjektTemp.set("deleted", false)
			} else {
				const parseObjectP = Parse.Object.extend("MedicationPrescription")
				prescriptionObjektTemp = new parseObjectP()
				prescriptionObjektTemp.set("number", data.attributes.number)
				prescriptionObjektTemp.set("name", data.attributes.name)
				prescriptionObjektTemp.set("doctor", data.attributes.doctor)
				prescriptionObjektTemp.set("date", data.attributes.date)
				prescriptionObjektTemp.set("hint", data.attributes.hint)
				prescriptionObjektTemp.set("files", data.attributes.files)
				prescriptionObjektTemp.set("client", data.attributes.client)
				prescriptionObjektTemp.set("deleted", data.attributes.deleted)
				prescriptionObjektTemp.set("otherFiles", data.attributes.otherFiles)
			}
			return prescriptionObjektTemp;

		},
		create_report_object(data) {

			let reportObjektTemp = {};

			if (this.mask == "new" || !data) {

				const dataObjectR = Parse.Object.extend("MedicationReport")
				reportObjektTemp = new dataObjectR()

				reportObjektTemp.set("number", Number(-1))
				reportObjektTemp.set("doctor", undefined)
				reportObjektTemp.set("date", undefined)
				reportObjektTemp.set("diagnose", undefined)
				reportObjektTemp.set("icd_10_code_array", [])
				reportObjektTemp.set("treatmentBegin", undefined)
				reportObjektTemp.set("treatmentEnd", undefined)
				reportObjektTemp.set("hint", "")
				reportObjektTemp.set("files", undefined)
				reportObjektTemp.set("client", this.focusedClient)
				reportObjektTemp.set("deleted", false)
			} else {
				const dataObjectR = Parse.Object.extend("MedicationReport")
				reportObjektTemp = new dataObjectR()
				reportObjektTemp.set("number", data.attributes.number)
				reportObjektTemp.set("doctor", data.attributes.doctor)
				reportObjektTemp.set("date", data.attributes.date)
				reportObjektTemp.set("diagnose", data.attributes.diagnose)
				reportObjektTemp.set("icd_10_code_array", data.attributes.icd_10_code_array)
				reportObjektTemp.set("treatmentBegin", data.attributes.treatmentBegin)
				reportObjektTemp.set("treatmentEnd", data.attributes.treatmentEnd)
				reportObjektTemp.set("hint", data.attributes.hint)
				reportObjektTemp.set("files", data.attributes.files)
				reportObjektTemp.set("client", data.attributes.client)
				reportObjektTemp.set("deleted", data.attributes.deleted)
				reportObjektTemp.set("otherFiles", data.attributes.otherFiles)
			}

			return reportObjektTemp;
		},
		async set_medication_object(new_data) {
			let medicationObjektTemp = this.medicationObject;

			if (new_data == 0) {
				if (this.mask == "edit" && this.focusedMedicationEntry.attributes.medication) {
					medicationObjektTemp = this.focusedMedicationEntry.attributes.medication;
				} else {
					if (this.medicationNumber == -1) {
						this.medicationNumber = await this.calculateNextMedicationNumber("MedicationClientMedication");
					}

					medicationObjektTemp.set("number", Number(this.medicationNumber))
				}

				medicationObjektTemp.set("medication", this.medicationObject.attributes.medication)
				medicationObjektTemp.set("min", this.medicationObject.attributes.min)
				medicationObjektTemp.set("reason", this.medicationObject.attributes.reason)
				medicationObjektTemp.set("expirationDate", this.medicationObject.attributes.expirationDate)
				medicationObjektTemp.set("startDate", this.medicationObject.attributes.startDate)
				medicationObjektTemp.set("endDate", this.medicationObject.attributes.endDate)
				medicationObjektTemp.set("dropDate", this.medicationObject.attributes.dropDate)
				medicationObjektTemp.set("hint", this.medicationObject.attributes.hint)
				medicationObjektTemp.set("contactUser", this.medicationObject.attributes.contactUser)
				medicationObjektTemp.set("client", this.focusedClient)
				medicationObjektTemp.set("deleted", false)
			} else if (this.focusedMedicationEntry && this.focusedMedicationEntry.attributes.medication) {
				medicationObjektTemp = this.focusedMedicationEntry.attributes.medication;
			}

			return medicationObjektTemp;
		},
		async set_plan_object(new_data) {
			let planObjektTemp = this.planObject;

			if (new_data == 0) {
				if (this.mask == "edit" && this.focusedMedicationEntry.attributes.medicationPlan) {
					planObjektTemp = this.focusedMedicationEntry.attributes.medicationPlan;
				} else {
					if (this.planNumber == -1) {
						this.planNumber = await this.calculateNextMedicationNumber("MedicationPlan");
					}

					planObjektTemp.set("number", Number(this.planNumber))
				}

				planObjektTemp.set("timePlanSpecial", this.planObject.attributes.timePlanSpecial)
				planObjektTemp.set("timePlan", this.planObject.attributes.timePlan)
				planObjektTemp.set("einzeldosis", this.planObject.attributes.einzeldosis)
				planObjektTemp.set("hint", this.planObject.attributes.hint)
				planObjektTemp.set("art", this.planObject.attributes.art)
				planObjektTemp.set("client", this.focusedClient)
				planObjektTemp.set("deleted", false)
			} else if (this.focusedMedicationEntry && this.focusedMedicationEntry.attributes.medicationPlan) {
				planObjektTemp = this.focusedMedicationEntry.attributes.medicationPlan;
			}

			return planObjektTemp;
		},
		async set_prescription_object(new_data) {

			let prescriptionObjektTemp = this.prescriptionObject;
			if (new_data == 0) {
				if (this.mask == "edit" && this.focusedMedicationEntry.attributes.medicationPrescription) {
					prescriptionObjektTemp = this.focusedMedicationEntry.attributes.medicationPrescription;
				} else {
					if (this.prescriptionObject.attributes.number == -1) {
						this.prescriptionNumber = await this.calculateNextMedicationNumber("MedicationPrescription");
					} else {
						this.prescriptionNumber = this.prescriptionObject.attributes.number;
					}

					prescriptionObjektTemp.set("number", Number(this.prescriptionNumber))
				}

				prescriptionObjektTemp.set("name", this.prescriptionObject.attributes.name)
				prescriptionObjektTemp.set("doctor", this.prescriptionObject.attributes.doctor)
				prescriptionObjektTemp.set("date", this.prescriptionObject.attributes.date)
				prescriptionObjektTemp.set("hint", this.prescriptionObject.attributes.hint)
				prescriptionObjektTemp.set("client", this.prescriptionObject.attributes.client)
				prescriptionObjektTemp.set("deleted", this.prescriptionObject.attributes.deleted)

				const parseFiles = [];
				const parseFilesToSave = [];
				const otherFiles = []
				let optionalData = {
					"subType": "PrescriptionFile"
				}
				for (const file of this.prescriptionObject.attributes.files) {
					if (file.type && file.type == "application/pdf") {
						otherFiles.push(file)
					} else {
						if (!file._url) {
							const parseFile = new Parse.File(uuid(), { base64: file }, "image/jpeg")
							parseFiles.push(parseFile);
							parseFilesToSave.push(parseFile);
						} else {
							parseFiles.push(file);
						}
					}
				}
				let savedPdfs = await savePdfFiles("MedicationFile", otherFiles, optionalData);

				if (this.prescriptionObject.attributes.otherFiles) {
					prescriptionObjektTemp.set("otherFiles", savedPdfs.concat(this.prescriptionObject.attributes.otherFiles))
				} else {
					prescriptionObjektTemp.set("otherFiles", savedPdfs)
				}

				prescriptionObjektTemp.set("files", parseFiles)
				for (const file of parseFilesToSave) {
					await file.save()
				}

			} else if (this.focusedMedicationEntry && this.focusedMedicationEntry.attributes.medicationPrescription) {
				prescriptionObjektTemp = this.focusedMedicationEntry.attributes.medicationPrescription;
			}
			return prescriptionObjektTemp;

		},
		async set_report_object(new_data) {
			let reportObjektTemp = this.reportObject;
			if (new_data == 0) {
				if (this.mask == "edit" && this.focusedMedicationEntry.attributes.medicationReport) {
					reportObjektTemp = this.focusedMedicationEntry.attributes.medicationReport;
				} else {
					if (this.reportObject.attributes.number == -1) {
						this.reportNumber = await this.calculateNextMedicationNumber("MedicationReport");
					} else {
						this.reportNumber = this.reportObject.attributes.number;
					}

					reportObjektTemp.set("number", Number(this.reportNumber))
				}
				reportObjektTemp.set("doctor", this.reportObject.attributes.doctor)
				reportObjektTemp.set("date", this.reportObject.attributes.date)
				reportObjektTemp.set("diagnose", this.reportObject.attributes.diagnose)
				reportObjektTemp.set("icd_10_code_array", this.reportObject.attributes.icd_10_code_array)
				reportObjektTemp.set("treatmentBegin", this.reportObject.attributes.treatmentBegin)
				reportObjektTemp.set("treatmentEnd", this.reportObject.attributes.treatmentEnd)
				reportObjektTemp.set("hint", this.reportObject.attributes.hint)
				reportObjektTemp.set("client", this.reportObject.attributes.client)
				reportObjektTemp.set("deleted", this.reportObject.attributes.deleted)
				const parseFiles = [];
				const parseFilesToSave = [];
				const otherFiles = []
				let optionalData = {
					"subType": "ReportFile"
				}
				for (const file of this.reportObject.attributes.files) {
					if (file.type && file.type == "application/pdf") {
						otherFiles.push(file)
					} else {
						if (!file._url && file.type != "application/pdf") {
							const parseFile = new Parse.File(uuid(), { base64: file }, "image/jpeg")
							parseFiles.push(parseFile);
							parseFilesToSave.push(parseFile);
						} else {
							parseFiles.push(file);
						}
					}
				}

				reportObjektTemp.set("files", parseFiles)
				let savedPdfs = await savePdfFiles("MedicationFile", otherFiles, optionalData);

				if (this.reportObject.attributes.otherFiles) {
					reportObjektTemp.set("otherFiles", savedPdfs.concat(this.reportObject.attributes.otherFiles))
				} else {
					reportObjektTemp.set("otherFiles", savedPdfs)
				}

				for (const file of parseFilesToSave) {
					await file.save()
				}
			} else if (this.focusedMedicationEntry && this.focusedMedicationEntry.attributes.medicationReport) {
				reportObjektTemp = this.focusedMedicationEntry.attributes.medicationReport;
			}


			return reportObjektTemp;
		},
		createMedicationLog() {

			this.medicationLog = [];
			if (this.focusedMedicationEntry.attributes.medicationPlan.attributes.art == 1) {
				let timeplan = this.focusedMedicationEntry.attributes.medicationPlan.attributes.timePlan;

				Object.keys(timeplan).forEach(key => {
					let zeiten = timeplan[key];

					let dates = this.getDaysBetweenDates(this.focusedMedicationEntry.attributes.medication.attributes.startDate, this.focusedMedicationEntry.attributes.medication.attributes.endDate, key);

					for (var i = 0; i < dates.length; i++) {
						Object.keys(zeiten).forEach(keyT => {

							if (zeiten[keyT].replace(/,/g, '.') > 0) {
								let logEntry = {
									'date': dates[i],
									'name': key,
									'time': keyT,
									'menge': zeiten[keyT],
									'medication': this.focusedMedicationEntry
								}
								this.medicationLog.push(logEntry);
							}
						});
					}

				});

				this.medicationLog.sort(compareByOrderDate);
			} else if (this.focusedMedicationEntry.attributes.medicationPlan.attributes.art == 2) {
				let timeplanSpecial = this.focusedMedicationEntry.attributes.medicationPlan.attributes.timePlanSpecial;

				let dates = this.getDaysBetweenDatesAll(this.focusedMedicationEntry.attributes.medication.attributes.startDate, this.focusedMedicationEntry.attributes.medication.attributes.endDate);

				let week_array = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'];

				Object.keys(timeplanSpecial).forEach(key => {
					let zeiten = timeplanSpecial[key];

					let dates_range = this.getDaysBetweenDatesAll(zeiten['von'], zeiten['bis']);

					for (var i = 0; i < dates_range.length; i++) {
						Object.keys(zeiten).forEach(keyT => {

							if (keyT != "von" && keyT != "bis" && zeiten[keyT].replace(/,/g, '.') > 0) {
								if (this.isInDateArray(dates,dates_range[i])) {
									
									let logEntry = {
										'date': dates_range[i],
										'name': week_array[dates_range[i].getDay()],
										'time': keyT,
										'menge': zeiten[keyT],
										'medication': this.focusedMedicationEntry
									}
									this.medicationLog.push(logEntry);
								}

							}
						});
					}

				});

				this.medicationLog.sort(compareByOrderDate);
			}
			this.isLogConfirmationModalActive = true
		},
		async saveMedicationLog() {
			this.isLoading = true;

			let rAccs = [];

			for (var i = 0; i < this.medicationLog.length; i++) {

				const dataObjectL = Parse.Object.extend("MedicationLog")
				const logObjektTemp = new dataObjectL()

				logObjektTemp.set("client", this.focusedClient);
				logObjektTemp.set("date", this.medicationLog[i].date);
				logObjektTemp.set("medication", this.medicationLog[i].medication);
				logObjektTemp.set("time", this.medicationLog[i].time);
				logObjektTemp.set("amount", this.medicationLog[i].menge);
				logObjektTemp.set("state", 0);
				logObjektTemp.set("externalMedication", false);
				rAccs.push(logObjektTemp);

			}

			if (rAccs.length != 0) {
				this.saveAllInBatches(rAccs);
			}

			const dataObject = this.dataObject;
			dataObject.set("hasMedicationLog", true);
			await dataObject.save().then(function (result) {
				let dataQuery = new Parse.Query('MedicationClientMedicationConnector');
				dataQuery.equalTo("objectId", result.id)
				dataQuery.include(["client", "medication", "medication.contactUser", "medication.contactUser.dependency", "medication.medication", "medication.medication.medicationUnit", "medicationPrescription", "medicationReport", "medicationPlan", "medicationReport.diagnose", "medicationReport.icd_10_code_array, medication.medicationPlan"]);

				dataQuery.find().then(function (result) {


				});
			});

			// Gehe in den "View"-Modus für das neu angelegte Objekt.
			this.dataObject = dataObject;


			this.isLoading = false;
			this.displayNewLog = true;
			this.$store.dispatch(
				"medicationEntries/setFocusedMedicationEntryMask",
				'view'
			);

			this.medicationObject = this.create_medication_object(dataObject.attributes.medication);
			this.medicationNumber = this.medicationObject.attributes.number;
			this.prescriptionObject = this.create_prescription_object(dataObject.attributes.medicationPrescription);
			this.reportObject = this.create_report_object(dataObject.attributes.medicationReport);
			this.planObject = this.create_plan_object(dataObject.attributes.medicationPlan);
			this.planNumber = this.planObject.attributes.number;
		},
		async saveAllInBatches(objects) {
			await Parse.Object.saveAll(objects);
		},
		getDaysBetweenDates(start, end, dayName) {
			var result = [];
			var days = { son: 0, mon: 1, die: 2, mit: 3, don: 4, fre: 5, sam: 6 };
			var day = days[dayName.toLowerCase().substr(0, 3)];
			var current = new Date(start);
			current.setDate(current.getDate() + (day - current.getDay() + 7) % 7);
			while (current <= end) {
				result.push(new Date(+current));
				current.setDate(current.getDate() + 7);
			}
			return result;
		},
		getDaysBetweenDatesAll(start, end) {
			var result = [];
			var current = new Date(start);
			while (current <= end) {
				result.push(new Date(+current));
				current.setDate(current.getDate() + 1);
			}
			return result;
		},
		isInDateArray(array, value) {
			return !!array.find(item => { return item.getTime() == value.getTime() });
		}
	},
};

function compareByOrderDate(a, b) {
	return b.date - a.date;
}
</script>

<style lang="scss" scoped>
.coloring {
	background-color: rgba(0, 0, 0, 0.8);
	color: white;
}

::v-deep .pagination-previous[disabled] {
	display: none;
}

::v-deep .pagination-next[disabled] {
	display: none;
}

hr {
	margin: 0.5rem;
}

.step-box {
	height: 80%;
}

::v-deep nav.steps {
	display: none;
}

.scrollable {
	overflow: auto;
	height: 100%;
}

.scrollable-box {
	overflow: auto;
	height: 40%;
}

.scrollable::-webkit-scrollbar {
	display: none;
}

.scrollable-box::-webkit-scrollbar {
	display: none;
}

button {
	margin: 10px;
}

.padded {
	margin: 12px 0;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollable {
	-ms-overflow-style: none;
	/* IE and Edge */
	scrollbar-width: none;
	/* Firefox */
}

@media (max-width: 450px) {
	.button.is-small:not(.is-rounded) {
		margin: 0;
	}
}
</style>
