<template>
	<b-modal v-model="isActive" has-modal-card @close="closeModal()" :can-cancel="false">
		<div class="modal-card modalCardContainer"
			style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%);">
			<header class="modal-card-head" style="background-color: rgba(40, 40, 40, 1); color: white">
				Erfassung Selbstmedikation für den {{ new Date().toLocaleDateString("de-DE",
				{
					year: "numeric",
					month: "2-digit",
					day: "2-digit",
				}) }}
			</header>
			<section class="modal-card-body" style="padding: 0.5em; color: #313131">
				<b-field expanded>
					<template #label>
						<div class="align-content-center">
							<p>Medikament</p>
						</div>
					</template>
					{{ medicationDisplayName }}
				</b-field>
				<b-field expanded>
					<template #label>
						<div class="align-content-center">
							<p>Einzeldosis</p>
						</div>
					</template>
					{{ getAmountString(einzelDosis) }} {{ medicationSymbol }}
				</b-field>

				<div class="is-flex is-flex-direction-column">
					<b-field expanded>
						<template #label>
							<div class="align-content-center">
								<p>Verabreichungszeitraum</p>
							</div>
						</template>
						<div class="block mb-1 mt-2">
							<b-radio v-model="time" name="time" class="mr-4" native-value="Morgens">
								Morgens
							</b-radio>
							<b-radio v-model="time" name="time" class="mr-4" native-value="Mittags">
								Mittags
							</b-radio>
							<b-radio v-model="time" name="time" native-value="Abends">
								Abends
							</b-radio>
							<b-radio v-model="time" name="time" native-value="Nachts">
								Nachts
							</b-radio>
						</div>
					</b-field>		
					<UserSearchableSelect :data-options="filteredUsers" :editObject="medicationContactUser"
						:enable-validation="true" validationMessage="Bitte wählen Sie einen Verantwortlichen aus!"
						label-text="Verabreicht von" :enable-order="true"
						:select="(object) => (medicationContactUser = object)" />
					<div>
						<b-field expanded>
							<template #label>
								<div class="align-content-center" style="display: inline-flex">
									<p>Anmerkungen</p>
								</div>
							</template>
							<b-input type="text" v-model="hintOptional" />
						</b-field>
					</div>

				</div>
			</section>
			<footer class="modal-card-foot footerContainer" style=" color: white">
				<b-button @click="closeModal()" type="is-primary">
					Abbrechen
				</b-button>
				<b-button @click="saveLogEntry()" type="is-primary" v-bind:loading="isLoading"
					v-bind:disabled="$v.medicationContactUser.$invalid">
					Speichern
				</b-button>
			</footer>
		</div>
	</b-modal>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import { negativeFeedBack, positiveFeedBack } from "@/lib/Toast";
import Parse from "parse/dist/parse.min.js";
import UserSearchableSelect from "@/components/helper/SearchableSelects/UserSearchableSelect.vue";

export default {
	name: "AddSelfMedicationModal",
	components: {
		UserSearchableSelect,
	},
	props: ["value", "selfMedication"],
	data() {
		return {		
			isLoading: false,
			medicationContactUser: undefined,
			hintOptional: "",			
			time: "Morgens",
		};
	},
	validations: {
		medicationContactUser: {
			required,
		},
	},
	watch: {
		
	},
	mounted() {

		this.medicationContactUser = this.selfMedication.attributes.medication.attributes.contactUser.toPointer();
	},
	computed: {
		...mapGetters({
			users: "users/getFilteredUsers",
			focusedClient: "clients/getFocusedClient",
		}),
		isActive: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit("input", val);
			},
		},
		filteredUsers() {
			let filteredClients = []
			if (this.users) {
				this.users.forEach(element => {
					if (element.attributes.dependency.attributes.first_name ||
						element.attributes.dependency.attributes.last_name)
						filteredClients.push(element)
				});
			}
			return filteredClients
		},
		medicationDisplayName()
		{
			return this.selfMedication.attributes.medication.attributes.medication.attributes.displayName;
		},
		medicationSymbol()
		{
			return this.selfMedication.attributes.medication.attributes.medication.attributes.medicationUnit.attributes.symbol;
		},
		einzelDosis()
		{
			return this.selfMedication.attributes.medicationPlan.attributes.einzeldosis;
		}
	},
	methods: {
		closeModal() {
			this.hintOptional = "";
			this.medicationContactUser = undefined;
			this.isActive = false;
		},
		async saveLogEntry() {
			this.isLoading = true;

			const dataObjectL = Parse.Object.extend("MedicationLog")
			const dataObject = new dataObjectL()

			dataObject.set("client", this.focusedClient);
			dataObject.set("date", new Date());
			dataObject.set("medication", this.selfMedication);
			dataObject.set("time", this.time);
			dataObject.set("amount", String(this.einzelDosis));
			dataObject.set("state", 1);
			dataObject.set("hint", this.hintOptional);
			dataObject.set("givingUser", this.medicationContactUser);

			await dataObject.save()
			this.isLoading = false;
			this.closeModal()
			await positiveFeedBack("Verabreichung dokumentiert!")
		},
		getAmountString(value)
		{
			return String(value).replace(".",",");
		}
	}
}

</script>



<style scoped></style>
  