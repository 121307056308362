<template>
	<div class="
            is-flex is-flex-direction-column is-justify-content-space-between
        " style="height: calc(100% - 52px - 128px)">
		<div class="container scrollable" v-if="currentUserMeta">
			<div class="
                    is-flex
                    is-flex-direction-row
                    is-flex-wrap-wrap
                    is-justify-content-center
                " style="padding: 0; margin: 0">
				<module-tile :text="'Profil'" :icon="'account'" :route="'Profile'" :isActive="setIsActive"
					:isDisabled="!(use_profile == 'true' ? true : false)" class="module-tile" :class="[{ zoom: true }]"></module-tile>
				<module-tile :text="'Tagebuch'" :icon="'book-open-variant'" :route="'Diary'" :isActive="setIsActive"
					:isDisabled="!(use_diary == 'true' ? true : false)" class="module-tile" :class="[{ zoom: true }]"></module-tile>
				<module-tile :text="'Medikation'" :icon="'medication'" :route="'Medication'" :isActive="setIsActive"
					:isDisabled="!(use_medication == 'true' ? true : false)" class="module-tile" :class="[{ zoom: true }]"></module-tile>
				<module-tile :text="'Benachrichtigungen'" :icon="'bell'" :route="'Notifications'" :isActive="setIsActive"
					:isDisabled="!(use_notification == 'true' ? true : false)" class="module-tile" :class="[{ zoom: true }]"></module-tile>
				<module-tile :text="'Kinderakte'" :icon="'text-box'" :route="'ClientRecord'" :isActive="setIsActive"
					:isDisabled="!(use_record == 'true' ? true : false)" class="module-tile" :class="[{ zoom: true }]"></module-tile>
				<module-tile :text="'Datenanalyse'" :icon="'chart-areaspline'" :route="'Charts'" :isActive="setIsActive"
					:isDisabled="!(use_chart == 'true' ? true : false)" class="module-tile" :class="[{ zoom: true }]"></module-tile>
				<module-tile :text="'Punkteplan'" :icon="'star'" :route="'Goal'" :isActive="setIsActive" :isDisabled="!(use_goal == 'true' ? true : false)"
					class="module-tile" :class="[{ zoom: true }]"></module-tile>
					
				<output-tile :text="'Geburtstage'" :icon="'cake'" :link=app_link_birthday :isActive="setIsActive"
					:isDisabled=!app_link_birthday class="module-tile" :class="[{ zoom: true }]"></output-tile>
				<output-tile :text="'Berichte'" :icon="'book'" :link=app_link_report :isActive="setIsActive"
					:isDisabled=!app_link_report class="module-tile" :class="[{ zoom: true }]"></output-tile>
				<output-tile :text="'Haushaltsbuch'" :icon="'wallet'" :link=app_link_balance :isActive="setIsActive"
					:isDisabled=!app_link_balance class="module-tile" :class="[{ zoom: true }]"></output-tile>
				<output-tile :text="'Wochenplan'" :icon="'calendar-week'" :link=app_link_schedule :isActive="setIsActive"
					:isDisabled=!app_link_schedule class="module-tile" :class="[{ zoom: true }]"></output-tile>
				<output-tile :text="'QM-Handbuch'" :icon="'book-open-page-variant'" :link=app_link_handbook :isActive="setIsActive"
					:isDisabled=!app_link_handbook class="module-tile" :class="[{ zoom: true }]"></output-tile>

				<output-tile v-for="(widget, index) in orderedWidgets" :key="index"
					:text=widget.attributes.title :icon=widget.attributes.icon :link=widget.attributes.link
					:isActive="setIsActive" :id=widget.id :isGlobal=widget.attributes.isGlobal class="module-tile" :class="[{ zoom: true }]"></output-tile>

				<module-tile :text="'Neues Widget'" :icon="'plus'" :route="'NewWidget'" :isActive="setIsActive"
					:isDisabled="false" class="module-tile" :class="[{ zoom: true }]"></module-tile>
			</div>
		</div>
		<div style="height: 128px"><about-bar /></div>
	</div>
</template>
<script>
/**
 * Module for the selection of the current module.
 * @displayName Selection
 */

import { mapGetters } from "vuex";
import ModuleTile from "@/components/tiles/ModuleTile";
import OutputTile from "../tiles/OutputTile.vue";
import AboutBar from "@/components/bars/AboutBar";
import { APP_LINK_BIRTHDAY, APP_LINK_REPORT, APP_LINK_BALANCE, APP_LINK_SCHEDULE, APP_LINK_HANDBOOK,
	APP_USE_PROFILE, APP_USE_DIARY, APP_USE_MEDICATION, APP_USE_NOTIFICATION, APP_USE_RECORD, APP_USE_CHART, APP_USE_GOAL } from "../../configuration/AppConfigurations";

export default {
	name: "ModuleSelection",
	data() {
		return {
			app_link_birthday : APP_LINK_BIRTHDAY,
			app_link_report : APP_LINK_REPORT,
			app_link_balance : APP_LINK_BALANCE,
			app_link_schedule : APP_LINK_SCHEDULE,
			app_link_handbook : APP_LINK_HANDBOOK,
			orderedWidgets : [],
			use_profile : APP_USE_PROFILE,
			use_diary : APP_USE_DIARY ,
			use_medication : APP_USE_MEDICATION,
			use_notification : APP_USE_NOTIFICATION,
			use_record : APP_USE_RECORD,
			use_chart : APP_USE_CHART,
			use_goal : APP_USE_GOAL,
		};
	},
	mounted(
	) {	
		this.sort_widgets();
		this.$store.dispatch("pagination/setPage", undefined);
	},
	updated() { 
		// this.sort_widgets();
	},
	computed: {
		...mapGetters({
			currentUserMeta: "usermetas/getCurrentUserMeta",
		}),
		isActive: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit("input", val);
			},
		}
	},
	methods: {
		async setIsActive(value) {
			if (!this.$store.state.clients.focusedClientId)
				await this.$store.dispatch("setSidePanelOpen", true);
			this.isActive = value;
		},
		sort_widgets()
		{
			this.orderedWidgets = [];

			if(this.currentUserMeta && this.currentUserMeta.attributes.widgets)
			{
				let all_widgets = this.currentUserMeta.attributes.widgets;
				let all_widgets_ordered = all_widgets.sort((a, b) => b.attributes.isGlobal - a.attributes.isGlobal);
				let all_widgets_ordered_not_empty = [];
				for(var i = 0; i < all_widgets_ordered.length; i++)
				{
					if(all_widgets_ordered[i].attributes != "undefined" && Object.keys(all_widgets_ordered[i].attributes).length !== 0)
					{
						all_widgets_ordered_not_empty.push(all_widgets_ordered[i]);
					}
				}
				this.orderedWidgets = all_widgets_ordered_not_empty;
			}
		}
	},
	components: {
		ModuleTile,
		OutputTile,
		AboutBar,
	},
	watch: {
		currentUserMeta(){
			this.sort_widgets();
		}
	},
};
</script>

<style scoped>
.scrollable {
	overflow: auto;
	height: 100%;
	width: 100%;
	padding-bottom: 1.5rem;
}

.module-tile {
	flex: 1;
	flex-basis: 15%;
	min-width: 0;
	max-width: 15%;
	overflow: hidden;
}

.zoom {
	-webkit-transition: -webkit-transform 0.2s ease-out;
	-moz-transition: -moz-transform 0.2s ease-out;
	-o-transition: -o-transform 0.2s ease-out;
	-ms-transition: -ms-transform 0.2s ease-out;
	transition: transform 0.2s ease-out;
}

.zoom:hover {
	-webkit-transform: scale(1.05);
	-moz-transform: scale(1.05);
	-o-transform: scale(1.05);
	transform: scale(1.05);
}

@media (max-width: 1000px) {
	.module-tile {
		flex-basis: 25%;
		max-width: 25%;
	}
}

@media (max-width: 769px) {
	.module-tile {
		flex-basis: 50%;
		max-width: 50%;
	}
}
</style>
