<template>
<div>
	<div class="pl-2">
		<div v-for="i in 20" :key="i">
			<template>
				<div
					class="box entry-box p-0 mt-1 mb-2 ml-0"
					style="border-radius: .5rem"
				>
					<article class="media p-3 article" style="display: flex; justify-content: center">
						<div class="media-left">
								<b-icon icon="file-document" v-if="type === 'record'" class="p-2" size="is-large" type="is-gray"/>
							<AuthorImage v-else/>
						</div>
						<div class="media-content" style="overflow: hidden">
							<div class="mb-0">
								<div class="mt-0 mb-0">
									<div
										class="
                                            pt-0
                                            authorTagContainer
                                            is-font-medium
                                        "
										style="position: relative"
									>
										<AuthorNameAndCreationTime
										/>
										<div style="position: absolute; top:0; right: 0; display: flex">
										<div class="skeleton skeleton-author mr-2" style="height: 18px;min-width: 50px;
border-radius: .5rem; width: 8%"></div>
										<div class="skeleton skeleton-author" style="height: 18px;min-width: 65px;
border-radius: .5rem; width: 10%"></div>
										</div>
									</div>
								</div>
								<EntryTitle/>
								<EntryContent v-if="!isCharts"/>
								<div class="skeleton skeleton-chart" v-else></div>
								<br />
							</div>
						</div>
					</article>
				</div>
			</template>
		</div>
	</div>
</div>
</template>

<script>
import EntryTitle from "@/components/helper/EntryTitle";
import EntryContent from "@/components/helper/EntryContent";
import AuthorNameAndCreationTime from "@/components/helper/AuthorNameAndCreationTime";
import AuthorImage from "@/components/helper/AuthorImage";

export default {
	name: "DiarySkeleton",
	props:["isCharts", "type"],
	components: {
		AuthorImage,
		EntryTitle,
		EntryContent,
		AuthorNameAndCreationTime,
	},
	data(){
		return{
			author:{
				attributes:{
					dependency:{
						attributes:{
							gender:"divers"
						}
					}
				}
			},
			authorMeta:{
				attributes:{
					gender:"divers"
				},
				first_name:"-",
				last_name:""
			}
		}
	}
}
</script>

<style scoped>
.user_image img {
	object-fit: cover;
	height: 100%;
	width: 100%;
}
.skeleton-chart{
	width:100%;
	height: 4rem;
	border-radius: 1rem !important;
}

::v-deep .skeleton-text{
	width:100%;
	height: .83rem;
	margin-bottom: .25rem;
	border-radius: .125rem;
}

::v-deep .skeleton-text:last-child{
	margin-bottom: 0;
	width: 85%;
}
::v-deep .skeleton-title{
	width:30%;
	height: 1.15rem;
	margin-bottom: .7rem;
	border-radius: .125rem;
}
::v-deep .skeleton-author{
	width:20%;
	height: .6rem;
	margin-bottom: .5rem;
	margin-top: .25rem;
	border-radius: .25rem;
}

::v-deep .skeleton{
	animation: skeleton-loading 1s linear infinite reverse;
	border-radius: 5rem;
	opacity: .7;
}

::v-deep .mdi-file-document{
	animation: icon-loading 1s linear infinite reverse;
}

@keyframes icon-loading {
	0%{
		color: hsl(200, 20%, 70%);
	}
	100%{
		color: hsl(200, 20%, 95%);
	}
}

@keyframes skeleton-loading {
	0%{
		background-color: hsl(200, 20%, 70%);
	}
	100%{
		background-color: hsl(200, 20%, 95%);
	}
}
</style>

<style scoped>
.white-text {
	color: white;
}
.entry-box {
	margin-right: 0.5em;
	margin-left: 0.5em;
	cursor: pointer;
	padding-bottom: 15px;
}

.entry-box:hover {
	background-color: whitesmoke;
}

.scrollable {
	overflow: auto;
	height: 100%;
	width: 100%;
	padding-bottom: 1.5rem;
}

.article {
	width: 100%;
	border-radius: 0.5rem;
}

.entry-box-entry {
	margin-right: 0.5em;
	margin-left: 0.5em;
	padding-bottom: 15px;
	text-align: center;
}
@media (max-width: 1026px) {
	.scrollable {
		overflow: auto;
		height: 100%;
		width: 100%;
	}
}

@media (max-width: 769px) {
	.tabs:not(:last-child),
	.pagination:not(:last-child),
	.message:not(:last-child),
	.level:not(:last-child),
	.breadcrumb:not(:last-child),
	.block:not(:last-child),
	.title:not(:last-child),
	.subtitle:not(:last-child),
	.table-container:not(:last-child),
	.table:not(:last-child),
	.progress:not(:last-child),
	.progress-wrapper.is-not-native:not(:last-child),
	.notification:not(:last-child),
	.content:not(:last-child),
	.box:not(:last-child) {
		margin-bottom: 0;
	}
	.scrollable {
		height: 100%;
		width: 95vw;
	}
	.entry-box {
		display: flex;
		width: 96%;
	}
}

@media (max-width: 400px) {
	div.media-left {
		margin: 0.1em 0 0 0;
	}
}

@media (max-width: 450px) {
	.authorTagContainer {
		display: flex;
		flex-direction: row;
		align-items: start;
	}

	.iconContainer {
		justify-content: start !important;
		display: flex !important;
		flex-direction: row !important;
	}
}
</style>
