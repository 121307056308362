<template>
    <div v-if="ratings.length > 0">
        <h2 class="is-font-large is-font-bold my-3">Verhaltensbewertung</h2>
        <div
            v-for="rating in ratings"
            :key="rating.id"
            class="is-flex is-flex-direction-row is-align-items-center pb-2"
        >
            <div class="pr-2">
                <figure class="user_image image is-32x32 margined">
                    <img
                        class="is-rounded"
                        :src="getPicture(rating.attributes.client)"
                        alt="Klientenbild"
                    />
                </figure>
            </div>
            <div>
                {{
                    rating.attributes.client.attributes.first_name +
                    " " +
                    rating.attributes.client.attributes.last_name
                }}
            </div>
            <div class="ml-6">
                {{ getRating(rating)}}
            </div>
        </div>
    </div>
</template>

<script>
/**
 * Component for the display of rating of an entry.
 * @displayName EntryRating
 */

import { getClientPicture } from "@/lib/imageFunctions";

export default {
    name: "EntryRating",
    props: {
        ratings: {
            type: Array,
        },
    },
    created() {},
    updated() {},
    computed: {

	},
    methods: {
		getRating(rating){
			return rating.attributes.rating ? rating.attributes.rating :"neutral"
		},
        getPicture(client) {
            const pictureObject = {
                newProfilePicture: undefined,
                clientPicture: client.attributes.profile_picture
                    ? client.attributes.profile_picture._url
                    : undefined,
                clientGender: client.attributes.gender,
            };
            return getClientPicture(pictureObject);
        },
    },
};
</script>

<style lang="scss" scoped>
.user_image img {
    object-fit: cover;
    height: 100%;
    width: 100%;
}
</style>
