<template>
    <div
        class="
            is-flex
            is-flex-direction-row
            is-align-items-center
            is-justify-content-space-between
            userTopBar
        "
    >
        <div class="is-flex is-flex-direction-row is-align-items-center">
            <div class="mx-3 is-flex">
                <user-image class="userImageContainer" />
            </div>
            <b class="mx-3" style="color: whitesmoke">{{ userFullName }}</b>
        </div>
        <div class="is-flex is-flex-direction-row is-align-items-center">
            <b-button
                id="logout"
                type="is-danger"
                v-on:click="logout"
                icon-left="logout"
                class="is-flex-grow-1 mx-2"
            >
                Abmelden
            </b-button>
        </div>
    </div>
</template>

<script>
/**
 * TopBar user section displaying the signed-in user for no focused client.
 * @displayName RootUserHeader
 */

import UserImage from "./UserImage";
import { mapGetters } from "vuex";

export default {
    name: "RootUserHeader",
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            userFullName: "usermetas/getUserFullName",
        }),
        searchQuery: {
            set(searchQuery) {
                this.$store.dispatch("setSearchQuery", searchQuery);
            },
            get() {
                // Or remove mapState and use this.$store.state.values.example
                return this.$store.state.searchQuery;
            },
        },
    },
    methods: {
        isMobile() {
            return this.$store.getters.isMobile;
        },
        logout() {
            this.$store.state.focusedClientId = undefined;
            this.$store
                .dispatch("logoutUser")
                .then((succ) => {
                    this.$buefy.toast.open({
                        message: "Nutzer abgemeldet!",
                        type: "is-success",
                        position: "is-bottom",
                    });
                    this.isActive = false;
                    //this.$router.push({ name: "Login" });
                })
                .catch((err) => {
                    this.$buefy.toast.open({
                        message: "Fehler " + err,
                        type: "is-error",
                        position: "is-bottom",
                    });
                });
        },
    },
    components: {
        UserImage,
    },
};

//rgb(185, 205, 216)
</script>

<style scoped>
::v-deep .mdi {
    color: whitesmoke;
}
::v-deep span.is-left {
    display: flex;
    justify-content: center;
    align-items: center;
}
::v-deep .input {
    border-radius: 6px;
}

.figureContainer {
    width: 64px;
}

@media (max-width: 769px) {
    ::v-deep .icon {
        display: flex;
        justify-content: start;
        align-items: start;
        padding-left: 0.25rem;
    }
}

@media (max-width: 400px) {
    .userImageContainer img {
        width: 90%;
        height: 80%;
        align-self: center;
    }

    .userImageContainer span {
        margin-left: 4px;
        min-width: 12px;
        width: 40%;
        height: 50%;
    }
}
</style>
