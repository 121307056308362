<template>
    <div class="is-flex is-flex-direction-column scrollable">
        <div v-if="goalsLoaded" class="box entry-box media p-3 mx-1 mb-1">
            <div class="media-left">
                <figure class="image is-48x48">
                    <img
                        class="is-rounded object-cover"
                        :src="focusedGoal.attributes.picture._url"
                        alt="Zielbild"
                    />
                </figure>
            </div>
            <div
                class="
                    media-content
                    is-flex
                    is-flex-direction-row
                    is-justify-content-space-between
                "
                style="overflow: hidden"
            >
                <div
                    class="
                        is-flex
                        is-flex-direction-column
                        is-justify-content-center
                    "
                >
                    <small
                        v-if="focusedGoal.attributes.client.attributes.goal_day"
                        >{{
                            (7 -
                                getDay) %
                            7
                        }}
                        Tage {{ 23 - getHour }} Stunden verbleibend</small
                    >
                    <small v-else>Kein Stichtag angelegt!</small>
                    <div class="is-font-extra-large is-font-bold">
                        {{ focusedGoal.attributes.title }}
                    </div>
                </div>
				<div>
					<b-button
						size="is-medium"
						@click="navigate"
						icon-left="pencil"
						class="mr-1" >
					</b-button>
					<b-button
						size="is-medium"
						@click="deleteGoal"
						icon-left="close"
						class="mr-1" >
					</b-button>
				</div>
            </div>
        </div>
        <div
            v-if="focusedGoal && focusedGoal.attributes.attributes.length > 0"
            class="box entry-box p-3 mx-1 mb-1"
        >
            <div class="is-font-extra-large is-font-bold">Attribute</div>
            <div class="is-flex is-flex-direction-row">
                <div
                    v-for="entry_attribute in focusedGoal.attributes.attributes"
                    :key="entry_attribute.id"
                >
                    <span
                        v-if="entry_attribute.attributes.attribute_config"
                        class="
                            tag
                            is-dark is-rounded
                            entryTag
                            mr-1
                            mb-2
                            is-font-small
                        "
                        v-bind:style="{
                            'background-color':
                                entry_attribute.attributes.attribute_config
                                    .attributes.color,
                            color: getColor(
                                entry_attribute.attributes.attribute_config
                                    .attributes.color
                            ),
                        }"
                        >{{ entry_attribute.attributes.name }}</span
                    >
                    <span
                        v-else
                        class="
                            tag
                            is-dark is-rounded
                            entryTag
                            mr-1
                            mb-2
                            is-font-small
                        "
                        >{{ entry_attribute.attributes.name }}</span
                    >
                </div>
            </div>
        </div>
        <div v-if="goalsLoaded" class="box entry-box p-3 mx-1 mb-1">
            <div class="is-font-extra-large is-font-bold">
                Gesammelte Bewertungen
            </div>
            <div
                class="is-flex is-flex-direction-row is-items-align-center mx-4"
            >
                <Progress
                    :value="ratingsSum"
                    :max="maxValue"
                    :tooltip="false"
                    :key="ratingsSum"
                    indicator
                    format="raw"
                    type="is-success"
                    size="is-small"
                    class="mr-5"
                >
                    <ProgressStep
                        v-for="(goalStep, index) in focusedGoal.attributes
                            .ratingSeperators"
                        :key="index"
                        :value="goalStep"
                    >
                        {{ index + 1 }}
                    </ProgressStep>
                </Progress>
            </div>
            <div
                class="
                    is-flex is-flex-direction-column is-justify-content-center
                "
                v-if="goalLevel >= 0"
            >
                <div class="is-font-extra-large is-font-bold">Level</div>
                <template v-if="goalLevel === 0">
                    <b-icon class="mt-1" size="is-medium" icon="numeric-0-box">
                    </b-icon>
                </template>
                <template v-if="goalLevel === 1">
                    <b-icon class="mt-1" size="is-medium" icon="numeric-1-box">
                    </b-icon>
                </template>
                <template v-if="goalLevel === 2">
                    <b-icon class="mt-1" size="is-medium" icon="numeric-2-box">
                    </b-icon>
                </template>
                <template v-if="goalLevel === 3">
                    <b-icon class="mt-1" size="is-medium" icon="numeric-3-box">
                    </b-icon>
                </template>
                <template v-if="goalLevel === 4">
                    <b-icon class="mt-1" size="is-medium" icon="numeric-4-box">
                    </b-icon>
                </template>
            </div>
            <div v-if="goalsLoaded">
                <div class="is-font-extra-large is-font-bold">
                    Durchschnittliche Bewertungen
                </div>
                <div
                    style="display: flex; align-items: center; position: relative; width: 97%; border-radius: 0.5rem;"
                    class="m-3 p-1"
                >
                    <canvas :id="numberUuid" />
                </div>
            </div>
        </div>
        <div class="card m-1">
            <header
                class="
                    card-header
                    is-flex
                    is-flex-direction-column
                    is-align-items-center
                    is-justify-content-center
                "
            >
                <div
                    class="
                        is-flex
                        is-flex-direction-row
                        is-align-items-center
                        is-justify-content-center
                        m-1
                    "
                >
                    <b-button
                        icon-right="chevron-left"
                        @click="selectedDay = Math.abs((6 + selectedDay) % 7)"
                        class="mx-1"
                    />
                    <b-select
                        placeholder="Wochentag"
                        v-model="selectedDay"
                        class="mx-1"
                    >
						<option v-for="(item) in weekDays" 
							:value="item.id" 
							:key="item.id">
							{{ item.label }}
						</option>
                    </b-select>
                    <b-button
                        icon-right="chevron-right"
                        @click="selectedDay = Math.abs((selectedDay + 1) % 7)"
                        class="mx-1"
                    />
                </div>
                <div
                    class="
                        is-flex is-align-items-center is-flex-direction-column
                        m-2
                    "
					v-if="avgRatingsSum>0"
                >
                    Durchschnittliche Bewertung:

                    <b-rate
                        :value="avgRatingsSum"
                        :max="focusedGoal.attributes.maxRating"
                        size="is-medium"
                        :show-score="true"
                        :disabled="true"
                    >
                    </b-rate>
                </div>
				<div
                    class="
                        is-flex is-align-items-center is-flex-direction-column
                        m-2
                    "
					v-else
                >
                    Keine Bewertungen
                </div>
            </header>
            <div class="card-content" v-if="avgRatingsSum>0">
                <div class="content">
                    <div
                        v-for="rating in focusedRatings"
                        :key="rating.id"
                        class="box entry-box p-0 m-1"
                    >
                        <article class="media p-3 article">
                            <div class="media-left">
                                <AuthorImage
                                    :author="rating.attributes.author"
                                />
                            </div>
                            <div class="media-content" style="overflow: hidden">
                                <AuthorNameAndCreationTime
                                    :object="rating"
                                    :author-meta-class="
                                        rating.attributes.author.attributes
                                            .dependency
                                    "
                                />
                                <div class="is-font-extra-large is-font-bold">
                                    <b-rate
                                        :value="rating.attributes.rating"
                                        :max="focusedGoal.attributes.maxRating"
                                        size="is-medium"
                                        :show-score="true"
                                        :disabled="true"
                                    >
                                    </b-rate>
									<router-link
									:to="{ name: 'GoalRating', params: {newVal:false, rating:rating, ratingDays: weekDays }}"
									tag="div"
									class="plusIcon"
									v-if="currentUser == rating.attributes.author.id"
									>
										<b-icon
											style="cursor: pointer"
											size="is-medium"
											icon="pencil"
											class="mx-2"
										>
									</b-icon>
								</router-link>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
        <b-collapse
            class="m-1 card"
            animation="slide"
            aria-id="filter-collapse"
        >
            <template #trigger="props">
                <div
                    class="card-header"
                    role="button"
                    aria-controls="filter-collapse"
                    :aria-expanded="props.open"
                >
                    <p class="card-header-title">
                        <b-icon :icon="'history'"> </b-icon>Bewertungshistorie
                    </p>
                    <a class="card-header-icon">
                        <b-icon :icon="props.open ? 'menu-down' : 'menu-up'">
                        </b-icon>
                    </a>
                </div>
            </template>
            <div class="card-content">
                <rating-summary
                    :goal="focusedGoal"
                    :ratingsummary="rating"
                    v-for="rating in focusedRatingSummary"
                    :key="rating.id"
					class="m-1"
                ></rating-summary>
            </div>
        </b-collapse>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import Progress from "@/components/progress/Progress";
import ProgressStep from "@/components/progress/ProgressStep";
import AuthorImage from "@/components/helper/AuthorImage";
import AuthorNameAndCreationTime from "@/components/helper/AuthorNameAndCreationTime";
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
    registerables,
} from "chart.js";
import { v4 as uuid } from "uuid";
import Parse from "parse/dist/parse.min.js";
import { HEX2RGB } from "@/lib/Color";
import RatingSummary from "./RatingSummary.vue";

ChartJS.register(...registerables);
ChartJS.register(
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale
);

export default {
    name: "GoalDetail",
    components: {
        Progress,
        ProgressStep,
        AuthorNameAndCreationTime,
        AuthorImage,
        RatingSummary,
    },
    props: {
        id: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            barChart: undefined,
        };
    },
    mounted() {
        this.selectedDay = this.getDay;
        const canvasNumberObject = document.getElementById(this.numberUuid);
        this.barChart = new ChartJS(canvasNumberObject, {
            type: "bar",
            data: this.avgRatings,
            options: {
                spanGaps: true,
                maintainAspectRatio: false,
                responsive: true,
				onClick: this.handleOnClick,
                scales: {
                    y: {
                        type: "linear",
                        min: 0,
                        ticks: {
                            stepSize: 1,
                        },
                        display: true,
                        grid: {
                            display: false,
                        },
                    },
                    x: {
                        display: true,
                        grid: {
                            display: false,
                        },
                    },
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                },
            },
        });
    },
    watch: {
        avgRatings(newValue) {
            if (this.barChart) {
                this.barChart.data.labels = newValue.labels;
                this.barChart.data.datasets = newValue.datasets;
                this.barChart.update();
            }
        },
    },
    computed: {
        ...mapGetters({
            goals: "goals/getGoals",
            goalsLoaded: "goals/areGoalsLoaded",
            ratings: "ratings/getRatings",
            focusedClient: "clients/getFocusedClient",
            ratingsummary: "ratingsummary/getRatingSummary",
			weekDays: "weekDays/getWeekDays",
			currentDay: "weekDays/getCurrentDay"
        }),
		currentUser() {
			return Parse.User.current().id;
		},
        selectedDay: {
            get() {
                return this.$store.getters.ratingDay;
            },
            set(value) {
                this.$store.commit("SET_RATING_DAY", value);
            },
        },
        numberUuid() {
            return uuid().concat(this.clientId).concat("_numbers"); //combine attr id and client id
        },
        focusedGoal() {
            if (this.goalsLoaded) {
                return this.goals.filter((goal) => {
                    return goal.id === this.id;
                })[0];
            }

            return undefined;
        },
        focusedRatings() {
            if (this.ratings) {
                return this.ratings.filter((rating) => {
                    return (
                        rating.attributes.goal.id === this.id &&
                        rating.attributes.rating_day === this.selectedDay
                    );
                });
            }
            return undefined;
        },
        focusedRatingSummary() {
            if (this.ratingsummary) {
                return this.ratingsummary.filter((ratingsummary) => {
                    return ratingsummary.attributes.goal.id === this.focusedGoal.id;
                });
            }
            return undefined;
        },
        avgRatingsSum() {
            if (this.focusedRatings) {
                var ratingSum = 0;
                var ratingNum = 0;

                this.focusedRatings.forEach((rating) => {
                    ratingSum += rating.attributes.rating;
                    ratingNum++;
                });
                if (ratingNum > 0) {
                    return ratingSum / ratingNum;
                } else {
                    return 0;
                }
            }
            return undefined;
        },
        avgRatings() {
            if (this.ratings) {
                var dayRatings = [];
                var labels = [];
                this.weekDays.forEach((day) => {
                    var relevantRatings = this.ratings.filter((rating) => {
                        return (
                            rating.attributes.goal.id === this.id &&
                            rating.attributes.rating_day === day.id
                        );
                    });
                    var sum = relevantRatings.reduce((total, rating) => {
                        return (total += rating.attributes.rating);
                    }, 0);

                    if (relevantRatings.length > 0) {
                        dayRatings.push(sum / relevantRatings.length);
                        labels.push(day.label);
                    } else {
                        dayRatings.push(0);
                        labels.push(day.label);
                    }
                });
                return {
                    labels: labels,
                    datasets: [
                        {
                            label: "Durchschnittliche Bewertung",
                            data: dayRatings,
                            backgroundColor: "rgba(54, 162, 235, 0.2)",
                            borderColor: "rgb(54, 162, 235)",
                            borderWidth: 2,
                            borderRadius: 5,
                        },
                    ],
                };
            }
            return undefined;
        },
        goalLevel() {
            if (this.focusedGoal && this.ratingsSum) {
                var seps = this.focusedGoal.attributes.ratingSeperators;
                if (seps.length > 0) {
                    var sortedSeps = seps.sort(function (a, b) {
                        return a - b;
                    });
                    var level = 0;
                    while (sortedSeps[level]/100 * this.maxValue <= this.ratingsSum) {
                        level++;
                    }
                    return level;
                } else {
                    return undefined;
                }
            }
            return 0;
        },
        ratingsSum() {
            if (this.ratings) {
                var dayRatings = [];
                this.weekDays.forEach((day) => {
                    var relevantRatings = this.ratings.filter((rating) => {
                        return (
                            rating.attributes.goal.id === this.id &&
                            rating.attributes.rating_day === day.id
                        );
                    });
                    var sum = relevantRatings.reduce((total, rating) => {
                        return (total += rating.attributes.rating);
                    }, 0);

                    if (relevantRatings.length > 0) {
                        dayRatings.push(sum / relevantRatings.length);
                    } else {
                        dayRatings.push(0);
                    }
                });
                return dayRatings.reduce((total, rating) => {
                    return (total += rating);
                }, 0);
            }
            return 0;
        },
		maxValue(){
			if (this.ratings) {
                var dayRatings = [];
                this.weekDays.forEach((day) => {
                    var relevantRatings = this.ratings.filter((rating) => {
                        return (
                            rating.attributes.goal.id === this.id &&
                            rating.attributes.rating_day === day.id
                        );
                    });
                    var sum = relevantRatings.reduce((total, rating) => {
                        return (total += rating.attributes.rating);
                    }, 0);

                    if (relevantRatings.length > 0) {
                        dayRatings.push(sum / relevantRatings.length);
                    } else {
                        dayRatings.push(0);
                    }
                });
				let usedDays = dayRatings.filter(value => value != 0).length
				return usedDays * this.focusedGoal.attributes.maxRating
            }
            return 0;
		},
        getDay() {
			if (this.currentDay == -1){
				const d = new Date();
				return d.getDay();
			} else {
				return this.currentDay
			}
        },
        getHour() {
            const d = new Date();
            return d.getHours();
        },
    },
    methods: {
		navigate(){
			this.$router.push({
				name: "GoalEdit",
				params: {
					id : this.focusedGoal.id
				}
			})
		},
		handleOnClick(evt){
			var activePoints = this.barChart.getElementsAtEventForMode(evt, 'nearest', { intersect: true }, true);
			if (activePoints.length > 0){
				this.selectedDay = Math.abs((activePoints[0].index) % 7) + 1
			}
		},
        getColor(hex) {
            if (hex) {
                let number = 0;
                HEX2RGB(hex).forEach((num) => (number += num));
                if (number > 393) return "black";
                return "white";
            }
        },
        deleteGoal() {
            this.$buefy.dialog.confirm({
                cancelText: "Abbrechen",
                confirmText: "Bestätigen",
                message:
                    "Ziel endgültig löschen? Alle Bewertungen werden unwiderbringlich <b>gelöscht</b>.",
                onConfirm: async () => {
                    const goalParams = {
                        clientId: this.focusedClient.id,
                        goalId: this.focusedGoal.id,
                    };

                    await Parse.Cloud.run("deleteGoal", goalParams);
                    await this.$router.push({ path: "/module/goal" });
                },
                onCancel: () => {},
            });
        },
    },
};
</script>
<style scoped>
.object-cover {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.scrollable {
    overflow: auto;
    height: 100%;
    width: 100%;
    padding-bottom: 1.5rem;
}

.rate {
    flex-wrap: wrap;
}
</style>