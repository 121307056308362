<template>
    <div class="mb-5" style="width: 100%">
        <h3 class="attribute_titel mb-3">Sichtbarkeit</h3>
        <div class="columns m-0" style="width: 100%">
            <b-field>
				<b-switch v-model="entry_private" >Privat</b-switch>
			</b-field>
        </div>
		<small style="font-size:12px;">Als Privat markierte Einträge können nur vom Autor gesehen, bearbeitet und kommentiert werden. Private Einträge werden in Auswertungen nicht berücksichtigt.</small>
    </div>
</template>

<script>
/**
 * Component for the display of entry types.
 * @displayName EntryTypes
 */

import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import { HEX2RGB } from "@/lib/Color";

export default {
    name: "EntryPrivate",
    props: ["diaryEntryPrivate", "setEntryPrivate"],
    validations: {
        entry_private: {
            required,
        },
    },
    computed: {
        entry_private: {
            set(value) {
                this.setEntryPrivate(value);
            },
            get() {
				if(!this.diaryEntryPrivate)
				{
					return false;
				}else
				{
					return this.diaryEntryPrivate;
				}
                
            },
        },
    },
    methods: {
    },
    mounted() {
    },
};
</script>

<style lang="scss" scoped>
.attribute_titel {
    margin-top: 12px;
    font-weight: bold;
}

@media (max-width: 769px) {
    .columns:not(:last-child) {
        margin-bottom: 0;
    }

    .attribute_titel {
        margin: 0.5rem 0 !important;
    }

    .columns {
        display: flex;
        align-items: center;
        flex-direction: row;
        padding: 0px;
        margin: 0px;
	}

    .column {
        padding: 0;
        margin: 0;
    }
}


</style>
