<template>
	<b-modal v-model="isActive" has-modal-card @close="closeModal()" :can-cancel="false">
		<div class="modal-card modalCardContainer"
			style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%);">
			<header class="modal-card-head" style="background-color: rgba(40, 40, 40, 1); color: white">
				Neuen Arzt anlegen
			</header>
			<section class="modal-card-body" style="padding: 0.5em; color: black">
				

				<b-field expanded :message="(!$v.doctorName.$model) ? 'Bitte geben Sie den Namen des Arztes ein!' : ''"
					:type="(!$v.doctorName.$model) ? 'is-danger' : 'is-success'">
					<template #label>
						<div class="align-content-center" style="display: inline-flex">
							<p>Name</p>
						</div>
					</template>
					<b-input expanded type="text" v-model="doctorName" />
				</b-field>

				<DoctorSpecializationSearchableSelect :data-options="doctorSpecializationArray" :editObject="doctorSpecialization"
						:enable-validation="false" validationMessage=""
						label-text="Spezialisierung" :enable-order="true"
						:select="(object) => (doctorSpecialization = object)" />

				<b-field expanded>
					<template #label>
						<div class="align-content-center" style="display: inline-flex">
							<p>Straße, Hausnummer</p>
						</div>
					</template>
					<b-input expanded type="text" v-model="doctorStreet" />
				</b-field>
				<b-field expanded>
					<template #label>
						<div class="align-content-center" style="display: inline-flex">
							<p>PLZ</p>
						</div>
					</template>
					<b-input expanded type="text" v-model="doctorPLZ" />
				</b-field>
				<b-field expanded >
					<template #label>
						<div class="align-content-center" style="display: inline-flex">
							<p>Ort</p>
						</div>
					</template>
					<b-input expanded type="text" v-model="doctorLocation" />
				</b-field>
				
			</section>
			<footer class="modal-card-foot footerContainer" style="color: white">
				<b-button @click="closeModal()" type="is-primary">
					Abbrechen
				</b-button>
				<b-button @click="saveDoctor()" type="is-primary" v-bind:loading="isLoading"
					v-bind:disabled="$v.doctorName.$invalid">
					Speichern
				</b-button>
			</footer>
		</div>
	</b-modal>
</template>

<script>

import { mapGetters } from "vuex";
import { minLength, required } from "vuelidate/lib/validators";
import Parse from "parse/dist/parse.min.js";
import DoctorSpecializationSearchableSelect from "@/components/helper/SearchableSelects/DoctorSpecializationSearchableSelect.vue";

export default {
	name: "NewDoctorModal",
	components: {
		DoctorSpecializationSearchableSelect,
	},
	props: ["value"],
	data() {
		return {
			isLoading: false,
			doctorSpecialization: undefined,
			doctorName: "",
			doctorStreet: "",
			doctorPLZ: "",
			doctorLocation: "",
		};
	},
	validations: {
		doctorName: {
			required,
			minLength: minLength(1),
		},
	},
	mounted() { },
	computed: {
		...mapGetters({
			doctorSpecializationArray: "doctorSpecialization/getData",
		}),
		isActive: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit("input", val);
			},
		},
	},
	methods: {
		closeModal() {
			this.doctorName = ""
			this.doctorStreet = ""
			this.doctorPLZ = ""
			this.doctorLocation = ""
			this.doctorSpecialization = undefined
			this.isActive = false;
		},
		async saveDoctor() {
			this.isLoading = true;
			const parseObject = Parse.Object.extend("MedicationDoctor")
			const dataObject = new parseObject()
			dataObject.set("name", this.doctorName)
			dataObject.set("street", this.doctorStreet)
			dataObject.set("plz", this.doctorPLZ)
			dataObject.set("location", this.doctorLocation)
			dataObject.set("specialization", this.doctorSpecialization)
			dataObject.set("deleted", false)
			let emitObject = await dataObject.save()
			this.$emit("emit-new-doctor", emitObject)
			this.isLoading = false;
			this.closeModal()
		},
	}
}

</script>

<style scoped></style>
 