<template>
	<b-field expanded :message="enableValidation ? (($v.selectedValue.$invalid) ? validationMessage : '') : ''"
		:type="enableValidation ? (($v.selectedValue.$invalid) ? 'is-danger' : 'is-success') : ''">
		<template #label>
			<div class="align-content-center" style="display: inline-flex">
				<p> {{ labelText }}</p>
			</div>
		</template>
		<b-autocomplete class="autocomplete_break" open-on-focus clearable v-model="inputString" :data="orderedFilteredDataArray" icon="magnify"
			:custom-formatter="option => (option?.attributes.name)"
			@select="option => set(option)">

			<template slot-scope="props">
				<div class="media">
					<div class="media-content">
						{{ props?.option?.attributes.name  }}
					</div>
				</div>
			</template>

			<template #empty>Keine Ergebnisse gefunden.</template>
		</b-autocomplete>
	</b-field>
</template>

<script>

import { required, requiredIf } from "vuelidate/lib/validators";

export default {
	name: "MedicationUnitSearchableSelect",
	props: [
		"editObject",
		"select",
		"dataOptions",
		"enableValidation",
		"validationMessage",
		"labelText",
		"enableOrder"
	],
	data() {
		return {
			inputString: '',
			selectedValue: undefined
		}
	},
	validations: {
		selectedValue: {
			required: requiredIf(function (enableValidation) {
				return enableValidation
			}),
		},
	},
	mounted() { },
	computed: {
		orderedFilteredDataArray() {
			if (this.enableOrder) {
				let sortedArray = this.filteredDataArray
				return sortedArray.sort((a, b) =>
					(a.attributes.name.toLowerCase() > b.attributes.name.toLowerCase()) ? 1
						: ((b.attributes.name.toLowerCase() > a.attributes.name.toLowerCase()) ? -1 : 0));
			}
			else {
				return this.filteredDataArray
			}
		},
		filteredDataArray() {
			let returnVal = this.dataOptions.filter((element) => {
				let searchString = element.attributes.name.toLowerCase()
				return searchString.indexOf((this.inputString.toLowerCase())) >= 0
			})
			return returnVal
		}
	},
	methods: {
		set(option) {
			if (option) {
				this.select(option.toPointer())
			}
			if (option == null){
				this.selectedValue = undefined
			}
		}
	},
	watch: {
		editObject(newValue) {
			if (newValue) {
				this.selectedValue = this.orderedFilteredDataArray.find((element) => this.editObject.objectId == element.id)
				this.inputString = this.selectedValue.attributes.name
			}
		},
	},
};
</script>

<style lang="scss">

.autocomplete_break.autocomplete .dropdown-item, 
.autocomplete_break.autocomplete .dropdown .dropdown-menu .has-link a
{
	white-space: wrap;
}

.autocomplete_break.autocomplete .dropdown-item .media-content span
{
	display:inline;
}
</style>