<template>
	<div class="is-flex is-flex-direction-column" style="width:100%;">
		<!--b-field>
			<template #label>
				<div class="align-content-center" style="display: inline-flex">
					<p>Medikationsplan</p>
				</div>
			</template>
			Platzhalter Medikationsplan
		</b-field-->

		<template v-if="mask == 'new' || mask == 'edit'">
			<b-field v-if="timeFrame">
				<template #label>
					<div class="align-content-center" style="display: inline-flex">
						<p>Einnahmezeitraum</p>
					</div>
				</template>
				{{ timeFrame }}
			</b-field>
		</template>

		<template v-if="mask == 'new' || mask == 'edit'">
			<b-field expanded>
				<template #label>
					<div class="align-content-center" style="display: inline-flex">
						<p>Variante</p>
					</div>
				</template>
				<section>
					<div class="block">
						<b-radio :disabled="proppedHasLog" v-model="medicationVariante" name="medicationVariante"
							native-value="1">
							Standard
						</b-radio>
						<b-radio :disabled="proppedHasLog" v-model="medicationVariante" name="medicationVariante"
							native-value="2">
							Zu besonderen Zeiten
						</b-radio>
						<b-radio :disabled="proppedHasLog" v-model="medicationVariante" name="medicationVariante"
							native-value="3">
							Selbstmedikation
						</b-radio>
					</div>
				</section>
			</b-field>
		</template>

		<template v-if="mask == 'new' || mask == 'edit'">
			<b-field v-if="medicationVariante == 1" expanded
				:message="($v.medicationTimePlan.$invalid) ? 'Bitte füllen Sie den Verabreichungsplan aus!' : ''"
				:type="($v.medicationTimePlan.$invalid) ? 'is-danger' : 'is-success'">

				<table class="medicationPlanTable" expanded>
					<tr>
						<th colspan="5">Verabreichungsplan (Woche)</th>
					</tr>
					<tr>
						<th style="text-align:left;">Wochentag</th>
						<th>Morgens</th>
						<th>Mittags</th>
						<th>Abends</th>
						<th>Nachts</th>
					</tr>
					<tr v-for="option in weekArray" :key="option.index">
						<td>
							<small>{{ option }}</small>
						</td>
						<td v-for="time in timeArray" :key="time.index">
							<div class="is-flex">
								<b-input :disabled="proppedHasLog" :key="componentKey" v-if="medicationTimePlan"
									style="width:100%;" class="" size="is-small" type="text"
									:value="medicationTimePlan[option][time]"
									@input="buildTimePlanObject(option, time, $event)" />
								<span style="margin-top:2px;" class="ml-1 mr-1"><small>{{ symbol }}</small></span>
							</div>

						</td>
					</tr>
				</table>

			</b-field>

			<b-field v-else-if="medicationVariante == 2" expanded
				:message="($v.medicationTimePlanSpecial.$invalid) ? 'Bitte füllen Sie den Verabreichungsplan aus!' : ''"
				:type="($v.medicationTimePlanSpecial.$invalid) ? 'is-danger' : 'is-success'">
				<table :key="componentKeySpecial" class="medicationPlanTable" expanded>
					<tr>
						<th colspan="6">Verabreichungsplan (Woche)</th>
					</tr>
					<tr>
						<th>Datum (von/bis)</th>
						<th>Morgens</th>
						<th>Mittags</th>
						<th>Abends</th>
						<th>Nachts</th>
						<th></th>
					</tr>
					<tr v-for="(option, index) in medicationTimePlanSpecial" :key="option.index">
						<td>
							<div class="columns is-multiline m-0 is-centered">
								<div class="column is-full is-narrow is-centered p-0 m-0">
									<b-datepicker :disabled="proppedHasLog" class="" size="is-small" :first-day-of-week=1
										:date-formatter="dateFormatter" trap-focus locale="de-DE" :value="option['von']"
										@input="buildTimePlanObjectSpecial(index, 'von', $event)">
									</b-datepicker>
								</div>
							</div>
							<div class="columns is-multiline m-0 is-centered">
								<div class="column is-full is-narrow is-centered p-0 m-0">
									<b-datepicker :disabled="proppedHasLog" style="margin-top:-1px;" size="is-small"
										:first-day-of-week=1 :date-formatter="dateFormatter" trap-focus locale="de-DE"
										:value="option['bis']" @input="buildTimePlanObjectSpecial(index, 'bis', $event)">
									</b-datepicker>

								</div>
							</div>
						</td>
						<td v-for="time in timeArray" :key="time.index">
							<div class="is-flex">
								<b-input :disabled="proppedHasLog" v-if="medicationTimePlanSpecial" style="width:100%;"
									class="" size="is-small" type="text" :value="option[time]"
									@input="buildTimePlanObjectSpecial(index, time, $event)" />
								<span style="margin-top:2px;" class="ml-1 mr-1"><small>{{ symbol }}</small></span>
							</div>

						</td>
						<td>
							<b-tooltip v-if="!proppedHasLog" label="Verabreichungseintrag entfernen">
								<b-button class="buttonRemovePlanSpecial is-small" @click="removeNewTimePlanSpecial(index)"
									type="is-primary" icon-left="minus" />
							</b-tooltip>
						</td>
					</tr>
					<tr v-if="!proppedHasLog">
						<td class="has-text-centered" colspan="6">
							<b-tooltip label="Neuen Verabreichungseintrag hinzufügen">
								<b-button class="buttonNewPlanSpecial is-small" @click="addNewTimePlanSpecial"
									type="is-primary" icon-left="plus" />
							</b-tooltip>
						</td>
					</tr>
				</table>

			</b-field>

			<b-field style="position:relative;" v-else expanded
				:message="($v.medicationEinzeldosis.$invalid) ? 'Bitte geben Sie die Einzeldosis an!' : ''"
				:type="($v.medicationEinzeldosis.$invalid) ? 'is-danger' : 'is-success'">

				<template #label>
					<div class="align-content-center" style="display: inline-flex">
						<p>Einzeldosis</p>
					</div>
				</template>
				<b-input :disabled="proppedHasLog" style="width:100%; " class="" type="text"
					v-model="medicationEinzeldosis" />
				<span style="margin-top:2px; position:absolute; top:4px; right: 30px; z-index:100;"
					class="ml-1 mr-1"><small>{{ symbol }}</small></span>
			</b-field>
		</template>

		<template v-if="mask == 'view' && medicationTimePlan">
			<b-field v-if="medicationVariante == 1" expanded>

				<table class="medicationPlanTable" expanded>
					<tr>
						<th colspan="5">Verabreichungsplan (Woche)</th>
					</tr>
					<tr>
						<th style="text-align:left;">Wochentag</th>
						<th>Morgens</th>
						<th>Mittags</th>
						<th>Abends</th>
						<th>Nachts</th>
					</tr>
					<tr v-for="option in weekArray" :key="option.index">
						<td>
							<small>{{ option }}</small>
						</td>
						<td v-for="time in timeArray" :key="time.index">
							<div class="is-flex is-justify-content-center">
								<small>
									<span> {{ medicationTimePlan[option][time] }} </span>
									<span style="margin-top:2px;" class="ml-1 mr-1"><small>{{ symbol }}</small></span>
								</small>
							</div>


						</td>
					</tr>
				</table>

			</b-field>

			<b-field v-if="medicationVariante == 2" expanded>
				<table class="medicationPlanTable" expanded>
					<tr>
						<th colspan="5">Verabreichungsplan (Woche)</th>
					</tr>
					<tr>
						<th style="text-align:left;">Datum (von/bis)</th>
						<th>Morgens</th>
						<th>Mittags</th>
						<th>Abends</th>
						<th>Nachts</th>
					</tr>
					<tr v-for="(option) in medicationTimePlanSpecial" :key="option.index">
						<td>
							<div class="columns is-multiline m-0 is-centered">
								<div class="column is-full is-narrow is-centered p-0 m-0">
									<small>{{ option['von'].toLocaleDateString("de-DE",
										{
											year: "numeric",
											month: "2-digit",
											day: "2-digit",
										}) }} - {{ option['bis'].toLocaleDateString("de-DE",
		{
			year: "numeric",
			month: "2-digit",
			day: "2-digit",
		}) }}</small>
								</div>
							</div>
						</td>
						<td v-for="time in timeArray" :key="time.index">
							<div class="is-flex is-justify-content-center">
								<small>{{ option[time] }} {{ symbol }} </small>
							</div>
						</td>
					</tr>
				</table>

			</b-field>

			<b-field v-if="medicationVariante == 3" expanded>
				<template #label>
					<div class="align-content-center" style="display: inline-flex">
						<p>Selbstmedikation - Einzeldosis</p>
					</div>
				</template>
				{{ medicationEinzeldosis }} {{ symbol }}
			</b-field>
		</template>

		<template v-if="mask == 'new' || mask == 'edit'">
			<b-field>
				<template #label>
					<div class="align-content-center" style="display: inline-flex">
						<p>Anmerkungen</p>
					</div>
				</template>
				<b-input type="textarea" v-model="medicationPlanHint" />
			</b-field>
		</template>
		<template v-if="mask == 'view' && medicationPlanHint">
			<b-field>
				<template #label>
					<div class="align-content-center" style="display: inline-flex">
						<p>Anmerkungen</p>
					</div>
				</template>
				{{ medicationPlanHint }}
			</b-field>
		</template>

		<template v-if="mask == 'new' || mask == 'edit'">
			<div style="display: flex; justify-content: center">
				<b-button class="mt-3 button mb-5" type="is-primary" @click="saveMedicationPlan" v-bind:loading="isLoading"
					v-bind:disabled="($v.medicationTimePlanSpecial.$invalid && medicationVariante == 2) || ($v.medicationTimePlan.$invalid && medicationVariante == 1) || ($v.medicationEinzeldosis.$invalid && medicationVariante == 3)"
					:size="isMobile ? 'is-small' : ''">Speichern
				</b-button>
			</div>
		</template>


	</div>
</template>

<script>
/**
 * Sub-Module allowing the creation of a new medicationplan.
 * @displayName MedicationPlanInput
 *  
 */

import { mapGetters } from "vuex";
import Parse from "parse/dist/parse.min.js";
import { negativeFeedBack, positiveFeedBack } from "@/lib/Toast";

export default {
	name: "MedicationPlanInput",
	props: ["proppedMedicationObject", "value", "proppedPlanObject", "mask", "proppedHasLog"],
	components: {
	},
	emits: ['emit-data-object'],
	validations: {
		medicationTimePlan: {
			validator: function (value) {
				if (value) {
					let isValidTimePlan = true;
					let count_positiv_values = 0;
					let count_invalid_values = 0;
					Object.keys(value).forEach(key => {
						let days = value[key];

						Object.keys(days).forEach(key => {
							let time = days[key];

							let numberValue = String(time).replace(",", ".");
							numberValue = Number(numberValue);
							if (isNaN(numberValue)) {
								isValidTimePlan = false;
							}

							if (numberValue > 0) {
								count_positiv_values++;
							}

							if (isNaN(parseFloat(time))) {
								count_invalid_values++;
							}

						});
					});

					return count_positiv_values > 0 && isValidTimePlan && count_invalid_values == 0;
				} else {
					return false;
				}

			}
		},
		medicationTimePlanSpecial: {
			validator: function (value) {
				if (value) {
					let isValidTimePlan = true;
					let count_positiv_values = 0;
					let count_invalid_values = 0;
					let date_invalid = false;

					Object.keys(value).forEach(key => {
						let days = value[key];

						Object.keys(days).forEach(key => {
							let time = days[key];

							if (key != "von" && key != "bis") {
								let numberValue = String(time).replace(",", ".");
								numberValue = Number(numberValue);

								if (isNaN(numberValue)) {
									isValidTimePlan = false;
								}

								if (numberValue > 0) {
									count_positiv_values++;
								}

								if (isNaN(parseFloat(time))) {
									count_invalid_values++;
								}
							} else {
								if (!time) {
									date_invalid = true;
								}
							}

						});
					});

					return count_positiv_values > 0 && isValidTimePlan && count_invalid_values == 0 && !date_invalid;
				} else {
					return false;
				}

			}
		},
		medicationEinzeldosis: {
			validator: function (value) {
				if (value) {
					let numberValue = String(value).replace(",", ".");
					numberValue = Number(numberValue);

					if (isNaN(numberValue) || numberValue <= 0) {
						return false;
					} else {
						return true;
					}
				} else {
					return false;
				}

			}
		},
	},
	data() {
		return {
			medicationTimePlan: undefined,
			medicationTimePlanSpecial: [],
			medicationEinzeldosis: undefined,
			medicationPlanHint: "",
			medicationVariante: undefined,
			weekArray: ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'],
			timeArray: ['Morgens', 'Mittags', 'Abends', 'Nachts'],
			componentKey: 0,
			componentKeySpecial: 0,
		};
	},
	mounted() {
		if (this.planObject) {
			this.medicationTimePlan = this.planObject.attributes.timePlan;
			this.medicationTimePlanSpecial = this.planObject.attributes.timePlanSpecial;
			this.medicationEinzeldosis = String(this.planObject.attributes.einzeldosis).replace(".", ",");
			this.medicationPlanHint = this.planObject.attributes.hint;
			this.medicationVariante = this.planObject.attributes.art;
		}

		if (!this.medicationVariante) {
			this.medicationVariante = 1;
		}
	},
	updated() {

	},
	computed: {
		getSize() {
			return this.$store.getters.isMobile ? "is-small" : "";
		},
		isMobile() {
			return this.$store.getters.isMobile;
		},
		...mapGetters({
			medication: "medicationMedication/getMedication",
		}),
		isLoading: {
			get() {
				return this.proppedPlanIsLoading
			},
			set(val) {
				this.$emit("emit-save-plan-input", val)
			}
		},
		planObject: {
			get() {
				return this.proppedPlanObject
			},
			set(val) {
				this.$emit("emit-plan-object", val)
			}
		},
		timeFrame: function () {
			let displayString = ""

			if (this.proppedMedicationObject.attributes.startDate && this.proppedMedicationObject.attributes.endDate) {
				let begin = this.proppedMedicationObject.attributes.startDate;
				let end = this.proppedMedicationObject.attributes.endDate;
				displayString += begin.toLocaleDateString(
					"de-DE",
					{
						year: "numeric",
						month: "2-digit",
						day: "2-digit",
					}
				) + " - " + end.toLocaleDateString(
					"de-DE",
					{
						year: "numeric",
						month: "2-digit",
						day: "2-digit",
					}
				)
			}

			return displayString
		},
		symbol: function () {
			let symbol_string = "";

			if (this.proppedMedicationObject && this.proppedMedicationObject.attributes.medication) {
				for (var i = 0; i < this.medication.length; i++) {

					let temp_id = this.proppedMedicationObject.attributes.medication.id;
					if (!temp_id) {
						temp_id = this.proppedMedicationObject.attributes.medication.objectId;
					}
					if (this.medication[i].id == temp_id) {
						symbol_string = this.medication[i].attributes.medicationUnit.attributes.symbol;
					}
				}
			}

			return symbol_string;
		}
	},
	methods: {
		buildTimePlanObject(tag, zeit, wert) {
			//Zeitplan für Standard

			let rerender = false;

			let render_check_value = wert;
			let entry_value = wert.replace(".", "").replace(",", ".");

			if (render_check_value.indexOf(".") != -1 || isNaN(entry_value) || wert == "") {
				rerender = true;
			}

			if (isNaN(entry_value) || wert == "") {
				entry_value = 0;
			}
			entry_value = String(entry_value).replace(".", ",");
			if (entry_value.indexOf(",") == -1) {
				this.medicationTimePlan[tag][zeit] = String(Number(entry_value));
			} else {
				this.medicationTimePlan[tag][zeit] = entry_value;
			}



			if (rerender) {
				this.componentKey += 1;
			}
			this.checkIfValid();


		},
		addNewTimePlanSpecial() {
			let counter = this.medicationTimePlanSpecial.length;

			this.buildTimePlanObjectSpecial(counter, 'von', undefined);
			this.buildTimePlanObjectSpecial(counter, 'bis', undefined);
			this.timeArray.forEach((time, index) => {
				this.buildTimePlanObjectSpecial(counter, time, "0");
			});
		},
		removeNewTimePlanSpecial(index) {
			this.medicationTimePlanSpecial.splice(index, 1)
		},
		buildTimePlanObjectSpecial(key, index, wert) {
			//Zeitplan für besondere Zeiten

			let rerender = false;

			let render_check_value = wert;
			let entry_value = wert;
			if (render_check_value && index != "von" && index != "bis") {
				entry_value = wert.replace(".", "").replace(",", ".");

				if (render_check_value.indexOf(".") != -1 || isNaN(entry_value) || wert == "") {
					rerender = true;
				}

				if (isNaN(entry_value) || wert == "") {
					entry_value = 0;
				}

				entry_value = String(entry_value).replace(".", ",");
				if (entry_value.indexOf(",") == -1) {
					entry_value = String(Number(entry_value));
				} 
			}

			let specialObject = {};

			if (!this.medicationTimePlanSpecial || this.medicationTimePlanSpecial.length <= key) {
				rerender = true;
				specialObject = {
					'von': undefined,
					'bis': undefined,
					'Morgens': "0",
					'Mittags': "0",
					'Abends': "0",
					'Nachts': "0"
				}

				specialObject[index] = entry_value;

				if (!this.medicationTimePlanSpecial) {
					this.medicationTimePlanSpecial = [];
				}
				this.medicationTimePlanSpecial.push(specialObject);
			} else {
				specialObject = this.medicationTimePlanSpecial[key];
				specialObject[index] = entry_value;
				this.medicationTimePlanSpecial[key] = specialObject;
			}

			if (rerender) {
				this.componentKeySpecial += 1;
			}

			this.checkIfValid();

		},
		async saveMedicationPlan() {
			let temp_timePlanSpecial = this.planObject.attributes.timePlanSpecial;
			let new_timePlanSpecial = [];
			for (var i = 0; i < temp_timePlanSpecial.length; i++) {
				if (temp_timePlanSpecial[i]["Morgens"] != 0 || temp_timePlanSpecial[i]["Mittags"] != 0 || temp_timePlanSpecial[i]["Abends"] != 0 || temp_timePlanSpecial[i]["Nachts"] != 0) {
					new_timePlanSpecial.push(temp_timePlanSpecial[i]);
				}
			}
			this.medicationTimePlanSpecial = new_timePlanSpecial;
			this.planObject.set("timePlanSpecial", this.medicationTimePlanSpecial)

			this.isLoading = true;
			await positiveFeedBack("Medikationsplan gespeichert!")
		},
		checkIfValid() {
			let is_valid = undefined;

			if (this.medicationVariante == 1) {
				is_valid = this.$v.medicationTimePlan.$invalid;
			} else if (this.medicationVariante == 2) {
				is_valid = this.$v.medicationTimePlanSpecial.$invalid;
			} else if (this.medicationVariante == 3) {
				is_valid = this.$v.medicationEinzeldosis.$invalid;
			}

			this.$emit("emit-plan-is-valid", !is_valid)
		},
		dateFormatter(dt) {
			return dt.toLocaleDateString("de-DE", {
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
			});
		}
	},
	watch: {
		medicationTimePlan(newVal) {
			this.planObject.set("timePlan", newVal)
			this.checkIfValid();
		},
		medicationTimePlanSpecial(newVal) {
			this.planObject.set("timePlanSpecial", newVal)
			this.checkIfValid();
		},
		medicationEinzeldosis(newVal) {
			this.planObject.set("einzeldosis", parseFloat(String(newVal).replace(",", ".")))
			this.checkIfValid();
		},
		medicationPlanHint(newVal) {
			this.planObject.set("hint", newVal)
		},
		medicationVariante(newVal, oldVal) {

			this.planObject.set("art", parseInt(newVal))

			if ((!isNaN(oldVal) && oldVal) && (oldVal != newVal)) {
				this.medicationEinzeldosis = 0;

				this.weekArray.forEach((day, index) => {
					this.timeArray.forEach((time, index) => {

						this.buildTimePlanObject(day, time, "0")
					});
				});

				this.medicationTimePlanSpecial = [];
				this.addNewTimePlanSpecial();

				this.planObject.set("timePlan", this.medicationTimePlan)
				this.planObject.set("timePlanSpecial", this.medicationTimePlanSpecial)
				this.planObject.set("einzeldosis", parseFloat(this.medicationEinzeldosis))
			}


			this.checkIfValid();
		}
	}
};
</script>

<style scoped>
.medicationPlanTable {
	width: 100%;
}

.medicationPlanTable td,
.medicationPlanTable th {
	border: 1px solid #313131;
	padding: 2px;
	vertical-align: middle;
}

.medicationPlanTable th {
	text-align: center;

}
</style>
