<template>
  <article class="columns p-1 articleContainer" >
    <figure v-if="!isMobile" class="column pr-0 pt-0" style="padding-left: 0 !important;" :style="isMobile ? 'display: flex; justify-content: center':
'display: flex; justify-content: center; flex: none; width: 5%; min-width: calc(50px + .75rem)'">
      <div class="image" style="width: 50px; height: 50px">
        <!--p class="image "-->
        <img
            class="is-rounded is-48x48 authorPicture"
            style="height: 100%; width: 100%; object-fit: cover"
            :src="getPicture(comment.attributes.author)"
            alt="Profilbild"
        />
        <!--/p-->
      </div>
    </figure>
    <div class="column pl-2 pb-5 pt-1" style="border-radius: .5rem; background-color: rgba(0,0,0,0.05)">
      <small  :style="isMobile ? 'display: flex; justify-content: center':
''">
        {{
          comment.attributes.author.attributes.dependency
              .attributes.first_name +
          " " +
          comment.attributes.author.attributes.dependency
              .attributes.last_name
        }}<template v-if="isMobile">; &nbsp;</template>
        <time>
          {{
            comment.createdAt.toLocaleDateString(
                "de-DE",
                {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                }
            ) +
            " - " +
            comment.createdAt.toLocaleTimeString(
                "de-DE",
                {
                  hour: "2-digit",
                  minute: "2-digit",
                }
            )
          }}</time
        >
      </small>

      <div v-html="comment.attributes.text"  :style="isMobile ? 'display: flex; justify-content: center':
''"></div>
    </div>
  </article>
</template>

<script>
import {getUserPicture} from "@/lib/imageFunctions";
import {mapGetters} from "vuex";

export default {
  name: "CommentDetail",
  props:["comment", "replyFunction"],
  computed:{
    ...mapGetters({
                 isMobile: "isMobile"
               })

  },
  methods:{
    getPicture(author) {
      const pictureObject = {
        newProfilePicture: undefined,
        userPicture: author.attributes.dependency.attributes
            .profile_picture
            ? author.attributes.dependency.attributes.profile_picture
                ._url
            : undefined,
        userGender: author.attributes.dependency.attributes.gender,
      };
      return getUserPicture(pictureObject);
    },
  }

}
</script>

<style scoped>

</style>
