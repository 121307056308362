<template>
	<div style="height: 100%; width: 100%">
		<div  style="height: 100%; position: relative" v-if="isLoaded && focusedClient">
			<DiaryEntries />
		</div>
		<div v-if="!isLoaded && focusedClient" style="height: 100%">
			<DiarySkeleton />
		</div>
		<div v-else style="text-align: center">
			Kein Klient ausgewählt!
		</div>

	</div>
</template>

<script>
/**
 * Module displaying the entries associated with the focusedClient.
 * Entries are represented with a router-link which routes the view to the EntryDetail.
 * @displayName Diary
 */

import { mapGetters } from "vuex";
import DiarySkeleton from "@/components/modules/diary/DiarySkeleton";
import DiaryEntries from "@/components/modules/diary/DiaryEntries";

export default {
    name: "DiaryEntryPanel",
    components: {
		DiaryEntries,
		DiarySkeleton
    },
	mounted() {
		if(this.$route.params.data){
			let searchString = this.$route.params.data[0]
			if(this.$route.params.type){
				searchString = searchString + " " + this.$route.params.type
			} 
			this.$store.dispatch("setEntrySearchQuery", searchString);
		}
	},
	destroyed (){
		this.$store.dispatch("setEntrySearchQuery", '');
	},
    computed: {
        ...mapGetters({
            focusedClient: "clients/getFocusedClient",
			isLoaded: "entrylinks/getLoaded"
        }),
    },
	watch:{
		focusedClient(newData){
			if(!newData){
				this.$store.dispatch("setEntrySearchQuery", '');
			}
		}
	},
};
</script>



<style scoped>

.white-text {
    color: white;
}
.entry-box {
    margin-right: 0.5em;
    margin-left: 0.5em;
    cursor: pointer;
    padding-bottom: 15px;
}

.entry-box:hover {
    background-color: whitesmoke;
}

.scrollable {
    overflow: auto;
    height: 100%;
    width: 100%;
    padding-bottom: 1.5rem;
}

.article {
    width: 100%;
    border-radius: 0.5rem;
}

.entry-box-entry {
    margin-right: 0.5em;
    margin-left: 0.5em;
    padding-bottom: 15px;
    text-align: center;
}
@media (max-width: 1026px) {
    .scrollable {
        overflow: auto;
        height: 100%;
        width: 100%;
    }
}

@media (max-width: 769px) {
    .tabs:not(:last-child),
    .pagination:not(:last-child),
    .message:not(:last-child),
    .level:not(:last-child),
    .breadcrumb:not(:last-child),
    .block:not(:last-child),
    .title:not(:last-child),
    .subtitle:not(:last-child),
    .table-container:not(:last-child),
    .table:not(:last-child),
    .progress:not(:last-child),
    .progress-wrapper.is-not-native:not(:last-child),
    .notification:not(:last-child),
    .content:not(:last-child),
    .box:not(:last-child) {
        margin-bottom: 0;
    }
    .scrollable {
        height: 100%;
        width: 95vw;
    }
    .entry-box {
        display: flex;
        width: 96%;
    }
}

@media (max-width: 400px) {
    div.media-left {
        margin: 0.1em 0 0 0;
    }
}

@media (max-width: 450px) {
    .authorTagContainer {
        display: flex;
        flex-direction: row;
        align-items: start;
    }

    .iconContainer {
        justify-content: start !important;
        display: flex !important;
        flex-direction: row !important;
    }
}
</style>
