import store from "@/lib/store/store";

const state = {
	data: [],
	focusedEntryLinkId: undefined,
	loaded: false,
};

const getters = {
	getRatings(state) {
		if (state.data) {
			return state.data;
		}
		return undefined;
	},
	areEntryLinksLoaded(state) {
		return state.data && state.data.length > 0;
	},
	getFilteredEntryLinks(state) {
		if (state.data.length > 0) {
			return state.data;
		}
		return undefined;
	},
	getFocusedEntryLink(state) {
		if (state.data) {
			return state.data.find(entryLink => entryLink.id === state.focusedEntryLinkId)
		}
		return undefined;
	},
	getFocusedEntry(state, getters, rootState, rootGetters) {
		return rootState.focusedEntry
	},
	getLoaded(state) {
		return state.loaded
	}
};

const mutations = {
	SET(state, objects) {
		state.data = objects
	},
	INSERT(state, object) {
		state.data.unshift(object)
	},
	DELETE(state, object) {
		state.data = state.data.filter((elem) => {
			return elem.id != object.id
		})
	},
	REPLACE(state, object) {
		const oldObject = state.data.filter(item => item.id == object.id)
		const index = state.data.indexOf(oldObject[0]);
		if (index > -1) {
			state.data.splice(index, 1, object);
		}
	},
	SET_FOCUSED_ENTRY_LINK_ID(state, data) {
		state.focusedEntryLinkId = data
	},
	LOAD(state, load) {
		state.loaded = load
	},
};

const actions = {
	set({ commit }, objects) {
		const entryLinks = objects;
		commit('SET', entryLinks)
	},
	insert({ commit }, object) {
		commit('INSERT', object)
	},
	delete({ commit }, object) {
		commit('DELETE', object)
	},
	replace({ commit }, object) {
		commit('REPLACE', object)
	},
	setFocusedEntryLinkId({ commit }, query) {
		commit("SET_FOCUSED_ENTRY_LINK_ID", query)
	},
	load({ commit }, object) {
		commit('LOAD', object)
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
