<template>
	
	<div :class="[
		{ disabledTileContainer: isDisabled },
		{ tileContainer: !isDisabled },
	]">
		<template>
			<a v-if="!isDisabled" target="_blank" :href=link>
				<div class="
							m-2
							box
							is-flex
							is-flex-direction-row
							is-justify-content-space-between
							has-light-grey-color
						">

					<div class="
							is-flex
							is-flex-direction-column
						">
						<b-icon :icon="icon" size="is-large" class="tileIcon" />
						<div class="tileName is-font-large is-font-bold">
							{{ text }}
						</div>
					</div>

					

					<div v-if="id && !isGlobal" class="is-flex is-flex-direction-column">
						<b-button @click="toggleButtonsOpen" size="is-small" class="mb-1 pl-2 pr-2">...</b-button>
						<b-button v-if="buttonsOpen" size="is-small" @click="triggerEdit"  icon-left="pencil" class="mb-1 pl-2 pr-2">
						</b-button>
						<b-button v-if="buttonsOpen" size="is-small" @click="triggerDelete"  icon-left="delete" class=" pl-2 pr-2">
						</b-button>
					</div>

				</div>
			</a>
			<span v-else >
				<div class="
							m-2
							box
							is-flex
							is-flex-direction-row
							is-justify-content-space-between
							has-light-grey-color
						">

					<div class="
							is-flex
							is-flex-direction-column
						">
						<a v-if="!isDisabled" target="_blank" :href=link>
							<b-icon :icon="icon" size="is-large" class="tileIcon" />
							<div class="tileName is-font-large is-font-bold">
								{{ text }}
							</div>
						</a>
						<span v-else >
							<b-icon :icon="icon" size="is-large" class="tileIcon" />
							<div class="tileName is-font-large is-font-bold">
								{{ text }}
							</div>
						</span>
						
						
					</div>
				</div>
			</span>

		</template>
	</div>
</template>

<script>
/**
 * Tile containing a link for linking to a certain website with a label.
 * 
 * @displayName OutputTile
 */
import Parse from "parse/dist/parse.min.js";
import { mapGetters } from "vuex";

export default {
	name: "output-tile",
	props: ["icon", "text", "link", "isDisabled", "id", "isGlobal"],
	data() {
		return {
			buttonsOpen: false,
			
		};
	},
	computed: {
		...mapGetters({
			currentUserMeta: "usermetas/getCurrentUserMeta",
		})
	},
	methods: {
		async triggerEdit(e) {
			e.preventDefault();
			const widgetParams = {
				widgetID: this.id,
				title: this.text,
				icon: this.icon,
				link: this.link,
			};
			await this.$router.push({ name: "EditWidget", params: { data: widgetParams } });
		},
		async triggerDelete(e) {
			e.preventDefault();
			const widgetParams = {
				widgetID: this.id,
				metaID: this.currentUserMeta.id,
			};
			await Parse.Cloud.run("deleteWidget", widgetParams);
		},
		toggleButtonsOpen(e)
		{
			e.preventDefault();
			this.buttonsOpen = !this.buttonsOpen;
		}
	},
};
</script>

<style scoped>
.box {
	cursor: pointer;
}

.disabledTileContainer .box {
	cursor: default;
	background-color: lightgrey;
}

.tileName {
	text-align: start;
	/*white-space: nowrap;*/
	overflow: hidden;
	/*text-overflow: ellipsis;*/
	color: hsl(0, 0%, 29%);
	height:50px;
}

.tileIcon {
	margin-bottom: 1.5em;
	color: hsl(0, 0%, 29%);
}

@media (max-width: 769px) {
	.box {
		margin-right: 3px;
		margin-left: 3px;
	}

	.tileIcon {
		margin-bottom: 0.25em;
	}
}
</style>
