<template>
	<div style="height: 100%; width: 100%">
		<div  style="height: 100%; position: relative" v-if="isLoaded && focusedClient">
			<ClientRecordEntries />
		</div>
		<div v-if="!isLoaded && focusedClient" style="height: 100%">
			<DiarySkeleton type="record"/>
		</div>
	</div>
</template>

<script>
/**
 * Module displaying the entries associated with the focusedClient.
 * Entries are represented with a router-link which routes the view to the EntryDetail.
 * @displayName Diary
 */

import { mapGetters } from "vuex";
import DiarySkeleton from "@/components/modules/diary/DiarySkeleton";
import DiaryEntries from "@/components/modules/diary/DiaryEntries";
import ClientRecordEntries from "@/components/modules/record/ClientRecordEntries";

export default {
	name: "ClientRecord",
	components: {
		ClientRecordEntries,
		DiarySkeleton
	},
	computed: {
		...mapGetters({
			focusedClient: "clients/getFocusedClient",
			isLoaded: "clientrecords/getLoaded"
		}),
	},
};
</script>



<style scoped>
</style>
