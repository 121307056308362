import Parse from 'parse/dist/parse.min.js';

const state = {
	data: [],
	mask: "",
	reiter: 0,
	focusedMedicationEntryId: undefined,
	focusedMedicationEntry: undefined
};

const getters = {
	getFilteredMedicationEntries(state) {
		if (state.data) {

			// let dateObject = [];
			for (var i = 0; i < state.data.length; i++) {
				let aktiv = 1;
				let akt_date = new Date();

				let medicationEndDate = undefined;
				let medicationDropDate = undefined;
				let prescriptionDate = undefined;
				let reportDate = undefined;
				let treatmentBegin = undefined;
				let treatmentEnd = undefined;


				if (state.data[i].attributes.medication) {
					medicationEndDate = state.data[i].attributes.medication.attributes.endDate;
					if (akt_date.setHours(0, 0, 0, 0) > medicationEndDate.setHours(0, 0, 0, 0)) {
						aktiv = 0;
					}

					if (state.data[i].attributes.medication.attributes.dropDate) {
						medicationDropDate = state.data[i].attributes.medication.attributes.dropDate;

						if (akt_date.setHours(0, 0, 0, 0) >= medicationDropDate.setHours(0, 0, 0, 0)) {

							aktiv = 0;
						}
					}

				}

				if (state.data[i].attributes.medicationPrescription) {
					if (state.data[i].attributes.medicationPrescription.attributes.date) {
						prescriptionDate = state.data[i].attributes.medicationPrescription.attributes.date;
					}
				}

				if (state.data[i].attributes.medicationReport) {
					reportDate = state.data[i].attributes.medicationReport.attributes.date;
					if (state.data[i].attributes.medicationReport.attributes.treatmentBegin) {
						treatmentBegin = state.data[i].attributes.medicationReport.attributes.treatmentBegin;
					}
					if (state.data[i].attributes.medicationReport.attributes.treatmentEnd) {
						treatmentEnd = state.data[i].attributes.medicationReport.attributes.treatmentEnd;
					}
				}


				if (medicationDropDate) {
					state.data[i].orderDate = medicationDropDate;
				} else if (medicationEndDate) {
					state.data[i].orderDate = medicationEndDate;
				} else if (treatmentEnd) {
					state.data[i].orderDate = treatmentEnd;
				} else if (prescriptionDate) {
					state.data[i].orderDate = prescriptionDate;
				} else {
					state.data[i].orderDate = new Date();
				}

				state.data[i].active = aktiv;
			}

			state.data.sort(compareByOrderDate)

			return state.data;
		}
		return undefined
	},
	getSelfMedicationEntries(state) {
		if (state.data) {
			let medicationSelfEntries = [];
			let akt_date = new Date();
			
			for (var i = 0; i < state.data.length; i++) {

				let medicationEndDate = undefined;
				let medicationDropDate = undefined;
				let aktiv = 1;

				if (state.data[i].attributes.medication) {
					medicationEndDate = state.data[i].attributes.medication.attributes.endDate;
					if (akt_date.setHours(0, 0, 0, 0) > medicationEndDate.setHours(0, 0, 0, 0)) {
						aktiv = 0;
					}

					if (state.data[i].attributes.medication.attributes.dropDate) {
						medicationDropDate = state.data[i].attributes.medication.attributes.dropDate;

						if (akt_date.setHours(0, 0, 0, 0) >= medicationDropDate.setHours(0, 0, 0, 0)) {

							aktiv = 0;
						}
					}

				}

				if(state.data[i].attributes.hasMedicationLog && state.data[i].attributes.medicationPlan.attributes.art == 3 && aktiv == 1)
				{
					medicationSelfEntries.push(state.data[i]);
				}
			}

			return medicationSelfEntries;
		}
		return undefined
	},
	getFocusedMedicationEntry(state, getters, rootState, rootGetters) {
		return state.focusedMedicationEntry
	},
	getFocusedMedicationEntryMask(state) {
		if (state.mask) {
			return state.mask;
		}
		return undefined
	},
	getFocusedMedicationReiter(state) {
		if (state.reiter) {
			return state.reiter;
		}
		return 0
	}
};

function compareByOrderDate(a, b) {
	return b.orderDate - a.orderDate;
}

const mutations = {
	SET(state, objects) {
		state.data = objects
	},
	INSERT(state, object) {
		state.data.push(object)
	},
	DELETE(state, object) {

		state.data = state.data.filter((elem) => {
			return elem.id != object.id
		})
	},
	REPLACE(state, object) {
		const oldObject = state.data.filter(item => item.id == object.id)
		const index = state.data.indexOf(oldObject[0]);
		if (index > -1) {
			state.data.splice(index, 1, object);
		}
	},
	LOAD(state, load) {
		state.loaded = load
	},
	SET_FOCUSED_MEDICATION_ENTRY_MASK(state, value) {
		state.mask = value;
	},
	SET_FOCUSED_MEDICATION_REITER(state, value) {
		state.reiter = value;
	},
	SET_FOCUSED_MEDICATION_ENTRY_ID(state, value) {
		state.focusedMedicationEntryId = value
	},
	SET_FOCUSED_MEDICATION_ENTRY(state, value) {
		state.focusedMedicationEntry = value;
	}
};

const actions = {
	set({ commit }, objects) {
		commit('SET', objects)
	},
	insert({ commit }, object) {
		commit('INSERT', object)
	},
	delete({ commit }, object) {
		commit('DELETE', object)
	},
	replace({ commit }, object) {
		commit('REPLACE', object)
	},
	load({ commit }, object) {
		commit('LOAD', object)
	},
	setFocusedMedicationEntryMask({ commit }, value) {
		commit('SET_FOCUSED_MEDICATION_ENTRY_MASK', value);
	},
	setFocusedMedicationReiter({ commit }, value) {
		commit('SET_FOCUSED_MEDICATION_REITER', value);
	},
	setFocusedMedicationEntryId({ commit }, query) {

		commit("SET_FOCUSED_MEDICATION_ENTRY_ID", query)
	},
	setFocusedMedicationEntry({ commit }, query) {
		commit("SET_FOCUSED_MEDICATION_ENTRY", query)
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};