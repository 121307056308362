const state = {
	data: [],
	filteredData: [],
};

const getters = {
	isAuthorLoaded(state) {
		return state.data && state.data.length > 0;
	},
	getUserName(state, getters, rootState) {
		if (rootState.currentUser) {
			return rootState.currentUser.attributes.username;
		}
		return undefined
	},
	getUserId(state, getters, rootState) {
		if (!rootState.currentUser) return undefined
		return rootState.currentUser.id
	},
	getUsers(state) {
		if (state.data) {
			return state.data;
		}
		return undefined
	},
	getFilteredUsers(state) {
		if (state.filteredData) {
			return state.filteredData;
		}
		return undefined
	}
};

const mutations = {
	SET(state, objects) {
		state.data = objects
		let filteredUsersLoc = objects.filter((element) => element.attributes.dependency.attributes.email != element.attributes.dependency.id && 
			element.attributes.dependency.attributes.deleted != true)
		state.filteredData = filteredUsersLoc
	},
	INSERT(state, object) {
		state.data.push(object)
		state.filteredData.push(object)
	},
	DELETE(state, object) {
		state.data = state.data.filter((elem) => {
			return elem.id != object.id
		})

		state.filteredData = state.filteredData.filter((elem) => {
			return elem.id != object.id
		})
	},
	REPLACE(state, object) {
		const oldObject = state.data.filter(item => item.id == object.id)
		const index = state.data.indexOf(oldObject[0]);
		if (index > -1) {
			state.data.splice(index, 1, object);
		}

		const oldObjectFiltered = state.filteredData.filter(item => item.id == object.id)
		const indexFiltered = state.filteredData.indexOf(oldObjectFiltered[0]);
		if (indexFiltered > -1) {
			state.filteredData.splice(indexFiltered, 1, object);
		}
	
	},
};

const actions = {
	set({ commit }, objects) {
		commit('SET', objects)
	},
	insert({ commit }, object) {
		commit('INSERT', object)
	},
	delete({ commit }, object) {
		commit('DELETE', object)
	},
	replace({ commit }, object) {
		commit('REPLACE', object)
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
