var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.notifications && _vm.isLoaded)?_c('div',_vm._l((_vm.notifications),function(notification){return _c('div',{key:notification.id + notification.attributes.client.id,staticClass:"notification-box p-0 mb-2 card-content collapseContentContainer box",staticStyle:{"height":"auto"}},[(_vm.isAuthorLoaded &&
                    _vm.isAuthorMetaClassLoaded &&
                    _vm.areEntryLinksLoaded &&
                    _vm.areEntryTypesLoaded &&
                    _vm.areClientsLoaded)?_c('article',{staticClass:"media p-3 article",on:{"click":function($event){return _vm.triggerNotificationTask(notification)}}},[_c('div',{staticClass:"media-left"},[_c('AuthorImage',{attrs:{"client":notification.attributes.client}})],1),_c('div',{staticClass:"media-content",staticStyle:{"overflow":"hidden"}},[_c('div',{staticClass:"mb-0"},[_c('div',{staticClass:"columns mt-0 mb-0"},[_c('div',{staticClass:"column pt-0 clientTagContainer is-flex is-justify-content-space-between"},[_c('AuthorNameAndCreationTime',{attrs:{"author-meta-class":notification.attributes.client,"group":notification.attributes.client
                                            .attributes.group}}),_c('div',{staticClass:"is-flex is-justify-content-space-between gap-2"},[_c('ColoredTag',{attrs:{"tag-name":notification.attributes.message,"tag-color":"#000000"}}),(_vm.type === 'diary')?_c('ColoredTag',{attrs:{"tag-name":notification.attributes.dependencyEntry
                                            .attributes.type.attributes.name,"tag-color":notification.attributes.dependencyEntry
                                            .attributes.type.attributes.color}}):_vm._e()],1)],1)]),(_vm.type === 'diary')?[_c('EntryTitle',{attrs:{"entry":notification.attributes.dependencyEntry}})]:_vm._e(),(_vm.type === 'document' && _vm.areDocumentsLoaded)?[_c('EntryTitle',{attrs:{"entry":notification.attributes.clientFile}})]:_vm._e()],2),(_vm.type === 'diary' || _vm.type === 'document')?[_c('AuthorNameAndCreationTime',{attrs:{"object":notification,"author-meta-class":notification.attributes.author
                                    .attributes.dependency}})]:_vm._e()],2)]):_vm._e()])}),0):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }