<template>
	<div style="width: 100%">
		<b-upload class="uploader" v-model="dropFiles" expanded multiple drag-drop
			:accept=acceptedData>
			<p>
				<b-icon icon="upload" size="is-small"></b-icon>
				Bilder/Dateien hochladen
			</p>
		</b-upload>
		<div v-if="dropFiles.length > 0">
			<div class="columns imagesContainer" style="flex-wrap: wrap">
				<div v-for="(file, index) in dropFiles" :key="'image_' + index" :class=isImageFile(file)>
					<div v-if="file.type != 'application/pdf'" class="imageContainer">
						<img class="image" :src="getPictureUrl(file)" alt="Keine Vorschau verfügbar" />
						<b-icon class="imageDelete" @click.native="deleteDropFile(index)" icon="close" />
					</div>
				</div>
			</div>

			<hr style="height: 1px; background-color: black" />

			<div v-for="(file, index) in dropFiles" :key="'file_' + index">
				<div v-if="file.type == 'application/pdf'">
					<div class="fileItem">
						<b-icon icon="file-pdf-box" size="is-medium" />
						<div v-if="file.attributes">
							<a tag="a" :href="file.attributes.file._url" target="_blank">
								{{ file.attributes.fileTitle }}
							</a>
						</div>
						<div v-else style="padding-top: 4px; margin-left: 6px">
							{{ file.name }}
						</div>
						<b-icon class="fileDelete" @click.native="deleteDropFile(index)" icon="close"/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
/**
 * Input of files.
 * @displayName FileInput
 */

import { getModifiedImage } from "@/lib/imageFunctions";

export default {
	name: "FileInput",
	props: [
		"dropFilesModified",
		"setModifiedDropFiles",
		"addModifiedDropFiles",
		"allowData",
	],
	data() {
		return {
			dropFiles: [],
		};
	},
	computed: {
		isMobile() {
			return this.$store.getters.isMobile;
		},
		acceptedData(){
			if(this.allowData){
				return ".jpg, .jpeg, .png, application/pdf"
			} else {
				return ".jpg, .jpeg, .png"
			}
		}
	},
	methods: {
		deleteDropFile(index) {
			this.dropFiles.splice(index, 1);
			this.dropFilesModified.splice(index, 1);
		},
		getPictureUrl(file) {
			return URL.createObjectURL(file);
		},
		isImageFile(file) {
			if(file.type != 'application/pdf')
			{
				return "column is-one-third";
			}else
			{
				return "";
			}
		}
	},
	watch: {
		dropFiles: {
			handler: function (fileArray) {
				this.setModifiedDropFiles([]);

				fileArray.forEach((value, index, array) => {
					if (value.type === 'image/jpeg' || value.type === 'image/jpg' || value.type === 'image/png') {
						getModifiedImage(value, 3000)
							.then((response) => {
								this.addModifiedDropFiles(response);
							})
							.catch(function (error) {
								console.error(error);
							});
					} else {
						if (this.allowData) {
							if (value.type === 'application/pdf') {
								this.addModifiedDropFiles(value);
							}
						}
					}
				});
			},
			deep: true,
		},
	},
};
</script>

<style lang="scss">
.imageContainer {
	position: relative !important;
	margin-top: 0.25rem !important;
}

.columns {
	display: flex;
}

.fileItem {
	flex-wrap: wrap !important;
	display: flex !important;
	align-items: flex-start !important;
	flex-direction: row !important;
	align-content: flex-start !important;
	justify-content: flex-start !important;
}

.fileItem span {
	width: auto !important;
}

.imageDelete {
	background-color: rgb(255 255 255) !important;
	border-radius: 0.375rem !important;
	position: absolute !important;
	top: 0.5rem !important;
	right: 0.5rem !important;
	cursor: pointer !important;
	width: auto !important;
}

.fileDelete {
	border-radius: 0.375rem !important;
	cursor: pointer !important;
	width: auto !important;	
	padding-top: 10px !important;
	margin-left: 10px !important;
}
</style>

<style lang="scss" scoped>
.uploader {
	height: 8rem;
}

::v-deep .upload-draggable {
	display: flex;
	justify-content: center;
	align-items: center;
}

span.icon {
	display: flex;
	justify-content: center;
	width: 100%;
}

.image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.imagesContainer {
	margin-top: 0.25rem;
	width: calc(100% - 1.5rem);
}

@media (max-width: 769px) {


	.columns:not(:last-child) {
		margin-bottom: 0;
	}

	.column {
		padding: 0;
		margin: 0;
	}

	::v-deep .icon {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.imagesContainer {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 0.25rem;
		margin-top: 0.25rem;
	}
}
</style>