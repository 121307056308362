<template>
    <b-field label="Klienten hinzufügen">
        <b-autocomplete
            dropdown-position="bottom"
            @focus="clickedSearchClient()"
            class="autoComplete"
            rounded
            v-model="name"
            :data="filteredClientArray"
            placeholder="Klientenname oder Gruppe"
            icon="magnify"
            clearable
            @select="(option) => selectClient(option)"
            :size="isMobile ? 'is-small' : ''"
        >
            <template slot-scope="props">
                <div class="media">
                    <div class="media-left">
                        <img
                            width="32"
                            :src="getPicture(props.option)"
                            alt="Klientenbild"
                        />
                    </div>
                    <div class="media-content">
                        {{
                            props.option.attributes.first_name +
                            " " +
                            props.option.attributes.last_name
                        }}
                        <br />
                        <small>
                            {{
                                props.option.attributes.group.attributes
                                    .group_name
                            }}
                        </small>
                    </div>
                </div>
            </template>
            <template #empty>Kein Ergebnis gefunden</template>
        </b-autocomplete>
    </b-field>
</template>

<script>
/**
 * Component for the search of clients in the sidebar.
 * @displayName ClientSearch
 */

import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import { getClientPicture } from "@/lib/imageFunctions";

export default {
    name: "ClientSearch",
    props: [
        "ratings",
        "clients",
        "setRatings",
        "setClients",
        "filteredRatings",
    ],
    data() {
        return {
            name: "",
        };
    },
    validations: {
        selectedRating: {
            required,
            $each: {
                required,
            },
        },
    },
    computed: {
        ...mapGetters({
            filteredClients: "clients/getOrderedClients",
            focusedClient: "clients/getFocusedClient",
        }),
        selectedRating: {
            set(value) {
                this.setRatings(value);
            },
            get() {
                return this.ratings;
            },
        },
        selectedClients: {
            set(value) {
                this.setClients(value);
            },
            get() {
                return this.clients;
            },
        },
        filteredClientArray() {
            return this.filteredClients.filter((option) => {
                if (
                    this.name &&
                    option !== this.focusedClient &&
                    this.selectedClients.indexOf(option) < 0 &&
                    this.ratedClientIds.indexOf(option.id) < 0
                ) {
                    const query = this.name.toLowerCase();
                    const isQueryInGroupName =
                        option.attributes.group.attributes.group_name
                            .toLowerCase()
                            .includes(query);
                    const isQueryInName =
                        `${option.attributes.first_name} ${option.attributes.last_name}`
                            .toLowerCase()
                            .includes(query);
                    return isQueryInGroupName || isQueryInName;
                }
            });
        },
        ratedClientIds() {
            return this.filteredRatings.map((rating) => {
                return rating.attributes.client.id;
            });
        },
        getSize() {
            return this.$store.getters.isMobile ? "is-small" : "";
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
    },
    methods: {
        selectClient(client) {
            if (!client) return;
            this.selectedClients = [client, ...this.selectedClients];
            this.selectedRating = [undefined, ...this.selectedRating];
            this.clickedSearchClient();
        },
        getPicture(selectedClient) {
            const pictureObject = {
                newProfilePicture: undefined,
                clientPicture: selectedClient.attributes.profile_picture
                    ? selectedClient.attributes.profile_picture._url
                    : undefined,
                clientGender: selectedClient.attributes.gender,
            };
            return getClientPicture(pictureObject);
        },
        clickedSearchClient() {
            if (this.$store.getters.isMobile) {
                this.$store.dispatch("toggleOpenedKeyboard");
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .input {
    border-radius: 6px;
}

::v-deep .icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 769px) {
    ::v-deep span.is-left {
        padding-left: 0.25rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .autoComplete {
        width: 100%;
    }
}
</style>
