<template>
    <time>
        {{
            object.createdAt.toLocaleDateString("de-DE", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            }) +
            " - " +
            object.createdAt.toLocaleTimeString("de-DE", {
                hour: "2-digit",
                minute: "2-digit",
            })
        }}</time
    >
</template>

<script>
/**
 * Component for the display of the entry creation time.
 * @displayName CreationTime
 */

export default {
    name: "CreationTime",
    props: ["object"], //desired parse object with creation time
};
</script>

<style scoped>
</style>
