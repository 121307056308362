import Parse from 'parse/dist/parse.min.js';
import { handleParseError } from '../../../errorhandling';
import router from '../../../router/router';

let localSubscription = undefined;

async function createSubscription(store, clientId) {
	let client_uuid = "";

	let clientQuery = new Parse.Query('Client');
	clientQuery.equalTo("objectId", clientId);
	clientQuery.notEqualTo("deleted", true);
	await clientQuery.find().then(response => client_uuid = response[0].attributes.uuid);

	let all_clients = "";

	let clientQuery2 = new Parse.Query('Client');
	clientQuery2.equalTo("uuid", client_uuid);
	clientQuery2.notEqualTo("deleted", true);
	await clientQuery2.find().then(response => all_clients = response);

	let dataQuery = new Parse.Query('MedicationReport');
	dataQuery.notEqualTo("deleted", true)
	dataQuery.include(["otherFiles", "diagnose", "doctor", "doctor.specialization", "icd_10_code_array"])
	// var clientPointer = {
	// 	__type: 'Pointer',
	// 	className: 'Client',
	// 	objectId: clientId
	// }

	// dataQuery.equalTo("client", clientPointer)
	dataQuery.containedIn("client", all_clients)

	dataQuery.find()
		.then((results) => {
			store.dispatch('medicationReport/set', results)
		})
		.catch((error) => {
			handleParseError(error);
		
		});
		dataQuery.subscribe().then((subscription) => {
		
		localSubscription = subscription;

		subscription.on('create', object => {
			
			store.dispatch('medicationReport/insert', object)
		});
		subscription.on('update', object => {
			
			store.dispatch('medicationReport/replace', object)
		});
		subscription.on('enter', object => {
			
			store.dispatch('medicationReport/insert', object)
		});
		subscription.on('leave', object => {
			
			store.dispatch('medicationReport/delete', object)
		});
		subscription.on('delete', object => {
			
			store.dispatch('medicationReport/delete', object)
		});
		subscription.on('close', () => {
			
		});
		subscription.on('error', error => {
			
		})
	})
}
export default function createReportSubscription() {
	return store => {
		store.subscribe(mutation => {
			if (mutation.type === 'clients/SET_FOCUSED_CLIENT_ID' && (router.currentRoute.name == "Medication" || router.currentRoute.name == "MedicationEntry")) {
				if (localSubscription) {
					localSubscription.unsubscribe();
				}
				if(mutation.payload)
				{
					createSubscription(store, mutation.payload)
				}
			}
		}),
		store.subscribe(mutation => {
			if (mutation.type === 'ADD_LAST_ROUTE' && (router.currentRoute.name == "Medication" || router.currentRoute.name == "MedicationEntry")) {
				if (localSubscription) {
					localSubscription.unsubscribe();
				}
				if (store.state.clients.focusedClientId) {
					createSubscription(store, store.state.clients.focusedClientId)
				}
			}
		})
	}
}
