<template>
    <div class="ml-2 readerContainer" v-if="number && number > 1">
        <b-tooltip label="Klienten" position="is-bottom">
            <div
                style="
                    display: grid;
                    grid-template-columns: repeat(2, 0.1fr);
                    grid-template-areas: 'icon num';
                "
            >
                <div style="grid-area: icon">
                    <b-icon icon="account-multiple"> </b-icon>
                </div>
                <div style="grid-area: num">
                    {{ number }}
                </div>
            </div>
        </b-tooltip>
    </div>
</template>

<script>
/**
 * Icon displaying the nubmer of clients associated with an entry.
 * @displayName ClientsOnEntryIcon
 */

import { mapGetters } from "vuex";

export default {
    name: "ClientsOnEntryIcon",
    props: ["number", "entry"],
	/*
    methods: {
        getClientsOnEntry(index) { //potnetully cause by sorting bug
            return this.numberOfClients[index];
        },
    },
    computed: {
        ...mapGetters({}),
    }, */
};
</script>

<style scoped>
@media (max-width: 450px) {
    .ml-2 {
        margin-left: 0 !important;
        margin-right: 0.5rem !important;
    }
}
</style>
