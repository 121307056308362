<template>
	<b-modal v-model="isActive" has-modal-card @close="closeModal()" :can-cancel="false">
		<div class="modal-card modalCardContainer"
			style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%);">
			<header class="modal-card-head" style="background-color: rgba(40, 40, 40, 1); color: white">
				Neue Diagnose anlegen
			</header>
			<section class="modal-card-body" style="padding: 0.5em; color: black">
				<div class="is-flex is-flex-direction-column">

					<b-field :message="(!$v.diagnoseName.$model) ? 'Bitte geben Sie den Namen der Diagnose ein!' : ''"
						:type="(!$v.diagnoseName.$model) ? 'is-danger' : 'is-success'">
						<template #label>
							<div class="align-content-center" style="display: inline-flex">
								<p>Name</p>
							</div>
						</template>
						<b-input type="text" v-model="diagnoseName" />
					</b-field>
				</div>
			</section>
			<footer class="modal-card-foot footerContainer" style=" color: white">
				<b-button @click="closeModal()" type="is-primary">
					Abbrechen
				</b-button>
				<b-button @click="saveDiagnose()" type="is-primary" v-bind:loading="isLoading"
					v-bind:disabled="$v.diagnoseName.$invalid">
					Speichern
				</b-button>
			</footer>
		</div>
	</b-modal>
</template>

<script>
import { minLength, required } from "vuelidate/lib/validators";
import Parse from "parse/dist/parse.min.js";

export default {
	name: "NewDoctorModal",
	props: ["value"],
	data() {
		return {
			isLoading: false,
			diagnoseName: "",
		};
	},
	validations: {
		diagnoseName: {
			required,
			minLength: minLength(1),
		},
	},
	mounted() { },
	computed: {
		isActive: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit("input", val);
			},
		},
	},
	methods: {
		closeModal() {
			this.diagnoseName = ""
			this.isActive = false;
		},
		async saveDiagnose() {
			this.isLoading = true;
			const parseObject = Parse.Object.extend("MedicationDiagnosis")
			const dataObject = new parseObject()
			dataObject.set("name", this.diagnoseName)
			dataObject.set("deleted", false)
			let emitObject = await dataObject.save()
			this.$emit("emit-new-diagnose", emitObject)
			this.isLoading = false;
			this.closeModal()
		},
	}
}

</script>

<style scoped></style>
  