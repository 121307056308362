<template>
	<div>
		<div ref="entryPanel" v-if="goalsLoaded && focusedClient" style="user-select: none"
			class="scrollable is-flex is-flex-direction-column">
			<router-link class="box entry-box media p-3 m-1" v-for="(goal, index) in goals" :key="index" tag="div"
				:to="{ path: `/module/goal/${goal.id}` }">
				<div class="
                        media-left
                        is-flex
                        is-flex-direction-column
                        is-justify-content-center
                        is-align-items-center
                    ">
					<figure class="image is-48x48">
						<img class="is-rounded object-cover" :src="goal.attributes.picture._url" alt="Zielbild" />
					</figure>
					<template v-if="goalLevel(goal) >= 0">
						<template v-if="goalLevel(goal) === 0">
							<b-icon class="mt-1" size="is-medium" icon="numeric-0-box">
							</b-icon>
						</template>
						<template v-else-if="goalLevel(goal) === 1">
							<b-icon class="mt-1" size="is-medium" icon="numeric-1-box">
							</b-icon>
						</template>
						<template v-else-if="goalLevel(goal) === 2">
							<b-icon class="mt-1" size="is-medium" icon="numeric-2-box">
							</b-icon>
						</template>
						<template v-else-if="goalLevel(goal) === 3">
							<b-icon class="mt-1" size="is-medium" icon="numeric-3-box">
							</b-icon>
						</template>
						<template v-else-if="goalLevel(goal) === 4">
							<b-icon class="mt-1" size="is-medium" icon="numeric-4-box">
							</b-icon>
						</template>
					</template>
				</div>
				<div class="media-content is-flex is-flex-direction-column" style="overflow: hidden">
					<div class="is-flex is-flex-direction-row" v-if="goal.attributes.client.attributes.goal_day">
						<small>{{
							(7 -
								getDay) %
							7
						}}
							Tage {{ 23 - getHour }} Stunden verbleibend </small>
					</div>
					<div class="is-flex is-flex-direction-row" v-else>
						<small> Kein Stichtag angelegt! </small>
					</div>
					<div class="is-font-extra-large is-font-bold">
						{{ goal.attributes.title }}
					</div>
					<div class="
                            is-flex is-flex-direction-row is-items-align-center
                            mx-4
                        " v-if="avgRatings">
						<Progress :value="avgRatings[index]" :max="maxValue(goal)" :tooltip="false" :key="avgRatings[index]"
							indicator format="raw" type="is-success" size="is-small" class="mr-5 mb-5">
							<ProgressStep v-for="(goalStep, index) in goal.attributes
								.ratingSeperators" :key="index" :value="goalStep">
								{{ index + 1 }}
							</ProgressStep>
						</Progress>
					</div>
				</div>
			</router-link>
		</div>
		<div v-else class="is-flex is-justify-content-center">
			Keine Ziele gefunden!
		</div>
	</div>
</template>

<script>
/**
 * Module displaying the goals associated with the focusedClient.
 * @displayName Goal
 */

import { mapGetters } from "vuex";
import Progress from "@/components/progress/Progress";
import ProgressStep from "@/components/progress/ProgressStep";

export default {
	name: "Goal",
	components: { Progress, ProgressStep },
	data() {
		return {
			rate: undefined,
			weekDays: [1, 2, 3, 4, 5, 6, 7],
			isLoading: false,
		};
	},
	updated() { },
	mounted() {
		this.orderWeekDays()
	},
	watch: {
		focusedClient(newValue) {
			this.orderWeekDays()
		}
	},
	computed: {
		...mapGetters({
			goals: "goals/getGoals",
			goalsLoaded: "goals/areGoalsLoaded",
			ratings: "ratings/getRatings",
			focusedClient: "clients/getFocusedClient",
			currentDay: "weekDays/getCurrentDay"
		}),
		getDay() {
			if (this.currentDay == -1) {
				const d = new Date();
				return d.getDay();
			} else {
				return this.currentDay
			}
		},
		getHour() {
			const d = new Date();
			return d.getHours();
		},
		avgRatings() {
			if (this.goals && this.ratings) {
				var avgRatings = [];

				this.goals.forEach((goal) => {
					var dayRatings = [];
					this.weekDays.forEach((day) => {
						var relevantRatings = this.ratings.filter((rating) => {
							return (
								rating.attributes.goal.id === goal.id &&
								rating.attributes.rating_day === day
							);
						});
						var sum = relevantRatings.reduce((total, rating) => {
							return (total += rating.attributes.rating);
						}, 0);

						if (relevantRatings.length > 0) {
							var avg = sum / relevantRatings.length;
							var fixedAvg = Math.round(avg * 1e2) / 1e2;
							dayRatings.push(fixedAvg);
						} else {
							dayRatings.push(0);
						}
					});
					avgRatings.push(
						dayRatings.reduce((total, rating) => {
							return (total += rating);
						}, 0)
					);
				});
				return avgRatings;
			} else if (this.goals) {
				var nullRatings = [];
				this.goals.forEach((goal) => {
					nullRatings.push(0);
				});
				return nullRatings;
			}
			return undefined;
		},
	},
	methods: {
		goalLevel(goal) {
			if (this.ratings) {
				let level = 0
				let filteredRatings = []
				let currentRating = 0
				this.ratings.forEach(element => {
					if (element.attributes.goal.id == goal.id) {
						filteredRatings.push(element)
						currentRating += element.attributes.rating
					}
				});
				if (goal.attributes.ratingSeperators.length > 0) {
					var sortedSeps = goal.attributes.ratingSeperators.sort(function (a, b) {
						return a - b;
					});
					while (sortedSeps[level] / 100 * this.maxValue(goal) <= currentRating) {
						level++;
					}
					return level
				} else {
					return 0
				}
			}
			return undefined;
		},
		maxValue(goal) {
			let focusedRatings = []
			if (this.ratings) {
				this.ratings.forEach(element => {
					if (element.attributes.goal.id == goal.id) {
						focusedRatings.push(element)
					}
				});
				if (focusedRatings) {
					return focusedRatings.length * goal.attributes.maxRating
				}
			}
			return 0;
		},
		orderWeekDays() {
			if (this.focusedClient) {
				let weekDays = [
					{ id: 1, label: "Montag" },
					{ id: 2, label: "Dienstag" },
					{ id: 3, label: "Mittwoch" },
					{ id: 4, label: "Donnerstag" },
					{ id: 5, label: "Freitag" },
					{ id: 6, label: "Samstag" },
					{ id: 7, label: "Sonntag" },
				]

				if (this.focusedClient.attributes.goal_day) {
					let increase;
					let decrease;
					let old_id;
					let currentDay = new Date().getDay()
					weekDays.forEach(element => {
						if (element.id == this.focusedClient.attributes.goal_day) {
							old_id = element.id
							increase = 7 - element.id
							decrease = element.id
						}
					})
		
					if (currentDay < old_id) {
						currentDay = currentDay + increase
					} else if (currentDay > old_id) {
						currentDay = currentDay - decrease
					} else if (currentDay == old_id) {
						currentDay = 7
					}

					weekDays.forEach(element => {
						if (element.id < old_id) {
							element.id = element.id + increase
						} else if (element.id > old_id) {
							element.id = element.id - decrease
						} else if (element.id == old_id) {
							element.id = 7
						}
					})

					weekDays.sort(function (a, b) {
						let x = a.id;
						let y = b.id;

						if (x > y) { return 1; }
						if (x < y) { return -1; }
						return 0;
					});
					this.$store.dispatch("weekDays/setWeekDays", weekDays);
					this.$store.dispatch("weekDays/setCurrDay", currentDay);
				} else {
					this.$store.dispatch("weekDays/setWeekDays", weekDays);
				}
			}
		},
		hasInfo(entryLink, index) {
			return (
				this.numberOfComments(entryLink.attributes.entry) > 0 ||
				entryLink.attributes.entry.attributes.attachment ||
				(entryLink.attributes.readers &&
					entryLink.attributes.readers.length > 0) ||
				this.getClientsOnEntry(index) > 1
			);
		},
		setFocusedEntryLink(entryLink) {
			this.$store.dispatch(
				"setFocusedEntryId",
				entryLink.attributes.entry.id
			);
			this.$store.dispatch(
				"entrylinks/setFocusedEntryLinkId",
				entryLink.id
			);
		},
		getClientsOnEntry(index) {
			return this.numberOfClients[index];
		},
		matchesSearchQuery(entry) {
			if (this.entrySearchQuery.length > 0) {
				let entryText = entry.attributes.title;
				entry.attributes.attributes.forEach(
					(attr) => (entryText += attr.attributes.name)
				);
				entryText += entry.attributes.type.attributes.name;
				entryText +=
					entry.attributes.author.attributes.dependency.attributes
						.first_name;
				entryText += " ";
				entryText +=
					entry.attributes.author.attributes.dependency.attributes
						.last_name;
				entryText += entry.createdAt.toLocaleDateString("de-DE", {
					year: "numeric",
					month: "2-digit",
					day: "2-digit",
				});
				return entryText
					.toLowerCase()
					.includes(this.entrySearchQuery.toLowerCase());
			}
			return true;
		},
	},
};
</script>

<style scoped>
.object-cover {
	object-fit: cover;
	height: 100%;
	width: 100%;
}

.white-text {
	color: white;
}

.entry-box {
	cursor: pointer;
}

.entry-box:hover {
	background-color: whitesmoke;
}

.scrollable {
	overflow: auto;
	height: 100%;
	width: 100%;
	padding-bottom: 1.5rem;
}

.article {
	width: 100%;
	border-radius: 0.5rem;
}

.entry-box-entry {
	margin-right: 0.5em;
	margin-left: 0.5em;
	padding-bottom: 15px;
	text-align: center;
}

@media (max-width: 1026px) {
	.scrollable {
		overflow: auto;
		height: 100%;
		width: 100%;
	}
}

@media (max-width: 769px) {

	.tabs:not(:last-child),
	.pagination:not(:last-child),
	.message:not(:last-child),
	.level:not(:last-child),
	.breadcrumb:not(:last-child),
	.block:not(:last-child),
	.title:not(:last-child),
	.subtitle:not(:last-child),
	.table-container:not(:last-child),
	.table:not(:last-child),
	.progress:not(:last-child),
	.progress-wrapper.is-not-native:not(:last-child),
	.notification:not(:last-child),
	.content:not(:last-child),
	.box:not(:last-child) {
		margin-bottom: 0;
	}

	.scrollable {
		height: 100%;
		width: 95vw;
	}

	.entry-box {
		display: flex;
		width: 96%;
	}
}

@media (max-width: 400px) {
	div.media-left {
		margin: 0.1em 0 0 0;
	}
}

@media (max-width: 450px) {
	.authorTagContainer {
		display: flex;
		flex-direction: column;
		align-items: start;
	}

	.iconContainer {
		justify-content: start !important;
		display: flex !important;
		flex-direction: row !important;
	}
}
</style>
