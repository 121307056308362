<template>
    <div
        class="b-slider-thumb-wrapper"
        :class="{ 'is-dragging': false, 'has-indicator': indicator }"
        :style="wrapperStyle"
    >
        <div
            class="b-slider-thumb"
            :tabindex="disabled ? false : 0"
            v-bind="$attrs"
        >
            <span v-if="indicator">{{ formattedValue }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "ProgressIndicator",
    components: {},
    inheritAttrs: false,
    props: {
        value: {
            type: Number,
            default: 0,
        },
        type: {
            type: String,
            default: "",
        },
        tooltip: {
            type: Boolean,
            default: true,
        },
        indicator: {
            type: Boolean,
            default: false,
        },
        customFormatter: Function,
        format: {
            type: String,
            default: "raw",
            validator: (value) => {
                return ["raw", "percent"].indexOf(value) >= 0;
            },
        },
        locale: {
            type: [String, Array],
            default: () => {
                return "de-DE";
            },
        },
        tooltipAlways: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            startX: 0,
            startPosition: 0,
            newPosition: null,
            oldValue: this.value,
        };
    },
    computed: {
        disabled() {
            return this.$parent.disabled;
        },
        max() {
            return this.$parent.max;
        },
        min() {
            return this.$parent.min;
        },
        step() {
            return this.$parent.step;
        },
        precision() {
            return this.$parent.precision;
        },
        currentPosition() {
            return `${
                ((this.value - this.min) / (this.max - this.min)) * 100
            }%`;
        },
        wrapperStyle() {
            return { left: this.currentPosition };
        },
        formattedValue() {
            if (typeof this.customFormatter !== "undefined") {
                return this.customFormatter(this.value);
            }

            if (this.format === "percent") {
                return new Intl.NumberFormat(this.locale, {
                    style: "percent",
                }).format((this.value - this.min) / (this.max - this.min));
            }

            return new Intl.NumberFormat(this.locale).format(this.value);
        },
    },
    methods: {
        setPosition(percent) {
            if (percent === null || isNaN(percent)) return;
            if (percent < 0) {
                percent = 0;
            } else if (percent > 100) {
                percent = 100;
            }
            const stepLength = 100 / ((this.max - this.min) / this.step);
            const steps = Math.round(percent / stepLength);
            let value =
                ((steps * stepLength) / 100) * (this.max - this.min) + this.min;
            value = parseFloat(value.toFixed(this.precision));
            this.$emit("input", value);
            if (!this.dragging && value !== this.oldValue) {
                this.oldValue = value;
            }
        },
    },
};
</script>

<style scoped>
	.b-slider .b-slider-thumb-wrapper.has-indicator .b-slider-thumb
	{
		padding: 12px 6px; 
		font-size: 13px;
	}
</style>