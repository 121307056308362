<template>
    <div
        style="
            height: 100%;
            width: 100%;
            margin: 0 auto;
            overflow: hidden;
            position: relative;
        "
        @click="clickedApp"
    >
        <mobile-container style="height: 100%; width: 100%" v-if="mobile" />

        <div
            class="is-flex is-flex-direction-row is-flex-align-items-center"
            style="height: 100%; width: 100%"
        >
            <DiarySidePanel v-if="user" style="width: 25%"></DiarySidePanel>
			<router-view style="width: 75%" class="pt-3" />
        </div>
    </div>
</template>

<script>
/**
 * Container containing the main router-view of the application. 
 * @displayName Module
 */

import DiarySidePanel from "@/components/bars/sideBar/DiarySideBar.vue";
import MobileContainer from "./MobileContainer";
import { mapGetters } from "vuex";

export default {
    name: "Module",
    computed: {
        user() {
            return this.$store.state.currentUser;
        },
        ...mapGetters({
            mobile: "isMobile",
        }),
    },
    methods: {
        clickedApp(event) {
            if (document.activeElement.className !== "input is-rounded") {
                this.$store.state.openedKeyBoard = false;
            }
        },
    },
    components: {
        MobileContainer,
        DiarySidePanel,
    },
};
</script>

<style scoped>
html {
    height: 100%;
    width: 100vw;
    overflow: hidden !important;
}

body {
    height: 100%;
    width: 100%;
}

.columns {
    height: 100%;
}

.show-enter-active,
.show-leave-enter {
    transform: translateX(0);
    transition: all 0.3s linear;
}

.show-enter,
.show-leave-to {
    transform: translateX(100%);
}

@media (max-width: 769px) {
    .hideBarOnMobile {
        display: none;
    }
}
</style>
