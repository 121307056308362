import Parse from 'parse/dist/parse.min.js';
import { handleParseError } from '@/lib/errorhandling';
import router from '../../../router/router';

let localSubscription = undefined;

function createSubscription(store) {
	let attributeQuery = new Parse.Query('EntryAttributes');
	attributeQuery
		.limit(1000)
		.find()
		.then((results) => {
			store.dispatch('attributes/set', results)
		
		})
		.catch((error) => {
			handleParseError(error);
		
		});
	attributeQuery.subscribe().then((subscription) => {
		
		localSubscription = subscription;

		subscription.on('create', object => {
			
			store.dispatch('attributes/insert', object)
		});
		subscription.on('update', object => {
			
			store.dispatch('attributes/replace', object)
		});
		subscription.on('enter', object => {
		
			store.dispatch('attributes/insert', object)
		});
		subscription.on('leave', object => {
			
			store.dispatch('attributes/delete', object)
		});
		subscription.on('delete', object => {
		
			store.dispatch('attributes/delete', object)
		});
		subscription.on('close', () => {
			
		});
		subscription.on('error', error => {
			
		})
	})
}

export default function createAttributeSubscription() {
	return store => {
		store.subscribe(mutation => {
			//1: Check - Wird die richtige Mutation ausgelöst? Diese wird im Router bei jedem Routing-Vorgang ausgelöst.
			if (mutation.type === 'ADD_LAST_ROUTE') {
				//2: Check - Bin ich aktuell im Tagebuch?
				if (router.currentRoute.name == "Diary" || router.currentRoute.name == "EntryDetail" || 
					router.currentRoute.name == "EntryInput" || router.currentRoute.name == "EntryEdit") {
					//3: Check - Prüfe, ob bereits eine lokale Subscription vorliegt.
					if (!localSubscription) {
						createSubscription(store)
					}
				}
			}
		})
	}
}
