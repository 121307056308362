const state = {
	categoryInput: "",
	searchTerm: "",
	selectedCategory: undefined,
	startDate: undefined,
	endDate: undefined,
};

const getters = {
	getCategoryInput(state) {
		return state.categoryInput
	},
	getSearchTerm(state) {
		return state.searchTerm
	},
	getSelectedCategory(state) {
		return state.selectedCategory
	},
	getStartDate(state) {
		return state.startDate
	},
	getEndDate(state) {
		return state.endDate
	},
};

const mutations = {
	SETCATEGORY(state, objects) {
		state.categoryInput = objects
	},
	SETSEARCHTERM(state, object) {
		state.searchTerm = object
	},
	SETSELECTEDCATEGORY(state, object) {
		state.selectedCategory = object
	},
	SETSTARTDATE(state, objects) {
		state.startDate = objects
	},
	SETENDDATE(state, object) {
		state.endDate = object
	}
};

const actions = {
	setCategoryInput({ commit }, objects) {
		commit('SETCATEGORY', objects)
	},
	setSearchTerm({ commit }, object) {
		commit("SETSEARCHTERM", object)
	}, 
	setSelectedCategory({ commit }, objects) {
		commit('SETSELECTEDCATEGORY', objects)
	},
	setStartDate({ commit }, object) {
		commit("SETSTARTDATE", object)
	}, 
	setEndDate({ commit }, object) {
		commit("SETENDDATE", object)
	}, 
	clearFilterData({commit}){
		commit('SETCATEGORY', "")
		commit("SETSEARCHTERM", "")
		commit('SETSELECTEDCATEGORY', undefined)
		commit("SETSTARTDATE", undefined)
		commit("SETENDDATE", undefined)
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
