<template>
    <div
        class="is-flex is-flex-direction-row is-align-items-center"
        style="user-select: none; height: 100%; width: 100%"
    >
        <div class="mx-3 is-flex is-align-items-center">
            <user-image class="userImageContainer" />
        </div>
        <div class="p-2 is-flex-grow-1">
            <b-input
                placeholder="Klienten suchen..."
                type="search"
                icon="magnify"
                v-model="searchQuery"
                autocomplete="off"
                expanded
                :size="isMobile() ? 'is-small' : ''"
            />
        </div>
    </div>
</template>

<script>
/**
 * TopBar user section displaying the signed-in user.
 * @displayName UserHeader
 */

import UserImage from "./UserImage";
import NotificationTag from "@/components/tags/NotificationTag";

export default {
    name: "UserHeader",
    data() {
        return {};
    },
    computed: {
        searchQuery: {
            set(searchQuery) {
                this.$store.dispatch("setSearchQuery", searchQuery);
            },
            get() {
                // Or remove mapState and use this.$store.state.values.example
                return this.$store.state.searchQuery;
            },
        },
    },
    methods: {
        isMobile() {
            return this.$store.getters.isMobile;
        },
    },
    components: {
        UserImage,
    },
};

//rgb(185, 205, 216)
</script>

<style scoped>
::v-deep span.is-left {
    display: flex;
    justify-content: center;
    align-items: center;
}
::v-deep .input {
    border-radius: 6px;
}


.position {
    position: relative;
    bottom: -5px;
    right: -5px;	
	z-index: 9;
}

@media (max-width: 769px) {
    ::v-deep .icon {
        display: flex;
        justify-content: start;
        align-items: start;
        padding-left: 0.25rem;
    }
}

@media (max-width: 400px) {
    .userImageContainer img {
        width: 90%;
        height: 80%;
        align-self: center;
    }

    .userImageContainer span {
        margin-left: 4px;
        min-width: 12px;
        width: 40%;
        height: 50%;
    }
}
</style>
