const state = {
    data: [],
};

const getters = {
    getOptionalAttributeConfigsUnfiltered(){
        return state.data
    }
};

const mutations = {
    SET(state, objects) {
        state.data = objects
    },
    INSERT(state, object) {
        state.data.push(object)
    },
    DELETE(state, object) {
        state.data = state.data.filter((elem) => {
            return elem.id != object.id
        })
    },
    REPLACE(state, object) {
        const oldObject = state.data.filter(item => item.id == object.id)
        const index = state.data.indexOf(oldObject[0]);
        if (index > -1) {
            state.data.splice(index, 1, object);
        }
    },
};

const actions = {
    set({ commit }, objects) {
        commit('SET', objects)
    },
    insert({ commit }, object) {
        commit('INSERT', object)
    },
    delete({ commit }, object) {
        commit('DELETE', object)
    },
    replace({ commit }, object) {
        commit('REPLACE', object)
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};