
<template>
	<div class="box entry-box p-0 mt-1 mb-1">
		<article class="media p-3 article">
			<div class="media-left">
				<AuthorImage v-if="autor" :author="autor" />
				<AuthorImage v-else :author="undefined" />
			</div>
			<div class="media-content" style="overflow: hidden">
				<div class="mb-0">
					<div class="mt-0 mb-0">
						<div class="
								pt-0
								authorTagContainer
								is-font-medium
							">
							<small v-if="entryLink.attributes.medication">
								{{
									autor.attributes?.dependency.attributes.first_name
									+
									" " +
									autor.attributes?.dependency.attributes.last_name
								}},
								<time>
									Einnahme vom {{
										entryLink.attributes?.medication.attributes?.medication.attributes?.startDate.toLocaleDateString("de-DE",
											{
												year: "numeric",
												month: "2-digit",
												day: "2-digit",
											}) +
										" bis zum " +
										entryLink.attributes?.medication.attributes?.medication.attributes?.endDate.toLocaleDateString("de-DE",
											{
												year: "numeric",
												month: "2-digit",
												day: "2-digit",
											})
									}}</time>
								<time v-if="entryLink.attributes?.medication.attributes?.medication.attributes?.dropDate">,
									abgesetzt am
									{{
										entryLink.attributes?.medication.attributes?.medication.attributes?.dropDate.toLocaleDateString("de-DE",
											{
												year: "numeric",
												month: "2-digit",
												day: "2-digit",
											})
									}}</time>
								<time
									v-if="entryLink.attributes?.medication.attributes?.medication.attributes?.expirationDate">,
									haltbar
									bis
									{{
										entryLink.attributes?.medication.attributes?.medication.attributes?.expirationDate.toLocaleDateString("de-DE",
											{
												year: "numeric",
												month: "2-digit",
												day: "2-digit",
											})
									}}</time>

							</small>
							<small v-if="entryLink.attributes.instantMedication">
								{{
									autor.attributes?.dependency.attributes.first_name
									+
									" " +
									autor.attributes?.dependency.attributes.last_name
								}}

							</small>
							
							<ColoredTag class="mr-1" :tag-name="entryLink.attributes?.date.toLocaleDateString('de-DE', {
										year: 'numeric', month: '2-digit', day: '2-digit',
									})" :tag-color="'#313131'" :is-right="true" />
							<ColoredTag class="mr-1" :tag-name="entryLink.attributes?.time" :tag-color="'#313131'"
								:is-right="true" />
							<ColoredTag v-if="entryLink.attributes?.medication" class="mr-1"
								:tag-name="getAmountString(entryLink.attributes?.amount) + ' ' + entryLink.attributes?.medication.attributes?.medication.attributes?.medication.attributes?.medicationUnit.attributes.symbol"
								:tag-color="'#313131'" :is-right="true" />
							<ColoredTag v-if="entryLink.attributes?.instantMedication" class="mr-1"
								:tag-name="getAmountString(entryLink.attributes?.amount) + ' ' + entryLink.attributes?.instantMedication.attributes?.medication.attributes?.medicationUnit.attributes.symbol"
								:tag-color="'#313131'" :is-right="true" />
							<ColoredTag v-if="entryLink.attributes?.externalMedication" class="mr-1" tag-name="Fremdvergabe" :tag-color="'#313131'"
								:is-right="true" />
							<ColoredTag :key="'art_'+entryLink.attributes?.medication.attributes?.medicationPlan.attributes?.art"
								v-if="entryLink.attributes?.medication"
								class="mr-1"
								:tag-name="getMedicationPlanName(entryLink)"
								:tag-color="'#313131'" :is-right="true" />

							<ColoredTag v-if="entryLink.attributes?.instantMedication" class="mr-1"
								:tag-name="'Bedarfsmedikation'" :tag-color="'#313131'" :is-right="true" />
						</div>
					</div>
					<p v-if="entryLink.attributes.medication" class="is-font-extra-large is-font-bold mb-1">
						M{{ entryLink.attributes?.medication.attributes?.medication.attributes?.number }}: {{
							entryLink.attributes?.medication.attributes?.medication.attributes?.medication.attributes?.displayName
						}}
					</p>
					<p v-if="entryLink.attributes.instantMedication" class="is-font-extra-large is-font-bold mb-1">
						BM{{ entryLink.attributes?.instantMedication.attributes?.number }}: {{
							entryLink.attributes?.instantMedication.attributes?.medication.attributes?.displayName
						}}
					</p>

					<p>
						<template v-if="entryLink.attributes?.medication">
							<small>{{ entryLink.attributes?.medication.attributes?.medicationPlan.attributes?.hint
							}}</small>
						</template>

					</p>
				</div>


			</div>
		</article>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import AuthorImage from "@/components/helper/AuthorImage";
import MedicationIcon from "@/components/icons/medication/MedicationIcon";
import ColoredTag from "@/components/tags/ColoredTag";

export default {
	name: "MedicationLogEntriesData",
	props: ["entryLink"],
	components: {
		AuthorImage,
		ColoredTag,
		// MedicationIcon
	},
	data() {
		return {

		};
	},
	beforeCreate() { },
	mounted() {
	},
	updated() {

	},
	watch: {
	},
	computed: {
		autor: function () {
			if (this.entryLink.attributes.givingUser) {
				return this.entryLink.attributes.givingUser;
			} else if (this.entryLink.attributes?.medication) {
				return this.entryLink.attributes?.medication.attributes?.medication.attributes?.contactUser;
			} else {
				return undefined;
			}
		}
	},
	methods: {
		getAmountString(value) {
			return String(value).replace(".", ",");
		},
		getMedicationPlanName(entry)
		{
			if(entry.attributes?.medication.attributes?.medicationPlan.attributes?.art == 1)
			{
				return 'Standard-Plan';
			}else if(entry.attributes?.medication.attributes?.medicationPlan.attributes?.art == 2)
			{
				return 'Zu besonderen Zeiten';
			}else if(entry.attributes?.medication.attributes?.medicationPlan.attributes?.art == 3)
			{
				return 'Selbstmedikation';
			}else
			{
				return '';
			}
			
		}
	},
}
</script>
<style scoped></style>