export default class Graph{

	constructor() {
		this.nodes = []
		this.edges = []
	}

	addNode(node){
		this.nodes.push(node)
	}

	addEdge(edge){
		this.edges.push(edge)
	}

	getNodes(){
		return this.nodes
	}

	getEdges(){
		return this.edges
	}

	getUnConnectedNodes(){
		let unConnectedNodes = this.nodes
		for(const edge of this.edges){
			unConnectedNodes = unConnectedNodes.filter(node => edge.getSource().id !== node.id && edge.getTarget().id !== node.id)
		}
		return unConnectedNodes
	}

	getConnectedNodes(){
		return this.nodes.filter(node =>{
			const unConnectedNodeIds = this.getUnConnectedNodes().map(n => n.id)
			return !unConnectedNodeIds.includes(node.id)
		})
	}

	getRootNodes(){
		let rootNodes = this.getConnectedNodes()
		for(const edge of this.edges){
			rootNodes = rootNodes.filter(node => edge.getTarget().id !== node.id)
		}
		return rootNodes
	}

	getTargetNodes(node){
		const targetNodes = []
		for(const edge of this.edges){
			if(edge.getSource().id === node.id){
				targetNodes.push(edge.getTarget())
			}
		}
		return targetNodes.sort((c1,c2) => c1.createdAt.getTime() - c2.createdAt.getTime()) //sorting for deep layers of the graph
	}

	getNodeIds(){
		return this.nodes.map(n => n.id)
	}

	isEdgeInNode(edge){
		return this.edges.find(e => e.getSource().id === edge.getSource().id && e.getTarget().id === edge.getTarget().id)
	}

	isNodeInGraph(node){
		return this.getNodeIds().includes(node.id)
	}
}
