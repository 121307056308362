<template>
    <div class="hero is-fullheight is-light" style="width: 100%">
        <div
            class="
                hero-head
                is-flex is-justify-content-center is-align-content-center
            "
        >
            <figure class="image mt-3">
                <img
                    class="logo-color"
                    :src="require('@/assets/logo/' + this.app_logo)"
                />
            </figure>
        </div>
        <div class="hero-body container">
            <div class="box">
                <p class="title is-4">Anmeldung</p>
                <b-field label="E-Mail">
                    <b-input
                        id="email"
                        type="email"
                        @keydown.native.enter="login"
                        v-on:input="$v.loginRequest.email.$touch"
                        v-model="loginRequest.email"
                    >
                    </b-input>
                </b-field>

                <b-field label="Passwort">
                    <b-input
                        id="password"
                        type="password"
                        v-on:input="$v.loginRequest.password.$touch"
                        @keydown.native.enter="login"
                        v-model="loginRequest.password"
                        password-reveal
                    >
                    </b-input>
                </b-field>

                <b-button
                    id="login"
                    type="is-primary"
                    v-on:click="login"
                    v-bind:loading="isLoading"
                    v-bind:disabled="$v.loginRequest.$invalid"
                    expanded
                    >Anmelden</b-button
                >
            </div>
        </div>
        <div class="hero-foot">
            <div style="height: 200px; width: 100%">
                <about-bar />
            </div>
        </div>
    </div>
</template>

<script>
/**
 * Module for user login.
 * @displayName Login
 */

import { required, email } from "vuelidate/lib/validators";
import AboutBar from "@/components/bars/AboutBar";
import { APP_LOGO } from "@/configuration/AppConfigurations";

export default {
    name: "Login",
    data() {
        return {
            loginRequest: {
                email: "",
                password: "",
            },
            isLoading: false,
			app_logo: APP_LOGO
        };
    },
    validations: {
        loginRequest: {
            email: { required, email },
            password: { required },
        },
    },
    computed: {},
	mounted() {
		// if(this.$route.params.data){
		// 	if(this.$route.params.data == true){
		// 		location.reload(true)
		// 	}
		// }
	},
    methods: {
        async login() {
            if (
                this.loginRequest &&
                this.loginRequest.email &&
                this.loginRequest.password
            ) {
                this.isLoading = true;
                try {
                    const user = await this.$store.dispatch(
                        "loginUser",
                        this.loginRequest
                    );
                    this.$buefy.toast.open({
                        message: "Nutzer angemeldet!",
                        type: "is-success",
                        position: "is-bottom",
                    });
                    await this.$router.push("/");
                } catch (err) {
                    if (err.message === "User was deleted!") {
                        this.$buefy.toast.open({
                            message: "Nutzer wurde gelöscht",
                            type: "is-danger",
                            position: "is-bottom",
                        });
                    }
                    if (err.message === "User is External!") {
                        this.$buefy.toast.open({
                            message:
                                "Externe Personen haben keine Zugriffsberechtigung",
                            type: "is-danger",
                            position: "is-bottom",
                        });
                    }
                    if (
                        !(err.message === "User was deleted!") &&
                        !(err.message === "User is External!")
                    ) {
                        console.error(err);
                        this.$buefy.toast.open({
                            message:
                                "Falsche Email-Adresse oder falsches Passwort!",
                            type: "is-danger",
                            position: "is-bottom",
                        });
                    }
                }
                this.isLoading = false;
            } else {
                this.$buefy.toast.open({
                    message: "Fehler: Daten nicht vollständig!",
                    type: "is-danger",
                    position: "is-bottom",
                });
            }
        },
    },
    components: { AboutBar },
};
</script>

<style scoped>

</style>
